<form [formGroup]="myFormGroup">
    <!------ Title ------->
    <coreui-section-header header="Single Rows (i.e., WHERE clause)"></coreui-section-header>
    <div class="container_section">
        <div *ngFor="let n of singleRowsFilterContainers" class="container_area">
            <!------ Container ------->
            <div class="grid_container">
                <!------ Add Button ------->
                <div class="grid_tall">
                    <button type="button" class="add_remove_button" coreUIButton="icon" withBorder
                        (click)="onAdd(SINGLEROWS, n.ID)" coreUITooltip="Add Container"><i
                            coreUIIcon="add"></i></button>
                </div>
                <!------- Droppable Container ------->
                <app-droppablefilter [containerID]="n.ID" [containerType]="SINGLEROWS"
                    [filtercontainer]="n"></app-droppablefilter>
                <!------ Remove Button ------->
                <div class="grid_tall">
                    <button type="button" class="add_remove_button" coreUIButton="icon" withBorder
                        (click)="onRemove(SINGLEROWS, n.ID)" coreUITooltip="Remove Container"><i
                            coreUIIcon="subtract"></i></button>
                </div>
            </div>
            <!------ Logical Operator ------->
            <div *ngIf="n.ID < singleRowsFilterContainers.length - 1" class="operator_spacing">
                <coreui-select type="secondary" [formControlName]="'filter_operator_' + SINGLEROWS + '_'+ n.ID"
                    placeholder="AND" coreUIFixedWidth="extraShort">
                    <coreui-option [value]="AND" (select)="onSelect(AND, SINGLEROWS, n.ID)">AND</coreui-option>
                    <coreui-option [value]="OR" (select)="onSelect(OR, SINGLEROWS, n.ID)">OR</coreui-option>
                </coreui-select>
            </div>
        </div>
    </div>
    <!-- ###############################################################################################################-->
    <!------ Title ------->
    <coreui-section-header header="Group Rows (i.e., HAVING clause)"></coreui-section-header>
    <div class="container_section">
        @for(n of groupRowsFilterContainers; track n.Filters){

        <!-- <div *ngFor="let n of  class="container_area"> -->
        <!------ Container ------->
        <div class="grid_container">
            <!------ Add Button ------->
            <div class="grid_tall">
                <button type="button" class="add_remove_button" coreUIButton="icon" withBorder
                    (click)="onAdd(GROUPROWS, n.ID)" coreUITooltip="Add Container"><i coreUIIcon="add"></i></button>
            </div>
            <!------- Droppable Container ------->
            <app-droppablefilter [containerID]="n.ID" [containerType]="GROUPROWS"
                [filtercontainer]="n"></app-droppablefilter>
            <!------ Remove Button ------->
            <div class="grid_tall">
                <button type="button" class="add_remove_button" coreUIButton="icon" withBorder
                    (click)="onRemove(GROUPROWS, n.ID)" coreUITooltip="Remove Container"><i
                        coreUIIcon="subtract"></i></button>
            </div>
        </div>
        <!------ Logical Operator ------->
        <div *ngIf="n.ID < groupRowsFilterContainers.length - 1" class="operator_spacing">
            <coreui-select type="secondary" [formControlName]="'filter_operator_' + GROUPROWS + '_'+ n.ID"
                placeholder="AND" coreUIFixedWidth="extraShort">
                <coreui-option [value]="AND" (select)="onSelect(AND, GROUPROWS, n.ID)">AND</coreui-option>
                <coreui-option [value]="OR" (select)="onSelect(OR, GROUPROWS, n.ID)">OR</coreui-option>
            </coreui-select>
        </div>
        }
        <!-- </div> -->
    </div>
</form>