<coreui-modal modalWidth="90" #erdModal>
    <ng-template #titleTemplate>
        <div class="titleTemplate">
            <span class="Modal-titleIcon"></span>ERD for {{activeERDTable}}
        </div>
    </ng-template>
    <ng-template #titleCloseButtonTemplate>
        <button type="button" aria-label="Close" (click)="closeErdModal()" coreUIButton="icon-large"><i
                coreUIIcon="close"></i></button>
    </ng-template>
    <ng-template #contentTemplate>
        <div class="inline-control">
            <coreui-radio-group [formGroup]="myFormGroup" label="Select Depth" orientation="horizontal">
                <coreui-form-field label="One">
                    <input type="radio" coreUIRadio value="1" name="inlineRadio" formControlName="inlineRadio" />
                </coreui-form-field>
                <coreui-form-field label="Two">
                    <input type="radio" coreUIRadio value="2" name="inlineRadio" formControlName="inlineRadio" />
                </coreui-form-field>
                <!-- <coreui-form-field label="Three">
                    <input type="radio" coreUIRadio value="3" name="inlineRadio" formControlName="inlineRadio" />
                </coreui-form-field> -->
                <coreui-form-error [shown]="!myFormGroup.valid"></coreui-form-error>
            </coreui-radio-group>
        </div>
        <div class="inline-control">
            <i coreUIIcon="warning"></i>Due to the color limitations, only up to 18 joins are displayed.
        </div>
        <app-sankey *ngIf="activeERDTable!==''" [activeTable]="activeERDTable" [depth]="adjustedDepth"
            (newTableName)="launchERDModal($event)"></app-sankey>
    </ng-template>
    <ng-template #buttonsTemplate>
        <button type="button" coreUIButton="secondary" (click)="closeErdModal()" coreUITrapFocusInitial>Close</button>
    </ng-template>
</coreui-modal>
<!-- <app-d3></app-d3> -->