<coreui-modal modalWidth="60" #basicModal>
    <ng-template #titleTemplate>
        {{action}} Folder
    </ng-template>
    <ng-template #titleCloseButtonTemplate>
        <button type="button" aria-label="Close" (click)="closeBasicModal()" coreUIButton="icon-large">
            <i coreUIIcon="close"></i></button>
    </ng-template>
    <ng-template #contentTemplate>
        <!---------- Content Here ---------->
        <form [formGroup]="myFormGroup">
            <div class="block_control">
                <coreui-form-field label="Path" class="inline_control">
                    <!--------- Path -------->
                    <coreui-select placeholder="Select" data-qa="select-search" (blur)="onBlur($event)"
                        [searchable]="true" formControlName="selectPath" (searchValueChange)="onSearchChange($event)"
                        coreUIFixedWidth="short">
                        <coreui-option *ngFor="let item of filteredItems | orderby:'name':'ASC'" [value]="item.Name"
                            (blur)="onBlur($event)">
                            {{item.Name}}
                        </coreui-option>
                        <span *ngIf="filteredItems.length === 0" class="no-results text-style-3">
                            No results found
                        </span>
                    </coreui-select>
                    <coreui-form-error>{{errMsgPath}}</coreui-form-error>
                </coreui-form-field>
                <coreui-form-field label="Name" class="inline_control">
                    <input coreUIInput name="inputDefault" formControlName="inputName" (blur)="onBlur($event)"
                        (input)="onKeyPress($event)" />
                    <coreui-form-error>{{errMsgName}}</coreui-form-error>
                </coreui-form-field>
            </div>
            <div class="block_control">
                <coreui-form-field label="Description" class="inline_control_wide">
                    <textarea rows="3" coreUIInput formControlName="textareaDescription" (blur)="onBlur($event)"
                        (input)="onKeyPress($event)"></textarea>
                    <coreui-form-error>{{errMsgDescription}}</coreui-form-error>
                </coreui-form-field>
            </div>
        </form>
    </ng-template>
    <ng-template #buttonsTemplate>
        <coreui-load-mask [active]="isFolderValidating" class="block_space"></coreui-load-mask>
        <button type="button" coreUIButton="primary" (click)="saveBasicModal()"
            [disabled]="!isFolderValid">Save</button>
        <button type="button" coreUIButton="secondary" (click)="closeBasicModal()"
            coreUITrapFocusInitial>Cancel</button>
    </ng-template>
</coreui-modal>
<!--------------- Toast --------------->
<coreui-notification notificationStyle="error" #infoError [messageDuration]="3000">
    {{msg}}
</coreui-notification>
<coreui-notification notificationStyle="success" #infoSuccess [messageDuration]="3000">
    {{msg}}
</coreui-notification>