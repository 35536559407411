import { Component, OnInit } from '@angular/core';
import { CoreUIModule } from '@epsilon/core-ui';
import { OktaService } from '../../service/okta.service';
import { TABLEAU_SITE, TABLEAU_SITE_ROLE, USER_ROLE } from '../../service/datamodel';
import { SitefilterPipe } from '../../pipe/sitefilter.pipe';
import { OrderbyPipe } from '../../pipe/orderby.pipe';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [CoreUIModule, SitefilterPipe, OrderbyPipe],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  public emailaddress: string;
  public username: string;
  public tableauUsername: string;
  public siteaccess: string;
  public userRole: USER_ROLE;
  public usersites: TABLEAU_SITE[];

  constructor(private userservice: UserService, private oktaservice: OktaService) {
    this.userRole = USER_ROLE.POWERUSER
  }

  ngOnInit(): void {
    this.oktaservice.email$.subscribe(x => {
      this.emailaddress = x
    })
    this.oktaservice.name$.subscribe(x => {
      this.username = x
    })
    this.userservice.usersites.subscribe(x => {
      this.usersites = x
      this.userRole = this.userservice.getMaxUserRole()
    })
  }

}
