import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CoreUIModule, TreeProgressiveComponent } from '@epsilon/core-ui';
import { OBJ, HIERARCHY } from '../../service/datamodel';
import { CommonModule } from '@angular/common';
import { DraggableobjectComponent } from "../draggableobject/draggableobject.component";
import { OrderbyPipe } from '../../pipe/orderby.pipe';
import { MetadataService } from '../../service/metadata.service';

@Component({
  selector: 'app-subjecthierarchy',
  standalone: true,
  imports: [CoreUIModule, CommonModule, DraggableobjectComponent, OrderbyPipe],
  templateUrl: './subjecthierarchy.component.html',
  styleUrl: './subjecthierarchy.component.scss'
})
export class SubjecthierarchyComponent implements OnInit, AfterViewInit {
  @ViewChild('myProgressiveTree') myProgressiveTree: TreeProgressiveComponent
  public items: HIERARCHY[];

  constructor(private metadataservice: MetadataService) {
  }

  ngOnInit() {

    this.metadataservice.isMetaObjsLoading.subscribe(x => {
      if (!x)
        this.items = this.metadataservice.getHierachies()
    })

  }

  ngAfterViewInit(): void {
  }

  public onActiveChange(item: OBJ) {
    item.IsHierarchy = true
  }

  // public onPaneChange(event) {
  //   console.log(event.pane.header)
  // }

}
