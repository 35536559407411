import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CoreUIModule, ModalComponent, NotificationComponent } from '@epsilon/core-ui';
import { Clipboard } from '@angular/cdk/clipboard';
import { AdminService } from '../../service/admin.service';

@Component({
  selector: 'app-sqlmodal',
  standalone: true,
  imports: [CoreUIModule],
  templateUrl: './sqlmodal.component.html',
  styleUrl: './sqlmodal.component.scss'
})
export class SqlmodalComponent implements OnInit {
  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;
  @ViewChild('basicModalButton', { read: ElementRef })
  public basicModalElementRef: ElementRef<HTMLButtonElement>;
  @ViewChild('infoCopy', { static: true })
  private infoCopy: NotificationComponent;
  public contentSQL: string

  public reactiveForm = new FormGroup({
    textareaDefault: new FormControl()
  });


  constructor(private clipboard: Clipboard, private adminservice: AdminService) {
  }

  ngOnInit(): void {
    this.adminservice.sql.subscribe(x => this.contentSQL = x)
    this.adminservice.xPlan.subscribe(x => this.contentSQL = x)

  }

  public async closeBasicModal(): Promise<void> {
    await this.basicModal.hide();
  }

  public async launchBasicModal(type: string): Promise<void> {
    await this.basicModal.show(this.basicModalElementRef);
  }

  public copySQL() {
    this.clipboard.copy(this.contentSQL);
    this.infoCopy.show()
  }


}
