<coreui-modal modalWidth="60" #basicModal>
    <ng-template #titleTemplate>
        {{action}} Table
    </ng-template>
    <ng-template #titleCloseButtonTemplate>
        <button type="button" aria-label="Close" (click)="closeBasicModal()" coreUIButton="icon-large">
            <i coreUIIcon="close"></i></button>
    </ng-template>
    <ng-template #contentTemplate>
        <!---------- Content Here ---------->
        <form [formGroup]="myFormGroup2">
            <div class="block_control">
                <coreui-segmented-control-group label="Type" formControlName="singleSelection" class="inline_control">
                    <button type="button" [coreUISegmentedButton]="REGULAR">{{REGULAR}}</button>
                    <button type="button" [coreUISegmentedButton]="ALIAS">{{ALIAS}}</button>
                    <button type="button" [coreUISegmentedButton]="DERIVED">{{DERIVED}}</button>
                </coreui-segmented-control-group>
            </div>
        </form>
        <form [formGroup]="myFormGroup">
            <div class="block_control" *ngIf="titleName==='Table'">
                <coreui-form-field [label]="titleName" class="inline_control_wide">
                    <coreui-select placeholder="Select Table" data-qa="select-search" [searchable]="true"
                        formControlName="selectName" (searchValueChange)="onSearchChange($event)"
                        coreUIFixedWidth="short">
                        <coreui-option *ngFor="let item of filteredItems | orderby:'name':'ASC'" [value]="item.Name"
                            (select)="onSelect($event)" (blur)="onBlur($event)">
                            <i coreUIIcon="table" scale="1.2"></i>
                            {{item.Name}}
                        </coreui-option>
                        <span *ngIf="filteredItems.length === 0" class="no-results text-style-3">
                            No results found
                        </span>
                    </coreui-select>
                    <coreui-form-error>{{errMsgName}}</coreui-form-error>
                </coreui-form-field>
            </div>
            <div class="block_control" *ngIf="titleName==='Alias Name' || titleName==='Table Name'">
                <coreui-form-field [label]="titleName" class="inline_control_wide">
                    <input coreUIInput name="inputName" formControlName="inputName" (blur)="onBlur($event)"
                        (input)="onKeyPress($event)" coreUIFixedWidth="short" />
                    <coreui-form-error>{{errMsgName}}</coreui-form-error>
                </coreui-form-field>
            </div>
            <div class="block_control">
                <coreui-form-field label="Description" class="inline_control_wide">
                    <textarea rows="3" coreUIInput formControlName="textareaDescription" (blur)="onBlur($event)"
                        (input)="onKeyPress($event)"></textarea>
                    <coreui-form-error>{{errMsgDescription}}</coreui-form-error>
                </coreui-form-field>
            </div>
            <div class="block_control">
                <coreui-form-field label="Aliased Table" class="inline_control_wide">
                    <coreui-select placeholder="Select Table" data-qa="select-search" [searchable]="true"
                        formControlName="selectAliased" (searchValueChange)="onSearchChange($event)"
                        coreUIFixedWidth="short" (blur)="onBlur($event)">
                        <coreui-option *ngFor="let item of filteredItems | orderby:'name':'ASC'" [value]="item.Name"
                            (select)="onSelect($event)">
                            <i coreUIIcon="table" scale="1.2"></i>
                            {{item.Name}}
                        </coreui-option>
                        <span *ngIf="filteredItems.length === 0" class="no-results text-style-3">
                            No results found
                        </span>
                    </coreui-select>
                    <coreui-form-error>{{errMsgAliased}}</coreui-form-error>
                </coreui-form-field>
            </div>
            <div class="block_control">
                <div [class]="activeTabletype === DERIVED? 'expression_label': 'expression_label_muted'">
                    Expression Builder</div>
                <app-expressionbuilder [metadataType]="TABLE" [tableType]="activeTabletype"
                    (expression)="onExpression($event)"></app-expressionbuilder>
                <coreui-form-field class="inline_control_wide">
                    <textarea rows="6" coreUIInput formControlName="textareaExpression" (blur)="onBlur($event)"
                        (input)="onKeyPress($event)" (keyup)="onKeyPress($event)"></textarea>
                    <coreui-form-error>{{errMsgExpression}}</coreui-form-error>
                </coreui-form-field>
            </div>
        </form>
    </ng-template>
    <ng-template #buttonsTemplate>
        <coreui-load-mask [active]="isTableValidating" class="block_space"></coreui-load-mask>
        <button type="button" coreUIButton="primary" (click)="saveBasicModal()" [disabled]="!isTableValid">Save</button>
        <button type="button" coreUIButton="secondary" (click)="closeBasicModal()"
            coreUITrapFocusInitial>Cancel</button>
    </ng-template>
</coreui-modal>
<!--------------- Toast --------------->
<coreui-notification notificationStyle="error" #infoError [messageDuration]="3000">
    {{msg}}
</coreui-notification>
<coreui-notification notificationStyle="success" #infoSuccess [messageDuration]="3000">
    {{msg}}
</coreui-notification>