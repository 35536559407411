import { Injectable } from '@angular/core';
import { FOLDER, JOIN, METADATA_TYPE, OBJECT, TABLE } from './datamodel';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private userservice: UserService) { }

  public downloadExcel(name: string, data: any) {
    var TenantCode: string = this.userservice.getSelectedSite().SiteCode

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(name);

    worksheet.addRows(this.flattenMetadata(data));

    // Save the workbook to a blob
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `DPT_Metadata_${name}_${TenantCode}.xlsx`);
    });

  }

  private flattenMetadata(data: any): any {
    var arr = []

    // header row
    arr.push(Object.keys(data[0]))

    // data rows
    data.forEach(element => {
      arr.push(Object.values(element))
    });

    return arr
  }

  public downloadMetadata(type: METADATA_TYPE, data: any[]) {
    if (type === METADATA_TYPE.OBJECT) {
      var data0: OBJECT[] = data.map(o => {
        return {
          ID: o.ID,
          Path: o.Path,
          Name: o.Name,
          Description: o.Description,
          Type: o.Type,
          DataType: o.DataType,
          Expression: o.Expression,
          Custom: o.IsCustom,
          Active: o.IsActive,
          SubjectArea: o.SubjectArea,
          CreateTS: o.CreateTS,
          UpdateTS: o.UpdateTS
        }
      })
      this.downloadExcel(type, data0)
    }
    else if (type === METADATA_TYPE.TABLE) {
      var data1: TABLE[] = data.map(t => {
        return {
          ID: t.ID,
          Name: t.Name,
          Description: t.Description,
          AliasedTable: t.AliasedTable,
          Expression: t.Expression,
          IsAliased: t.IsAliased,
          IsCustom: t.IsCustom,
          IsDerived: t.IsDerived,
          Custom: t.IsCustom,
          Active: t.IsActive,
          CreateTS: t.CreateTS,
          UpdateTS: t.UpdateTS
        }
      })
      this.downloadExcel(type, data1)
    }
    else if (type === METADATA_TYPE.JOIN) {
      var data2: JOIN[] = data.map(j => {
        return {
          ID: j.ID,
          LeftTable: j.LeftTable,
          RightTable: j.RightTable,
          JoinExpression92: j.JoinExpression92,
          JoinType: j.JoinType,
          JoinExpression89: j.JoinExpression89,
          Custom: j.IsCustom,
          Active: j.IsActive,
          CreateTS: j.CreateTS,
          UpdateTS: j.UpdateTS
        }
      })
      this.downloadExcel(type, data2)
    }
    else if (type === METADATA_TYPE.FOLDER) {
      var data3: FOLDER[] = data.map(f => {
        return {
          ID: f.ID,
          PID: f.PID,
          Path: f.Path,
          Name: f.Name,
          Type: f.Type,
          Description: f.Description,
          Custom: f.IsCustom,
          Active: f.IsActive,
          SubjectArea: f.SubjectArea,
          CreateTS: f.CreateTS,
          UpdateTS: f.UpdateTS
        }
      })
      this.downloadExcel(type, data0)
    }
  }


}
