
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { DispatchbuilderComponent } from './layout/dispatchbuilder/dispatchbuilder.component';
import { DispatchadminComponent } from './layout/dispatchadmin/dispatchadmin.component';
import { DispatchprofileComponent } from './layout/dispatchprofile/dispatchprofile.component';
import { DispatchsettingsComponent } from './layout/dispatchsettings/dispatchsettings.component';
import { COREUI_DEFAULT_THEME, Theme } from '@epsilon/core-ui';

export const routes: Routes = [
    { path: 'login/callback', component: OktaCallbackComponent },
    { path: 'profile', component: DispatchprofileComponent },
    { path: 'settings', component: DispatchsettingsComponent },
    { path: 'admin', component: DispatchadminComponent },
    { path: '', component: DispatchbuilderComponent },

];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []

})

export class AppRoutingModule { }
export const routingComponents = { OktaCallbackComponent }