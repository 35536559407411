<div class="actionbar-container">
    <app-actionbar [filterName1]="'Aliased'" [filterOptions1]="aliased" [filterName2]="'Derived'"
        [filterOptions2]="derived" [metadataType]="TABLE" (filterValue1)="onFilter1($event)"
        (filterValue2)="onFilter2($event)" (searchedText)="onSearch($event)" (add)="onAdd()" (reset)="onReset()"
        (download)="onDownload()" [filterOptions3]="customed" [filterName3]="'Custom?'"
        (filterValue3)="onFilter3($event)" [filterName4]="'Active?'" [filterOptions4]="actived"
        (filterValue4)="onFilter4($event)" (refresh)="onRefresh()"></app-actionbar>
</div>
<coreui-table [fixedHeader]="true" class="tables-fixed-header" coreUIInfiniteScroll #scrollTable
    [scrollTarget]="scrollTable.scrollContainer" (scrollLimitReached)="onScrollLimitReached()">
    <caption>Showcasing default table implementation</caption>
    <colgroup>
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
    </colgroup>
    <thead coreUITableSort>
        <tr coreUITableHeaderRow>
            <th scope="col" aria-label="actions" coreUITableActionColumn></th>
            <th scope="col"
                [attr.aria-sort]="defaultSortOption === 'Name' ? sortAscDesc(defaultSortOption) : undefined">
                <coreui-table-header-column (sort)="scrollSort($event, 'Name')" [active]="true" sortDirection="ASC">
                    Name
                </coreui-table-header-column>
            </th>
            <th scope="col">
                <coreui-table-header-column [disableSort]="true">
                    Description
                </coreui-table-header-column>
            </th>
            <th scope="col"
                [attr.aria-sort]="defaultSortOption==='AliasTable' ? sortAscDesc(defaultSortOption):undefined">
                <coreui-table-header-column (sort)="scrollSort($event,'AliasTable')">
                    Aliased Table
                </coreui-table-header-column>
            </th>
            <th scope="col">
                <coreui-table-header-column [disableSort]="true">
                    Expression
                </coreui-table-header-column>
            </th>
            <th scope="col">
                <coreui-table-header-column [disableSort]="false">
                    Custom?
                </coreui-table-header-column>
            </th>
            <th scope="col">
                <coreui-table-header-column [disableSort]="false">
                    Active?
                </coreui-table-header-column>
            </th>
            <th scope="col"
                [attr.aria-sort]="defaultSortOption==='CreateTS' ? sortAscDesc(defaultSortOption):undefined">
                <coreui-table-header-column (sort)="scrollSort($event,'CreateTS')">
                    Create TS
                </coreui-table-header-column>
            </th>
            <th scope="col"
                [attr.aria-sort]="defaultSortOption==='UpdateTS' ? sortAscDesc(defaultSortOption):undefined">
                <coreui-table-header-column (sort)="scrollSort($event,'UpdateTS')">
                    Update TS
                </coreui-table-header-column>
            </th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="!loadingTable">
            <tr *ngFor="let item of defaultItems | orderby:scrollSortOption:scrollSortDirection" coreUITableRow>
                <td aria-label="actions" coreUITableActionColumn>
                    <coreui-menu menuButtonStyle="link" icon="more">
                        <coreui-menu-section>
                            <button type="button" coreUIMenuItem (click)="onEdit(item)">
                                Edit
                            </button>
                            <button type="button" coreUIMenuItem (click)="onDelete(item)">
                                Delete
                            </button>
                            <button type="button" coreUIMenuItem *ngIf="!item.IsActive" (click)="onActivate(item)">
                                Activate
                            </button>
                            <button type="button" coreUIMenuItem *ngIf="item.IsActive" (click)="onDeactivate(item)">
                                Deactivate
                            </button>
                            <button type="button" coreUIMenuItem *ngIf="!item.IsCustom" (click)="onCustomize(item)">
                                Customize
                            </button>
                            <button type="button" coreUIMenuItem *ngIf="item.IsCustom" (click)="onUncustomize(item)">
                                Uncustomize
                            </button>
                            <button type="button" coreUIMenuItem (click)="onViewERD(item)"
                                [disabled]="item.IsDerived === 1">
                                View Relationship
                            </button>
                        </coreui-menu-section>
                    </coreui-menu>
                </td>
                <td><span [innerHTML]="item.Name | highlighter:searchedText"></span></td>
                <td><span [innerHTML]="item.Description | highlighter:searchedText"></span></td>
                <td><span [innerHTML]="item.AliasedTable | highlighter:searchedText"></span></td>
                <td>
                    <span [innerHTML]="item.Excerpt | highlighter:searchedText"></span>
                </td>
                <td>{{item.IsCustom === 0? 'False': 'True'}} </td>
                <td>{{item.IsActive === 0? 'False': 'True'}} </td>
                <td>{{item.CreateTS}}</td>
                <td>{{item.UpdateTS}}</td>
            </tr>
            <tr *ngIf="loadingItems && !loadingTable" coreUITableRow>
                <td colspan="9">
                    <coreui-load-mask class="loading-more" [active]="loadingItems">
                    </coreui-load-mask>
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="loadingTable">
            <tr coreUITableRow>
                <td><coreui-loading-table-column></coreui-loading-table-column></td>
                <td><coreui-loading-table-column></coreui-loading-table-column></td>
                <td><coreui-loading-table-column></coreui-loading-table-column></td>
                <td><coreui-loading-table-column></coreui-loading-table-column></td>
                <td><coreui-loading-table-column></coreui-loading-table-column></td>
                <td><coreui-loading-table-column></coreui-loading-table-column></td>
                <td><coreui-loading-table-column></coreui-loading-table-column></td>
                <td><coreui-loading-table-column></coreui-loading-table-column></td>
                <td><coreui-loading-table-column></coreui-loading-table-column></td>
            </tr>
        </ng-container>
        <ng-container *ngIf="!loadingTable && defaultItems.length === 0 && !loadingItems">
            <tr>
                <td colspan="9">
                    <coreui-graphic-message label="No Results Found" graphic="noResults">
                        Sorry, we did not find any results for your search.
                    </coreui-graphic-message>
                </td>
            </tr>
        </ng-container>
    </tbody>
</coreui-table>
<app-tablesmodal #tablesModal></app-tablesmodal>
<app-deletemodal #alertModal (confirmTablesEvent)="confirmDelete($event)"></app-deletemodal>
<app-erdmodal #erdModal></app-erdmodal>