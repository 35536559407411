<coreui-site-header [logoTpl]="dataHubLogo" [primaryTpl]="dataHubNav" [secondaryTpl]="dataHubMenu"
    [titleTpl]="titleTpl">
    <ng-template #dataHubLogo>
        <coreui-logo type="loyalty"></coreui-logo>
    </ng-template>
    <ng-template #titleTpl>
        Data Prep Tool
    </ng-template>
    <ng-template #dataHubNav>
        <div class="header-badge">
            <span class="text-style-5">
                Ver. 1.0
            </span>
        </div>
        <coreui-primary-navigation-item (click)="goBack()" class="navi-link">
            <a coreUIPrimaryNavigationItemLink *ngIf="!isHomePage"><i class="new-tab-icon" coreUIIcon="chevronLeft"
                    [scale]="1.5"></i>Go to Builder</a>
        </coreui-primary-navigation-item>
        <coreui-select placeholder="Select Tenant" data-qa="select-search" [searchable]="true"
            (searchValueChange)="onSearchChange($event)" [disabled]="!isAuthenticated">
            <coreui-option *ngFor="let item of filteredItems | sitefilter: true | orderby:'SiteName':'ASC'"
                [value]="item.SiteCode" (select)="onSelect(item)">
                {{item.SiteName}}
            </coreui-option>
            <span *ngIf="filteredItems.length === 0" class="no-results text-style-3">
                No results found
            </span>
        </coreui-select>
    </ng-template>
    <ng-template #dataHubMenu>
        <div *ngIf="isAuthenticated === true" class="menutext text-style-2">
            Hi {{name}}!
        </div>
        <coreui-menu verticalPosition="bottom" menuButtonStyle="link" icon="settings" title="">
            <coreui-menu-section>
                <button type="button" (click)="goSettings()" coreUIMenuItem>Settings</button>
                <button type="button" (click)="goAdmin()" *ngIf="(isAuthenticated && isAdmin)" coreUIMenuItem>Admin
                    Console</button>
            </coreui-menu-section>
        </coreui-menu>
        <coreui-menu verticalPosition="bottom" menuButtonStyle="link" icon="users" title="">
            <coreui-menu-section>
                <button type="button" (click)="signIn()" *ngIf="!isAuthenticated" coreUIMenuItem>Sign In</button>
                <button type="button" (click)="goProfile()" *ngIf="(isAuthenticated && name !== undefined)"
                    coreUIMenuItem>My
                    Profile</button>
                <coreui-menu-item-divider *ngIf="(isAuthenticated && name !== undefined)">
                </coreui-menu-item-divider>
                <button type="button" (click)="signOut()" *ngIf="(isAuthenticated && name !== undefined)"
                    coreUIMenuItem>Sign
                    Out</button>
            </coreui-menu-section>
        </coreui-menu>
    </ng-template>
</coreui-site-header>