import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SavemodalComponent } from '../../parts/savemodal/savemodal.component';
import { CoreUIModule, NotificationComponent } from '@epsilon/core-ui';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BuilderService } from '../../service/builder.service';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { EstimateService } from '../../service/estimate.service';
import { SqlmodalComponent } from "../../admin/sqlmodal/sqlmodal.component";
import { AdminService } from '../../service/admin.service';
import { ANALYZED_DATASET, USER_ROLE } from '../../service/datamodel';
import { Router } from '@angular/router';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-editormenu',
  standalone: true,
  imports: [CoreUIModule, CommonModule, SavemodalComponent, ReactiveFormsModule, SqlmodalComponent],
  templateUrl: './editormenu.component.html',
  styleUrl: './editormenu.component.scss'
})
export class EditormenuComponent implements OnInit {
  @ViewChild('basicModal2') basicModal2: SavemodalComponent
  @ViewChild('basicModal3') basicModal3: SqlmodalComponent
  @Output('segmentEvent') segmentEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('infoError', { static: true }) public infoError: NotificationComponent;
  @ViewChild('infoFilterError', { static: true }) public infoFilterError: NotificationComponent;
  @ViewChild('infoNoObjectError', { static: true }) public infoNoObjectError: NotificationComponent;


  private valueChangesSubscription: Subscription
  public isEstimating: boolean;
  public numMeasures: number;
  public numDimensions: number;
  public numFilters: number;
  public numSubjectAreas: number;
  public numRowCount: number;
  public timeRefresh: string;
  public isAdmin: boolean;
  public toggleDetailsMessage: string;
  public datasetStatus: ANALYZED_DATASET;
  public NA: ANALYZED_DATASET;
  public GOOD: ANALYZED_DATASET;
  public LARGE: ANALYZED_DATASET;
  public TOO_LARGE: ANALYZED_DATASET;
  public ERROR: ANALYZED_DATASET;
  public isAccordionExpanded: boolean;
  public errorMessage: string;


  constructor(private _router: Router, private builderservice: BuilderService, private userservice: UserService, private estimatingservice: EstimateService, private adminservice: AdminService) {
    this.isEstimating = false;
    this.isAdmin = false
    this.numMeasures = 0
    this.numDimensions = 0
    this.numFilters = 0
    this.numSubjectAreas = 0
    this.NA = ANALYZED_DATASET.NA
    this.GOOD = ANALYZED_DATASET.GOOD
    this.LARGE = ANALYZED_DATASET.LARGE
    this.TOO_LARGE = ANALYZED_DATASET.TOO_LARGE
    this.ERROR = ANALYZED_DATASET.ERROR
    this.datasetStatus = this.NA
    this.toggleDetailsMessage = "Show Details"
    this.isAccordionExpanded = false;
    this.errorMessage = ""
  }

  ngOnInit(): void {

    this.estimatingservice.isEstimating.subscribe(x => { this.isEstimating = x })
    this.builderservice.numMeasures.subscribe(x => { this.numMeasures = x })
    this.builderservice.numDimensions.subscribe(x => { this.numDimensions = x })
    this.builderservice.numFilters.subscribe(x => this.numFilters = x)
    this.builderservice.numSubjectAreas.subscribe(x => this.numSubjectAreas = x)
    this.estimatingservice.datasetStatus.subscribe(x => this.datasetStatus = x)
    this.estimatingservice.numRowCount.subscribe(x => this.numRowCount = x)
    this.estimatingservice.numRefreshSecs.subscribe(x => this.timeRefresh = this.convertTime(x))
    this.estimatingservice.errorMessage.subscribe(x => { this.errorMessage = x })
    this.userservice.isTenantSelected.subscribe(x => {
      if (x) {
        var siteUserRole = this.userservice.getUserRole()
        if (siteUserRole === USER_ROLE.SUPERADMIN || siteUserRole === USER_ROLE.ADMIN)
          this.isAdmin = true
        else this.isAdmin = false
      }
    })
  }

  public launchBasicModal2() {
    this.basicModal2.launchBasicModal();
  }

  public onReset() {
    this.builderservice.resetSelected()
    this.estimatingservice.resetEstimate()
    // this.previewservice.resetTable()
  }

  onSQL() {
    if (!this.builderservice.getIsFiltersValid())
      this.showToast(this.infoFilterError)
    else if (this.builderservice.getSelectedObjects().length === 0)
      this.showToast(this.infoNoObjectError)
    else {
      this.adminservice.getSQL()
      this.basicModal3.launchBasicModal('sql');
    }

  }

  onXPlan() {
    if (!this.builderservice.getIsFiltersValid())
      this.showToast(this.infoFilterError)
    else if (this.builderservice.getSelectedObjects().length === 0)
      this.showToast(this.infoNoObjectError)
    else {
      this.adminservice.getXPlan()
      this.basicModal3.launchBasicModal('xplan');
    }
  }

  onConsole() {
    this._router.navigateByUrl('admin');
  }

  convertTime(seconds: number): string {

    if (seconds < 60)
      return seconds + "s"
    else
      return Math.floor(seconds / 60).toFixed(0).toString() + "m"
  }

  public showToast(item: NotificationComponent): void {
    item.show();
  }

  public onActiveChange(event) {
    this.isAccordionExpanded = event._active
  }

}
