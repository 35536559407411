import { Injectable } from '@angular/core';
import { COMPARISON_OPERATOR, DATA_TYPE, FILTER, FILTER_CONTAINER, FILTER_CONTAINER_EXPORT, FILTER_CONTAINER_TYPE, FILTER_METHOD, HIERARCHY, LOGICAL_OPERATOR, METADATA_TYPE, OBJ, OBJECT_TYPE, PARAM, PARAMS, SUBJECT, TABLEAU_SITE, USER_ROLE } from './datamodel';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OktaService } from './okta.service';
import { AccessToken, IDToken } from '@okta/okta-auth-js';
import { EstimateService } from './estimate.service';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import { MetadataService } from './metadata.service';

export class RepeatingServiceCall<T> {
  readonly observable$: Observable<T>;

  constructor(delay: number) {
    this.observable$ = timer(0, delay)
      .pipe(
        map(() => <T>{})
      );
  }
}

@Injectable({
  providedIn: 'root'
})

export class BuilderService {

  public objects: BehaviorSubject<OBJ[]>;
  // public availableOjects: BehaviorSubject<OBJ[]>;
  public searchedSubjects: BehaviorSubject<SUBJECT[]>;
  public selectedObjects: BehaviorSubject<OBJ[]>;
  public selectedSinglerowsFilterContainers: BehaviorSubject<FILTER_CONTAINER[]>;
  public selectedGrouprowsFilterContainers: BehaviorSubject<FILTER_CONTAINER[]>;
  // public hierarchies: BehaviorSubject<HIERARCHY[]>;
  public arrParams: BehaviorSubject<PARAMS[]>;
  private oktaToken: AccessToken;
  private oktaToken2: IDToken;
  private singleRowsFilterContainersNone: FILTER_CONTAINER
  private groupRowsFilterContainersNone: FILTER_CONTAINER
  private duration: number = 3;
  private timerOn: boolean = false;
  private currTime: number = 0;
  public isReset: BehaviorSubject<boolean>;
  public numMeasures: BehaviorSubject<number>;
  public numDimensions: BehaviorSubject<number>;
  public numFilters: BehaviorSubject<number>;
  public numSubjectAreas: BehaviorSubject<number>;
  private caller: any;
  public isDragging: BehaviorSubject<boolean>;
  public isMouseIn: BehaviorSubject<boolean>;
  // private draggingFilterContainer: BehaviorSubject<FILTER_CONTAINER>
  // private nonDraggingFitlerContainers: BehaviorSubject<FILTER_CONTAINER[]>
  // public connectedListNames: BehaviorSubject<string[]>
  public M_HANDWRITING: FILTER_METHOD;
  public M_HANDWRITING_DECIMAL: FILTER_METHOD;
  public M_LIKE_HANDWRITING: FILTER_METHOD;
  public M_DUAL_HANDWRITING: FILTER_METHOD;
  public M_DUAL_HANDWRITING_DECIMAL: FILTER_METHOD;
  public M_SINGLE_SELECT: FILTER_METHOD;
  public M_MULTI_SELECT: FILTER_METHOD;
  public M_DATE: FILTER_METHOD;
  public M_DUAL_DATE: FILTER_METHOD;
  public M_TOGGLE: FILTER_METHOD;
  public M_DATETIME: FILTER_METHOD;
  public M_DUAL_DATETIME: FILTER_METHOD;
  public M_SUBQUERY: FILTER_METHOD;
  public M_IS_NULL: FILTER_METHOD;
  public M_IS_NOT_NULL: FILTER_METHOD;
  private isFiltersValid: BehaviorSubject<boolean>;

  private subscription: Subscription

  constructor(private http: HttpClient, private oktaservice: OktaService, private estimateservice: EstimateService, private userservice: UserService, private metadataservice: MetadataService) {
    this.isFiltersValid = new BehaviorSubject<boolean>(false)
    this.objects = new BehaviorSubject<OBJ[]>([])
    // this.availableOjects = new BehaviorSubject<OBJ[]>([])
    this.searchedSubjects = new BehaviorSubject<SUBJECT[]>([])
    this.selectedObjects = new BehaviorSubject<OBJ[]>([])
    this.singleRowsFilterContainersNone = { ID: 0, LogicalOperator: LOGICAL_OPERATOR.AND, Filters: [], Type: FILTER_CONTAINER_TYPE.SINGLEROWS }
    this.groupRowsFilterContainersNone = { ID: 0, LogicalOperator: LOGICAL_OPERATOR.AND, Filters: [], Type: FILTER_CONTAINER_TYPE.GROUPROWS }
    this.selectedSinglerowsFilterContainers = new BehaviorSubject<FILTER_CONTAINER[]>([])
    this.selectedGrouprowsFilterContainers = new BehaviorSubject<FILTER_CONTAINER[]>([])
    // this.hierarchies = new BehaviorSubject<HIERARCHY[]>([])
    this.arrParams = new BehaviorSubject<PARAMS[]>([]);
    this.oktaservice.accessToken$.subscribe(x => { if (x) { this.oktaToken = x } })
    this.oktaservice.idToken$.subscribe(x => { if (x) { this.oktaToken2 = x } })
    this.isReset = new BehaviorSubject<boolean>(false);
    this.numMeasures = new BehaviorSubject<number>(0)
    this.numDimensions = new BehaviorSubject<number>(0)
    this.numFilters = new BehaviorSubject<number>(0)
    this.numSubjectAreas = new BehaviorSubject<number>(0)
    this.caller = new RepeatingServiceCall<any>(1000);
    this.caller.observable$.subscribe(() => this.onTick());
    this.isDragging = new BehaviorSubject<boolean>(false)
    this.isMouseIn = new BehaviorSubject<boolean>(false)
    // this.draggingFilterContainer = new BehaviorSubject<FILTER_CONTAINER>(undefined)
    // this.nonDraggingFitlerContainers = new BehaviorSubject<FILTER_CONTAINER[]>([])
    // this.connectedListNames = new BehaviorSubject<string[]>([])
    this.userservice.isTenantSelected.subscribe(x => {
      this.resetSelected()
    })
    this.metadataservice.isMetaObjsLoading.subscribe(x => {
      if (!x)
        this.objects.next(this.metadataservice.getObjs())
    })
    // this.metadataservice.isMetaHierarchiesLoading.subscribe(x => {
    //   if (!x)
    //     this.hierarchies.next(this.metadataservice.getHierachies().filter(h => h.IsActive === 1))
    // })
    this.M_HANDWRITING = FILTER_METHOD.HANDWRITING;
    this.M_HANDWRITING_DECIMAL = FILTER_METHOD.HANDWRITING_DECIMAL;
    this.M_LIKE_HANDWRITING = FILTER_METHOD.LIKE_HANDWRITING
    this.M_DUAL_HANDWRITING = FILTER_METHOD.DUAL_HANDWRITING;
    this.M_HANDWRITING_DECIMAL = FILTER_METHOD.DUAL_HANDWRITING_DECIMAL;
    this.M_DUAL_HANDWRITING_DECIMAL = FILTER_METHOD.DUAL_HANDWRITING_DECIMAL;
    this.M_SINGLE_SELECT = FILTER_METHOD.SINGLE_SELECT;
    this.M_MULTI_SELECT = FILTER_METHOD.MULTI_SELECT
    this.M_DATE = FILTER_METHOD.DATE
    this.M_DUAL_DATE = FILTER_METHOD.DUAL_DATE;
    this.M_TOGGLE = FILTER_METHOD.TOGGLE;
    this.M_DATETIME = FILTER_METHOD.DATETIME;
    this.M_DUAL_DATETIME = FILTER_METHOD.DUAL_DATETIME;
    this.M_SUBQUERY = FILTER_METHOD.SUBQUERY;
    this.M_IS_NULL = FILTER_METHOD.IS_NULL;
    this.M_IS_NOT_NULL = FILTER_METHOD.IS_NOT_NULL;
  }


  /////////////////// Util //////////////////
  public onTick() {

    if (this.timerOn && this.currTime > this.duration) {
      this.timerOn = false
      if (this.selectedObjects.value.length > 0) {
        this.validateAllFilters()
        if (this.isFiltersValid.value)
          this.estimateservice.obtainEstimates(this.userservice.getSelectedSite().SiteCode, this.exportifyObjects(this.selectedObjects.value), this.exportifyFilterContainers(this.selectedSinglerowsFilterContainers.value), this.exportifyFilterContainers(this.selectedGrouprowsFilterContainers.value))
      }
      this.currTime = 0
    }
    else if (this.timerOn)
      this.currTime++

  }

  public resetTimer() {
    this.validateAllFilters()
    this.estimateservice.isPublishShow.next(false)
    if (this.isFiltersValid.value) {
      this.timerOn = true
      this.currTime = 0
    }

  }


  /////////////////////////////////////////////////////////////////////////////////////

  public resetSelected() {
    this.selectedObjects.next([])
    //reset the # of selected Objects to 0
    this.countObjects(this.selectedObjects.value)
    this.resetSelectedFilterContainers(FILTER_CONTAINER_TYPE.SINGLEROWS)
    this.resetSelectedFilterContainers(FILTER_CONTAINER_TYPE.GROUPROWS)
    this.updateFilterCount()
    this.arrParams.next([])
    this.estimateservice.resetEstimate()
    this.isReset.next(true)
  }

  /////////////////////////////// Objects /////////////////////////////////////
  public addSelectedObject(obj: OBJ) {
    var objs = this.selectedObjects.value
    objs.push(obj)
    this.setSelectedObjects(objs)
    this.resetTimer()
  }

  public getSelectedObjects(): OBJ[] {
    return this.selectedObjects.value;
  }

  public setSelectedObjects(objs: OBJ[]) {
    this.selectedObjects.next(objs)
    this.countObjects(objs)
    this.resetTimer()
  }

  private countObjects(objs: OBJ[]) {
    var numM: number = 0;
    var numD: number = 0;
    var SAs: string[] = [];
    for (const obj of objs) {
      if (obj.Type === OBJECT_TYPE.MEASURE)
        numM++
      else if (obj.Type === OBJECT_TYPE.DIMENSION)
        numD++
      SAs = [...new Set([...SAs, obj.SubjectArea])]
    }
    this.numMeasures.next(numM)
    this.numDimensions.next(numD)
    this.numSubjectAreas.next(SAs.length)
  }

  public removeSelectedObject(obj: OBJ) {
    var objs = this.selectedObjects.value
    objs = objs.filter(o => o.ID !== obj.ID)
    this.setSelectedObjects(objs)
    this.resetTimer()
  }

  public getJsonifiedSelectedObjects() {
    return this.exportifyObjects(this.selectedObjects.value)
  }

  public setSearchedObjects(objs: OBJ[]) {

    const groups = this.groupBy(objs, "SubjectArea")
    var subjects = []
    for (const [key, value] of Object.entries(groups)) {
      subjects.push({ Name: key, Objects: value });
    }
    this.searchedSubjects.next(subjects)

  }

  private groupBy = (arr, key) => {
    return arr.reduce((result, item) => {
      const value = item[key];
      if (!result[value]) {
        result[value] = [];
      }
      result[value].push(item);
      return result;
    }, {});
  };


  public checkObjectSelected(obj: OBJ): string {
    if (this.selectedObjects.value.filter(o => o.ID === obj.ID).length > 0)
      return "duplicate"
    else if (obj.Type === OBJECT_TYPE.STANDALONE)
      return "standalone"
    else if (obj.Type === OBJECT_TYPE.SUBQUERY)
      return "subquery"
    else
      return "success"

  }

  ////////////////////////// Filters  //////////////////////////////

  public addSelectedFilter(containertype: FILTER_CONTAINER_TYPE, fcid: number, obj: FILTER) {
    if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS) {
      var fs = this.selectedSinglerowsFilterContainers.value.filter(x => x.ID === fcid && x.Type === containertype)[0].Filters
      this.bumpFilters(fs, obj)
      this.setSelectedFilterContainers(containertype, this.selectedSinglerowsFilterContainers.value)
    }
    else {
      var fs = this.selectedGrouprowsFilterContainers.value.filter(x => x.ID === fcid && x.Type === containertype)[0].Filters
      this.bumpFilters(fs, obj)
      this.setSelectedFilterContainers(containertype, this.selectedGrouprowsFilterContainers.value)
    }
    this.interpretFilterInputMethod(obj)
    this.validateFilter(obj)
    if (obj.IsValid) {
      this.resetTimer()
      this.isFiltersValid.next(true)
    }
    else
      this.isFiltersValid.next(false)

  }

  public reorderSlectedFilters(
    containertype: FILTER_CONTAINER_TYPE | any,
    fcid: number,
    items: FILTER[]) {
    if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS) {
      this.selectedSinglerowsFilterContainers.value.filter(x => x.ID === fcid)[0].Filters = this.reorderFilters(items)
      this.setSelectedFilterContainers(containertype, this.selectedSinglerowsFilterContainers.value)
    }
    else if (containertype === FILTER_CONTAINER_TYPE.GROUPROWS) {
      this.selectedGrouprowsFilterContainers.value.filter(x => x.ID === fcid)[0].Filters = this.reorderFilters(items)
      this.setSelectedFilterContainers(containertype, this.selectedGrouprowsFilterContainers.value)
    }
  }

  public reorderFilters(items: FILTER[]): FILTER[] {
    for (var i = 0; i < items.length; i++) {
      items[i].FilterIndex = i;
    }
    return items
  }


  private bumpFilters(filters: FILTER[], newobj: FILTER) {

    filters.push(newobj)

    filters.filter(f => f.FilterIndex >= newobj.FilterIndex && f.FilterID !== newobj.FilterID).forEach(ff => {
      ff.FilterIndex++
    })

    filters.sort((a, b) => { return a.FilterIndex - b.FilterIndex })

  }

  public updateSelectedFilterValue(containertype: FILTER_CONTAINER_TYPE, fcid: number, obj: FILTER) {

    if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS) {
      this.selectedSinglerowsFilterContainers.value.filter(x => x.ID === fcid)[0].Filters.filter(f => f.FilterID === obj.FilterID)[0] = obj
    }
    else {
      this.selectedGrouprowsFilterContainers.value.filter(x => x.ID === fcid)[0].Filters.filter(f => f.FilterID === obj.FilterID)[0] = obj
    }
    this.interpretFilterInputMethod(obj)
    this.validateFilter(obj)
    if (obj.IsValid) {
      this.resetTimer()
      this.isFiltersValid.next(true)
    }
    else
      this.isFiltersValid.next(false)

  }

  public removeSelectedFilter(containertype: FILTER_CONTAINER_TYPE | any, containerid: number, obj: FILTER) {

    if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS) {
      var filtercontainer = this.selectedSinglerowsFilterContainers.value.filter(x => x.ID == containerid && x.Type === containertype)[0]
      var minusfilters = filtercontainer.Filters.filter(f => f.FilterID !== obj.FilterID)
      filtercontainer.Filters = minusfilters
    }

    else {
      var filtercontainer = this.selectedGrouprowsFilterContainers.value.filter(x => x.ID == containerid && x.Type === containertype)[0]
      var minusfilters = filtercontainer.Filters.filter(f => f.FilterID !== obj.FilterID)
      filtercontainer.Filters = minusfilters
    }
    this.resetTimer()

  }

  public interpretFilterInputMethod(item: FILTER) {

    //this.readFromControl(item)
    var ComparisonOperator = item.ComparisonOperator
    var ObjectType = item.Type
    var DataType = item.DataType
    var SubqueryObjID = item.SubqueryObjID
    var ID = item.ID

    //IsNULL or IsNotNull
    if (ComparisonOperator === COMPARISON_OPERATOR.IS_NULL)
      item.FilterMethod = this.M_IS_NULL
    else if (ComparisonOperator === COMPARISON_OPERATOR.IS_NOT_NULL)
      item.FilterMethod = this.M_IS_NOT_NULL

    //Measure
    else if (ObjectType === OBJECT_TYPE.MEASURE) {
      if (ComparisonOperator === COMPARISON_OPERATOR.BETWEEN || ComparisonOperator === COMPARISON_OPERATOR.NOT_BETWEEN)
        item.FilterMethod = this.M_DUAL_HANDWRITING
      else
        item.FilterMethod = this.M_HANDWRITING
    }
    //Dimension
    else if (ObjectType === OBJECT_TYPE.DIMENSION) {
      if (SubqueryObjID !== undefined)
        item.FilterMethod = this.M_SUBQUERY
      else if (DataType === DATA_TYPE.DATE) {
        if (ComparisonOperator === COMPARISON_OPERATOR.BETWEEN || ComparisonOperator === COMPARISON_OPERATOR.NOT_BETWEEN)
          item.FilterMethod = this.M_DUAL_DATE
        else
          item.FilterMethod = this.M_DATE
      }
      else if (DataType === DATA_TYPE.DATETIME)
        if (ComparisonOperator === COMPARISON_OPERATOR.BETWEEN || ComparisonOperator === COMPARISON_OPERATOR.NOT_BETWEEN)
          item.FilterMethod = this.M_DUAL_DATETIME
        else
          item.FilterMethod = this.M_DATETIME
      else if (DataType === DATA_TYPE.BOOLEAN)
        item.FilterMethod = this.M_TOGGLE
      else if (DataType === DATA_TYPE.BLOB || DataType === DATA_TYPE.LONGTEXT)
        item.FilterMethod = this.M_LIKE_HANDWRITING
      else if (DataType === DATA_TYPE.NUMERIC)
        if (ComparisonOperator === COMPARISON_OPERATOR.BETWEEN || ComparisonOperator === COMPARISON_OPERATOR.NOT_BETWEEN)
          item.FilterMethod = this.M_DUAL_HANDWRITING
        else
          item.FilterMethod = this.M_HANDWRITING
      else if (DataType === DATA_TYPE.STRING)
        if (ComparisonOperator === COMPARISON_OPERATOR.LIKE || ComparisonOperator === COMPARISON_OPERATOR.NOT_LIKE)
          item.FilterMethod = this.M_LIKE_HANDWRITING
        else if (ComparisonOperator === COMPARISON_OPERATOR.LESS_THAN
          || ComparisonOperator === COMPARISON_OPERATOR.LESS_THAN_OR_EQUAL_TO
          || ComparisonOperator === COMPARISON_OPERATOR.GREATER_THAN
          || ComparisonOperator === COMPARISON_OPERATOR.GREATER_THAN_OR_EQUAL_TO)
          item.FilterMethod = this.M_HANDWRITING
        else if (ComparisonOperator === COMPARISON_OPERATOR.BETWEEN || ComparisonOperator === COMPARISON_OPERATOR.NOT_BETWEEN)
          item.FilterMethod = this.M_DUAL_HANDWRITING
        else if (ComparisonOperator === COMPARISON_OPERATOR.IN || ComparisonOperator === COMPARISON_OPERATOR.NOT_IN) {
          var objParam = this.arrParams.value.filter(y => y.ID === ID)[0]
          if (objParam)
            if (objParam.Params.length === 0)
              item.FilterMethod = this.M_HANDWRITING
            else
              item.FilterMethod = this.M_MULTI_SELECT
          else
            item.FilterMethod = this.M_HANDWRITING
        }
        // operator: > < <= >=, etc.
        else {
          var objParam = this.arrParams.value.filter(y => y.ID === ID)[0]
          if (objParam)
            if (objParam.Params.length === 0)
              item.FilterMethod = this.M_HANDWRITING
            else
              item.FilterMethod = this.M_SINGLE_SELECT
          else
            item.FilterMethod = this.M_HANDWRITING
        }
      // any other value type of Dimension? 
      else
        item.FilterMethod = this.M_HANDWRITING
    }
    // catch all !Dimension or !Measure
    else
      item.FilterMethod = this.M_HANDWRITING

  }

  public validateFilter(item: FILTER) {
    // first of all, comparison operator must be selected
    if (item.ComparisonOperator) {
      // go through each filter method
      if (item.FilterMethod === this.M_IS_NULL || item.FilterMethod === this.M_IS_NOT_NULL)
        item.IsValid = true
      else if (item.FilterMethod === this.M_MULTI_SELECT)
        if (Array.isArray(item.FilterValue))
          if (item.FilterValue.length === 0)
            item.IsValid = false
          else
            item.IsValid = true
        else
          item.IsValid = false
      else if (item.FilterMethod === this.M_DATE || item.FilterMethod === this.M_DATETIME) {
        if (this.isValidDate(item.FilterValue))
          item.IsValid = true
        else
          item.IsValid = false
      }
      else if (item.FilterMethod === this.M_DUAL_DATE || item.FilterMethod === this.M_DUAL_DATETIME) {
        if (this.isValidDate(item.FilterValue) && this.isValidDate(item.FilterValue2))
          item.IsValid = true
        else
          item.IsValid = false
      }
      else if (item.FilterMethod === this.M_TOGGLE)
        item.IsValid = true
      else if (item.FilterMethod === this.M_HANDWRITING_DECIMAL)
        if (this.isIntegerOrFloat(item.FilterValue))
          item.IsValid = true
        else
          item.IsValid = false
      else if (item.FilterMethod === this.M_DUAL_HANDWRITING_DECIMAL)
        if (this.isIntegerOrFloat(item.FilterValue) && this.isIntegerOrFloat(item.FilterValue2))
          item.IsValid = true
        else
          item.IsValid = false
      else {
        if (item.FilterValue ?? '' !== '')
          item.IsValid = true
        else
          item.IsValid = false
      }
      if (!item.IsValid && item.SubqueryObjID)
        item.IsValid = true
    }
    else
      item.IsValid = false
  }

  public getIsFiltersValid() {
    this.validateAllFilters()
    return this.isFiltersValid.value
  }

  private validateAllFilters() {
    var validity: boolean = true
    this.selectedGrouprowsFilterContainers.value.forEach(sgfc => {
      sgfc.Filters.forEach(f => {
        this.validateFilter(f)
        if (!f.IsValid)
          validity = false
      })
    })
    this.selectedSinglerowsFilterContainers.value.forEach(sgfc => {
      sgfc.Filters.forEach(f => {
        this.validateFilter(f)
        if (!f.IsValid)
          validity = false
      })
    })
    if (this.isFiltersValid)
      this.isFiltersValid.next(validity)
  }

  private isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  private isIntegerOrFloat(str) {
    const num = parseFloat(str); // Parse the string as a number
    return !isNaN(num) && isFinite(num); // Check if it's a valid number
  }

  /////////////////////////// Filter Containers /////////////////////////////

  public checkFilterExists(item: OBJ, containertype: FILTER_CONTAINER_TYPE) {
    var isFilterFound: boolean = false;
    if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS) {
      this.selectedSinglerowsFilterContainers.value.forEach(fc => {
        fc.Filters.forEach(f => {
          if (f.ID === item.ID)
            isFilterFound = true
        })
      })
    }
    else {
      this.selectedGrouprowsFilterContainers.value.forEach(fc => {
        fc.Filters.forEach(f => {
          if (f.ID === item.ID)
            isFilterFound = true
        })
      })
    }
    return isFilterFound
  }

  public setSelectedFilterContainers(containertype: FILTER_CONTAINER_TYPE, fcs: FILTER_CONTAINER[]) {
    if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS)
      this.selectedSinglerowsFilterContainers.next(fcs)
    else
      this.selectedGrouprowsFilterContainers.next(fcs)

    this.updateFilterCount()
  }

  private updateFilterCount() {
    var num: number = 0;
    this.selectedSinglerowsFilterContainers.value.forEach(c => {
      num += c.Filters.length
    })
    this.selectedGrouprowsFilterContainers.value.forEach(c => {
      num += c.Filters.length
    })
    this.numFilters.next(num)
  }

  public addSelectedFilterContainer(containertype: FILTER_CONTAINER_TYPE, fcid: number) {

    if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS) {
      var fcs = this.selectedSinglerowsFilterContainers.value
      var newSinglerowFitlerContainer: FILTER_CONTAINER = JSON.parse(JSON.stringify(this.singleRowsFilterContainersNone))
      this.setSelectedFilterContainers(containertype, this.bumpFilterContainers(fcs, newSinglerowFitlerContainer, fcid))
    }
    else {
      var fcs = this.selectedGrouprowsFilterContainers.value
      var newGrouprowFilterContainer: FILTER_CONTAINER = JSON.parse(JSON.stringify(this.groupRowsFilterContainersNone))
      this.setSelectedFilterContainers(containertype, this.bumpFilterContainers(fcs, newGrouprowFilterContainer, fcid))
    }
    this.resetTimer()
  }

  private bumpFilterContainers(filtercontainers: FILTER_CONTAINER[], filtercontainer: FILTER_CONTAINER, id: number): FILTER_CONTAINER[] {

    filtercontainer.ID = id + 1
    for (const fc of filtercontainers) {
      if (fc.ID > id)
        fc.ID += 1
    }
    filtercontainers.push(filtercontainer)

    return filtercontainers.sort((a, b) => { return a.ID - b.ID })

  }


  public updateSelectedFilterContainerLogicalOperator(containertype: any, containerid: number, logicaloperator: LOGICAL_OPERATOR) {
    if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS) {
      this.selectedSinglerowsFilterContainers.value.filter(c => c.ID === containerid)[0].LogicalOperator = logicaloperator
    }
    else if (containertype === FILTER_CONTAINER_TYPE.GROUPROWS) {
      this.selectedGrouprowsFilterContainers.value.filter(c => c.ID === containerid)[0].LogicalOperator = logicaloperator
    }
    this.resetTimer()
  }


  public removeSelectedFilterContainer(containertype: FILTER_CONTAINER_TYPE, fcid: number) {

    if (fcid === 0)
      this.resetSelectedFilterContainers(containertype)
    else {
      if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS) {
        var fcs = this.selectedSinglerowsFilterContainers.value
        this.setSelectedFilterContainers(containertype, this.carryupFilterContainers(fcs, fcid));
      }
      else if (containertype === FILTER_CONTAINER_TYPE.GROUPROWS) {
        var fcs = this.selectedGrouprowsFilterContainers.value
        this.setSelectedFilterContainers(containertype, this.carryupFilterContainers(fcs, fcid));
      }
    }

    this.resetTimer()

  }

  private carryupFilterContainers(filtercontainers: FILTER_CONTAINER[], id: number): FILTER_CONTAINER[] {

    filtercontainers.splice(id, 1);

    for (const fc of filtercontainers) {
      if (fc.ID > id)
        fc.ID -= 1
    }

    return filtercontainers.sort((a, b) => { return a.ID - b.ID })

  }

  public resetSelectedFilterContainers(containertype: FILTER_CONTAINER_TYPE) {
    if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS) {
      this.setSelectedFilterContainers(containertype, [JSON.parse(JSON.stringify(this.singleRowsFilterContainersNone))])
    }
    else {
      this.setSelectedFilterContainers(containertype, [JSON.parse(JSON.stringify(this.groupRowsFilterContainersNone))])
    }
    this.resetTimer()
  }

  public getSelectedFilterContrainers(containertype: FILTER_CONTAINER_TYPE) {
    if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS)
      return this.selectedSinglerowsFilterContainers.value
    else
      return this.selectedGrouprowsFilterContainers.value
  }

  public getSelectedFilterContrainers$(containertype: FILTER_CONTAINER_TYPE): Observable<FILTER_CONTAINER[]> {
    if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS)
      return new Observable(i => {
        this.selectedSinglerowsFilterContainers.value
      })
    else
      return new Observable(i => {
        this.selectedGrouprowsFilterContainers.value
      })
  }

  public getSelectedFilterContainerNames(): string[] {
    var containerNames: string[] = []
    for (const fc of this.selectedSinglerowsFilterContainers.value) {
      containerNames.push('droppablefilter_' + FILTER_CONTAINER_TYPE.SINGLEROWS + '_' + fc.ID)
    }
    for (const fc of this.selectedSinglerowsFilterContainers.value) {
      containerNames.push('droppablefilter_' + FILTER_CONTAINER_TYPE.GROUPROWS + '_' + fc.ID)
    }
    return containerNames
  }

  public getJsonifiedSelectedFilterContainers(containertype: FILTER_CONTAINER_TYPE): string {
    if (containertype === FILTER_CONTAINER_TYPE.SINGLEROWS)
      return this.exportifyFilterContainers(this.selectedSinglerowsFilterContainers.value)
    else
      return this.exportifyFilterContainers(this.selectedGrouprowsFilterContainers.value)
  }


  /////////////////////////// Misc. Functions ///////////////////////////

  public setDragging(tf: boolean, fcn?: any) {
    //console.log(tf)
    this.isDragging.next(tf)
  }


  public setMouseIn(tf: boolean) {
    this.isMouseIn.next(tf)
  }

  private findSelectedFilterContainer(fct: FILTER_CONTAINER_TYPE, fcid: number): FILTER_CONTAINER {

    if (this.selectedGrouprowsFilterContainers.value.filter(gfc => gfc.Type === fct && gfc.ID === fcid).length > 0)
      return this.selectedGrouprowsFilterContainers.value.filter(gfc => gfc.Type === fct && gfc.ID === fcid)[0]
    else
      return this.selectedSinglerowsFilterContainers.value.filter(sfc => sfc.Type === fct && sfc.ID === fcid)[0]

  }

  /////////////////////////// Helper functions ///////////////////////////

  private skimFilters(objs: FILTER[]): FILTER[] {
    var filters: FILTER[] = objs.map(jf => {
      return {
        FilterLogicalOperator: jf.FilterLogicalOperator,
        ComparisonOperator: jf.ComparisonOperator,
        ID: jf.ID,
        FilterID: jf.FilterID,
        FilterValue: jf.FilterValue,
        FilterValue2: jf.FilterValue2,
        FilterIndex: jf.FilterIndex,
        FilterMethod: jf.FilterMethod,
        FilterContainerType: jf.FilterContainerType,
        SubqueryObjID: jf.SubqueryObjID
      }
    })
    return filters
  }

  public convertOBJtoFILTER(obj: OBJ): FILTER {
    var filter: FILTER =
    {
      FilterLogicalOperator: LOGICAL_OPERATOR.AND,
      ComparisonOperator: undefined,
      ID: obj.ID,
      Name: obj.Name,
      FilterValue: "",
      FilterValue2: "",
      FilterIndex: 0,
      FilterMethod: undefined,
      FilterContainerType: undefined
    }

    return filter
  }

  public exportifyObjects(objs: OBJ[]): string {
    var j = objs.map(o => { return { ID: o.ID } })
    return JSON.stringify(j)
  }

  public exportifyFilters(objs: FILTER[]): string {
    var f = objs.map(o => { return { ID: o.ID } })
    return JSON.stringify(f)
  }


  public exportifyFilterContainers(fcs: FILTER_CONTAINER[]): string {
    var efcs = []
    for (const fc of fcs) {
      var filters: FILTER[] = fc.Filters.map(jf => {
        return jf
      })
      var efc: FILTER_CONTAINER_EXPORT = JSON.parse(JSON.stringify(fc))
      efc.Filters = filters
      efcs.push(efc)
    }
    return JSON.stringify(efcs.map(x => {
      return {
        ID: x.ID,
        LogicalOperator: x.LogicalOperator,
        Filters: this.skimFilters(x.Filters),
        Type: x.Type
      }
    }))
  }


  ///////////////////////////// HTTP ////////////////////////////////

  public queryParamValues(item: FILTER) {

    // retrieve parameters only if they have not been loaded before
    if (this.arrParams.value.map(p => { return p.ID }).indexOf(item.ID) < 0) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'AuthCode': this.oktaToken.accessToken,
        'AuthCode2': this.oktaToken2.idToken,
      });
      let options = { headers: headers };
      let body = new URLSearchParams();
      body.set('Object', this.exportifyFilters([item]));
      body.set('TenantCode', this.userservice.getSelectedSite().SiteCode)
      this.subscription = this.http.post<{ Message: string, params: string }>(`${environment.REST_API_SERVER}/params`, body, options).subscribe(x => {

        var newParams: PARAMS;

        if (x.Message === "Success") {

          var pms: PARAM[] = this.splitAndRemoveQuotes(x.params).map(p => {
            return { Name: p, Value: p }
          })
          newParams = { ID: item.ID, Params: pms }
        }
        // Too many values or No values found
        else
          newParams = { ID: item.ID, Params: [] }

        if (this.arrParams.value.filter(p => p.ID === item.ID).length === 0)
          this.arrParams.next([...new Set([...this.arrParams.value, newParams])])

        else
          this.arrParams.next(this.arrParams.value)
      })
    }
  }


  private splitAndRemoveQuotes(str) {
    const regex = /,(?=(?:[^"]*"[^"]*")*[^"]*$)/;
    return str.split(regex).map(item => item.replace(/"/g, ''));
  }

}