<div cdkDropList id="searchedobject" [cdkDropListConnectedTo]="['droppableobject']" cdkDropListOrientation="vertical"
    [cdkDropListData]="allitems" (cdkDropListDropped)="drop($event)" class="nav-list">
    <div class="subjectgroup" *ngFor="let item of items">
        <div class=" subjectarea text-style-6">{{item.Name}}</div>
        <div class="nav__container" cdkDrag *ngFor="let itm of item.Objects" (cdkDragEntered)="onDragEnter($event)"
            (cdkDragExited)="onDragExit($event)" (cdkDragStarted)="onDragStart($event)"
            (cdkDragReleased)="onDragRelease($event)">
            <div class="nav__item" (dblclick)="onDoubleClick(itm)">
                <i coreUIIcon="dragHandle" scale="1" class="nav_component"></i>
                <div [class]="itm.Type">
                    <div *ngIf="itm.Type === DIMENSION">
                        <i coreUIIcon="boxFill" scale="1" class="nav_component"></i>
                    </div>
                    <div *ngIf="itm.Type === STANDALONE">
                        <i coreUIIcon="rectangle" scale="1" class="nav_component"></i>
                    </div>
                    <div *ngIf="itm.Type === SUBQUERY">
                        <i coreUIIcon="object" scale="1" class="nav_component"></i>
                    </div>
                    <div *ngIf="itm.Type === MEASURE">
                        <i coreUIIcon="radioFill" scale="1" class="nav_component"></i>
                    </div>
                </div>
                <span>{{ itm.Name }}</span>
                <coreui-card-container *cdkDragPreview>
                    <coreui-card role="button" tabindex="0" [header]="itm.Name" class="Card-small" [text]="cardTxt4">
                        <ng-template #cardTxt4>
                            <div class="custom-definition text-style-3">
                                Subject Area: <span class="text-style-2">{{ itm.SubjectArea }}</span> <br />
                                Object Type: <span class="text-style-2">{{ itm.Type }}</span> <br />
                                Data Type: <span class="text-style-2">{{itm.DataType}}</span>
                            </div>
                            <div coreUIDivider="secondary"></div>
                            <div class="custom-definition text-style-3">
                                {{ itm.Description }}<br />
                            </div>
                        </ng-template>
                    </coreui-card>
                </coreui-card-container>
            </div>
        </div>
    </div>
</div>
<coreui-notification notificationStyle="information" #infoMustInclude>
    A grouped row filter must also be included in the result objects.
</coreui-notification>
<coreui-notification notificationStyle="information" #infoDuplicate>
    An Object with the same name already exists in the container.
</coreui-notification>
<coreui-notification notificationStyle="information" #infoStandalone>
    A Standalone Object is not allowed in the result container.
</coreui-notification>
<coreui-notification notificationStyle="information" #infoSubquery>
    A Subquery Object is not allowed in the result container.
</coreui-notification>