import { AfterViewInit, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { OktaService } from './service/okta.service';
import { Theme, ThemeColorService } from '@epsilon/core-ui';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'data-prep-tool';

  constructor(private oktaservice: OktaService, private themeColorService: ThemeColorService
  ) {
    this.oktaservice.svInit()
  }

  ngOnInit(): void {
    var localTheme = localStorage.getItem('theme')
    if (localTheme === undefined || localTheme == null) {
      this.themeColorService.setTheme(Theme.Light)
      localStorage.setItem('theme', Theme.Light.toString())
    }
    else
      this.themeColorService.setTheme(parseInt(localStorage.getItem('theme')));
  }
}
