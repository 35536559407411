import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CoreUIModule, ModalComponent } from '@epsilon/core-ui';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { TABLE } from '../../service/datamodel';
import { MetadataService } from '../../service/metadata.service';
import { SankeyComponent } from '../sankey/sankey.component';
import { D3Component } from '../d3/d3.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-erdmodal',
  standalone: true,
  imports: [CommonModule, CoreUIModule, ReactiveFormsModule, SankeyComponent],
  templateUrl: './erdmodal.component.html',
  styleUrl: './erdmodal.component.scss',
})

export class ErdmodalComponent implements OnInit {

  @ViewChild('erdModal', { static: true })
  private erdModal: ModalComponent;
  public activeERDTable: string;
  public adjustedDepth: number = 1;
  private _filteredItems: TABLE[] = [];
  public set filteredItems(value: TABLE[]) {
    this._filteredItems = value;
  }
  public get filteredItems(): TABLE[] {
    return this._filteredItems;
  }
  public myFormGroup: FormGroup = new FormGroup({
    inlineRadio: new FormControl("1")
  })
  private subChange: Subscription

  constructor(private metadataservice: MetadataService) {
  }

  ngOnInit(): void {
    this.subChange = this.myFormGroup.valueChanges.subscribe(val => this.formChanged(val))

  }

  private formChanged(val) {
    this.adjustedDepth = val.inlineRadio
    this.reInit(this.activeERDTable)

  }

  reInit(tbl: string) {
    this.activeERDTable = "";
    setTimeout(() => {
      this.activeERDTable = tbl;
    },1);
  }

  public async closeErdModal(): Promise<void> {

    await this.erdModal.hide();
    // this.alertModalElementRef.nativeElement.focus();
  }

  public async launchERDModal(tablename: string): Promise<void> {
    await this.erdModal.show();
    this.activeERDTable = tablename
    this.reInit(this.activeERDTable)
  }


}