<form [formGroup]="myFormGroup">
    <!--- Filter Value --->
    <div class="nav_inline">
        <!-- Handwriting-->
        <div *ngIf="item.FilterMethod === M_HANDWRITING || item.FilterMethod === M_LIKE_HANDWRITING">
            <input coreUIInput type="text" [formControlName]="'filter_value'" [value]="item.FilterValue"
                *ngIf="item.DataType===DT_STRING" class="nav_inline_select" />
            <input coreUIInput type="text" [formControlName]="'filter_value'" [value]="item.FilterValue"
                coreUIDecimalMask *ngIf="item.DataType===DT_NUMERIC" />
        </div>
        <!-- Dual Handwriting-->
        <div *ngIf="item.FilterMethod === M_DUAL_HANDWRITING" class="nav_inline_between">
            <input coreUIInput type="text" [formControlName]="'filter_value'" [value]="item.FilterValue"
                *ngIf="item.DataType===DT_STRING" coreUIFixedWidth="short" />
            <input coreUIInput type="text" [formControlName]="'filter_value'" [value]="item.FilterValue"
                coreUIDecimalMask *ngIf="item.DataType===DT_NUMERIC" coreUIFixedWidth="short" />
            <span class="nav_inline_and_non_float">and</span>
            <input coreUIInput type="text" [formControlName]="'filter_value2'" [value]="item.FilterValue2"
                *ngIf="item.DataType===DT_STRING" coreUIFixedWidth="short" />
            <input coreUIInput type="text" [formControlName]="'filter_value2'" [value]="item.FilterValue2"
                coreUIDecimalMask *ngIf="item.DataType===DT_NUMERIC" coreUIFixedWidth="short" />
        </div>
        <!-- Single Select-->
        <div *ngIf="item.FilterMethod === M_SINGLE_SELECT && params.length > 0" class="nav_inline_select">
            <coreui-select [formControlName]="'filter_value'" placeholder="Select">
                <coreui-option *ngFor="let p of params" [value]="p.Value">{{p.Name}}</coreui-option>
                <!-- message part -->
                <span *ngIf="params?.length=== 0">
                    No results found
                </span>
            </coreui-select>
        </div>
        <!-- Multi Select-->
        <div *ngIf="item.FilterMethod === M_MULTI_SELECT" class="nav_inline_select">
            <coreui-select [formControlName]="'filter_multivalue'" placeholder="Select options" [multiple]="true"
                [searchable]="true" (blur)="onSelectBlur()" (searchValueChange)="onSearchChange($event)">
                <!-- display part -->
                <ng-template #multipleSelectedTpl let-selectedOptions="selectedOptionsCount" let-value="value">
                    <coreui-select-tag [value]="selectedOptions"
                        [attr.aria-label]="selectedOptions + ' options selected'"></coreui-select-tag>
                    <div>{{strSelectedOptions?.sort().join(', ')}}</div>
                </ng-template>
                <!-- selected items part -->
                <coreui-option *ngFor="let itm of multiSearchSelectAllSelectedItems$ | async" [value]="itm">
                    {{itm.Name}}
                </coreui-option>
                <!-- divider part -->
                <coreui-menu-item-divider
                    *ngIf="multiSearchSelectAllSelectedItems$.getValue().length > 0 && multiSearchSelectAllFilteredItems$.getValue().length > 0"></coreui-menu-item-divider>
                <!-- selectable items part -->
                <coreui-option *ngFor="let item of multiSearchSelectAllFilteredItems$ | async" [value]="item">
                    {{item.Name}}
                </coreui-option>
                <!-- message part -->
                <span
                    *ngIf="((multiSearchSelectAllFilteredItems$| async)?.length=== 0 && (multiSearchSelectAllSelectedItems$ | async)?.length === 0)">
                    No results found
                </span>
            </coreui-select>
        </div>
        <!-- Toggle-->
        <div *ngIf="item.FilterMethod === M_TOGGLE">
            <coreui-toggle [formControlName]="'filter_value'" rightLabel="true" leftLabel="false"
                [enableHammerIcon]="false" data-qa="toggle-default"></coreui-toggle>
        </div>
        <!-- Date-->
        <div *ngIf="item.FilterMethod === M_DATE">
            <coreui-date-picker [minDate]="minDate" locale="en-US" [formControlName]="'filter_value'"
                data-qa="date-picker-single">
            </coreui-date-picker>
        </div>
        <!-- Dual Date-->
        <div *ngIf="item.FilterMethod === M_DUAL_DATE">
            <div class="nav_sameline">
                <coreui-date-picker [minDate]="minDate" locale="en-US" [formControlName]="'filter_value'"
                    data-qa="date-picker-single">
                </coreui-date-picker>
            </div>
            <span class="nav_inline_and">
                and
            </span>
            <div class="nav_sameline">
                <coreui-date-picker [minDate]="minDate" locale="en-US" [formControlName]="'filter_value2'"
                    data-qa="date-picker-single">
                </coreui-date-picker>
            </div>
        </div>
        <!-- DateTime-->
        <div *ngIf="item.FilterMethod === M_DATETIME">
            <coreui-date-time-picker locale="en-US" [formControlName]="'filter_value'">
            </coreui-date-time-picker>
        </div>
        <!-- Dual DateTime-->
        <div *ngIf="item.FilterMethod === M_DUAL_DATETIME">
            <div class="nav_sameline">
                <coreui-date-time-picker locale="en-US" [formControlName]="'filter_value'">
                </coreui-date-time-picker>
            </div>
            <span class="nav_inline_and">
                and
            </span>
            <div class="nav_sameline">
                <coreui-date-time-picker locale="en-US" [formControlName]="'filter_value2'">
                </coreui-date-time-picker>
            </div>
        </div>
    </div>
</form>