import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CoreUIModule, ModalComponent, NotificationComponent } from '@epsilon/core-ui';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DatasourceService } from '../../service/datasource.service';
import { BuilderService } from '../../service/builder.service';
import { environment } from '../../../environments/environment';
import { HistoryService } from '../../service/history.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-savemodal',
  standalone: true,
  imports: [CoreUIModule, CommonModule, ReactiveFormsModule],
  templateUrl: './savemodal.component.html',
  styleUrl: './savemodal.component.scss'
})
export class SavemodalComponent implements OnInit {
  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;
  @ViewChild('launchBasicModalButton', { read: ElementRef })
  public basicModalElementRef: ElementRef<HTMLButtonElement>;

  public datasourceName: string;
  public targetServer: string;
  public myFormGroup: FormGroup = new FormGroup({
    inputDatasourceName: new FormControl('')
  })
  public listObjects: string[];
  public interprettedSinglerowFilters: string;
  public interprettedGrouprowFilters: string;
  public isSubmittable: boolean;

  constructor(private datasourceservice: DatasourceService, private builderservice: BuilderService, private historyservice: HistoryService) {
    this.targetServer = environment.TABLEAU_SERVER
    this.isSubmittable = false
    this.listObjects = []
    this.interprettedSinglerowFilters = ''
    this.interprettedGrouprowFilters = ''
    // this.siteName = 'n/a'
    this.datasourceName = ''
  }

  ngOnInit(): void {

    this.myFormGroup.valueChanges.subscribe(x => {
      this.validateDatasourceName()
    })

    this.datasourceservice.interprettedSinglerowFilters.subscribe(x => {
      this.interprettedSinglerowFilters = x
    })
    this.datasourceservice.interprettedGrouprowFilters.subscribe(x => {
      this.interprettedGrouprowFilters = x
    })

  }

  private validateDatasourceName() {
    if (this.datasourceName !== this.myFormGroup.controls["inputDatasourceName"].value) {
      this.datasourceName = this.myFormGroup.controls["inputDatasourceName"].value
      if (this.listObjects.length === 0 || this.datasourceName.length === 0)
        this.isSubmittable = false
      else
        this.isSubmittable = true
    }
  }

  private initDialog() {
    this.listObjects = this.builderservice.getSelectedObjects().map(o => { return o.Name })
    this.datasourceName = this.datasourceservice.getSelectedDatasourceName()
    this.myFormGroup.controls["inputDatasourceName"].setValue(this.datasourceName)
    this.datasourceservice.interpretFilters('simple')
    if (this.listObjects.length > 0 && this.datasourceName.length > 0)
      this.isSubmittable = true
    else
      this.isSubmittable = false
  }

  private saveDialog() {
    this.datasourceName = this.myFormGroup.controls["inputDatasourceName"].value
    this.datasourceservice.setSelectedDatasourceName(this.datasourceName)
    this.isSubmittable = false
  }

  public async closeBasicModal(): Promise<void> {
    this.saveDialog()
    await this.basicModal.hide();
    //this.basicModalElementRef.nativeElement.focus();
  }

  public async launchBasicModal(): Promise<void> {
    this.initDialog()
    await this.basicModal.show(this.basicModalElementRef);
  }

  public async saveProgress() {
    this.saveDialog()
    this.datasourceservice.setSelectedDatasourceName(this.datasourceName)
    this.historyservice.saveHistory()
    await this.basicModal.hide();
  }


}
