import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CoreUIModule } from '@epsilon/core-ui';
import { BehaviorSubject } from 'rxjs';
import { HistoryService } from '../../service/history.service';
import { PUBLISH_HISTORY } from '../../service/datamodel';

@Component({
  selector: 'app-historyviewer',
  standalone: true,
  imports: [CoreUIModule, CommonModule],
  templateUrl: './historyviewer.component.html',
  styleUrl: './historyviewer.component.scss'
})
export class HistoryviewerComponent implements OnInit {
  @ViewChild('target', { static: true })
  public scrollTarget: ElementRef;
  private filteredHistories: PUBLISH_HISTORY[]
  public items$: BehaviorSubject<Array<PUBLISH_HISTORY>>;
  public chunkedItems: [PUBLISH_HISTORY[]];
  chunkSize: number = 10;


  constructor(private historyservice: HistoryService) {
    this.items$ = new BehaviorSubject<Array<PUBLISH_HISTORY>>([]);
    this.chunkedItems = [[]]
  }

  ngOnInit(): void {
    this.historyservice.isHistoryFiltering.subscribe(x => {
      if (!x) {
        this.filteredHistories = this.historyservice.getFilteredHistories()
        this.buildChunks()
      }
    })
  }

  private buildChunks() {
    this.chunkedItems = [[]]
    this.items$.next([])
    this.chunkedItems.pop()

    for (let i = 0; i < this.filteredHistories.length; i += this.chunkSize) {
      this.chunkedItems.push(this.filteredHistories.slice(i, i + this.chunkSize))
    }

    this.addChunk()
  }

  public limitReached(): void {
    setTimeout(() => {
      const current = this.items$.getValue();
      if (current.length < 100) {
        this.addChunk(current.length);
      }
    }, 200);
  }

  private addChunk(start = 0): void {
    const current = this.items$.getValue();
    var next: PUBLISH_HISTORY[] = [];
    if (this.chunkedItems.length > 0) {
      next = this.chunkedItems[0];
      this.chunkedItems.shift() //next block
    }
    this.items$.next([...current, ...next]);
  }

  public onLoad(item: any) {
    this.historyservice.loadHistory(item)
  }
  public onDelete(item: any) {
    this.historyservice.deleteHistory(item)
  }
}
