<form [formGroup]="myFormGroup">
    <coreui-card-container>
        <coreui-card class="Card-block" header="Tableau" [text]="cardTxt4" cardType="standard" icon="tableau">
            <ng-template #cardTxt4>
                <coreui-form-field label="Datasource Publish Location" class="block-select">
                    <coreui-select formControlName="selectProject" placeholder="Select option"
                        (valueChange)="onValueChange($event)">
                        <coreui-option *ngFor="let itm of items | orderby:'BreadCrumb':'ASC'" [value]="itm.ProjectID"
                            (select)="onSelect(itm)" (blur)="onSelect(itm)">{{itm.BreadCrumb}}
                        </coreui-option>
                        <span *ngIf="(items || []).length === 0" class="no-results">No results found...</span>
                    </coreui-select>
                    <coreui-form-error>Required</coreui-form-error>
                </coreui-form-field>
            </ng-template>
        </coreui-card>
        <coreui-card class="Card-block" header="Website" [text]="cardTxt5" cardType="standard" icon="tools">
            <ng-template #cardTxt5>
                <coreui-form-field label="Theme" class="block-select">
                    <coreui-toggle formControlName="labelsToggle" [enableHammerIcon]="false" leftLabel="Light"
                        rightLabel="Dark" [backgroundColor]="'white'"
                        (valueChange)="onThemeToggleChange($event)"></coreui-toggle>
                </coreui-form-field>
            </ng-template>
        </coreui-card>
    </coreui-card-container>
</form>