<section class="component-wrapper">
    <h3>Parallel Hint</h3><br />
    <form [formGroup]="myFormGroup">
        <coreui-segmented-control-group label="Usage" formControlName="singleSelection">
            <button type="button" [coreUISegmentedButton]="DISABLED">{{DISABLED}}</button>
            <button type="button" [coreUISegmentedButton]="AUTO">{{AUTO}}</button>
            <button type="button" [coreUISegmentedButton]="DEFAULT">{{DEFAULT}}</button>
            <button type="button" [coreUISegmentedButton]="CUSTOM">{{CUSTOM}}</button>
        </coreui-segmented-control-group>
    </form>
    <form [formGroup]="myFormGroup">
        <coreui-form-field label="# of processes">
            <div class="slider">
                <coreui-slider formControlName="sliderDefault"></coreui-slider>
                <div *ngIf="parallelHint.Usage === CUSTOM">{{ parallelHint.Value }}</div>
            </div>
        </coreui-form-field>
    </form>
</section>
<section class="component-wrapper">
    <h3>SQL</h3><br />
    <form [formGroup]="myFormGroup2">
        <coreui-form-field label="JOIN Syntax" class="block-select">
            <coreui-toggle formControlName="labelsToggle" [enableHammerIcon]="false" leftLabel="ANSI-89"
                rightLabel="ANSI-92" [backgroundColor]="'white'"
                (valueChange)="onOuterJoinToggleChange($event)"></coreui-toggle>
        </coreui-form-field>
    </form>
</section>