import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CoreUIModule, ThemeColorService, Theme } from '@epsilon/core-ui';
import { PROJECT, TABLEAU_SITE, USER_ROLE } from '../../service/datamodel';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { OrderbyPipe } from "../../pipe/orderby.pipe";
import { DatasourceService } from '../../service/datasource.service';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [CoreUIModule, ReactiveFormsModule, CommonModule, OrderbyPipe],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent implements OnInit {
  public myFormGroup = new FormGroup({
    selectProject: new FormControl(null, [Validators.required]),
    labelsToggle: new FormControl(0)
  });
  public items: PROJECT[]
  public targetProject: PROJECT
  private site: TABLEAU_SITE

  constructor(
    private themeColorService: ThemeColorService,
    private _router: Router,
    private datasourceservice: DatasourceService,
    private userservice: UserService
  ) {
    this.themeColorService.setTheme(parseInt(localStorage.getItem('theme')));
    this.myFormGroup.controls.labelsToggle.setValue(parseInt(localStorage.getItem('theme')))
  }

  ngOnInit(): void {

    this.userservice.isTenantSelected.subscribe(x => {
      this.site = this.userservice.getSelectedSite()
      this.datasourceservice.queryProjects()
    })
    this.datasourceservice.projects.subscribe(x => {
      if (this.userservice.getUserRole() === USER_ROLE.POWERUSER)
        this.items = x.filter(p => p.BreadCrumb.includes("Shared"))
      else
        this.items = x

      var activeProject = this.items.filter(i => i.IsActive === 1)
      if (activeProject.length > 0)
        this.myFormGroup.controls["selectProject"].setValue(activeProject[0].ProjectID)
    })
  }

  public onThemeToggleChange(value: boolean): void {
    this.themeColorService.setTheme(value ? Theme.Dark : Theme.Light);
    localStorage.setItem('theme', value ? Theme.Dark.toString() : Theme.Light.toString());
  }

  public onValueChange(e): void {

  }

  public onSelect(item: PROJECT) {
    this.datasourceservice.overwriteLocalSettings(item)
  }

  goBack() {
    this._router.navigateByUrl('');
  }

  onSave() {

  }

}
