import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CoreUIModule, TableHeaderColumnComponent } from '@epsilon/core-ui';
import { OBJECT, OBJECT_TYPE, METADATA_ACTION, METADATA_TYPE, DATA_TYPE } from '../../service/datamodel';
import { Subscription } from 'rxjs';
import { MetadataService } from '../../service/metadata.service';
import { CommonModule } from '@angular/common';
import { OrderbyPipe } from '../../pipe/orderby.pipe';
import { ObjectsmodalComponent } from '../objectsmodal/objectsmodal.component';
import { DeletemodalComponent } from '../deletemodal/deletemodal.component';
import { ActionbarComponent } from '../actionbar/actionbar.component';
import { HighlighterPipe } from '../../pipe/highlighter.pipe';
import { UserService } from '../../service/user.service';
import { DownloadService } from '../../service/download.service';

@Component({
  selector: 'app-objects',
  standalone: true,
  imports: [CoreUIModule, CommonModule, OrderbyPipe, ObjectsmodalComponent, DeletemodalComponent, ActionbarComponent, HighlighterPipe],
  templateUrl: './objects.component.html',
  styleUrl: './objects.component.scss'
})
export class ObjectsComponent implements OnInit, OnDestroy {
  public loadingTable: boolean;
  @ViewChild('objectsModal') objectsModal: ObjectsmodalComponent;
  @ViewChild('alertModal') alertModal: DeletemodalComponent;

  public loadingItems: boolean = false;

  public defaultItems: OBJECT[];
  private defaultItem: OBJECT;
  public chunkedItems: [OBJECT[]];
  public subjectAreas: string[]
  public types: OBJECT_TYPE[];
  public customed: string[]
  public actived: string[];
  private activeCustomed: string;
  private activeActived: string;

  private activeSubjectArea: string;
  private activeType: string;
  private chunkSize = 20

  public defaultSortOption = 'Path';
  public defaultSortDirection = 'ASC';
  public scrollSortOption = '';
  public scrollSortDirection = '';
  private subscription: Subscription
  public DIMENSION: OBJECT_TYPE
  public MEASURE: OBJECT_TYPE
  public FOLDER: OBJECT_TYPE
  public UPDATE: METADATA_ACTION
  public DELETE: METADATA_ACTION
  public INSERT: METADATA_ACTION
  public OBJECT: METADATA_TYPE
  public searchedText: string
  private orderbypipe: OrderbyPipe;

  constructor(private metadataservice: MetadataService, private userservice: UserService, private downloadservice: DownloadService) {
    this.defaultItems = []
    this.DIMENSION = OBJECT_TYPE.DIMENSION
    this.MEASURE = OBJECT_TYPE.MEASURE
    this.FOLDER = OBJECT_TYPE.FOLDER
    this.UPDATE = METADATA_ACTION.UPDATE
    this.DELETE = METADATA_ACTION.DELETE
    this.INSERT = METADATA_ACTION.INSERT
    this.OBJECT = METADATA_TYPE.OBJECT
    this.chunkedItems = [[]]
    this.subjectAreas = []
    this.types = [OBJECT_TYPE.ALL, OBJECT_TYPE.MEASURE, OBJECT_TYPE.DIMENSION, OBJECT_TYPE.STANDALONE, OBJECT_TYPE.SUBQUERY]
    this.customed = ["All", "True", "False"]
    this.actived = ["All", "True", "False"]
    this.activeCustomed = "All"
    this.activeActived = "All"
    this.searchedText = ""
    this.activeSubjectArea = "All"
    this.activeType = OBJECT_TYPE.ALL
    this.defaultItem = {
      ID: 0,
      PID: 0,
      Name: "",
      Path: "",
      Description: "",
      Type: OBJECT_TYPE.MEASURE,
      DataType: DATA_TYPE.STRING,
      Expression: "",
      TenantCode: "",
      IsCustom: 1,
      IsActive: 1
    }
    this.orderbypipe = new OrderbyPipe()

  }

  public ngOnInit(): void {
    this.loadingTable = true;
    this.subscription = this.metadataservice.isMetaObjectsLoading.subscribe(x => {
      this.chunkedItems = [[]]
      this.defaultItems = []
      var objects: OBJECT[] = this.metadataservice.getObjects()
      if (!x && objects.length > 0) {
        var typenull: any;
        objects.filter(o => {
          if (!o)
            typenull = o

        })
        var items = this.orderbypipe.transform(objects.filter(o => o.Type !== this.FOLDER), "Name", "ASC")
        this.chunkedItems.pop()

        for (let i = 0; i < items.length; i += this.chunkSize) {
          this.chunkedItems.push(items.slice(i, i + this.chunkSize))
        }
        this.onScrollLimitReached()
        this.subjectAreas = ["All", ...new Set(this.metadataservice.getSubjectAreas())]
      }
      this.setFilteredItems()
      this.loadingTable = x;
    })


  }

  public scrollSort(
    sortComponent: TableHeaderColumnComponent,
    sortOption
  ): void {
    this.scrollSortOption = sortOption;
    this.scrollSortDirection = sortComponent.sortDirection;
  }


  public sortAscDesc(option: string | string[]): string | undefined {
    return this.defaultSortOption === option ||
      option.includes(this.defaultSortOption)
      ? this.defaultSortDirection === 'ASC'
        ? 'ascending'
        : 'descending'
      : undefined;
  }

  public onScrollLimitReached(): void {
    if (this.chunkedItems.length > 0) {
      this.loadingItems = true;
      setTimeout(() => {
        if (this.chunkedItems.length > 0) {
          this.defaultItems.push(...this.chunkedItems[0]);
          this.chunkedItems.shift() //next block
        }
        this.loadingItems = false;
      }, 500);
    }

  }

  onFilter1(item: string) {
    this.activeSubjectArea = item
    this.setFilteredItems()
  }

  onFilter2(item: string) {
    this.activeType = item
    this.setFilteredItems()
  }

  onFilter3(item: string) {
    this.activeCustomed = item
    this.setFilteredItems()
  }

  onFilter4(item: string) {
    this.activeActived = item
    this.setFilteredItems()
  }


  onSearch(item: string) {
    this.searchedText = item.toUpperCase()
    this.setFilteredItems()
  }

  onReset() {
    this.searchedText = ""
    this.activeSubjectArea = "All"
    this.activeType = OBJECT_TYPE.ALL
    this.activeCustomed = "All"
    this.activeActived = "All"
    this.setFilteredItems()
  }

  onDownload() {
    this.downloadservice.downloadMetadata(this.OBJECT, this.metadataservice.getObjects())

  }

  onRefresh() {
    this.metadataservice.querySpecificMetadata(this.OBJECT)
  }

  private setFilteredItems() {
    this.defaultItems = []
    this.chunkedItems = [[]]
    this.chunkedItems.pop()

    var items = this.metadataservice.getObjects().filter(o => o.Type !== this.FOLDER &&
      (o.Name.toUpperCase().includes(this.searchedText) ||
        o.Description.toUpperCase().includes(this.searchedText) ||
        o.Expression.toUpperCase().includes(this.searchedText))
      && (o.Type === this.activeType || this.activeType === "All")
      && (o.SubjectArea === this.activeSubjectArea || this.activeSubjectArea === "All")
      && ((o.IsCustom === 1 && this.activeCustomed === "True") || (o.IsCustom === 0 && this.activeCustomed === "False") || this.activeCustomed === "All")
      && ((o.IsActive === 1 && this.activeActived === "True") || (o.IsActive === 0 && this.activeActived === "False") || this.activeActived === "All"))

    for (let i = 0; i < items.length; i += this.chunkSize) {
      this.chunkedItems.push(items.slice(i, i + this.chunkSize))
    }
    this.onScrollLimitReached()
  }

  public onEdit(item: OBJECT) {
    this.objectsModal.launchBasicModal(item, this.UPDATE)
  }

  public onAdd() {
    this.objectsModal.launchBasicModal(JSON.parse(JSON.stringify(this.defaultItem)), this.INSERT)
  }

  public onDelete(item: OBJECT) {
    this.alertModal.launchAlertModal(METADATA_TYPE.OBJECT, item)
  }

  public confirmDelete(item: OBJECT) {
    this.metadataservice.deleteMeta(METADATA_TYPE.OBJECT, item)
    this.defaultItems = this.defaultItems.filter(i => i.ID !== item.ID)
  }

  public onActivate(item: OBJECT) {
    item.IsActive = 1
    this.metadataservice.setMeta(item, METADATA_TYPE.OBJECT, this.UPDATE)
  }

  public onDeactivate(item: OBJECT) {
    item.IsActive = 0
    this.metadataservice.setMeta(item, METADATA_TYPE.OBJECT, this.UPDATE)
  }

  public onCustomize(item: OBJECT) {
    item.IsCustom = 1
    this.metadataservice.setMeta(item, METADATA_TYPE.OBJECT, this.UPDATE)
  }

  public onUncustomize(item: OBJECT) {
    item.IsCustom = 0
    this.metadataservice.setMeta(item, METADATA_TYPE.OBJECT, this.UPDATE)
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe()
  }


}
