import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { CoreUIModule, ModalComponent } from '@epsilon/core-ui';
import { METADATA_TYPE } from '../../service/datamodel';

@Component({
  selector: 'app-deletemodal',
  standalone: true,
  imports: [CoreUIModule],
  templateUrl: './deletemodal.component.html',
  styleUrl: './deletemodal.component.scss'
})
export class DeletemodalComponent {
  @ViewChild('alertModal', { static: true })
  private alertModal: ModalComponent;

  @ViewChild('alertModalButton', { read: ElementRef })
  public alertModalElementRef: ElementRef<HTMLButtonElement>;

  @Output('confirmObjectsEvent') confirmObjectsEvent: EventEmitter<any> = new EventEmitter();
  @Output('confirmTablesEvent') confirmTablesEvent: EventEmitter<any> = new EventEmitter();
  @Output('confirmJoinsEvent') confirmJoinsEvent: EventEmitter<any> = new EventEmitter();
  @Output('confirmFoldersEvent') confirmFoldersEvent: EventEmitter<any> = new EventEmitter();



  private item: any;
  private metadata_type: METADATA_TYPE;

  public async closeAlertModal(isDelete: boolean): Promise<void> {
    if (isDelete) {
      if (this.metadata_type === METADATA_TYPE.OBJECT)
        this.confirmObjectsEvent.emit(this.item)
      else if (this.metadata_type === METADATA_TYPE.TABLE)
        this.confirmTablesEvent.emit(this.item)
      else if (this.metadata_type === METADATA_TYPE.JOIN)
        this.confirmJoinsEvent.emit(this.item)
      else if (this.metadata_type === METADATA_TYPE.FOLDER)
        this.confirmFoldersEvent.emit(this.item)
    }

    await this.alertModal.hide();
    // this.alertModalElementRef.nativeElement.focus();
  }

  public async launchAlertModal(mtype: METADATA_TYPE, obj: any): Promise<void> {
    this.item = obj
    this.metadata_type = mtype
    await this.alertModal.show(this.alertModalElementRef);
  }
}
