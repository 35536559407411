<form coreUIForm [formGroup]="myFormGroup">
    <coreui-modal modalWidth="60" #basicModal>
        <ng-template #titleTemplate>
            Save Progress
        </ng-template>
        <ng-template #titleCloseButtonTemplate>
            <button type="button" aria-label="Close" (click)="closeBasicModal()" coreUIButton="icon-large">
                <i coreUIIcon="close"></i></button>
        </ng-template>
        <ng-template #contentTemplate>
            <div class="inline_control">Objects:
                <div *ngIf="listObjects.length > 0" class="inline_space text-style-2">{{listObjects.join(", ")}}</div>
                <div *ngIf="listObjects.length === 0" class="inline_space test-style-5">Select at least one Object</div>
            </div>
            <br />
            <div class="inline_control">Single Rows Filters:<span class="inline_space text-style-2">
                    {{interprettedSinglerowFilters}}</span></div><br />
            <div class="inline_control">Grouped Rows Filters:<span class="inline_space text-style-2">
                    {{interprettedGrouprowFilters}}</span></div><br />
            <div class="inline_control">Save As Name:</div>
            <div class="inline_required">
                <input coreUIInput formControlName="inputDatasourceName" placeholder="Enter a name"
                    class="inline_control" coreUIFixedWidth="short" />
            </div>
            <div *ngIf="datasourceName.length===0" class="inline_required text-style-error">Required</div>
            <br />
            <br />
        </ng-template>
        <ng-template #buttonsTemplate>
            <button type="button" coreUIButton="primary" (click)="saveProgress()"
                [disabled]="!isSubmittable">Save</button>
            <button type="button" coreUIButton="secondary" (click)="closeBasicModal()"
                coreUITrapFocusInitial>Close</button>
        </ng-template>
    </coreui-modal>
</form>