import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CoreUIModule, RangeValue } from '@epsilon/core-ui';
import { MetadataService } from '../../service/metadata.service';
import { ESTIMATE } from '../../service/datamodel';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

export function getPowerFromValue(base: number, exponent: number): number {
  return 2;
}

export function getValueFromPercentage(value: number, bounds: number[]): number {
  return bounds[0] + (bounds[1] - bounds[0]) * value * 0.01;
}
@Component({
  selector: 'app-estimations',
  standalone: true,
  imports: [CoreUIModule, ReactiveFormsModule, CommonModule],
  templateUrl: './estimations.component.html',
  styleUrl: './estimations.component.scss'
})
export class EstimationsComponent implements OnInit, OnDestroy {
  public bounds = [0, 100];
  public defaultSliderValue = 1;
  public estimations: ESTIMATE[] = [];

  public singeSliderForm = new FormGroup({
    sliderEstRowCount: new FormControl([1, 2, 4, 8, 16]),
    sliderEstRefreshTime: new FormControl(),
    sliderCoefficient1: new FormControl(),
    sliderCoefficient2: new FormControl(),
    sliderCoefficient3: new FormControl()
  });
  public singleTempValue: string;
  public multipleTempValue: string;
  public loadingTable = false;

  public control1Options = new Array<ESTIMATE>(...this.estimations);
  private subscription: Subscription;
  constructor(private metadataservice: MetadataService) {
    this.estimations = []
  }

  ngOnInit(): void {
    this.subscription = this.metadataservice.isMetaEstimatesLoading.subscribe(x => {
      if (!x) {
        this.estimations = this.metadataservice.getEstimations()
      }
      this.loadingTable = x;
    })
  }

  public onSliderChange(value: RangeValue): void {
    this.defaultSliderValue = getValueFromPercentage(
      value as number,
      this.bounds
    );
  }


  public onMultipleInputValueChange(value: string): void {
    // const matchedOption = this.estimations.find((f) => f. === value);
    // this.multipleTempValue = matchedOption ? '' : value.trim();
    // this.control1Options = value.trim()
    //   ? this.estimations.filter((f) =>
    //     f.Name.toLowerCase().includes(value.toLowerCase())
    //   )
    //   : [...this.estimations];
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe()
  }

}
