<coreui-modal modalWidth="60" #basicModal>
    <ng-template #titleTemplate>
        {{action}} Object
    </ng-template>
    <ng-template #titleCloseButtonTemplate>
        <button type="button" aria-label="Close" (click)="closeBasicModal()" coreUIButton="icon-large">
            <i coreUIIcon="close"></i></button>
    </ng-template>
    <ng-template #contentTemplate>
        <!---------- Content Here ---------->
        <form [formGroup]="myFormGroup">
            <div class="block_control">
                <coreui-form-field label="Path" class="inline_control">
                    <!--------- Path -------->
                    <coreui-select placeholder="Select" data-qa="select-search" (blur)="onBlur($event)"
                        [searchable]="true" formControlName="selectPath" (searchValueChange)="onSearchChange($event)"
                        coreUIFixedWidth="short">
                        <coreui-option *ngFor="let item of filteredItems | orderby:'name':'ASC'" [value]="item.Name"
                            (blur)="onBlur($event)">
                            {{item.Name}}
                        </coreui-option>
                        <span *ngIf="filteredItems.length === 0" class="no-results text-style-3">
                            No results found
                        </span>
                    </coreui-select>
                    <coreui-form-error>{{errMsgPath}}</coreui-form-error>
                </coreui-form-field>
                <coreui-form-field label="Name" class="inline_control">
                    <input coreUIInput name="inputDefault" formControlName="inputName" (input)="onKeyPress($event)"
                        (keydown)="onKeyPress($event)" />
                    <coreui-form-error>{{errMsgName}}</coreui-form-error>
                </coreui-form-field>
            </div>
            <div class="block_control">
                <coreui-form-field label="Description" class="inline_control_wide">
                    <textarea rows="3" coreUIInput formControlName="textareaDescription" (input)="onKeyPress($event)"
                        (keydown)="onKeyPress($event)"></textarea>
                    <coreui-form-error>{{errMsgDescription}}</coreui-form-error>
                </coreui-form-field>
            </div>
            <div class="block_control">
                <coreui-form-field label="Type" class="inline_control">
                    <coreui-select formControlName="selectType" placeholder="Select" #defaultSelect
                        data-qa="select-default" (blur)="onBlur($event)">
                        <coreui-option [value]="type" *ngFor="let type of types" (select)="onSelect($event)">
                            <div [class]="type">
                                <div *ngIf="type === DIMENSION">
                                    <i coreUIIcon="boxFill" scale="1" class="nav_component"></i>
                                </div>
                                <div *ngIf="type === STANDALONE">
                                    <i coreUIIcon="rectangle" scale="1" class="nav_component"></i>
                                </div>
                                <div *ngIf="type === SUBQUERY">
                                    <i coreUIIcon="object" scale="1" class="nav_component"></i>
                                </div>
                                <div *ngIf="type === MEASURE">
                                    <i coreUIIcon="radioFill" scale="1" class="nav_component"></i>
                                </div>
                            </div>{{type}}
                        </coreui-option>
                    </coreui-select>
                    <coreui-form-error>{{errMsgType}}</coreui-form-error>
                </coreui-form-field>
                <coreui-form-field label="DataType" class="inline_control">
                    <coreui-select formControlName="selectDataType" placeholder="Select" #defaultSelect
                        data-qa="select-default">
                        <coreui-option [value]="datatype" *ngFor="let datatype of datatypes"
                            (select)="onSelect($event)">{{datatype}}</coreui-option>
                    </coreui-select>
                    <coreui-form-error>{{errMsgDataType}}</coreui-form-error>
                </coreui-form-field>
            </div>
            <!----- Expression Builder ----->
            <div class="">
                <div class="text-style-3 expression_label">Expression Builder</div>
                <div class="inline">
                    <app-expressionbuilder [objectType]="activeType" [metadataType]="OBJECT"
                        (expression)="onExpression($event)"></app-expressionbuilder>
                </div>
                <div class="inline_space">
                    <!-- Measure -->
                    <ng-template #contextualTooltip>
                        <span class="tooltipMessage">i.e., avg, count, countd, sum, max, min</span>
                    </ng-template>
                    <div class="tooltip-container" *ngIf="myFormGroup.controls.selectType.value === MEASURE">
                        <div class="tooltip-content">
                            Measure must include an aggregate function
                            <a href="javascript:void(0)" aria-labelledby="coreui-tooltip"
                                [coreUITooltip]="contextualTooltip">
                                <u>aggregate function</u>
                            </a> and usually is numeric.
                        </div>
                    </div>
                    <!-- Dimension -->
                    <ng-template #contextualTooltip2>
                        <span class="tooltipMessage">i.e., regular, aliased, or derived</span>
                    </ng-template>
                    <div class="tooltip-container" *ngIf="myFormGroup.controls.selectType.value === DIMENSION">
                        <div class="tooltip-content">
                            Dimension must be a column of a
                            <a href="javascript:void(0)" aria-labelledby="coreui-tooltip"
                                [coreUITooltip]="contextualTooltip2">
                                <u>physical table</u>
                            </a>
                        </div>
                    </div>
                    <!-- Standalone -->
                    <ng-template #contextualTooltip3>
                        <span class="tooltipMessage">e.g., sysdate() - 7</span>
                    </ng-template>
                    <div class="tooltip-container" *ngIf="myFormGroup.controls.selectType.value === STANDALONE">
                        <div class="tooltip-content">
                            Standalone must be a constant or function and is the
                            <a href="javascript:void(0)" aria-labelledby="coreui-tooltip"
                                [coreUITooltip]="contextualTooltip3">
                                <u> expression part </u>
                            </a>of a DUAL statement.
                        </div>
                    </div>
                    <!-- Subquery -->
                    <ng-template #contextualTooltip4>
                        <span class="tooltipMessage">()</span>
                    </ng-template>
                    <div class="tooltip-container" *ngIf="myFormGroup.controls.selectType.value === SUBQUERY">
                        <div class="tooltip-content">
                            Subquery must be a complete SQL and surrounded by
                            <a href="javascript:void(0)" aria-labelledby="coreui-tooltip"
                                [coreUITooltip]="contextualTooltip4">
                                <u>prentices</u>
                            </a>
                        </div>
                    </div>
                </div>
                <coreui-form-field class="inline_control_wide">
                    <textarea rows="6" coreUIInput formControlName="textareaExpression" (keydown)="onKeyPress($event)"
                        (input)="onKeyPress($event)"></textarea>
                    <coreui-form-error>{{errMsgExpression}}</coreui-form-error>
                    <!----- Message ------>
                </coreui-form-field>
            </div>
        </form>
    </ng-template>
    <ng-template #buttonsTemplate>
        <coreui-load-mask [active]="isObjectValidating" class="block_space"></coreui-load-mask>
        <button type="button" coreUIButton="primary" (click)="saveBasicModal()"
            [disabled]="!isObjectValid">Save</button>
        <button type="button" coreUIButton="secondary" (click)="closeBasicModal()"
            coreUITrapFocusInitial>Cancel</button>
    </ng-template>
</coreui-modal>
<!--------------- Toast --------------->
<coreui-notification notificationStyle="error" #infoError [messageDuration]="3000">
    {{msg}}
</coreui-notification>
<coreui-notification notificationStyle="success" #infoSuccess [messageDuration]="3000">
    {{msg}}
</coreui-notification>