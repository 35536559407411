<form [formGroup]="formGroup">
    <coreui-action-bar>
        <!----- Left Side ----->
        <ng-template #leftTpl>
            <div coreUIButtonGroup class="menu-spacing">
                <button type="button" coreUIButton="icon-large" [coreUITooltip]="'Add a new item'" withBorder
                    (click)="onAdd()">
                    <i coreUIIcon="add" scale="2.5"></i>
                </button>
            </div>
        </ng-template>
        <ng-template #centerTpl>
            <div coreUIButtonGroup class="menu-spacing" *ngIf="filterOptions1">
                <coreui-filter-group [label]="filterName1">
                    <coreui-filter data-qa="costsFilter-filters" panelCssClass="costsPanel" #costsFilter
                        [buttonLabel]="filterActive1" class="costsGroup">
                        <coreui-filter-select [label]="filterName1">
                            <div [class.scroll]="filterOptions1?.length > 8">
                                <button type="button" class="toggle-listItem" [active]="item === filterActive1"
                                    *ngFor="let item of filterOptions1" coreUIFilterOption
                                    (click)="onFilterControl1Click(item)">
                                    {{ item }}
                                </button>
                            </div>
                        </coreui-filter-select>
                    </coreui-filter>
                </coreui-filter-group>
            </div>
            <div coreUIButtonGroup class="menu-spacing" *ngIf="filterOptions2">
                <coreui-filter-group [label]="filterName2">
                    <coreui-filter data-qa="costsFilter-filters" panelCssClass="costsPanel" #costsFilter
                        [buttonLabel]="filterActive2" class="costsGroup">
                        <coreui-filter-select [label]="filterName2">
                            <div [class.scroll]="filterOptions2?.length > 8">
                                <button type="button" class="toggle-listItem" [active]="item === filterActive2"
                                    *ngFor="let item of filterOptions2" coreUIFilterOption
                                    (click)="onFilterControl2Click(item)">
                                    {{ item }}
                                </button>
                            </div>
                        </coreui-filter-select>
                    </coreui-filter>
                </coreui-filter-group>
            </div>
            <div coreUIButtonGroup class="menu-spacing" *ngIf="filterOptions3">
                <coreui-filter-group [label]="filterName3">
                    <coreui-filter data-qa="costsFilter-filters" panelCssClass="costsPanel" #costsFilter
                        [buttonLabel]="filterActive3" class="costsGroup">
                        <coreui-filter-select [label]="filterName3">
                            <div [class.scroll]="filterOptions3?.length > 8">
                                <button type="button" class="toggle-listItem" [active]="item === filterActive3"
                                    *ngFor="let item of filterOptions3" coreUIFilterOption
                                    (click)="onFilterControl3Click(item)">
                                    {{ item }}
                                </button>
                            </div>
                        </coreui-filter-select>
                    </coreui-filter>
                </coreui-filter-group>
            </div>
            <div coreUIButtonGroup class="menu-spacing" *ngIf="filterOptions4">
                <coreui-filter-group [label]="filterName4">
                    <coreui-filter data-qa="costsFilter-filters" panelCssClass="costsPanel" #costsFilter
                        [buttonLabel]="filterActive4" class="costsGroup">
                        <coreui-filter-select [label]="filterName4">
                            <div [class.scroll]="filterOptions4?.length > 8">
                                <button type="button" class="toggle-listItem" [active]="item === filterActive4"
                                    *ngFor="let item of filterOptions4" coreUIFilterOption
                                    (click)="onFilterControl4Click(item)">
                                    {{ item }}
                                </button>
                            </div>
                        </coreui-filter-select>
                    </coreui-filter>
                </coreui-filter-group>
            </div>
            <div coreUIButtonGroup class="menu-spacing">
                <form [formGroup]="searchForm">
                    <div class="input-search">
                        <coreui-table-search placeholder="Search" formControlName="search"
                            coreUIFixedWidth="extraShort"></coreui-table-search>
                    </div>
                </form>
            </div>
            <div coreUIButtonGroup class="menu-spacing">
                <button type="button" coreUIButton="icon-large" [coreUITooltip]="'Reset Filters'" withBorder
                    (click)="onReset()">
                    <i coreUIIcon="undo" scale="2.5"></i>
                </button>
            </div>
            <div coreUIButtonGroup class="menu-spacing-large">
                <button type="button" coreUIButton="icon-large" [coreUITooltip]="'Refresh'" withBorder
                    (click)="onRefresh()">
                    <i coreUIIcon="refresh" scale="2.5"></i>
                </button>
            </div>
            <div coreUIButtonGroup class="menu-spacing-large">
                <button type="button" coreUIButton="icon-large" [coreUITooltip]="'Download Metadata'" withBorder
                    (click)="onDownload()">
                    <i coreUIIcon="download" scale="2.5"></i>
                </button>
            </div>
        </ng-template>
        <ng-template #centerTpl>
        </ng-template>
        <ng-template #rightTpl>
        </ng-template>
    </coreui-action-bar>
</form>