<!---------------------- Modal -------------------------->
<coreui-modal modalWidth="60" #basicModal>
    <ng-template #titleTemplate>
    </ng-template>
    <ng-template #titleCloseButtonTemplate>
        <button type="button" aria-label="Close" (click)="closeBasicModal()" coreUIButton="icon-large"><i
                coreUIIcon="close"></i></button>
    </ng-template>
    <ng-template #contentTemplate>
        <textarea rows="20">{{contentSQL}}</textarea>
    </ng-template>
    <ng-template #buttonsTemplate>
        <button class="icon-tertiary-button" coreUIButton="secondary" withIcon="left" (click)="copySQL()">
            <i coreUIIcon="file"></i>Copy to Clipboard
        </button>
        <button type="button" coreUIButton="secondary" (click)="closeBasicModal()">Close</button>
    </ng-template>
</coreui-modal>
<!------- Info Notification -------->
<coreui-notification notificationStyle="information" #infoCopy>
    The content has been copied to Clipboard
</coreui-notification>