import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MetadataService } from '../../service/metadata.service';
import { CoreUIModule, ModalComponent, NotificationComponent } from '@epsilon/core-ui';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DATA_TYPE, METADATA_ACTION, METADATA_TYPE, TABLE, TABLE_TYPE } from '../../service/datamodel';
import { Subscription } from 'rxjs';
import { OrderbyPipe } from "../../pipe/orderby.pipe";
import { ExpressionbuilderComponent } from '../expressionbuilder/expressionbuilder.component';

@Component({
  selector: 'app-tablesmodal',
  standalone: true,
  imports: [CoreUIModule, ReactiveFormsModule, CommonModule, OrderbyPipe, ExpressionbuilderComponent],
  templateUrl: './tablesmodal.component.html',
  styleUrl: './tablesmodal.component.scss'
})
export class TablesmodalComponent implements OnInit {
  @ViewChild('basicModal', { static: true }) private basicModal: ModalComponent;
  @Output('confirmEvent') confirmEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('infoError', { static: true }) public infoError: NotificationComponent;
  @ViewChild('infoSuccess', { static: true }) public infoSuccess: NotificationComponent;
  public myFormGroup = new FormGroup({
    inputName: new FormControl(""),
    selectName: new FormControl("", [Validators.required]),
    textareaDescription: new FormControl("", [Validators.required]),
    selectAliased: new FormControl("", [Validators.required]),
    textareaExpression: new FormControl("", [Validators.required])
  })
  public myFormGroup2 = new FormGroup({
    singleSelection: new FormControl()
  })
  private subscription: Subscription
  private subChange: Subscription
  public isTableValid: boolean;
  public isTableValidating: boolean;
  public tables: TABLE[]
  public item: TABLE;
  public action: METADATA_ACTION
  public isFormError: boolean;
  public errMsgName: string
  public errMsgDescription: string;
  public errMsgAliased: string;
  public errMsgExpression: string
  public titleName: string;
  public activeTabletype: TABLE_TYPE;
  public REGULAR: TABLE_TYPE;
  public ALIAS: TABLE_TYPE;
  public DERIVED: TABLE_TYPE;
  public TABLE: METADATA_TYPE
  private typing: number;
  private _filteredItems: TABLE[] = [];
  public set filteredItems(value: TABLE[]) {
    this._filteredItems = value;
  }
  public get filteredItems(): TABLE[] {
    return this._filteredItems;
  }
  private table_name: string;
  private column_name: string;
  private posCursor: number;
  public BLOB: DATA_TYPE;
  public BOOLEAN: DATA_TYPE;
  public DATE: DATA_TYPE;
  public DATETIME: DATA_TYPE;
  public LONGTEXT: DATA_TYPE;
  public NUMERIC: DATA_TYPE;
  public STRING: DATA_TYPE
  public msg: string;
  public isExpressionBuilder: boolean;

  constructor(private changeDetectorRef: ChangeDetectorRef, private metadataservice: MetadataService) {
    this.tables = []
    this.isTableValid = false
    this.isTableValidating = false
    this.TABLE = METADATA_TYPE.TABLE;
    this.REGULAR = TABLE_TYPE.REGULAR;
    this.ALIAS = TABLE_TYPE.ALIAS;
    this.DERIVED = TABLE_TYPE.DERIVED;
    this.titleName = "Name"
    this.posCursor = 0
    this.BLOB = DATA_TYPE.BLOB;
    this.BOOLEAN = DATA_TYPE.BOOLEAN;
    this.DATE = DATA_TYPE.DATE;
    this.DATETIME = DATA_TYPE.DATETIME;
    this.LONGTEXT = DATA_TYPE.LONGTEXT;
    this.NUMERIC = DATA_TYPE.NUMERIC;
    this.STRING = DATA_TYPE.STRING;
    this.msg = "";
    this.isExpressionBuilder = false;
    this.activeTabletype = this.REGULAR;
  }

  ngOnInit(): void {

    this.myFormGroup2.controls.singleSelection.setValue(this.REGULAR)

    this.subscription = this.metadataservice.isOracleTableLoading.subscribe(x => {
      if (!x) {
        this.tables = this.metadataservice.getOracleTables()
        this.filteredItems = this.tables
      }
    })

    this.subscription = this.metadataservice.isMetaTableValidating.subscribe(x => {
      this.isTableValidating = x
    })

    this.subscription = this.metadataservice.isMetaTableValid.subscribe(x => {
      if (x) {
        //Success
        this.isTableValid = x.Result
        if (this.isTableValid) {
          this.myFormGroup.controls.inputName.setErrors(null)
          this.myFormGroup.controls.selectName.setErrors(null)
          this.myFormGroup.controls.selectAliased.setErrors(null)
          this.myFormGroup.controls.textareaDescription.setErrors(null)
          this.myFormGroup.controls.textareaExpression.setErrors(null)
        }
        else {
          if (x.Message === "Failed: Table already exists") {
            this.myFormGroup.controls.inputName.setErrors({ "msg": "Failed: A Table with the same name exists" })
            this.errMsgName = "A Table with the same name exists"
          }
          else if (x.Message === "Failed: An Aliased Table with the same name exists" && x.Message === "Failed: Table already exists") {
            this.myFormGroup.controls.selectAliased.setErrors({ "msg": "Failed: An Alias Table with the same name exists" })
            this.errMsgAliased = "An Alias Table with the same name exists"
          }
          else if (x.Message.indexOf("Failed: Invalid Expression") >= 0) {
            this.myFormGroup.controls.textareaExpression.setErrors({ "msg": "Failed: Invalid Expression" })
            this.errMsgExpression = x.Message.replace("Failed: ", "")
          }
        }
      }

    })

    this.subChange = this.myFormGroup2.valueChanges.subscribe(val => this.formChanged(val))

    this.subscription = this.metadataservice.msgMetaAction.subscribe(x => {
      if (x) {
        this.msg = x
        if (this.msg.toUpperCase().indexOf("SUCCESS") >= 0)
          this.showToast(this.infoSuccess)
        else
          this.showToast(this.infoError)
      }
    })
  }

  private formChanged(val: any) {
    this.subChange.unsubscribe()

    this.isTableValid = false
    this.activeTabletype = val.singleSelection

    if (this.activeTabletype === TABLE_TYPE.REGULAR) {
      this.titleName = "Table"
      this.isExpressionBuilder = false
      if (this.action === METADATA_ACTION.UPDATE) {
        this.myFormGroup2.controls.singleSelection.disable()
        this.myFormGroup.controls.selectName.disable()
        this.myFormGroup.controls.inputName.disable()
        this.myFormGroup.controls.selectAliased.disable()
        this.myFormGroup.controls.textareaExpression.disable()
      }
      else {
        this.myFormGroup2.controls.singleSelection.enable()
        this.myFormGroup.controls.selectName.enable()
        this.myFormGroup.controls.inputName.disable()
        this.myFormGroup.controls.selectAliased.disable()
        this.myFormGroup.controls.textareaExpression.disable()
      }

    }
    else if (this.activeTabletype === TABLE_TYPE.ALIAS) {
      this.titleName = "Alias Name"
      this.isExpressionBuilder = false
      if (this.action === METADATA_ACTION.UPDATE) {
        this.myFormGroup2.controls.singleSelection.disable()
        this.myFormGroup.controls.selectName.disable()
        this.myFormGroup.controls.inputName.enable()
        this.myFormGroup.controls.selectAliased.disable()
        this.myFormGroup.controls.textareaExpression.disable()
      }
      else {
        this.myFormGroup2.controls.singleSelection.enable()
        this.myFormGroup.controls.selectName.disable()
        this.myFormGroup.controls.inputName.enable()
        this.myFormGroup.controls.selectAliased.enable()
        this.myFormGroup.controls.textareaExpression.disable()
      }
    }
    else if (this.activeTabletype === TABLE_TYPE.DERIVED) {
      this.titleName = "Table Name"
      this.isExpressionBuilder = true
      if (this.action === METADATA_ACTION.UPDATE) {
        this.myFormGroup2.controls.singleSelection.disable()
        this.myFormGroup.controls.selectName.disable()
        this.myFormGroup.controls.inputName.enable()
        this.myFormGroup.controls.selectAliased.disable()
        this.myFormGroup.controls.textareaExpression.enable()
      }
      else {
        this.myFormGroup2.controls.singleSelection.enable()
        this.myFormGroup.controls.selectName.disable()
        this.myFormGroup.controls.inputName.enable()
        this.myFormGroup.controls.selectAliased.disable()
        this.myFormGroup.controls.textareaExpression.enable()
      }
    }

    this.subChange = this.myFormGroup2.valueChanges.subscribe(val => this.formChanged(val))

  }

  public async closeBasicModal(): Promise<void> {
    await this.basicModal.hide();
    this.isTableValidating = false
  }

  public async saveBasicModal(): Promise<void> {
    this.readControlsPerm()
    this.metadataservice.setMeta(this.item, METADATA_TYPE.TABLE, this.action)
    this.isTableValidating = false
    await this.basicModal.hide()
  }

  public onBlur(event) {
    this.onValidate()
    this.getCaretPos(event)
  }

  public onSelect(event) {
    this.onValidate()
  }

  // find cursor position and return it
  public getCaretPos(event: any): void {
    if (event) {
      const target = event.target as HTMLInputElement | HTMLTextAreaElement;
      this.posCursor = target.selectionStart;
    }
  }

  // show intellisense dropdowns on click
  public onExpression(event: string) {
    var currentText = this.myFormGroup.controls.textareaExpression.value
    let result = currentText.slice(0, this.posCursor) + (currentText.substring(this.posCursor, currentText.length) === ' ' ? '' : ' ') + event + ' ' + currentText.slice(this.posCursor);
    this.myFormGroup.controls.textareaExpression.setValue(result)
    this.onValidate()
  }


  public onKeyPress(event: any) {
    if (this.typing) {
      window.clearTimeout(this.typing);
      this.typing = undefined;
    }
    this.typing = window.setTimeout(() => {
      this.onValidate()
      this.changeDetectorRef.detectChanges();
    }, 600);
  }

  public onValidate() {
    var temp = this.readControlsTemp()

    this.isFormError = false
    if (this.myFormGroup.controls.selectName.enabled && temp.Name.trim() === "") {
      this.myFormGroup.controls.selectName.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgName = "Required"
    }
    if (this.myFormGroup.controls.inputName.enabled && temp.Name.trim() === "") {
      this.myFormGroup.controls.inputName.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgName = "Required"
    }
    if (this.myFormGroup.controls.inputName.enabled && !(/^(\d|\w|[0-9]){0,127}$/).test(this.myFormGroup.controls.inputName.value)) {
      this.myFormGroup.controls.inputName.setErrors({ msg: "Invalid characters used" })
      this.isFormError = true
      this.errMsgName = "Invalid characters used"
    }
    if (temp.Description.trim() === "") {
      this.myFormGroup.controls.textareaDescription.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgDescription = "Required"
    }
    if (this.myFormGroup.controls.selectAliased.enabled && temp.AliasedTable.trim() === "") {
      this.myFormGroup.controls.selectAliased.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgAliased = "Required"
    }
    if (this.myFormGroup.controls.textareaExpression.enabled && temp.Expression.trim() === "") {
      this.myFormGroup.controls.textareaExpression.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgExpression = "Required"
    }

    if (!this.isFormError)
      this.metadataservice.setMetadataValidation(temp, METADATA_TYPE.TABLE, this.action)

  }

  public async launchBasicModal(item: TABLE, action: METADATA_ACTION): Promise<void> {
    this.action = action
    this.item = item
    this.loadControls(item)

    if (this.metadataservice.getOracleTables().length === 0)
      this.metadataservice.querySpecificOracleMetadata(METADATA_TYPE.ORACLETABLES)

    if (this.action === METADATA_ACTION.INSERT)
      this.myFormGroup2.controls.singleSelection.patchValue(TABLE_TYPE.REGULAR)
    //this.formChanged({ singleSelection: TABLE_TYPE.REGULAR })
    else {
      if (item.IsAliased)
        this.myFormGroup2.controls.singleSelection.patchValue(TABLE_TYPE.ALIAS)
      else if (item.IsDerived)
        this.myFormGroup2.controls.singleSelection.patchValue(TABLE_TYPE.DERIVED)
      else
        this.myFormGroup2.controls.singleSelection.patchValue(TABLE_TYPE.REGULAR)

    }
    await this.basicModal.show();
  }

  private loadControls(item: TABLE) {
    this.myFormGroup.controls.inputName.setValue(item.Name)
    this.myFormGroup.controls.selectName.setValue(item.Name)
    this.myFormGroup.controls.textareaDescription.setValue(item.Description)
    this.myFormGroup.controls.selectAliased.setValue(item.AliasedTable)
    this.myFormGroup.controls.textareaExpression.setValue(item.Expression)
  }

  private readControlsTemp() {
    var dummy: TABLE = JSON.parse(JSON.stringify(this.item))
    if (this.activeTabletype === this.REGULAR) {
      dummy.Name = this.myFormGroup.controls.selectName.value
      dummy.IsAliased = 0
      dummy.IsDerived = 0
    }
    else if (this.activeTabletype === this.ALIAS) {
      dummy.Name = this.myFormGroup.controls.inputName.value
      dummy.AliasedTable = this.myFormGroup.controls.selectAliased.value
      dummy.IsAliased = 1
      dummy.IsDerived = 0
    }
    // derived
    else {
      dummy.Name = this.myFormGroup.controls.inputName.value
      dummy.IsAliased = 0
      dummy.IsDerived = 1
    }
    dummy.Description = this.myFormGroup.controls.textareaDescription.value
    dummy.Expression = this.myFormGroup.controls.textareaExpression.value

    return dummy
  }

  private readControlsPerm() {
    if (this.activeTabletype === this.REGULAR) {
      this.item.Name = this.myFormGroup.controls.selectName.value
      this.item.IsAliased = 0
      this.item.IsDerived = 0
    }
    else if (this.activeTabletype === this.ALIAS) {
      this.item.Name = this.myFormGroup.controls.inputName.value
      this.item.AliasedTable = this.myFormGroup.controls.selectAliased.value
      this.item.IsAliased = 1
      this.item.IsDerived = 0
    }
    // derived
    else {
      this.item.Name = this.myFormGroup.controls.inputName.value
      this.item.IsAliased = 0
      this.item.IsDerived = 1
    }
    this.item.Description = this.myFormGroup.controls.textareaDescription.value
    this.item.Expression = this.myFormGroup.controls.textareaExpression.value
  }


  public onSearchChange(searchText): void {
    this.filteredItems = searchText
      ? this.tables.filter(
        (item) =>
          item.Name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      : this.tables;
  }

  public showToast(item: NotificationComponent): void {
    item.show();
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe()
  }

}
