import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlighter',
  standalone: true
})
export class HighlighterPipe implements PipeTransform {

  transform(text: string, searchtext: string, clob?: string, displaytext?: string): string {
    if (searchtext) {
      //link higlighting
      if (clob) {
        if (searchtext.trim() !== '' && clob.toUpperCase().includes(searchtext.toUpperCase())) return '<mark>' + displaytext + '</mark>';
        else return displaytext
      }
      //text highlighting
      else if (searchtext.trim() !== '') {
        //Escape RegEx control characters (i.e. . ^ $ * + - ? ( ) [ ] { } \ | — /)
        searchtext = searchtext.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
        //ignore case
        var regEx = new RegExp(searchtext, "ig");

        if (text) {
          var replaceMask = text.match(regEx)
          //if case-insensitive searchtext found
          if (replaceMask) {
            var newtext = text;
            //replace searchtext in a case-sensitive manner
            for (let i = 0; i < replaceMask.length; i++) {
              newtext = newtext.replaceAll(replaceMask[i], '<~!>' + replaceMask[i] + '</~!>')
            }
            return newtext.replaceAll('~!', 'mark')
          }
        }
        else return text
      }
      return text
    }
    else
      return text
  }

}
