import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MetadataService } from '../../service/metadata.service';
import { map, Observable } from 'rxjs';
import { JOIN, TABLE } from '../../service/datamodel';
import { COREUI_DATA_VIZ_CONFIG, CoreUIDataVizModule, DataVizConfig, DataVizSankeyPoint } from '@epsilon/core-ui/data-viz';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sankey',
  standalone: true,
  imports: [CoreUIDataVizModule, CommonModule],
  templateUrl: './sankey.component.html',
  styleUrl: './sankey.component.scss',
  providers: [{
    provide: COREUI_DATA_VIZ_CONFIG,
    useValue: <DataVizConfig>{
      margins: {
        top: 10,
        bottom: 40,
        left: 10,
        right: 10
      }
    }
  }]
})
export class SankeyComponent implements OnInit {
  @Input() activeTable: string
  @Input() depth: number
  @Output('newTableName') newTableName: EventEmitter<string> = new EventEmitter();
  public joins: JOIN[]
  public joinDept: number
  public joinsDataSource$: Observable<Array<DataVizSankeyPoint>>;
  public joinExpression$: Observable<string>
  private lastID: number

  constructor(private metadataservice: MetadataService) {
  }

  ngOnInit(): void {
    this.joins = this.metadataservice.getJoins()
    this.joinsDataSource$ = this.metadataservice.metaERDjoins.pipe(
      map((x) => {
        // match joins that have tables in either left or right
        // identify joins first
        var availableJoins: JOIN[] = JSON.parse(JSON.stringify(x))
        var firstJoins = x.filter(y => y.LeftTable === this.activeTable || y.RightTable === this.activeTable)
        availableJoins = availableJoins.filter(aj => !firstJoins.includes(aj))
        var partialResultJoins: JOIN[] = []
        var resultJoins: JOIN[] = []
        firstJoins.forEach(fj => {
          partialResultJoins = this.recJoins(availableJoins, fj, 1, this.depth)
          var i = 0
          for (const prj of partialResultJoins) {
            if (i < 16)
              resultJoins.push(prj)
            i += 1
          }
        })

        var uniqueJoins = [...new Set(resultJoins)]

        return uniqueJoins.slice(0, 17).map(j => {
          return {
            source: j.LeftTable,
            target: j.RightTable,
            y: j.ID + 100000
          }
        });
      })
    );
  }

  private recJoins(availableJoins: JOIN[], activeJoin: JOIN, currentDepth: number, maxDepth: number): JOIN[] {

    if (currentDepth < maxDepth) {
      var relatedJoins = availableJoins.filter(j => j.LeftTable === activeJoin.LeftTable
        || j.RightTable === activeJoin.RightTable
        || j.LeftTable === activeJoin.RightTable
        || j.RightTable === activeJoin.LeftTable
      )
      // var relatedTables: string[] = relatedJoins.map(rj => { return rj.LeftTable }).concat(relatedJoins.map(rj => { return rj.RightTable }))
      // var uniqueTables: string[] = [...new Set(relatedTables)]
      availableJoins = availableJoins.filter(aj => !relatedJoins.includes(aj))
      var uniqueJoins = [...new Set(relatedJoins)]
      var newDepth = currentDepth + 1
      uniqueJoins.forEach(j => {
        return this.recJoins(availableJoins, j, newDepth, maxDepth)
      })
      return uniqueJoins
    }
    else
      return [activeJoin]
  }

  public IDtoExpression(ID: number): Observable<string> {
    if (ID) {
      this.lastID = ID - 100000
      return this.metadataservice.metaERDjoins.pipe(
        map((x) => {
          var j = x.filter(y => y.ID === ID - 100000)
          if (j && j.length > 0)
            return j[0].JoinExpression92
          else
            return ""
        })
      );
    }
    else
      return new Observable()

  }

  public onClick(point: any) {
    console.log(this.lastID)
    var j = this.joins.filter(j => j.ID === this.lastID)[0]

    this.newTableName.emit(j.LeftTable === this.activeTable ? j.RightTable : j.LeftTable)


  }

}
