<coreui-table>
    <caption>Prevew Sample Data</caption>
    <colgroup>
        @for (c of tableColumns; track c.Name) {
        <col />
        }
    </colgroup>
    <thead coreUITableSort>
        <tr coreUITableHeaderRow>
            <ng-container *ngFor="let c of tableColumns">
                <th scope="col" aria-label="actions" coreUITableActionColumn>{{c.Name}}</th>
            </ng-container>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="!isLoadingTable && !isTimeOut">
            <tr coreUITableRow *ngFor="let itemRow of tableRows">
                <ng-container *ngFor="let itemCol of itemRow.cols">
                    <td scope="col" aria-label="actions" coreUITableActionColumn>{{itemCol.Value}}</td>
                </ng-container>
            </tr>
        </ng-container>
        <ng-container *ngIf="isLoadingTable">
            <tr coreUITableRow class="Core-LoadingColumn">
                @for (c of tableColumns; track c.Name) {
                <td>
                    <coreui-loading-table-column></coreui-loading-table-column>
                </td>
                }
            </tr>
        </ng-container>
        <ng-container *ngIf="!isLoadingTable && tableColumns.length === 0">
            <tr coreUITableRow>
                <td [attr.colspan]="tableColumns.length">
                    <coreui-graphic-message label="No Data Available" graphic="noData">
                        Try again later.
                    </coreui-graphic-message>
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="!isLoadingTable && isTimeOut">
            <tr coreUITableRow>
                <td [attr.colspan]="tableColumns.length">
                    <coreui-graphic-message label="No Preview Data Available" graphic="noData">
                        Timed out after 60 seconds. Please simplify data model or publish datasource to Tableau.
                    </coreui-graphic-message>
                </td>
            </tr>
        </ng-container>
    </tbody>
</coreui-table>