<coreui-action-bar class="area-actionbar">
    <ng-template #leftTpl>
        <button type="button" coreUIButton (click)="launchBasicModal1()" withIcon="left" class="menu-spacing"
            [coreUITooltip]="tooltip2" #launchBasicModalButton [disabled]="isPublishDisabled"><i
                coreUIIcon="upload"></i>Publish</button>
    </ng-template>
    <ng-template #rightTpl>
        <button type="button" coreUIButton (click)="viewData()" withIcon="left" class="menu-spacing"
            [coreUITooltip]="tooltip"><i coreUIIcon="eyeOpen"></i>View Samples</button>
    </ng-template>
</coreui-action-bar>
<app-datatable></app-datatable>
<!---------------- Modal ---------------->
<app-publishmodal #basicModal1></app-publishmodal>
<!---------------- Tool Tips ---------------->
<ng-template #tooltip>
    <span class="tooltipMessage">
        The data are limited to the first 1000 rows.
    </span>
</ng-template>
<ng-template #tooltip2>
    <span class="tooltipMessage">
        Published data set to Tableau server.
        Live data source
    </span>
</ng-template>
<!--------------- Toast --------------->
<coreui-notification notificationStyle="error" #infoFilterError>
    Error(s) exist in the filter container(s).
</coreui-notification>
<coreui-notification notificationStyle="information" #infoNoObjectError>
    Select at least one Result Object.
</coreui-notification>
<coreui-notification notificationStyle="error" #infoQueryError [messageDuration]="2500">
    Unable to retrieve sample data.
</coreui-notification>