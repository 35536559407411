<div cdkDropList [id]="'droppablefilter_' + containerType + '_' + containerID" [cdkDropListData]="items"
    (cdkDropListDropped)="drop($event)" [class]="'nav-list-' + containerType"
    [cdkDropListConnectedTo]="['draggableobject', 'droppableobject', 'searchedobject']"
    [cdkDropListSortingDisabled]="isDragging">
    <!---------- Drag Container ---------->
    <div *ngFor="let item of items">
        <div cdkDrag>
            <!------- Filter Item -------->
            <div class="nav__item" (mouseenter)="onMouseEntered(item)" (mouseout)="onMouseExitted(item)">
                <div class="nav_component">
                    <i coreUIIcon="dragHandle" scale="1"></i>
                </div>
                <!------- Filter Type & Name -------->
                <div [class]="item.Type">
                    <div *ngIf="item.Type === DIMENSION">
                        <i coreUIIcon="boxFill" scale="1" class="nav_component"></i>
                    </div>
                    <div *ngIf="item.Type === STANDALONE">
                        <i coreUIIcon="rectangle" scale="1" class="nav_component"></i>
                    </div>
                    <div *ngIf="item.Type === SUBQUERY">
                        <i coreUIIcon="object" scale="1" class="nav_component"></i>
                    </div>
                    <div *ngIf="item.Type === MEASURE">
                        <i coreUIIcon="radioFill" scale="1" class="nav_component"></i>
                    </div>
                </div>
                <div class="nav_component">
                    <span>{{ item.Name }}</span>
                </div>
                <!------- Comparison Operator -------->
                <app-filteroperator [containerID]="containerID" [containerType]="containerType" [item]="item"
                    class="nav_component"></app-filteroperator>
                <!------- Subquery Display -------->
                <ng-container
                    *ngIf="!item.isSubqueryMode && (item.FilterMethod !== M_IS_NULL || item.FilterMethod !== M_IS_NOT_NULL)">
                    <!------- Filter Value -------->
                    <app-filtervalue [containerID]="containerID" [containerType]="containerType" [item]="item"
                        class="nav_component" *ngIf="item.SubqueryObjID === undefined"></app-filtervalue>
                    <app-subquerydisplay [containerID]="containerID" [containerType]="containerType" [parent]="item"
                        *ngIf="item.SubqueryObjID !== undefined"></app-subquerydisplay>
                </ng-container>
                <!------- subquery drop -------->
                <ng-container *ngIf="item.isSubqueryMode">
                    <app-subquerydrop [containerID]="containerID" [containerType]="containerType" [parent]="item"
                        class="nav_component"></app-subquerydrop>
                </ng-container>
                <!-- Filter Status Indicator -->
                <div class="nav_component">
                    <i coreUIIcon="success" class="success" *ngIf="item.IsValid" scale="1.4"></i>
                    <i coreUIIcon="error" class="error" *ngIf="!item.IsValid" scale="1.4"></i>
                </div>
                <!------- Remove Button ------->
                <div class="nav_component">
                    <a coreUIPrimaryNavigationItemLink (click)="onRemove(item)" class="new-tab-icon"><i
                            coreUIIcon="close" scale="1.2"></i></a>
                </div>
            </div>
            <!------- Logical Operator -------->
            <form [formGroup]="myFormGroup">
                <div class="nav__operator">
                    <coreui-select
                        [formControlName]="'logical_operator_'+ containerType + '_' + containerID + '_' + item.ID + '_'  + item.FilterID"
                        placeholder="AND" type="secondary" coreUIFixedWidth="extraShort"
                        *ngIf="item.FilterIndex < items.length - 1">
                        <coreui-option [value]="AND">AND</coreui-option>
                        <coreui-option [value]="OR">OR</coreui-option>
                    </coreui-select>
                </div>
            </form>
            <!------- Preview Container -------->
            <coreui-card-container *cdkDragPreview>
                <coreui-card role="button" tabindex="0" [header]="item.Name" class="Card-small" [text]="cardTxt4">
                    <ng-template #cardTxt4>
                        <div class="custom-definition text-style-3">
                            Subject Area: <span class="text-style-2">{{ item.SubjectArea }}</span> <br />
                            Object Type: <span class="text-style-2">{{ item.Type }}</span> <br />
                            Data Type: <span class="text-style-2">{{item.DataType}}</span>
                        </div>
                        <div coreUIDivider="secondary"></div>
                        <div class="custom-definition text-style-3">
                            {{ item.Description }}<br />
                        </div>
                    </ng-template>
                </coreui-card>
            </coreui-card-container>
        </div>
    </div>
</div>
<!------- Info Notification -------->
<div>
    <coreui-notification notificationStyle="warning" #infoNotMeasure>
        A measure can only dropped inside Grouped Rows filter container.
    </coreui-notification>
    <coreui-notification notificationStyle="warning" #infoNotDimension>
        A dimension can only dropped inside Single Rows filter container.
    </coreui-notification>
    <coreui-notification notificationStyle="information" #infoMustInclude>
        A grouped row filter must also be included in the result objects.
    </coreui-notification>
    <coreui-notification notificationStyle="warning" #infoNoBLOB>
        A BLOB object cannot be placed in a filter container.
    </coreui-notification>
    <coreui-notification notificationStyle="information" #infoStandalone>
        A Standalone Object must be dropped in a subquery container.
    </coreui-notification>
    <coreui-notification notificationStyle="information" #infoSubq>
        A Subquery Object must be dropped in a subquery container.
    </coreui-notification>
    <coreui-notification notificationStyle="information" #infoDataTypes>
        {{filterDataType}} is expected where as {{subqueryDataType}} is provided
    </coreui-notification>
</div>