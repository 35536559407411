import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CoreUIModule, RangeValue } from '@epsilon/core-ui';
import { PARALLEL_HINT, PARALLEL_HINT_USAGE } from '../../service/datamodel';
import { Subscription } from 'rxjs';
import { AdminService } from '../../service/admin.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-optimizations',
  standalone: true,
  imports: [CoreUIModule, ReactiveFormsModule, CommonModule],
  templateUrl: './optimizations.component.html',
  styleUrl: './optimizations.component.scss'
})
export class OptimizationsComponent implements OnInit, OnDestroy {
  @Input() parallelHint: PARALLEL_HINT;
  @Input() joinSyntax: string;
  public bounds = [1, 4];
  public myFormGroup: FormGroup = new FormGroup({
    singleSelection: new FormControl(),
    sliderDefault: new FormControl()
  })
  public myFormGroup2: FormGroup = new FormGroup({
    labelsToggle: new FormControl(0)
  })

  public DISABLED = PARALLEL_HINT_USAGE.DISABLED
  public DEFAULT = PARALLEL_HINT_USAGE.DEFAULT
  public CUSTOM = PARALLEL_HINT_USAGE.CUSTOM
  public AUTO = PARALLEL_HINT_USAGE.AUTO
  private valueChangesSubscription: Subscription
  private valueChangesSubscription2: Subscription


  constructor(private adminservice: AdminService, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.myFormGroup2.controls["labelsToggle"].setValue(this.joinSyntax === "ANSI92" ? 1 : 0)
    this.valueChangesSubscription = this.myFormGroup.valueChanges.subscribe(val => this.myValueChange(val))
    this.valueChangesSubscription2 = this.myFormGroup2.valueChanges.subscribe(val => this.myValueChange2(val))
    this.setSlider()
  }

  private setSlider() {
    this.myFormGroup.controls["singleSelection"].setValue(this.parallelHint.Usage)
    this.myFormGroup.controls['sliderDefault'].setValue(this.getPercentageFromValue(this.parallelHint.Value, []))
    if (this.parallelHint.Usage === this.DISABLED || this.parallelHint.Usage === this.AUTO || this.parallelHint.Usage === this.DEFAULT) {
      this.myFormGroup.controls["sliderDefault"].disable()
    }
    else {
      this.myFormGroup.controls["sliderDefault"].enable()
    }
    this.changeDetectorRef.detectChanges();

  }

  private myValueChange(val) {
    if (this.valueChangesSubscription)
      this.valueChangesSubscription.unsubscribe()

    this.parallelHint.Usage = val.singleSelection
    if (this.parallelHint.Usage === PARALLEL_HINT_USAGE.CUSTOM && val.sliderDefault !== undefined)
      this.parallelHint.Value = this.getValueFromPercentage(val.sliderDefault, [])

    this.setSlider()

    this.valueChangesSubscription = this.myFormGroup.valueChanges.subscribe(val => this.myValueChange(val))

    this.adminservice.setOptimization(this.parallelHint.Usage === PARALLEL_HINT_USAGE.CUSTOM ?
      this.parallelHint.Value : this.parallelHint.Usage, this.joinSyntax ? "ANSI92" : "ANSI89")

    this.changeDetectorRef.detectChanges();

  }

  private myValueChange2(val) {
    this.joinSyntax = val.labelsToggle
    this.adminservice.setOptimization(this.parallelHint.Usage === PARALLEL_HINT_USAGE.CUSTOM ?
      this.parallelHint.Value : this.parallelHint.Usage, this.joinSyntax ? "ANSI92" : "ANSI89")

    this.changeDetectorRef.detectChanges();

  }


  private getPercentageFromValue(value: number, bounds: number[]): number {
    var exponent = Math.log2(value) - 1
    return exponent * 25
  }

  private getValueFromPercentage(value: number, bounds: number[]): number {
    var tick: number;
    if (value < 12.5)
      tick = 0
    else if (value >= 12.5 && value < 37.5)
      tick = 1
    else if (value >= 37.5 && value < 62.5)
      tick = 2
    else if (value >= 62.5 && value < 87.5)
      tick = 3
    else if (value >= 87.5)
      tick = 4

    return Math.pow(2, tick + 1)
  }

  public onOuterJoinToggleChange(event) {

  }

  ngOnDestroy(): void {
    if (this.valueChangesSubscription)
      this.valueChangesSubscription.unsubscribe()
    if (this.valueChangesSubscription2)
      this.valueChangesSubscription2.unsubscribe()
  }
}
