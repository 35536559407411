import { Pipe, PipeTransform } from '@angular/core';
import { TABLEAU_SITE } from '../service/datamodel';


@Pipe({
  name: 'sitefilter',
  standalone: true
})
export class SitefilterPipe implements PipeTransform {

  transform(sites: TABLEAU_SITE[], onlyActive: boolean): TABLEAU_SITE[] {
    if (sites) {
      if (onlyActive === true)
        return sites.filter(s => !(s.SiteCode === 'Development'
          || s.SiteCode === 'Staging' || s.SiteCode === 'ProdCentral' || s.SiteCode === 'Default' || s.SiteName.indexOf('Z_') >= 0
        ) && s.IsActive === 1)
      else return sites
    }
    else
      return []


  }
}
