import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderby',
  standalone: true
})
export class OrderbyPipe implements PipeTransform {

  transform(source: any[], sortOption: string, sortDirection: string): any[] {
    const thisRef = this;
    //console.log('order pipe' + Date())
    if (source) {
      if (source.length > 0) {
        switch (sortOption.toUpperCase()) {
          case 'NAME':
            source.sort(function (a: any, b: any): any {
              const nameA = a.Name.toUpperCase();
              const nameB = b.Name.toUpperCase();
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                nameA,
                nameB
              );
            });
            break;
          case 'SITENAME':
            source.sort(function (a: any, b: any): any {
              const nameA = a.SiteName.toUpperCase();
              const nameB = b.SiteName.toUpperCase();
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                nameA,
                nameB
              );
            });
            break;
          case 'BREADCRUMB':
            source.sort(function (a: any, b: any): any {
              const nameA = a.BreadCrumb.toUpperCase();
              const nameB = b.BreadCrumb.toUpperCase();
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                nameA,
                nameB
              );
            });
            break;
          case 'PATH':
            source.sort(function (a: any, b: any): any {
              const nameA = a.Path.toUpperCase();
              const nameB = b.Path.toUpperCase();
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                nameA,
                nameB
              );
            });
            break;
          case 'TYPE':
            source.sort(function (a: any, b: any): any {
              const nameA = a.Type.toUpperCase();
              const nameB = b.Type.toUpperCase();
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                nameA,
                nameB
              );
            });
            break;
          case 'DATATYPE':
            source.sort(function (a: any, b: any): any {
              const nameA = a.DataType.toUpperCase();
              const nameB = b.DataType.toUpperCase();
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                nameA,
                nameB
              );
            });
            break;
          case 'ALIASEDTABLE':
            source.sort(function (a: any, b: any): any {
              const nameA = a.AliasedTable.toUpperCase();
              const nameB = b.AliasedTable.toUpperCase();
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                nameA,
                nameB
              );
            });
            break;
          case 'LEFTTABLE':
            source.sort(function (a: any, b: any): any {
              const nameA = a.LeftTable.toUpperCase();
              const nameB = b.LeftTable.toUpperCase();
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                nameA,
                nameB
              );
            });
            break;
          case 'RIGHTTABLE':
            source.sort(function (a: any, b: any): any {
              const nameA = a.RightTable.toUpperCase();
              const nameB = b.RightTable.toUpperCase();
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                nameA,
                nameB
              );
            });
            break;
          case 'JOINTYPE':
            source.sort(function (a: any, b: any): any {
              const nameA = a.JoinType.toUpperCase();
              const nameB = b.JoinType.toUpperCase();
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                nameA,
                nameB
              );
            });
            break;
          case 'ISACTIVE':
            source.sort(function (a: any, b: any): any {
              const numA: number = a.IsActive ? 1 : 0;
              const numB: number = b.IsActive ? 1 : 0;
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                numA,
                numB
              );
            });
            break;
          case 'ISCUSTOM':
            source.sort(function (a: any, b: any): any {
              const numA: number = a.IsCustom ? 1 : 0;
              const numB: number = b.IsCustom ? 1 : 0;
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                numA,
                numB
              );
            });
            break;
          case 'UPDATETS':
            source.sort(function (a: any, b: any): any {
              const nameA = (a.UpdateTS !== null) ? a.UpdateTS.toUpperCase() : '';
              const nameB = (b.UpdateTS !== null) ? b.UpdateTS.toUpperCase() : '';
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                nameA,
                nameB
              );
            });
            break;
          case 'CREATETS':
            source.sort(function (a: any, b: any): any {
              const nameA = (a.CreateTS !== null) ? a.CreateTS.toUpperCase() : '';
              const nameB = (b.CreateTS !== null) ? b.CreateTS.toUpperCase() : '';
              return thisRef.getSortedBasedOnDirection(
                sortDirection,
                nameA,
                nameB
              );
            });
            break;
        }
      }
    }
    return source;
  }

  private getSortedBasedOnDirection(sortDirection: any, nameA: any, nameB: any) {
    switch (sortDirection) {
      case 'ASC':
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
        break;
      case 'DESC':
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
        break;
      default:
        return 0;
    }
  }
}
