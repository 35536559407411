import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FILTER, OBJ, OBJECT_TYPE } from '../../service/datamodel';
import { BuilderService } from '../../service/builder.service';
import { CdkDrag, CdkDropList, CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragPreview, CdkDragEnter, CdkDragExit, CdkDragStart, CdkDragEnd, CdkDragRelease } from '@angular/cdk/drag-drop';
import { CoreUIModule, NotificationComponent } from '@epsilon/core-ui';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-droppableobject',
  standalone: true,
  imports: [CoreUIModule, CommonModule, CdkDrag, CdkDropList, CdkDragPreview],
  templateUrl: './droppableobject.component.html',
  styleUrl: './droppableobject.component.scss'
})
export class DroppableobjectComponent implements OnInit {
  @Input() purpose: string
  public items: OBJ[];
  @ViewChild('infoDuplicate', { static: true })
  public infoDuplicate: NotificationComponent;
  @ViewChild('infoStandalone', { static: true })
  public infoStandalone: NotificationComponent;
  @ViewChild('infoSubquery', { static: true })
  public infoSubquery: NotificationComponent;
  public STANDALONE: OBJECT_TYPE;
  public DIMENSION: OBJECT_TYPE;
  public MEASURE: OBJECT_TYPE;
  public SUBQUERY: OBJECT_TYPE

  constructor(private builderservice: BuilderService) {
    this.items = []
    this.STANDALONE = OBJECT_TYPE.STANDALONE
    this.DIMENSION = OBJECT_TYPE.DIMENSION
    this.MEASURE = OBJECT_TYPE.MEASURE
    this.SUBQUERY = OBJECT_TYPE.SUBQUERY
  }

  ngOnInit(): void {

    this.builderservice.selectedObjects.subscribe(x => {
      this.items = x;
    })

  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else if (event.previousContainer.id === "draggableobject" || event.previousContainer.id === "searchedobject") {
      // Need to check if the same object doesn't exist in the target container
      var droppedItem: OBJ = event.previousContainer.data[event.previousIndex]
      if (event.container.data.filter(i => i.ID === droppedItem.ID).length > 0)
        this.showToast(this.infoDuplicate)
      // else if (droppedItem.Type === this.SUBQUERY)
      //   this.showToast(this.infoSubquery)
      else
        this.builderservice.addSelectedObject(droppedItem)

    }
    else if (event.previousContainer.id.indexOf('droppablefilter_') >= 0) {
      var droppedFilter: FILTER = event.previousContainer.data[event.previousIndex]
      var prevDOMID = event.previousContainer.id
      var prevContainerType = prevDOMID.substring(prevDOMID.indexOf('_') + 1, prevDOMID.lastIndexOf('_'))
      var prevContainerIndex = parseInt(prevDOMID.substring(prevDOMID.lastIndexOf('_') + 1, prevDOMID.length))
      this.builderservice.removeSelectedFilter(prevContainerType, prevContainerIndex, droppedFilter)
    }
  }

  public showToast(item: NotificationComponent): void {
    item.show();
  }

  public onRemove(item: OBJ) {
    this.builderservice.removeSelectedObject(item)
  }

  onDragEnter(event: CdkDragEnter<any[]>) {
    if (event.container.id !== 'droppableobject')
      this.builderservice.setDragging(true, event.container.id)
  }

  onDragExit(event: CdkDragExit<any[]>) {
    if (event.container.id !== 'droppableobject')
      this.builderservice.setDragging(false, event.container.id)
  }

  onDragStart(event: CdkDragStart<any[]>) {
    this.builderservice.setDragging(true, event.source.reset())
  }

  onDragRelease(event: CdkDragRelease<any[]>) {
    this.builderservice.setDragging(false, event.source.reset())
  }
}

