<coreui-modal modalWidth="60" #basicModal>
    <ng-template #titleTemplate>
        {{action}} Join
    </ng-template>
    <ng-template #titleCloseButtonTemplate>
        <button type="button" aria-label="Close" (click)="closeBasicModal()" coreUIButton="icon-large">
            <i coreUIIcon="close"></i></button>
    </ng-template>
    <ng-template #contentTemplate>
        <!---------- Content Here ---------->
        <form [formGroup]="myFormGroup">
            <div class="block_control">
                <div class="inline_control_no_margin">
                    <coreui-form-field label="Left Table" class="inline_control">
                        <coreui-select placeholder="Select Table" data-qa="select-search" [searchable]="true"
                            formControlName="selectLeftTable" (searchValueChange)="onSearchChange($event)"
                            coreUIFixedWidth="short" (blur)="onBlur($event)">
                            <coreui-option *ngFor="let item of filteredItems | activeonly | orderby:'name':'ASC'"
                                [value]="item.Name" (blur)="onBlur($event)">
                                <i coreUIIcon="table" scale="1.2"
                                    *ngIf="item.IsAliased === 0 && item.IsDerived === 0"></i>
                                <i coreUIIcon="clone" scale="1.2" *ngIf="item.IsAliased === 1"></i>
                                <i coreUIIcon="tools" scale="1.2" *ngIf="item.IsDerived === 1"></i>
                                {{item.Name}}
                            </coreui-option>
                            <span *ngIf="filteredItems.length === 0" class="no-results text-style-3">
                                No results found
                            </span>
                        </coreui-select>
                        <coreui-form-error>{{errMsgLeftTable}}</coreui-form-error>
                    </coreui-form-field>
                </div>
                <div class="inline_control_no_margin">
                    <coreui-form-field label="Right Table" class="inline_control">
                        <coreui-select placeholder="Select Table" data-qa="select-search" [searchable]="true"
                            formControlName="selectRightTable" (searchValueChange)="onSearchChange2($event)"
                            coreUIFixedWidth="short" (blur)="onBlur($event)">
                            <coreui-option *ngFor="let item of filteredItems2 | activeonly | orderby:'name':'ASC'"
                                [value]="item.Name" (blur)="onBlur($event)">
                                <i coreUIIcon="table" scale="1.2"
                                    *ngIf="item.IsAliased === 0 && item.IsDerived === 0"></i>
                                <i coreUIIcon="clone" scale="1.2" *ngIf="item.IsAliased === 1"></i>
                                <i coreUIIcon="tools" scale="1.2" *ngIf="item.IsDerived === 1"></i>
                                {{item.Name}}
                            </coreui-option>
                            <span *ngIf="filteredItems2.length === 0" class="no-results text-style-3">
                                No results found
                            </span>
                        </coreui-select>
                        <coreui-form-error>{{errMsgRightTable}}</coreui-form-error>
                    </coreui-form-field>
                </div>
            </div>
            <!------ Expression Builder ------>
            <div class="block_control">
                <div class="text-style-3 expression_label">Join Expression Builder(ANSI-92)</div>
                <app-expressionbuilder [metadataType]="JOIN" [leftTableName]="activeLeftTableName"
                    [rightTableName]="activeRightTableName" (expression)="onExpression($event)"></app-expressionbuilder>
                <coreui-form-field class="inline_control_wide">
                    <textarea rows="3" coreUIInput formControlName="textareaJoinExpression92"
                        (blur)="onTextBlur($event)" (input)="onKeyPress($event)"></textarea>
                    <coreui-form-error>{{errMsgJoinExpression92}}</coreui-form-error>
                </coreui-form-field>
            </div>
            <div class="block_control">
                <coreui-form-field label="Join Type (ANSI-92)" class="inline_control">
                    <coreui-select formControlName="selectJoinType" placeholder="Select" (blur)="onBlur($event)">
                        <coreui-option *ngFor="let jointype of joinTypes"
                            [value]="jointype">{{jointype}}</coreui-option>
                    </coreui-select>
                    <coreui-form-error>{{errMsgJoinType}}</coreui-form-error>
                </coreui-form-field>
            </div>
        </form>
    </ng-template>
    <ng-template #buttonsTemplate>
        <coreui-load-mask [active]="isJoinValidating" class="block_space"></coreui-load-mask>
        <button type="button" coreUIButton="primary" (click)="saveBasicModal()" [disabled]="!isJoinValid">Save</button>
        <button type="button" coreUIButton="secondary" (click)="closeBasicModal()"
            coreUITrapFocusInitial>Cancel</button>
    </ng-template>
</coreui-modal>
<!--------------- Toast --------------->
<coreui-notification notificationStyle="error" #infoError [messageDuration]="3000">
    {{msg}}
</coreui-notification>
<coreui-notification notificationStyle="success" #infoSuccess [messageDuration]="3000">
    {{msg}}
</coreui-notification>