import { IconType } from "@epsilon/core-ui";

export interface OBJ {
    ID: number;
    PID: number;
    Path: string;
    Name: string;
    Description: string;
    Type: OBJECT_TYPE;
    DataType: DATA_TYPE;
    SubjectArea: string;
    IsActive?: number;
    IsError: number;
    IsHierarchy?: boolean;
}

export interface OBJECT {
    ID: number;
    PID?: number;
    Path: string;
    Name: string;
    Description: string;
    Type: OBJECT_TYPE;
    DataType: DATA_TYPE;
    SubjectArea?: string;
    Expression: string;
    Excerpt?: string;
    DistinctRowCount?: number;
    Density?: number;
    IsCustom?: number;
    IsActive?: number;
    IsError?: number;
    SortOrder?: number;
    TenantCode?: string;
    CreateTS?: Date;
    UpdateTS?: Date;
}

export interface FOLDER {
    ID: number;
    PID?: number;
    Path: string;
    Name: string;
    Type: OBJECT_TYPE;
    Description: string;
    SubjectArea?: string;
    IsCustom?: number;
    IsActive?: number;
    SortOrder?: number;
    TenantCode?: string;
    CreateTS?: Date;
    UpdateTS?: Date;
}

export interface FILTER {
    ID: number;
    Name?: string;
    SubjectArea?: string;
    Description?: string;
    Type?: OBJECT_TYPE;
    DataType?: DATA_TYPE;
    FilterID?: string;
    ComparisonOperator: COMPARISON_OPERATOR;
    FilterValue: string;
    FilterValue2: string;
    FilterIndex: number;
    FilterMethod: FILTER_METHOD;
    FilterLogicalOperator: LOGICAL_OPERATOR;
    FilterContainerType: FILTER_CONTAINER_TYPE;
    isSubqueryMode?: Boolean;
    SubqueryObjID?: number;
    SubqueryObj?: OBJ;
    IsValid?: boolean;
}


export interface OBJ_EXPORT {
    ID: number;
}

export interface HIERARCHY {
    PID?: number;
    ID: number;
    Name: string;
    Description: string;
    SortOrder?: number;
    Children: HIERARCHY[];
    IsActive: number;
    IsDisplay?: boolean;
    IsExpand?: boolean;
}

export interface TABLE {
    ID: number;
    Name: string;
    Description: string;
    AliasedTable: string;
    IsDerived: number;
    IsAliased: number;
    Expression: string;
    Excerpt?: string;
    RowCount?: number;
    IsActive?: number;
    CreateTS?: Date;
    UpdateTS?: Date;
    IsCustom?: number;
}

export interface JOIN {
    ID: number;
    LeftTableID?: number;
    RightTableID?: number;
    LeftTable: string;
    RightTable: string;
    JoinExpression89: string;
    JoinExpression92: string;
    Cardinality?: string;
    JoinType: string;
    IsShortcut?: number;
    Excerpt89?: string;
    Excerpt92?: string;
    UniquenessIndex?: number;
    IsActive?: number;
    CreateTS?: Date;
    UpdateTS?: Date;
    IsCustom?: number;
}

export interface PATH {
    ID: number;
    PID: number;
    Name: string;
    IsActive: number;
}

export interface SUBJECT {
    Name: string;
    SortOrder: number;
    Objects?: OBJ[];
}

export interface COLUMN {
    Name: string;
    Value?: any;
    DataType?: string;
    Type?: string;
}

export interface ROW {
    cols: COLUMN[];
}

export interface OPERATOR {
    Name: COMPARISON_OPERATOR
}

export interface TABLEAU_SITE {
    SiteCode: string;
    SiteName: string;
    SiteID: string;
    UserName?: string;
    RoleName?: string;
    AdminLevel?: number;
    UserID?: string;
    IsActive?: number;
}

export interface USER {
    Name: string;
    EmailAddress: string;
    MaxUserRole?: USER_ROLE;
}

export interface FILTER_CONTAINER {
    ID: number;
    LogicalOperator: LOGICAL_OPERATOR;
    Filters: FILTER[];
    Type: FILTER_CONTAINER_TYPE;
}

export interface HTTP_RESPONSE {
    Result: string;
    Message?: string;
    Data: any;
}

export interface FILTER_CONTAINER_EXPORT {
    ID: number;
    LogicalOperator: LOGICAL_OPERATOR;
    Filters: FILTER[];
    Type: FILTER_CONTAINER_TYPE;
}

export interface PUBLISH_HISTORY {
    DatasourceName: string;
    TenantCode: string;
    Objects: OBJ[];
    JSONObjects?: string;
    SinglerowFilterContainers: FILTER_CONTAINER[];
    GrouprowFilterContainers: FILTER_CONTAINER[];
    NumOfFilters?: number;
    CreateBy: string;
    CreateTS: Date;
    UpdateBy: string;
    UpdateTS: Date;
    Action: IconType;
    ID: number;
}

export interface PUBLISH_HISTORY_EXPORT {
    DatasourceName: string;
    TenantCode: string;
    Objects: OBJ_EXPORT[];
    SinglerowFilterContainers: FILTER_CONTAINER_EXPORT[];
    GrouprowFilterContainers: FILTER_CONTAINER_EXPORT[];
    CreateBy: string;
    CreateTS: Date;
    Action: IconType;
}

export interface PUBLISH_HISTORY_IMPORT {
    DatasourceName: string;
    TenantCode: string;
    Objects: OBJ_EXPORT[];
    SinglerowFilterContainers: FILTER_CONTAINER_EXPORT[];
    GrouprowFilterContainers: FILTER_CONTAINER_EXPORT[];
    CreateBy: string;
    CreateTS: Date;
    Action: IconType;
    ID: number;
}

export interface PROJECT_HIERARCHY {
    ProductName: string;
    Projects: PROJECT[]
}

export interface PROJECT {
    ProjectID: string;
    ProjectName: string;
    BreadCrumb: string;
    IsActive?: number;
}

export interface DATASOURCE {
    Name: string;
    ProjectID: string;
}

export interface ESTIMATE {
    Count: number;
    Time: number;
    Status: ANALYZED_DATASET;
    Error: string;
}

export interface PARAM {
    Name: string;
    Value: string
}

export interface PARAMS {
    ID: number;
    Params: PARAM[]
}

export interface PARALLEL_HINT {
    Usage: PARALLEL_HINT_USAGE;
    Value: number;
}

export interface TENANT {
    TenantCode: string;
    TenantName: string;
    IsActive: number;
}

// used by hierarchy editor
export interface DROP_INFO {
    TargetID: number;
    Action?: string;
}


///////////////////////////////////////////////////////////////////////////////
export enum STATUS {
    ACTIVE = 'A',
    INACTIVE = 'I'
}

export enum ACTION {
    ADD = 'Add',
    DELETE = 'Delete',
    INIT = 'Initial',
    RESET = 'Reset',
    PATCH = 'Patch'
}

export enum METADATA_ACTION {
    INSERT = 'Insert',
    DELETE = 'Delete',
    UPDATE = "Update"
}

export enum COMPARISON_OPERATOR {
    EQUAL_TO = "=",
    NOT_EQUAL_TO = "<>",
    IN = "in",
    NOT_IN = "not in",
    GREATER_THAN = ">",
    LESS_THAN = "<",
    GREATER_THAN_OR_EQUAL_TO = ">=",
    LESS_THAN_OR_EQUAL_TO = "<=",
    BETWEEN = "between",
    NOT_BETWEEN = "not between",
    LIKE = 'like',
    NOT_LIKE = 'not like',
    IS_NULL = 'is null',
    IS_NOT_NULL = 'is not null'
}

export enum LOGICAL_OPERATOR {
    AND = "and",
    OR = "or"
}

export enum OBJECT_TYPE {
    MEASURE = "Measure",
    DIMENSION = "Dimension",
    FOLDER = "Folder",
    ATTRIBUTE = "Attribute",
    STANDALONE = "Standalone",
    SUBQUERY = "Subquery",
    NATIVE_RELATIONAL_FILTER = "Native Relational Filter",
    ALL = "All"
}

export enum DATA_TYPE {
    BLOB = "BLOB",
    DATE = "DATE",
    DATETIME = "DATE_TIME",
    LONGTEXT = "LONG_TEXT",
    NUMERIC = "NUMERIC",
    STRING = "STRING",
    BOOLEAN = "BOOLEAN"
}

export enum FILTER_METHOD {
    HANDWRITING = 'Handwriting',
    DUAL_HANDWRITING = 'DualHandwriting',
    HANDWRITING_DECIMAL = 'HandwritingDecimal',
    DUAL_HANDWRITING_DECIMAL = 'DualHandwritingDecimal',
    LIKE_HANDWRITING = 'LikeHandwriting',
    SINGLE_SELECT = 'Select',
    MULTI_SELECT = 'Multi-Select',
    TOGGLE = 'Toggle',
    DATE = 'Date',
    DUAL_DATE = 'DualDate',
    DATETIME = 'DateTime',
    DUAL_DATETIME = 'DualDateTime',
    SUBQUERY = 'Subquery',
    IS_NULL = 'IsNull',
    IS_NOT_NULL = 'IsNotNull'
}

export enum FILTER_CONTAINER_TYPE {
    SINGLEROWS = 'singlerows',
    GROUPROWS = 'grouprows'
}

export enum METADATA_TYPE {
    OBJ = 'Objs',
    OBJECT = 'Objects',
    TABLE = 'Tables',
    JOIN = 'Joins',
    COLUMNS = 'Columns',
    ERD = 'ERD',
    FOLDER = 'Folders',
    FLAT_HIERARCHY = 'Flat_Hierarchy',
    HIERARCHY_EDITOR = "Hierarchy Editor",
    HIERARCHY = 'Hierarchy',
    ESTIMATION = 'Estimations',
    OPTIMIZATION = "Optimizations",
    PATH = "Paths",
    MULTITENANT = "Multi-Tenant",
    ORACLETABLES = "OracleTables",
    ORACLECOLUMNS = "OracleColumns",
    TENANTS = "Tenants"

}

export enum ANALYZED_DATASET {
    GOOD = 'Good',
    LARGE = 'Large',
    TOO_LARGE = 'Too Large',
    NA = "n/a",
    ERROR = "Error"
}

export enum HISTORY_ACTION {
    SAVE = 'save',
    PUBLISH = 'upload'
}

export enum PARALLEL_HINT_USAGE {
    DISABLED = "Disabled",
    AUTO = "Auto",
    DEFAULT = "Default",
    CUSTOM = "Custom"
}

export enum USER_ROLE {
    SUPERADMIN = "Super Admin",
    ADMIN = "Admin",
    POWERUSER = "Power User",
    VIEWER = "Viewer"
}

export enum TABLEAU_SITE_ROLE {
    SITE_ADMINISTRATOR_EXPLORER = "Site Administrator Explorer",
    SUPPORT_USER = "Support User",
    EXPLORER_PUBLISH = "Explorer(Can Publish)",
    EXPLORER = "Explorer",
    GUEST = "Guest",
    UNLICENSED = "Unlicensed",
    VIEWER = "Viewer",
    SITE_ADMINISTRATOR_CREATOR = "Site Administrator Creator",
    CREATTOR = "Creator"
}

export enum JOIN_TYPE {
    LEFT_JOIN = "LEFT JOIN",
    RIGHT_JOIN = "RIGHT JOIN",
    INNER_JOIN = "INNER JOIN",
    ALL = "ALL"
}

export enum TABLE_TYPE {
    REGULAR = "Regular",
    ALIAS = "Alias",
    DERIVED = "Derived"
}

export enum EXPRESSION_TABLE_TYPE {
    ORACLE = "Oracle",
    DPT = "Dpt",
}

export enum FORMULA_TYPE {
    COUNT = "count()",
    DCOUNT = "count(distinct)",
    MAX = "max()",
    MIN = "min()",
    AVG = "avg()"
}

export enum SQL_KEYWORD {
    SELECT = "select",
    FROM = "from",
    WHERE = "where",
    GROUP_BY = "group by",
    HAVING = "having",
    ORDER_BY = "order by",
    LEFT_JOIN = "left join",
    RIGHT_JOIN = "right join"
}