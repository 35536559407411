import { Component, OnInit } from '@angular/core';
import { OktaService } from '../../service/okta.service';
import { BuilderService } from '../../service/builder.service';
import { BuilderComponent } from '../builder/builder.component';
import { UnauthorizedComponent } from '../unauthorized/unauthorized.component';
import { UntenantedComponent } from '../untenanted/untenanted.component';
import { NgIf } from '@angular/common';
import { UserService } from '../../service/user.service';
import { MetadataService } from '../../service/metadata.service';
import { METADATA_TYPE } from '../../service/datamodel';
import { UnauthenticatedComponent } from "../unauthenticated/unauthenticated.component";

@Component({
  selector: 'app-dispatchbuilder',
  standalone: true,
  imports: [BuilderComponent, UntenantedComponent, NgIf, UnauthenticatedComponent],
  templateUrl: './dispatchbuilder.component.html',
  styleUrl: './dispatchbuilder.component.scss'
})
export class DispatchbuilderComponent implements OnInit {
  public IsAuth: boolean
  public IsTenant: boolean

  constructor(private oktaservice: OktaService, private userservice: UserService, private metadataservice: MetadataService) {
    this.IsAuth = false
    this.IsTenant = false
  }

  ngOnInit() {
    this.oktaservice.isAuthenticated$.subscribe(x => {
      this.IsAuth = x
    })
    this.userservice.isTenantSelected.subscribe(x => {
      this.IsTenant = x
    })
  }
}
