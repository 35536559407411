import { Component, OnInit } from '@angular/core';
import { OktaService } from '../../service/okta.service';
import { UntenantedComponent } from '../untenanted/untenanted.component';
import { NgIf } from "@angular/common";
import { SettingsComponent } from "../settings/settings.component";
import { UserService } from "../../service/user.service";
import { UnauthenticatedComponent } from "../unauthenticated/unauthenticated.component";

@Component({
  selector: 'app-dispatchsettings',
  standalone: true,
  imports: [UntenantedComponent, NgIf, SettingsComponent, UnauthenticatedComponent],
  templateUrl: './dispatchsettings.component.html',
  styleUrl: './dispatchsettings.component.scss'
})
export class DispatchsettingsComponent implements OnInit {

  public IsAuth: boolean
  public IsTenant: boolean

  constructor(private oktaservice: OktaService, private userservice: UserService) {
    this.IsAuth = false
    this.IsTenant = false
  }

  ngOnInit() {
    this.oktaservice.isAuthenticated$.subscribe(x => {
      this.IsAuth = x
    })
    this.userservice.isTenantSelected.subscribe(x => {
      this.IsTenant = x
    })
  }

}
