import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activeonly',
  standalone: true
})
export class ActiveonlyPipe implements PipeTransform {

  transform(value: any[]): any[] {

    return value.filter(v => v.IsActive === 1);
  }

}
