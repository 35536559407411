import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DATA_TYPE, FILTER_CONTAINER_TYPE, OBJ, OBJECT_TYPE } from '../../service/datamodel';
import { BuilderService } from '../../service/builder.service';
import { CdkDrag, CdkDropList, CdkDragDrop, DropListOrientation, moveItemInArray, CdkDragPreview, CdkDragEnter, CdkDragExit, CdkDragStart, CdkDragRelease } from '@angular/cdk/drag-drop';
import { CoreUIModule, NotificationComponent } from '@epsilon/core-ui';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { MetadataService } from '../../service/metadata.service';

@Component({
  selector: 'app-draggableobject',
  standalone: true,
  imports: [CoreUIModule, CommonModule, CdkDrag, CdkDropList, CdkDragPreview],
  templateUrl: './draggableobject.component.html',
  styleUrl: './draggableobject.component.scss'
})
export class DraggableobjectComponent implements OnInit, OnDestroy {

  @Input() purpose: string
  @Input() orient: DropListOrientation;
  private _PID: number
  @Input()
  set PID(val: number) {
    this._PID = val
    this.setItems()
  }
  get PID() {
    return this._PID
  }
  private objects: OBJ[]
  public items: OBJ[];
  @ViewChild('infoMustInclude', { static: true })
  public infoMustInclude: NotificationComponent;
  @ViewChild('infoDuplicate', { static: true })
  public infoDuplicate: NotificationComponent;
  @ViewChild('infoStandalone', { static: true })
  public infoStandalone: NotificationComponent;
  @ViewChild('infoSubquery', { static: true })
  public infoSubquery: NotificationComponent;

  public MEASURE: OBJECT_TYPE;
  public DIMENSION: OBJECT_TYPE;
  public STANDALONE: OBJECT_TYPE;
  public SUBQUERY: OBJECT_TYPE
  public DATE: DATA_TYPE;
  public DATETIME: DATA_TYPE;
  public connectedList: string[]
  private subscription: Subscription

  constructor(private builderservice: BuilderService, private changeDetectorRef: ChangeDetectorRef, private metadataservice: MetadataService) {
    this.items = []
    this.MEASURE = OBJECT_TYPE.MEASURE
    this.DIMENSION = OBJECT_TYPE.DIMENSION
    this.STANDALONE = OBJECT_TYPE.STANDALONE
    this.SUBQUERY = OBJECT_TYPE.SUBQUERY
    this.DATE = DATA_TYPE.DATE
    this.DATETIME = DATA_TYPE.DATETIME
    this.connectedList = ['droppableobject', 'subquery']
  }

  ngOnInit(): void {

    this.metadataservice.isMetaObjsLoading.subscribe(x => {
      if (!x) {
        this.objects = this.metadataservice.getObjs()
        this.setItems()
      }
    })
    // this.subscription = this.builderservice.connectedListNames.subscribe(x => {
    //   //console.log('connected list names: ' + x)
    //   this.connectedList = x
    //   this.changeDetectorRef.detectChanges();
    // })
  }

  private setItems() {
    if (this.objects) {
      this.items = this.objects.filter(y => y.PID === this.PID && y.Type)
      this.items.sort((a, b) => a.Type.localeCompare(b.Type) || a.Name.localeCompare(b.Name))
    }

  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else if (event.previousContainer.id === "droppableobject") {
      var droppedItem: OBJ = event.previousContainer.data[event.previousIndex]
      //A result object cannot be removed while it is found in the groupedrow filter container.
      if (droppedItem.Type === OBJECT_TYPE.MEASURE &&
        this.builderservice.checkFilterExists(droppedItem, FILTER_CONTAINER_TYPE.GROUPROWS)) {
        this.showToast(this.infoMustInclude)
      }
      else
        this.builderservice.removeSelectedObject(droppedItem)

    }
    else if (event.previousContainer.id.indexOf('droppablefilter_') >= 0) {
      var droppedItem: OBJ = event.previousContainer.data[event.previousIndex]
      var prevDOMID = event.previousContainer.id
      var prevContainerType = prevDOMID.substring(prevDOMID.indexOf('_') + 1, prevDOMID.lastIndexOf('_'))
      var prevContainerIndex = parseInt(prevDOMID.substring(prevDOMID.lastIndexOf('_') + 1, prevDOMID.length))
      this.builderservice.removeSelectedFilter(prevContainerType, prevContainerIndex, JSON.parse(JSON.stringify(droppedItem)))
    }
  }

  public showToast(item: NotificationComponent): void {
    item.show();
  }

  public onDoubleClick(item: OBJ) {
    var result = this.builderservice.checkObjectSelected(item)
    if (result === "success")
      this.builderservice.addSelectedObject(item)
    else if (result === "standalone")
      this.builderservice.addSelectedObject(item)
    // this.showToast(this.infoStandalone)
    else if (result === "subquery")
      this.builderservice.addSelectedObject(item)
    //   this.showToast(this.infoSubquery)
    else if (result === "duplicate")
      this.showToast(this.infoDuplicate)
  }

  onDragEnter(event: CdkDragEnter<any[]>) {
    if (event.container.id !== 'draggableobject')
      this.builderservice.setDragging(true, event.container.id)
  }

  onDragExit(event: CdkDragExit<any[]>) {
    if (event.container.id !== 'draggableobject')
      this.builderservice.setDragging(false, event.container.id)
  }

  onDragStart(event: CdkDragStart<any[]>) {
    this.builderservice.setDragging(true, event.source.reset())
  }

  onDragRelease(event: CdkDragRelease<any[]>) {
    this.builderservice.setDragging(false, event.source.reset())
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe()
  }

}
