<div cdkDropList id="droppableobject" [cdkDropListConnectedTo]="['draggableobject']"
    cdkDropListOrientation="mixed" [cdkDropListData]="items" (cdkDropListDropped)="drop($event)" class="nav-list">
    <!---------- Drag Container ---------->
    <div class="nav__container" cdkDrag *ngFor="let item of items" (cdkDragEntered)="onDragEnter($event)"
        (cdkDragExited)="onDragExit($event)" (cdkDragStarted)="onDragStart($event)"
        (cdkDragReleased)="onDragRelease($event)">
        <div class="nav__item">
            <i coreUIIcon="dragHandle" scale="1" class="nav_component"></i>
            <div [class]="item.Type">
                <div *ngIf="item.Type === DIMENSION">
                    <i coreUIIcon="boxFill" scale="1" class="nav_component"></i>
                </div>
                <div *ngIf="item.Type === STANDALONE">
                    <i coreUIIcon="rectangle" scale="1" class="nav_component"></i>
                </div>
                <div *ngIf="item.Type === SUBQUERY">
                    <i coreUIIcon="object" scale="1" class="nav_component"></i>
                </div>
                <div *ngIf="item.Type === MEASURE">
                    <i coreUIIcon="radioFill" scale="1" class="nav_component"></i>
                </div>
            </div> <span class="nav_component">{{ item.Name }}</span>
            <!---------- Preview ---------->
            <coreui-card-container *cdkDragPreview>
                <coreui-card role="button" tabindex="0" [header]="item.Name" class="Card-small" [text]="cardTxt4">
                    <ng-template #cardTxt4>
                        <div class="custom-definition text-style-3">
                            Subject Area: <span class="text-style-2">{{ item.SubjectArea }}</span><br />
                            Object Type: <span class="text-style-2">{{ item.Type }}</span><br />
                            Data Type: <span class="text-style-2">{{item.DataType}}</span>
                        </div>
                        <div coreUIDivider="secondary"></div>
                        <div class="custom-definition text-style-3">
                            {{ item.Description }}<br />
                        </div>
                    </ng-template>
                </coreui-card>
            </coreui-card-container>
            <a coreUIPrimaryNavigationItemLink (click)="onRemove(item)" class="new-tab-icon"><i coreUIIcon="close"
                    scale="1.2"></i></a>
        </div>
    </div>
</div>
<coreui-notification notificationStyle="information" #infoDuplicate>
    An Object with the same name already exists in the container.
</coreui-notification>
<coreui-notification notificationStyle="information" #infoStandalone>
    A Standalone Object is not allowed in the result container.
</coreui-notification>
<coreui-notification notificationStyle="information" #infoSubquery>
    A Subquery Object is not allowed in the result container.
</coreui-notification>