import { Component, OnInit } from '@angular/core';
import { OktaService } from '../../service/okta.service';
import { NavigationEnd, Router } from '@angular/router';
import { CoreUIModule } from '@epsilon/core-ui';
import { CoreUILogosModule } from '@epsilon/core-ui/logos'
import { CommonModule } from '@angular/common';
import { TABLEAU_SITE, USER_ROLE } from '../../service/datamodel';
import { OrderbyPipe } from '../../pipe/orderby.pipe';
import { SitefilterPipe } from "../../pipe/sitefilter.pipe";
import { UserService } from '../../service/user.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MetadataService } from '../../service/metadata.service';
import { CoreUIIllustrationsModule } from '@epsilon/core-ui/illustrations';
import { filter } from 'rxjs';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CoreUIModule, CoreUILogosModule, CoreUIIllustrationsModule, CommonModule, OrderbyPipe, SitefilterPipe, ReactiveFormsModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  public name: string = '';
  public isAuthenticated: boolean = false;
  public userSites: TABLEAU_SITE[] = [];
  public isHomePage: boolean = false;
  public isAdmin: boolean = false;
  public myFormGroup = new FormGroup({
    selectTenant: new FormControl([])
  })
  public set filteredItems(value: TABLEAU_SITE[]) {
    this._filteredItems = value;

  }
  public get filteredItems(): TABLEAU_SITE[] {
    return this._filteredItems;
  }
  private _filteredItems: TABLEAU_SITE[] = [...this.userSites];


  constructor(private _router: Router, private oktaservice: OktaService,
    private userservice: UserService, private metadataservice: MetadataService) {
    this.isAdmin = false
  }

  ngOnInit(): void {
    this.oktaservice.name$.subscribe(x => this.name = x)
    this.oktaservice.isAuthenticated$.subscribe(x => {
      this.isAuthenticated = x
      if (this.isAuthenticated)
        this.myFormGroup.controls['selectTenant'].enable()
      else
        this.myFormGroup.controls['selectTenant'].disable()
    })
    this.userservice.usersites.subscribe(x => {
      this.userSites = x
      this.filteredItems = x
    })

    this._router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.urlAfterRedirects === '/')
          this.isHomePage = true
        else
          this.isHomePage = false
      });

    this.userservice.isTenantSelected.subscribe(x => {
      if (x) {
        var siteUserRole = this.userservice.getUserRole()
        if (siteUserRole === USER_ROLE.SUPERADMIN || siteUserRole === USER_ROLE.ADMIN)
          this.isAdmin = true
        else this.isAdmin = false
      }
    })
  }

  public signIn() {
    this.oktaservice.signIn();
  }

  public signOut() {
    this.oktaservice.signOut();
  }

  public goProfile() {
    this._router.navigateByUrl('profile');
  }

  public goSettings() {
    this._router.navigateByUrl('settings');
  }

  public goAdmin() {
    this._router.navigateByUrl('admin')
  }

  public goBack() {
    this._router.navigateByUrl('');
  }

  public onSelect(t: TABLEAU_SITE) {
    this.userservice.setSelectedSite(t);
  }

  public onSearchChange(searchText): void {
    this.filteredItems = searchText
      ? this.userSites.filter(
        (item) =>
          item.SiteName.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      : this.userSites;
  }


}
