<div class="drop-group" cdkDropListGroup>
    <app-objectselector></app-objectselector>
    <div class="area-right">
        <app-editormenu (segmentEvent)="segmentChanged($event)"></app-editormenu>
        <coreui-accordion [multiple]="true">
            <coreui-accordion-pane label="Result Objects" (activeChange)="onActiveChange('result')"
                [active]="isResultExpand">
                <app-resultcontainer></app-resultcontainer>
            </coreui-accordion-pane>
            <coreui-accordion-pane label="Query Filters" (activeChange)="onActiveChange('filter')"
                [active]="isFilterExpand">
                <app-filtereditor></app-filtereditor>
            </coreui-accordion-pane>
            <coreui-accordion-pane label="Data Preview" (activeChange)="onActiveChange('preview')"
                [active]="isPreviewExpand">
                <app-previewcontainer></app-previewcontainer>
            </coreui-accordion-pane>
        </coreui-accordion>
    </div>
</div>