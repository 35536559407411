import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MetadataService } from '../../service/metadata.service';
import { JOIN, JOIN_TYPE, METADATA_ACTION, METADATA_TYPE } from '../../service/datamodel';
import { CoreUIModule, TableHeaderColumnComponent } from '@epsilon/core-ui';
import { CommonModule } from '@angular/common';
import { OrderbyPipe } from '../../pipe/orderby.pipe';
import { Subscription } from 'rxjs';
import { JoinsmodalComponent } from '../joinsmodal/joinsmodal.component';
import { DeletemodalComponent } from '../deletemodal/deletemodal.component';
import { ActionbarComponent } from '../actionbar/actionbar.component';
import { HighlighterPipe } from '../../pipe/highlighter.pipe';
import { DownloadService } from '../../service/download.service';

@Component({
  selector: 'app-joins',
  standalone: true,
  imports: [CoreUIModule, CommonModule, OrderbyPipe, JoinsmodalComponent, DeletemodalComponent, ActionbarComponent, HighlighterPipe],
  templateUrl: './joins.component.html',
  styleUrl: './joins.component.scss'
})
export class JoinsComponent implements OnInit, OnDestroy {
  public loadingTable: boolean = false;
  @ViewChild('joinsModal') joinsModal: JoinsmodalComponent;
  @ViewChild('alertModal') alertModal: DeletemodalComponent;
  public loadingItems: boolean = false;

  public defaultItems: JOIN[];
  public defaultItem: JOIN;
  public chunkedItems: [JOIN[]];
  private chunkSize = 20

  public defaultSortOption = 'LeftTable';
  public defaultSortDirection = 'ASC';
  public scrollSortOption = '';
  public scrollSortDirection = '';
  private subscription: Subscription
  public UPDATE: METADATA_ACTION
  public DELETE: METADATA_ACTION
  public INSERT: METADATA_ACTION
  public JOIN: METADATA_TYPE
  public searchedText: string
  private activeJoinType: JOIN_TYPE
  public actived: string[];
  private activeActived: string;
  public customed: string[]
  private activeCustomed: string;
  public joinTypes: JOIN_TYPE[]

  constructor(private metadataservice: MetadataService, private downloadservice: DownloadService) {
    this.defaultItems = [];
    this.UPDATE = METADATA_ACTION.UPDATE
    this.DELETE = METADATA_ACTION.DELETE
    this.INSERT = METADATA_ACTION.INSERT
    this.JOIN = METADATA_TYPE.JOIN
    this.chunkedItems = [[]]
    this.searchedText = ""
    this.actived = ["All", "True", "False"]
    this.customed = ["All", "True", "False"]
    this.activeActived = "All"
    this.activeCustomed = "All"
    this.defaultItem = {
      ID: 0,
      LeftTable: "",
      RightTable: "",
      JoinExpression89: "",
      JoinExpression92: "",
      Cardinality: "",
      JoinType: "",
      IsShortcut: 0,
      UniquenessIndex: 0.0
    }
    this.activeJoinType = JOIN_TYPE.ALL
    this.joinTypes = [JOIN_TYPE.ALL, JOIN_TYPE.LEFT_JOIN, JOIN_TYPE.RIGHT_JOIN, JOIN_TYPE.INNER_JOIN]
  }

  public ngOnInit(): void {
    this.loadingTable = true;
    this.subscription = this.metadataservice.isMetaJoinsLoading.subscribe(x => {
      this.chunkedItems = [[]]
      this.defaultItems = []
      if (!x && this.metadataservice.getJoins().length > 0) {
        var items = this.metadataservice.getJoins()
        this.chunkedItems.pop()

        for (let i = 0; i < items.length; i += this.chunkSize) {
          this.chunkedItems.push(items.slice(i, i + this.chunkSize))
        }
        this.onScrollLimitReached()
      }
      this.setFilteredItems()
      this.loadingTable = x;
    })
  }

  public scrollSort(
    sortComponent: TableHeaderColumnComponent,
    sortOption
  ): void {
    this.scrollSortOption = sortOption;
    this.scrollSortDirection = sortComponent.sortDirection;
  }


  public sortAscDesc(option: string | string[]): string | undefined {
    return this.defaultSortOption === option ||
      option.includes(this.defaultSortOption)
      ? this.defaultSortDirection === 'ASC'
        ? 'ascending'
        : 'descending'
      : undefined;
  }

  public onScrollLimitReached(): void {
    if (this.chunkedItems.length > 0) {
      this.loadingItems = true;
      setTimeout(() => {
        if (this.chunkedItems.length > 0) {
          this.defaultItems.push(...this.chunkedItems[0]);
          this.chunkedItems.shift() //next block
        }
        this.loadingItems = false;
      }, 500);
    }

  }

  onFilter1(item: JOIN_TYPE) {
    this.activeJoinType = item
    this.setFilteredItems()
  }

  onFilter2(item: string) {
    this.setFilteredItems()
  }

  onFilter3(item: string) {
    this.activeCustomed = item
    this.setFilteredItems()
  }

  onFilter4(item: string) {
    this.activeActived = item
    this.setFilteredItems()
  }

  onSearch(item: string) {
    this.searchedText = item.toUpperCase()
    this.setFilteredItems()
  }

  onReset() {
    this.searchedText = ""
    this.activeActived = "All"
    this.activeCustomed = "All"
    this.setFilteredItems()
  }

  onDownload() {
    this.downloadservice.downloadMetadata(this.JOIN, this.metadataservice.getJoins())
  }

  onRefresh() {
    this.metadataservice.querySpecificMetadata(this.JOIN)
  }

  private setFilteredItems() {
    this.defaultItems = []
    this.chunkedItems = [[]]
    this.chunkedItems.pop()

    var items = this.metadataservice.getJoins().filter(j => (j.LeftTable.toUpperCase().includes(this.searchedText) ||
      j.RightTable.toUpperCase().includes(this.searchedText) || j.JoinExpression92.toUpperCase().includes(this.searchedText))
      && (j.JoinType === this.activeJoinType || this.activeJoinType === JOIN_TYPE.ALL)
      && ((j.IsCustom === 1 && this.activeCustomed === "True") || (j.IsCustom === 0 && this.activeCustomed === "False") || this.activeCustomed === "All")
      && ((j.IsActive === 1 && this.activeActived === "True") || (j.IsActive === 0 && this.activeActived === "False") || this.activeActived === "All"))

    for (let i = 0; i < items.length; i += this.chunkSize) {
      this.chunkedItems.push(items.slice(i, i + this.chunkSize))
    }
    this.onScrollLimitReached()
  }

  public onEdit(item: JOIN) {
    this.joinsModal.launchBasicModal(item, this.UPDATE)
  }

  public onAdd() {
    this.joinsModal.launchBasicModal(JSON.parse(JSON.stringify(this.defaultItem)), this.INSERT)
  }

  public onDelete(item: JOIN) {
    this.alertModal.launchAlertModal(this.JOIN, item)
  }

  public onActivate(item: JOIN) {
    item.IsActive = 1
    this.metadataservice.setMeta(item, METADATA_TYPE.JOIN, this.UPDATE)
  }

  public onDeactivate(item: JOIN) {
    item.IsActive = 0
    this.metadataservice.setMeta(item, METADATA_TYPE.JOIN, this.UPDATE)
  }

  public onCustomize(item: JOIN) {
    item.IsCustom = 1
    this.metadataservice.setMeta(item, METADATA_TYPE.JOIN, this.UPDATE)
  }

  public onUncustomize(item: JOIN) {
    item.IsCustom = 0
    this.metadataservice.setMeta(item, METADATA_TYPE.JOIN, this.UPDATE)
  }

  public confirmDelete(item: JOIN) {
    this.metadataservice.deleteMeta(this.JOIN, item)
    this.defaultItems = this.defaultItems.filter(i => i.ID !== item.ID)
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe()
  }

}
