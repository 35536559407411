import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CoreUIModule, ModalComponent } from '@epsilon/core-ui';
import { DATASOURCE, PROJECT } from '../../service/datamodel';
import { BuilderService } from '../../service/builder.service';
import { environment } from '../../../environments/environment';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIf } from '@angular/common';
import { DatasourceService } from '../../service/datasource.service';
import { UserService } from '../../service/user.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-publishmodal',
  standalone: true,
  imports: [CoreUIModule, ReactiveFormsModule, NgIf],
  templateUrl: './publishmodal.component.html',
  styleUrl: './publishmodal.component.scss'
})
export class PublishmodalComponent implements OnInit, OnDestroy {
  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;
  @ViewChild('launchBasicModalButton', { read: ElementRef })
  public basicModalElementRef: ElementRef<HTMLButtonElement>;

  public statusDatasourcePublishing: string;
  public publishedURL: string;
  // public workingDatasourceName: string;
  public datasources: DATASOURCE[];
  public msgWarningDSName: string;
  public msgErrorDSName: string;
  public targetServer: string;
  public siteName: string;
  public myFormGroup: FormGroup = new FormGroup({
    inputDatasourceName: new FormControl('', [Validators.required])
  })
  public listObjects: string[];
  public interprettedSinglerowFilters: string;
  public interprettedGrouprowFilters: string;
  public selectedDestinationProject: PROJECT;
  public isSubmittable: boolean;
  private subscription: Subscription

  constructor(private _router: Router, private datasourceservice: DatasourceService, private builderservice: BuilderService, private userservice: UserService) {
    this.targetServer = environment.TABLEAU_SERVER
    this.isSubmittable = false
    this.listObjects = []
    this.interprettedSinglerowFilters = ''
    this.interprettedGrouprowFilters = ''
    this.siteName = 'n/a'
    this.selectedDestinationProject = { ProjectID: '', ProjectName: 'Datasources', BreadCrumb: 'Loyalty > Datasources' }
    this.msgErrorDSName = ""
    this.msgWarningDSName = ""
  }
  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe()
  }

  ngOnInit(): void {
    this.datasourceservice.statusDatasourcePublishing.subscribe(x => {
      this.statusDatasourcePublishing = x
      if (this.statusDatasourcePublishing === "Publishing")
        this.publishedURL = ""
    })
    this.datasourceservice.newDatasourceURL.subscribe(x => {
      this.statusDatasourcePublishing = x
      if (x === "Error")
        this.publishedURL = undefined
      else
        this.publishedURL = x
    })
    this.myFormGroup.valueChanges.subscribe(x => {
      this.validateDatasource()
    })
    this.datasourceservice.interprettedSinglerowFilters.subscribe(x => {
      this.interprettedSinglerowFilters = x
    })
    this.datasourceservice.interprettedGrouprowFilters.subscribe(x => {
      this.interprettedGrouprowFilters = x
    })
    this.userservice.isTenantSelected.subscribe(x => {
      if (x)
        this.datasourceservice.queryProjects()
    })
    this.datasourceservice.isDatasourcesQuerying.subscribe(x => {
      if (!x) {
        this.datasources = this.datasourceservice.getDatasources()
        this.validateDatasource()
      }

    })
    this.subscription = this.myFormGroup.valueChanges.subscribe(val => this.formChanged(val))
  }

  private formChanged(val) {
    this.validateDatasource()
  }

  private validateDatasource() {
    // datasource name is required, if empty, validation fails,
    // A user is alerted if the datasource name already exists in Tableau server.
    // need to wait until a list of datasource names (i.e. this.datasources) comeback from backend and check is done. 

    var datasourceName = this.myFormGroup.controls["inputDatasourceName"].value


    this.myFormGroup.controls["inputDatasourceName"].setErrors(null)
    this.msgErrorDSName = ""
    this.msgWarningDSName = ""
    var isError: boolean = false;

    if (this.myFormGroup.controls["inputDatasourceName"].value.length === 0) {
      this.msgErrorDSName = "Required"
      this.myFormGroup.controls["inputDatasourceName"].setErrors({ msg: 'Required' })
      isError = true
    }
    else if (this.datasources.filter(d => d.Name === datasourceName && this.selectedDestinationProject.ProjectID === d.ProjectID).length > 0) {
      this.msgWarningDSName = "A Datasource with the same name exists. It will be overwritten."
    }

    if (!isError)
      this.isSubmittable = true
    else
      this.isSubmittable = false

  }

  private initDialog() {
    this.isSubmittable = false
    this.publishedURL = ""
    this.statusDatasourcePublishing = ""
    this.siteName = this.userservice.getSelectedSite().SiteName;
    this.listObjects = this.builderservice.getSelectedObjects().map(o => { return o.Name })
    this.myFormGroup.controls["inputDatasourceName"].setValue(this.datasourceservice.getSelectedDatasourceName())
    this.datasourceservice.interpretFilters('simple')
    this.selectedDestinationProject = this.datasourceservice.getTargetProject()
    this.datasourceservice.queryDatasources()
  }

  private saveDialog() {
    this.datasourceservice.setSelectedDatasourceName(this.myFormGroup.controls["inputDatasourceName"].value)
    this.isSubmittable = false
  }

  public async closeBasicModal(): Promise<void> {
    await this.basicModal.hide();
    this.isSubmittable = false
    //this.basicModalElementRef.nativeElement.focus();
  }

  public async launchBasicModal(): Promise<void> {
    this.initDialog()
    await this.basicModal.show(this.basicModalElementRef);
  }

  public publishDataSource() {
    this.saveDialog()
    this.datasourceservice.postDatasource()
  }


  public goSettings() {
    this._router.navigateByUrl('settings');
  }


}