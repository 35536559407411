<section class="component-wrapper">
    <h3>Copy a tenant's metadata</h3><br />
    <form [formGroup]="checkboxRequiredFormGroup">
        <coreui-checkbox-group label="Types of metadata" orientation="horizontal" role="group">
            <coreui-form-field label="Objects">
                <input type="checkbox" aria-label="Objects" coreUICheckbox formControlName="requiredCheckbox1"
                    checked="true" />
            </coreui-form-field>
            <coreui-form-field label="Tables">
                <input type="checkbox" aria-label="Tables" coreUICheckbox formControlName="requiredCheckbox2"
                    checked="true" />
            </coreui-form-field>
            <coreui-form-field label="Joins">
                <input type="checkbox" aria-label="Joins" coreUICheckbox formControlName="requiredCheckbox3"
                    checked="true" />
            </coreui-form-field>
            <coreui-form-error [shown]="!checkboxRequiredFormGroup.valid">You must select at least one
                option</coreui-form-error>
        </coreui-checkbox-group>
    </form>
    <form [formGroup]="myFormGroup">
        <coreui-form-field label="Copy From">
            <coreui-select placeholder="Select Tenant" formControlName="selectTenantFrom" coreUIFixedWidth="short"
                [searchable]="true" (searchValueChange)="onSearchChange($event)">
                <coreui-option (select)="onSelectSource(p)"
                    *ngFor="let p of filteredItems | sitefilter : false | orderby:'SiteName':'ASC' "
                    [value]="p.SiteName">{{p.SiteName}}</coreui-option>
            </coreui-select>
            <coreui-form-error>{{errMsgTenantFrom}}</coreui-form-error>
        </coreui-form-field>
        <coreui-form-field label="Copy To">
            <coreui-select placeholder="Select Tenant" formControlName="selectTenantTo" coreUIFixedWidth="short"
                [searchable]="true" (searchValueChange)="onSearchChange2($event)">
                <coreui-option (select)="onSelectTarget(p)"
                    *ngFor="let p of filteredItems2 | sitefilter: false  | orderby:'SiteName':'ASC' "
                    [value]="p.SiteName">{{p.SiteName}}</coreui-option>
            </coreui-select>
            <coreui-form-error>{{errMsgTenantTo}}</coreui-form-error>
        </coreui-form-field>
        <coreui-form-field label="Status">
            <coreui-badge color="Lime" *ngIf="!isTenantCopying && statusTenantCopy === 'Success'" class="inline_space"
                [subMessage]="messageTpl1">Success</coreui-badge>
            <ng-template #messageTpl1>{{msgCopy}}</ng-template>
            <coreui-badge color="Apple" *ngIf="!isTenantCopying && statusTenantCopy === 'Failed'" class="inline_space"
                [subMessage]="messageTpl2">Failed</coreui-badge>
            <ng-template #messageTpl2>{{msgCopy}}</ng-template>
            <coreui-load-mask [active]="isTenantCopying" class="inline_space">
            </coreui-load-mask>
        </coreui-form-field>
        <button type="button" coreUIButton (click)="onCopy()"
            [disabled]="!(isTenantsValid && isSomeChecked)">Run</button>
    </form>
</section>