import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CoreUIModule } from '@epsilon/core-ui';
import { OBJ, OBJECT_TYPE } from '../../service/datamodel';
import { BuilderService } from '../../service/builder.service';
import { SearchedobjectComponent } from '../searchedobject/searchedobject.component';
import { MetadataService } from '../../service/metadata.service';

@Component({
  selector: 'app-objectsearch',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, CoreUIModule, SearchedobjectComponent],
  templateUrl: './objectsearch.component.html',
  styleUrl: './objectsearch.component.scss'
})
export class ObjectsearchComponent implements OnInit, AfterViewInit {
  public isLoading: WritableSignal<boolean> = signal(false);
  public isObjectsShow: boolean;

  public searchForm = new FormGroup({
    search: new FormControl()
  });

  public autocompleteItems: OBJ[];
  public allItems: OBJ[]
  public searchedItem: OBJ

  private searching: number;

  constructor(private changeDetectorRef: ChangeDetectorRef, private builderservice: BuilderService, private metadataservice: MetadataService) {
    this.autocompleteItems = []
    this.allItems = []
    this.isObjectsShow = false

  }

  ngOnInit(): void {

    this.metadataservice.isMetaObjsLoading.subscribe(x => {
      if (!x) {
        this.allItems = this.metadataservice.getObjs().filter(x => x.IsActive === 1)
        this.searchForm.controls.search.reset()
      }
    })

    this.searchForm.valueChanges.subscribe(val => {
      this.onSearchChange(val.search)
    })

    this.builderservice.searchedSubjects.subscribe(x => {
      if (x.length > 0)
        this.isObjectsShow = true;
      else
        this.isObjectsShow = false;
    })

  }

  ngAfterViewInit(): void {
  }

  // displayWith tells the autocomplete how to convert an object value into
  // a string value for display; this may not be necessary if your values are
  // strings or numbers rather than objects.
  public displayWith(item: OBJ): string {
    return item.Name;
  }

  public onSearchChange(searchText: string): void {
    this.isLoading.set(true);
    if (this.searching) {
      window.clearTimeout(this.searching);
      this.searching = undefined;
    }
    this.searching = window.setTimeout(() => {
      this.autocompleteItems = this.allItems
      this.autocompleteItems = searchText
        ? this.autocompleteItems.filter(
          (item) =>
            item.Name
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) > -1
        )
        : [];
      this.builderservice.setSearchedObjects(this.autocompleteItems)
      this.isLoading.set(false);
      this.changeDetectorRef.detectChanges();
    }, 600);
  }


}
