<coreui-dataviz-card header="Entity Relationship Diagram">
    <coreui-dataviz-portal (click)="onClick(undefined)">
        <coreui-dataviz-tooltip (click)="onClick(undefined)">
            <ng-template coreUIDataVizTooltipTemplate let-point="point" (click)="onClick(point)">
                <div class="tooltip" (click)="onClick(point)">
                    <span class="tooltip-color" [style.background-color]="'slate'" (click)="onClick(point)"></span>
                    <div class="tooltip-display" (click)="onClick(point)">
                        <span class="tooltip-xvalue">
                            {{ IDtoExpression(point?.y ) | async}}
                        </span>
                    </div>
                </div>
            </ng-template>
        </coreui-dataviz-tooltip>
        <coreui-dataviz-sankey-series [dataSource]="joinsDataSource$"></coreui-dataviz-sankey-series>
    </coreui-dataviz-portal>
</coreui-dataviz-card>