import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MetadataService } from '../../service/metadata.service';
import { CoreUIModule, ModalComponent, NotificationComponent } from '@epsilon/core-ui';
import { OBJECT, PATH, DATA_TYPE, OBJECT_TYPE, METADATA_TYPE, METADATA_ACTION, TABLE, COLUMN } from '../../service/datamodel';
import { CommonModule } from '@angular/common';
import { OrderbyPipe } from "../../pipe/orderby.pipe";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ExpressionbuilderComponent } from "../expressionbuilder/expressionbuilder.component";
import { ActiveonlyPipe } from "../../pipe/activeonly.pipe";

@Component({
  selector: 'app-objectsmodal',
  standalone: true,
  imports: [CoreUIModule, CommonModule, OrderbyPipe, ReactiveFormsModule, ExpressionbuilderComponent],
  templateUrl: './objectsmodal.component.html',
  styleUrl: './objectsmodal.component.scss'
})
export class ObjectsmodalComponent implements OnInit, OnDestroy {
  @ViewChild('basicModal', { static: true }) private basicModal: ModalComponent;
  @ViewChild('myInput') myInput: ElementRef;
  @ViewChild('infoError', { static: true }) public infoError: NotificationComponent;
  @ViewChild('infoSuccess', { static: true }) public infoSuccess: NotificationComponent;
  private paths: PATH[]
  public item: OBJECT;
  public types: OBJECT_TYPE[];
  public datatypes: DATA_TYPE[];
  public OBJECT: METADATA_TYPE;
  public myFormGroup = new FormGroup({
    selectPath: new FormControl('', [
      Validators.required
    ]),
    inputName: new FormControl('', [
      Validators.required
    ]),
    textareaDescription: new FormControl('', [
      Validators.required
    ]),
    selectType: new FormControl('', [
      Validators.required
    ]),
    selectDataType: new FormControl('', [
      Validators.required
    ]),
    textareaExpression: new FormControl('', [
      Validators.required
    ]),
    selectTable: new FormControl('', [
    ]),
    selectColumn: new FormControl('', [
    ])
  })
  private subscription: Subscription
  private subChange: Subscription
  public isObjectValid: boolean;
  public isObjectValidating: boolean;
  public activeType: OBJECT_TYPE
  public action: METADATA_ACTION;
  public isFormError: boolean;
  public errMsgPath: string
  public errMsgName: string
  public errMsgDescription: string;
  public errMsgType: string;
  public errMsgDataType: string
  public errMsgExpression: string
  private posCursor: number;
  private typing: number;
  private _filteredItems: PATH[] = [];
  public set filteredItems(value: PATH[]) {
    this._filteredItems = value;
  }
  public get filteredItems(): PATH[] {
    return this._filteredItems;
  }
  public STANDALONE: OBJECT_TYPE;
  public DIMENSION: OBJECT_TYPE;
  public MEASURE: OBJECT_TYPE
  public SUBQUERY: OBJECT_TYPE
  public BLOB: DATA_TYPE;
  public BOOLEAN: DATA_TYPE;
  public DATE: DATA_TYPE;
  public DATETIME: DATA_TYPE;
  public LONGTEXT: DATA_TYPE;
  public NUMERIC: DATA_TYPE;
  public STRING: DATA_TYPE
  public msg: string;


  constructor(private changeDetectorRef: ChangeDetectorRef, private metadataservice: MetadataService) {
    this.datatypes = [DATA_TYPE.BLOB, DATA_TYPE.BOOLEAN, DATA_TYPE.DATE, DATA_TYPE.DATETIME, DATA_TYPE.LONGTEXT, DATA_TYPE.NUMERIC, DATA_TYPE.STRING]
    this.isObjectValid = false
    this.isObjectValidating = false
    this.STANDALONE = OBJECT_TYPE.STANDALONE
    this.DIMENSION = OBJECT_TYPE.DIMENSION
    this.MEASURE = OBJECT_TYPE.MEASURE
    this.SUBQUERY = OBJECT_TYPE.SUBQUERY
    this.types = [this.MEASURE, this.DIMENSION, this.STANDALONE, this.SUBQUERY]
    this.posCursor = 0
    this.BLOB = DATA_TYPE.BLOB;
    this.BOOLEAN = DATA_TYPE.BOOLEAN;
    this.DATE = DATA_TYPE.DATE;
    this.DATETIME = DATA_TYPE.DATETIME;
    this.LONGTEXT = DATA_TYPE.LONGTEXT;
    this.NUMERIC = DATA_TYPE.NUMERIC;
    this.STRING = DATA_TYPE.STRING;
    this.msg = ""
    this.activeType = OBJECT_TYPE.MEASURE
    this.OBJECT = METADATA_TYPE.OBJECT
  }

  ngOnInit(): void {

    this.subscription = this.metadataservice.isMetaPathLoading.subscribe(x => {
      if (!x) {
        this.paths = this.metadataservice.getPaths()
        this.filteredItems = this.paths
      }
    })

    this.subscription = this.metadataservice.msgMetaAction.subscribe(x => {
      if (x) {
        this.msg = x
        if (this.msg.toUpperCase().indexOf("SUCCESS") >= 0)
          this.showToast(this.infoSuccess)
        else
          this.showToast(this.infoError)
      }
    })


    this.subChange = this.myFormGroup.valueChanges.subscribe(val => this.formChanged(val))

    this.subscription = this.metadataservice.isMetaObjectValidating.subscribe(x => {
      this.isObjectValidating = x
    })

    this.subscription = this.metadataservice.isMetaObjectValid.subscribe(x => {

      if (x) {
        //Success
        if (x.Result) {
          this.myFormGroup.controls["inputName"].setErrors(null)
          this.myFormGroup.controls["textareaExpression"].setErrors(null)
          this.isObjectValid = true;
        }
        //Failure
        else {
          if (x.Message === "Failed: An Object with the same name exists") {
            this.myFormGroup.controls.inputName.setErrors({ "msg": "Failed: Invalid Name" })
            this.errMsgName = "An Object with the same name exists in the Subject Area"
          }
          else if (x.Message.indexOf("Failed: ") >= 0) {
            this.myFormGroup.controls.textareaExpression.setErrors({ "msg": x.Message })
            this.errMsgExpression = x.Message.replace("Failed: ", "")
          }
          this.isObjectValid = false
          this.myFormGroup.markAsDirty()
        }
      }
    })

  }

  private formChanged(val: any) {
    this.isObjectValid = false
  }

  public async closeBasicModal(): Promise<void> {
    await this.basicModal.hide();
    this.isObjectValidating = false
  }

  public async saveBasicModal(): Promise<void> {
    this.readControlsPerm()
    this.metadataservice.setMeta(this.item, METADATA_TYPE.OBJECT, this.action)
    this.isObjectValidating = false
    await this.basicModal.hide()
  }

  public onBlur(event) {
    this.onValidate()
    this.getCaretPos(event)
  }

  public onSelect(event) {
    this.onValidate()
  }

  // find cursor position and return it
  public getCaretPos(event: any): void {
    if (event) {
      const target = event.target as HTMLInputElement | HTMLTextAreaElement;
      this.posCursor = target.selectionStart;
    }
  }

  // show intellisense dropdowns on click
  public onExpression(event: string) {
    var currentText = this.myFormGroup.controls.textareaExpression.value
    let result = currentText.slice(0, this.posCursor) + (currentText.substring(this.posCursor, currentText.length) === ' ' ? '' : ' ') + event + ' ' + currentText.slice(this.posCursor);
    this.myFormGroup.controls.textareaExpression.setValue(result)
    this.onValidate()
  }

  public onSearchChange(searchText): void {
    this.filteredItems = searchText
      ? this.paths.filter(
        (item) =>
          item.Name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      : this.paths;
  }

  public onKeyPress(event: any) {
    this.getCaretPos(event)
    if (this.typing) {
      window.clearTimeout(this.typing);
      this.typing = undefined;
    }
    this.typing = window.setTimeout(() => {
      this.onValidate()
      this.changeDetectorRef.detectChanges();
    }, 900);
  }

  public onValidate() {
    var temp: OBJECT = this.readControlsTemp()
    this.isFormError = false
    if (temp.Path === "") {
      this.myFormGroup.controls.selectPath.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgPath = "Required"
    }
    if (temp.Name === "") {
      this.myFormGroup.controls.inputName.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgName = "Required"
    }
    if (temp.Description.trim() === "") {
      this.myFormGroup.controls.textareaDescription.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgDescription = "Required"
    }
    if (temp.Type === undefined) {
      this.myFormGroup.controls.selectType.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgType = "Required"
    }
    if (temp.DataType === undefined) {
      this.myFormGroup.controls.selectDataType.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgDataType = "Required"
    }
    if (temp.Expression.trim() === "") {
      this.myFormGroup.controls.textareaExpression.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgExpression = "Required"
    }

    if (!this.isFormError)
      this.metadataservice.setMetadataValidation(temp, METADATA_TYPE.OBJECT, this.action)

  }

  public async launchBasicModal(item: OBJECT, action: METADATA_ACTION): Promise<void> {

    this.action = action
    this.item = item
    this.loadControls(item)
    // this.onValidate()
    // Load meta tables only for the first time
    if (this.metadataservice.getTables().length === 0)
      this.metadataservice.querySpecificMetadata(METADATA_TYPE.TABLE)
    if (this.metadataservice.getPaths().length === 0)
      this.metadataservice.querySpecificMetadata(METADATA_TYPE.PATH)

    await this.basicModal.show();

  }

  private loadControls(item: OBJECT) {
    this.myFormGroup.controls['selectPath'].patchValue(item.Path)
    this.myFormGroup.controls['inputName'].setValue(item.Name)
    this.myFormGroup.controls['textareaDescription'].setValue(item.Description)
    this.myFormGroup.controls['selectType'].setValue(item.Type)
    this.myFormGroup.controls['selectDataType'].setValue(item.DataType)
    this.myFormGroup.controls['textareaExpression'].setValue(item.Expression)
  }

  private readControlsTemp(): OBJECT {

    var dummy: OBJECT = JSON.parse(JSON.stringify(this.item))
    var path: PATH = this.filteredItems.filter(p => p.Name === this.myFormGroup.controls['selectPath'].value)[0]
    if (path) {
      dummy.Path = path.Name
      dummy.PID = path.ID
      dummy.SubjectArea = path.Name.split('/')[0]
    }
    else {
      dummy.Path = ""
      dummy.PID = 0
    }
    dummy.Name = this.myFormGroup.controls['inputName'].value
    dummy.Description = this.myFormGroup.controls['textareaDescription'].value
    dummy.Type = this.myFormGroup.controls['selectType'].value as OBJECT_TYPE
    this.activeType = dummy.Type
    if (this.activeType === OBJECT_TYPE.STANDALONE) {
      this.myFormGroup.controls.selectTable.disable()
      this.myFormGroup.controls.selectColumn.disable()
    }
    else {
      this.myFormGroup.controls.selectTable.enable()
      this.myFormGroup.controls.selectColumn.enable()
    }

    dummy.DataType = this.myFormGroup.controls['selectDataType'].value as DATA_TYPE
    dummy.Expression = this.myFormGroup.controls['textareaExpression'].value
    dummy.SortOrder = 0

    return dummy
  }

  private readControlsPerm() {

    var path: PATH = this.filteredItems.filter(p => p.Name === this.myFormGroup.controls['selectPath'].value)[0]
    if (path) {
      this.item.Path = path.Name
      this.item.PID = path.ID
      this.item.SubjectArea = path.Name.split('/')[0]
    }
    else {
      this.item.Path = ""
      this.item.PID = 0
    }
    this.item.Name = this.myFormGroup.controls['inputName'].value
    this.item.Description = this.myFormGroup.controls['textareaDescription'].value
    this.item.Type = this.myFormGroup.controls['selectType'].value as OBJECT_TYPE
    this.item.DataType = this.myFormGroup.controls['selectDataType'].value as DATA_TYPE
    this.item.Expression = this.myFormGroup.controls['textareaExpression'].value
    this.item.SortOrder = 0
    this.item.IsCustom = 1

  }


  public showToast(item: NotificationComponent): void {
    item.show();
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe()
  }
}
