<div cdkDropList [id]="listID" [cdkDropListOrientation]="'horizontal'" [cdkDropListData]="allitems"
    (cdkDropListDropped)="drop($event)" class="nav-list"
    [cdkDropListConnectedTo]="['draggableobject', 'droppableobject', 'searchedobject']">
    <div cdkDrag *ngFor="let item of items">
        <!------- Filter Item -------->
        <div class="nav__item">
            <div class="nav_component">
                <i coreUIIcon="dragHandle" scale="0.75"></i>
            </div>
            <!------- Filter Type & Name -------->
            <div [class]="item.Type">
                <div *ngIf="item.Type === DIMENSION">
                    <i coreUIIcon="boxFill" scale="0.75" class="nav_component"></i>
                </div>
                <div *ngIf="item.Type === STANDALONE">
                    <i coreUIIcon="rectangle" scale="0.75" class="nav_component"></i>
                </div>
                <div *ngIf="item.Type === SUBQUERY">
                    <i coreUIIcon="object" scale="0.75" class="nav_component"></i>
                </div>
                <div *ngIf="item.Type === MEASURE">
                    <i coreUIIcon="radioFill" scale="0.75" class="nav_component"></i>
                </div>
            </div>
            <div class="nav_component">
                <span>{{ item.Name }}</span>
            </div>
            <!------- Remove Button ------->
            <div class="nav_component">
                <a coreUIPrimaryNavigationItemLink (click)="onRemove(item)" class="new-tab-icon"><i coreUIIcon="close"
                        scale="0.9"></i></a>
            </div>
        </div>
        <!------- Preview Container -------->
        <coreui-card-container *cdkDragPreview>
            <coreui-card role="button" tabindex="0" [header]="item.Name" class="Card-small" [text]="cardTxt4">
                <ng-template #cardTxt4>
                    <div class="custom-definition text-style-3">
                        Subject Area: <span class="text-style-2">{{ item.SubjectArea }}</span>
                        <br />
                        Object Type: <span class="text-style-2">{{ item.Type }}</span> <br />
                        Data Type: <span class="text-style-2">{{item.DataType}}</span>
                    </div>
                    <div coreUIDivider="secondary"></div>
                    <div class="custom-definition text-style-3">
                        {{ item.Description }}<br />
                    </div>
                </ng-template>
            </coreui-card>
        </coreui-card-container>
    </div>
</div>