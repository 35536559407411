import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OktaService } from './okta.service';
import { AccessToken, IDToken } from '@okta/okta-auth-js';
import { BuilderService } from './builder.service';
import { FILTER_CONTAINER_TYPE, JOIN_TYPE, METADATA_ACTION, METADATA_TYPE, PARALLEL_HINT, PARALLEL_HINT_USAGE, TABLEAU_SITE, USER_ROLE } from './datamodel';
import { environment } from '../../environments/environment';
import { BehaviorSubject, map, Observable, Subscription, timer } from 'rxjs';
import { format } from 'sql-formatter';
import { UserService } from './user.service';


export class RepeatingServiceCall<T> {
  readonly observable$: Observable<T>;

  constructor(delay: number) {
    this.observable$ = timer(0, delay)
      .pipe(
        map(() => <T>{})
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private oktaToken: AccessToken;
  private oktaToken2: IDToken;
  public sql: BehaviorSubject<string>;
  public xPlan: BehaviorSubject<string>;
  public isOptimizationLoading: BehaviorSubject<boolean>
  private parallelHint: BehaviorSubject<PARALLEL_HINT>
  private joinSyntax: BehaviorSubject<string>
  private subscription: Subscription;
  public isTenantCopying: BehaviorSubject<boolean>;
  public statusTenantCopy: string;
  public msgTenantCopy: string;
  private duration: number = 1;
  private timerOn: boolean = false;
  private currTime: number = 0;
  private caller: any;
  private value1: any;
  private value2: any;

  constructor(private http: HttpClient, private oktaservice: OktaService, private builderservice: BuilderService, private userservice: UserService) {
    this.oktaservice.accessToken$.subscribe(x => { if (x) { this.oktaToken = x } })
    this.oktaservice.idToken$.subscribe(x => { if (x) { this.oktaToken2 = x } })
    this.sql = new BehaviorSubject<string>('')
    this.xPlan = new BehaviorSubject<string>('')
    this.parallelHint = new BehaviorSubject<PARALLEL_HINT>(undefined)
    this.isOptimizationLoading = new BehaviorSubject<boolean>(false)
    this.isTenantCopying = new BehaviorSubject<boolean>(false)
    this.joinSyntax = new BehaviorSubject<string>("ANSI89")
    this.caller = new RepeatingServiceCall<any>(1000);
    this.caller.observable$.subscribe(() => this.onTick());
    this.value1 = ""
    this.value2 = ""
    this.statusTenantCopy = 'n/a'
    this.msgTenantCopy = ""
  }

  public onTick() {

    if (this.timerOn && this.currTime > this.duration) {
      this.timerOn = false
      this.updateOptimization()
      this.currTime = 0
    }
    else if (this.timerOn)
      this.currTime++

  }

  public resetTimer() {
    this.timerOn = true
    this.currTime = 0
  }

  public getSQL() {

    this.sql.next("Loading...")
    this.xPlan.next("Loading...")
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let body = new URLSearchParams();
    body.set('TenantCode', this.userservice.getSelectedSite().SiteCode)
    body.set('Objects', this.builderservice.getJsonifiedSelectedObjects());
    body.set('SinglerowsFilters', this.builderservice.getJsonifiedSelectedFilterContainers(FILTER_CONTAINER_TYPE.SINGLEROWS));
    body.set('GrouprowsFilters', this.builderservice.getJsonifiedSelectedFilterContainers(FILTER_CONTAINER_TYPE.GROUPROWS));

    this.subscription = this.http.post<any>(`${environment.REST_API_SERVER}/sql`, body, options).subscribe(x => {
      if (x.Message === "Success")
        this.sql.next(this.formatSQL(x.SQL))
      else {
        this.sql.next(x.SQL)
      }
      // this.subscription.unsubscribe()
    })

  }

  public getXPlan() {

    this.sql.next("Loading...")
    this.xPlan.next("Loading...")
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let body = new URLSearchParams();
    body.set('TenantCode', this.userservice.getSelectedSite().SiteCode)
    body.set('Objects', this.builderservice.getJsonifiedSelectedObjects());
    body.set('SinglerowsFilters', this.builderservice.getJsonifiedSelectedFilterContainers(FILTER_CONTAINER_TYPE.SINGLEROWS));
    body.set('GrouprowsFilters', this.builderservice.getJsonifiedSelectedFilterContainers(FILTER_CONTAINER_TYPE.GROUPROWS));

    this.subscription = this.http.post<any>(`${environment.REST_API_SERVER}/xplan`, body, options).subscribe(x => {
      if (x.Message === "Success") {
        var newLine = "\r\n";
        this.xPlan.next(x.xplan.join(newLine))
      }
      else {
        this.xPlan.next(x.Message)
      }

      // this.subscription.unsubscribe()
    })

  }

  public getParallelHint(): PARALLEL_HINT {
    return this.parallelHint.value
  }

  public getJoinSyntax(): string {
    return this.joinSyntax.value
  }

  public getOptimization() {
    this.isOptimizationLoading.next(true)
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    var tenantcode = this.userservice.getSelectedSite().SiteCode
    this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/metadata?type=${METADATA_TYPE.OPTIMIZATION}&tenantcode=${tenantcode}`, options).subscribe((resp: any) => {
      this.parallelHint.next({ Usage: resp.Usage, Value: resp.Value })
      this.joinSyntax.next(resp.Syntax)
      this.isOptimizationLoading.next(false)
      // this.subscription.unsubscribe()
    })
  }

  public setOptimization(value1, value2) {
    this.value1 = value1
    this.value2 = value2
    this.resetTimer()
  }

  public updateOptimization() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let body = new URLSearchParams();
    body.set('TenantCode', this.userservice.getSelectedSite().SiteCode)
    body.set('Data', `{ "ParallelHint": "${this.value1}", "JoinSyntax": "${this.value2}" }`)
    body.set('MetaType', METADATA_TYPE.OPTIMIZATION)
    body.set('Operation', METADATA_ACTION.UPDATE)


    this.subscription = this.http.post<any>(`${environment.REST_API_SERVER}/metadata`, body, options).subscribe(x => {
    })

  }

  private formatSQL(str: string): string {
    try {
      return format(str, { language: 'plsql' })
    }
    catch (e) {
      return str
    }
  }

  public copyTenant(fromTenant: TABLEAU_SITE, toTenant: TABLEAU_SITE, types: string[]) {
    this.isTenantCopying.next(true)
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let body = new URLSearchParams();
    body.set('SourceTenantCode', fromTenant.SiteCode)
    body.set('TargetTenantCode', toTenant.SiteCode)
    body.set('MetadataTypes', types.join(""))

    this.subscription = this.http.post<any>(`${environment.REST_API_SERVER}/multi-tenant`, body, options).subscribe(x => {
      this.statusTenantCopy = x.Status //"Success" or "Failed"
      this.msgTenantCopy = x.Message
      this.isTenantCopying.next(false)
      //this.subscription.unsubscribe()
    })
  }

  public getStatusTenantCopy() {
    return this.statusTenantCopy
  }

  public getMessageTenantCopy() {
    return this.msgTenantCopy
  }



}