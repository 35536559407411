import { Component, OnInit } from '@angular/core';
import { OktaService } from '../../service/okta.service';
import { UnauthorizedComponent } from '../unauthorized/unauthorized.component';
import { UntenantedComponent } from '../untenanted/untenanted.component';
import { ConsoleComponent } from '../../admin/console/console.component';
import { NgIf } from '@angular/common';
import { UserService } from '../../service/user.service';
import { USER_ROLE } from '../../service/datamodel';
import { UnauthenticatedComponent } from '../unauthenticated/unauthenticated.component';

@Component({
  selector: 'app-dispatchadmin',
  standalone: true,
  imports: [ConsoleComponent, UnauthorizedComponent, UntenantedComponent, NgIf, UnauthenticatedComponent],
  templateUrl: './dispatchadmin.component.html',
  styleUrl: './dispatchadmin.component.scss'
})
export class DispatchadminComponent implements OnInit {
  public IsAuth: boolean
  public IsTenant: boolean
  public isAdmin: boolean

  constructor(private oktaservice: OktaService, private userservice: UserService) {
    this.IsAuth = false
    this.IsTenant = false
    this.isAdmin = false
  }

  ngOnInit() {
    this.oktaservice.isAuthenticated$.subscribe(x => {
      this.IsAuth = x
    })
    this.userservice.isTenantSelected.subscribe(x => {
      this.IsTenant = x
    })

    this.userservice.isTenantSelected.subscribe(x => {
      if (x) {
        var siteUserRole = this.userservice.getUserRole()
        if (siteUserRole === USER_ROLE.SUPERADMIN || siteUserRole === USER_ROLE.ADMIN)
          this.isAdmin = true
        else this.isAdmin = false
      }
    })
  }
}
