import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CoreUIModule } from '@epsilon/core-ui';
import { CommonModule } from '@angular/common';
import { OperatorfilterPipe } from "../../pipe/operatorfilter.pipe";
import { ACTION, FILTER, FILTER_CONTAINER_TYPE, OBJECT_TYPE, OPERATOR } from '../../service/datamodel';
import { BuilderService } from '../../service/builder.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filteroperator',
  standalone: true,
  imports: [CoreUIModule, ReactiveFormsModule, CommonModule, OperatorfilterPipe],
  templateUrl: './filteroperator.component.html',
  styleUrl: './filteroperator.component.scss'
})
export class FilteroperatorComponent implements OnInit, OnChanges {
  public myFormGroup = new FormGroup({})
  @Input() containerID: number;
  @Input() containerType: FILTER_CONTAINER_TYPE;
  private _item: FILTER
  @Input()
  set item(value: any) {
    this._item = value;
    this.builderservice.validateFilter(value)
  }
  get item(): any {
    return this._item;
  }
  @Input() public isDraggable: boolean
  // @Output('comparisonOperatorSetEvent') comparisonOperatorSetEvent: EventEmitter<any> = new EventEmitter();
  public operators: OPERATOR[]
  private subFormChange: Subscription

  constructor(private builderservice: BuilderService) {
    this.operators = []
  }

  ngOnInit(): void {
    this.buildForm(this.item, ACTION.ADD)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      // Call your function here
      //this.builderservice.updateSelectedFilterValue(this.containerType, this.containerID, this.item);
    }
  }


  private buildForm(obj: FILTER, action?: ACTION): void {
    if (this.subFormChange)
      this.subFormChange.unsubscribe()

    //start with a clean slat
    if (action === ACTION.INIT) {
    }

    else if (obj && action === ACTION.ADD) {
      if (obj.Type === OBJECT_TYPE.DIMENSION)
        this.builderservice.queryParamValues(obj)
      this.myFormGroup.setControl('comparison_operator', new FormControl(obj.ComparisonOperator, [Validators.required]))
    }

    else if (obj && action === ACTION.DELETE) {
      this.myFormGroup.removeControl('comparison_operator')
    }

    this.subFormChange = this.myFormGroup.valueChanges.subscribe(val => this.onFilterValueChange(val))

  }

  private onFilterValueChange(val) {
    this.readFromControl()
    this.builderservice.updateSelectedFilterValue(this.containerType, this.containerID, this.item)
  }

  private readFromControl() {
    var newOperator = this.myFormGroup.controls['comparison_operator'].value
    this.item.ComparisonOperator = newOperator
    // this.comparisonOperatorSetEvent.emit(newOperator)
  }

}
