import { ProfileComponent } from "../profile/profile.component";
import { Component, OnInit } from '@angular/core';
import { OktaService } from '../../service/okta.service';
import { NgIf } from "@angular/common";
import { UserService } from "../../service/user.service";
import { UnauthenticatedComponent } from "../unauthenticated/unauthenticated.component";

@Component({
  selector: 'app-dispatchprofile',
  standalone: true,
  imports: [ProfileComponent, NgIf, UnauthenticatedComponent],
  templateUrl: './dispatchprofile.component.html',
  styleUrl: './dispatchprofile.component.scss'
})
export class DispatchprofileComponent implements OnInit {

  public IsAuth: boolean
  public IsTenant: boolean

  constructor(private oktaservice: OktaService, private userservice: UserService) {
    this.IsAuth = false
    this.IsTenant = false
  }

  ngOnInit() {
    this.oktaservice.isAuthenticated$.subscribe(x => {
      this.IsAuth = x
    })
    this.userservice.isTenantSelected.subscribe(x => {
      this.IsTenant = x
    })
  }

}
