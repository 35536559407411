import { Component } from '@angular/core';
import { CoreUIModule } from '@epsilon/core-ui';
import { CoreUIIllustrationsModule } from '@epsilon/core-ui/illustrations';

@Component({
  selector: 'app-untenanted',
  standalone: true,
  imports: [CoreUIModule, CoreUIIllustrationsModule],
  templateUrl: './untenanted.component.html',
  styleUrl: './untenanted.component.scss'
})
export class UntenantedComponent {

}
