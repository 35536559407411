import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CoreUIModule } from '@epsilon/core-ui';
import { ACTION, FILTER_CONTAINER, FILTER_CONTAINER_TYPE, LOGICAL_OPERATOR } from '../../service/datamodel';
import { FormControl, FormGroup } from '@angular/forms';
import { BuilderService } from '../../service/builder.service';
import { ReactiveFormsModule } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';
import { DroppablefilterComponent } from "../../parts/droppablefilter/droppablefilter.component";
import { HistoryService } from '../../service/history.service';

@Component({
  selector: 'app-filtereditor',
  standalone: true,
  imports: [CoreUIModule, ReactiveFormsModule, DroppablefilterComponent, NgIf, NgFor],
  templateUrl: './filtereditor.component.html',
  styleUrl: './filtereditor.component.scss'
})
export class FiltereditorComponent implements OnInit, OnDestroy {

  public singleRowsFilterContainers: FILTER_CONTAINER[];
  public groupRowsFilterContainers: FILTER_CONTAINER[];
  public myFormGroup = new FormGroup({})

  public SINGLEROWS: FILTER_CONTAINER_TYPE;
  public GROUPROWS: FILTER_CONTAINER_TYPE;
  public AND: LOGICAL_OPERATOR;
  public OR: LOGICAL_OPERATOR;
  // private valueChangesSubscription: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef, private builderservice: BuilderService, private historyservice: HistoryService) {
    this.SINGLEROWS = FILTER_CONTAINER_TYPE.SINGLEROWS;
    this.GROUPROWS = FILTER_CONTAINER_TYPE.GROUPROWS;
    this.AND = LOGICAL_OPERATOR.AND;
    this.OR = LOGICAL_OPERATOR.OR;
  }

  ngOnInit(): void {
    // this.valueChangesSubscription = this.myFormGroup.valueChanges.subscribe(val => {
    //   this.handleChange(val)
    // })

    this.builderservice.selectedSinglerowsFilterContainers.subscribe(x => {
      this.singleRowsFilterContainers = x
      this.changeDetectorRef.detectChanges();
      this.myFormGroup = new FormGroup({})
      this.singleRowsFilterContainers.forEach(sfc => {
        this.buildForm(this.SINGLEROWS, sfc, ACTION.ADD)
      })

    })
    this.builderservice.selectedGrouprowsFilterContainers.subscribe(x => {
      this.groupRowsFilterContainers = x
      this.myFormGroup = new FormGroup({})
      this.groupRowsFilterContainers.forEach(gfc => {
        this.buildForm(this.GROUPROWS, gfc, ACTION.ADD)
      })
    })
    this.historyservice.isHistoryLoading.subscribe(x => {
      if (!x) {
        this.singleRowsFilterContainers = this.builderservice.getSelectedFilterContrainers(this.SINGLEROWS)
        this.groupRowsFilterContainers = this.builderservice.getSelectedFilterContrainers(this.GROUPROWS)
        this.myFormGroup = new FormGroup({})
        this.singleRowsFilterContainers.forEach(fc => {
          this.buildForm(this.SINGLEROWS, fc, ACTION.ADD)
        })
        this.groupRowsFilterContainers.forEach(fc => {
          this.buildForm(this.GROUPROWS, fc, ACTION.ADD)
        })
      }
    })
  }

  private buildForm(container_type: FILTER_CONTAINER_TYPE, fc: FILTER_CONTAINER, action: string): void {

    //start with a clean slat
    if (action === ACTION.INIT) {
      this.myFormGroup = new FormGroup({})
    }

    // Add or remove logical operator for a given container. Container type and id must be specified.
    if (fc && action === ACTION.ADD) {
      this.myFormGroup.addControl('filter_operator_' + container_type + '_' + fc.ID, new FormControl(fc.LogicalOperator))
    }
    else if (fc && action === ACTION.DELETE) {
      this.myFormGroup.removeControl('filter_operator_' + container_type + '_' + fc.ID)
    }
  }

  onSelect(op: LOGICAL_OPERATOR, type: FILTER_CONTAINER_TYPE, id: number) {
    this.builderservice.updateSelectedFilterContainerLogicalOperator(type, id, op)
    if (type === FILTER_CONTAINER_TYPE.SINGLEROWS)
      this.singleRowsFilterContainers.filter(c => c.ID === id)[0].LogicalOperator = op
    else
      this.groupRowsFilterContainers.filter(c => c.ID === id)[0].LogicalOperator = op
  }

  onAdd(containertype: FILTER_CONTAINER_TYPE, id: number) {
    this.builderservice.addSelectedFilterContainer(containertype, id)
  }

  onRemove(containertype: FILTER_CONTAINER_TYPE, id: number) {
    if (containertype === this.SINGLEROWS) {
      this.builderservice.removeSelectedFilterContainer(this.SINGLEROWS, id)
    }
    else {
      this.builderservice.removeSelectedFilterContainer(this.GROUPROWS, id)
    }
  }
  ngOnDestroy(): void {
    // if (this.valueChangesSubscription)
    //   this.valueChangesSubscription.unsubscribe()
  }

}
