import { Component, Input, OnInit } from '@angular/core';
import { FILTER, FILTER_CONTAINER_TYPE, FILTER_METHOD, OBJ, OBJECT_TYPE } from '../../service/datamodel';
import { CoreUIModule } from '@epsilon/core-ui';
import { CommonModule } from '@angular/common';
import { BuilderService } from '../../service/builder.service';

@Component({
  selector: 'app-subquerydisplay',
  standalone: true,
  imports: [CoreUIModule, CommonModule],
  templateUrl: './subquerydisplay.component.html',
  styleUrl: './subquerydisplay.component.scss'
})
export class SubquerydisplayComponent implements OnInit {
  @Input() parent: FILTER;
  @Input() containerID: number;
  @Input() containerType: FILTER_CONTAINER_TYPE;
  public item: OBJ;
  public DIMENSION: OBJECT_TYPE;
  public MEASURE: OBJECT_TYPE;
  public STANDALONE: OBJECT_TYPE;
  public SUBQUERY: OBJECT_TYPE
  public M_DUAL_HANDWRITING: FILTER_METHOD;
  public M_DUAL_HANDWRITING_DECIMAL: FILTER_METHOD;
  public M_DUAL_DATETIME: FILTER_METHOD;
  public M_DUAL_DATE: FILTER_METHOD;


  constructor(private builderservice: BuilderService) {
    this.DIMENSION = OBJECT_TYPE.DIMENSION
    this.MEASURE = OBJECT_TYPE.MEASURE
    this.STANDALONE = OBJECT_TYPE.STANDALONE
    this.SUBQUERY = OBJECT_TYPE.SUBQUERY
    this.M_DUAL_HANDWRITING = FILTER_METHOD.DUAL_HANDWRITING;
    this.M_DUAL_DATE = FILTER_METHOD.DUAL_DATE;
    this.M_DUAL_DATETIME = FILTER_METHOD.DUAL_DATETIME;

  }

  ngOnInit(): void {
    this.item = this.parent.SubqueryObj
  }
  // when subquey remove button is clicked
  public onSubqueryRemove() {
    this.parent.SubqueryObj = undefined
    this.parent.SubqueryObjID = undefined
    this.builderservice.updateSelectedFilterValue(this.containerType, this.containerID, this.parent)
  }

}
