import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ObjectselectorComponent } from '../../panes/objectselector/objectselector.component';
import { PreviewcontainerComponent } from '../../panes/previewcontainer/previewcontainer.component';
import { ResultcontainerComponent } from '../../panes/resultcontainer/resultcontainer.component';
import { FiltereditorComponent } from '../../panes/filtereditor/filtereditor.component';
import { CoreUIModule, ThemeColorService } from '@epsilon/core-ui';
import { CdkDropListGroup } from '@angular/cdk/drag-drop';
import { EditormenuComponent } from '../../panes/editormenu/editormenu.component';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-builder',
  standalone: true,
  imports: [CoreUIModule, ReactiveFormsModule, PreviewcontainerComponent, ObjectselectorComponent,
    ResultcontainerComponent, FiltereditorComponent, CdkDropListGroup, EditormenuComponent],
  templateUrl: './builder.component.html',
  styleUrl: './builder.component.scss'
})
export class BuilderComponent implements OnInit, AfterViewInit {
  public isResultExpand: boolean;
  public isFilterExpand: boolean;
  public isPreviewExpand: boolean;

  constructor(private themeColorService: ThemeColorService) {
    this.isResultExpand = true;
    this.isFilterExpand = true;
    this.isPreviewExpand = true;
    this.themeColorService.setTheme(parseInt(localStorage.getItem('theme')));

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
  }

  public onActiveChange(event) {
  }

  public segmentChanged(x) {
    if (x.indexOf('result') >= 0) {
      this.isResultExpand = false
      this.isResultExpand = true
    }
    else
      this.isResultExpand = false
    if (x.indexOf('filter') >= 0) {
      this.isFilterExpand = false
      this.isFilterExpand = true
    }
    else
      this.isFilterExpand = false
    if (x.indexOf('preview') >= 0) {
      this.isPreviewExpand = false
      this.isPreviewExpand = true
    }
    else
      this.isPreviewExpand = false
  }

}
