import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MetadataService } from '../../service/metadata.service';
import { CoreUIModule, ModalComponent, NotificationComponent } from '@epsilon/core-ui';
import { FOLDER, PATH, DATA_TYPE, OBJECT_TYPE, METADATA_TYPE, METADATA_ACTION, TABLE, COLUMN } from '../../service/datamodel';
import { CommonModule } from '@angular/common';
import { OrderbyPipe } from "../../pipe/orderby.pipe";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-foldermodal',
  standalone: true,
  imports: [CoreUIModule, CommonModule, OrderbyPipe, ReactiveFormsModule],
  templateUrl: './foldermodal.component.html',
  styleUrl: './foldermodal.component.scss'
})
export class FoldermodalComponent implements OnInit, OnDestroy {
  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;
  @ViewChild('myInput') myInput: ElementRef;
  @ViewChild('infoError', { static: true }) public infoError: NotificationComponent;
  @ViewChild('infoSuccess', { static: true }) public infoSuccess: NotificationComponent;
  private paths: PATH[]
  public item: FOLDER;
  public types: OBJECT_TYPE[];
  public FOLDER: METADATA_TYPE;
  public myFormGroup = new FormGroup({
    selectPath: new FormControl('', [
      Validators.required
    ]),
    inputName: new FormControl('', [
      Validators.required
    ]),
    textareaDescription: new FormControl('', [
      Validators.required
    ])
  })
  private subscription: Subscription
  private subChange: Subscription
  public isFolderValid: boolean;
  public isFolderValidating: boolean;
  public action: METADATA_ACTION;
  public isFormError: boolean;
  public errMsgPath: string
  public errMsgName: string
  public errMsgDescription: string;
  private typing: number;
  private _filteredItems: PATH[] = [];
  public set filteredItems(value: PATH[]) {
    this._filteredItems = value;
  }
  public get filteredItems(): PATH[] {
    return this._filteredItems;
  }
  public msg: string;


  constructor(private changeDetectorRef: ChangeDetectorRef, private metadataservice: MetadataService) {
    this.isFolderValid = false
    this.isFolderValidating = false
    this.msg = ""
    this.FOLDER = METADATA_TYPE.FOLDER
  }

  ngOnInit(): void {

    this.subscription = this.metadataservice.isMetaPathLoading.subscribe(x => {
      if (!x) {
        this.paths = this.metadataservice.getPathsPlus()
        this.filteredItems = this.paths
      }
    })

    this.subscription = this.metadataservice.msgMetaAction.subscribe(x => {
      if (x !== undefined) {
        this.msg = x
        if (this.msg.toUpperCase().indexOf("SUCCESS") >= 0) {
          this.showToast(this.infoSuccess)
        }
        else
          this.showToast(this.infoError)
      }
    })


    this.subChange = this.myFormGroup.valueChanges.subscribe(val => this.formChanged(val))

    this.subscription = this.metadataservice.isMetaFolderValidating.subscribe(x => {
      this.isFolderValidating = x
    })

    this.subscription = this.metadataservice.isMetaFolderValid.subscribe(x => {

      if (x) {
        //Success
        if (x.Result) {
          this.myFormGroup.controls["inputName"].setErrors(null)
          this.isFolderValid = true;
        }
        //Failure
        else {
          if (x.Message === "Failed: A folder with the same name exists in the same subject.") {
            this.myFormGroup.controls.inputName.setErrors({ "msg": "Failed: Invalid Name" })
            this.errMsgName = "A folder with the same name exists in the same subject."
          }
          else if (x.Message.indexOf("Failed: ") >= 0) {
            this.errMsgDescription = x.Message.replace("Failed: ", "")
          }
          this.myFormGroup.markAsDirty()
        }
      }
    })

  }

  private formChanged(val: any) {
    this.isFolderValid = false
  }

  public async closeBasicModal(): Promise<void> {
    await this.basicModal.hide();
    this.isFolderValidating = false
  }

  public async saveBasicModal(): Promise<void> {
    this.readControlsPerm()
    this.metadataservice.setMeta(this.item, METADATA_TYPE.FOLDER, this.action)
    this.isFolderValidating = false
    await this.basicModal.hide()
  }

  public onBlur(event) {
    this.onValidate()
  }

  public onSelect(event) {
    console.log(event)
    this.onValidate()
  }

  public onSearchChange(searchText): void {
    this.filteredItems = searchText
      ? this.paths.filter(
        (item) =>
          item.Name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      : this.paths;
  }

  public onKeyPress(event: any) {
    if (this.typing) {
      window.clearTimeout(this.typing);
      this.typing = undefined;
    }
    this.typing = window.setTimeout(() => {
      this.onValidate()
      this.changeDetectorRef.detectChanges();
    }, 600);
  }

  public onValidate() {
    var temp: FOLDER = this.readControlsTemp()
    this.isFormError = false
    if (temp.Path === "") {
      this.myFormGroup.controls.selectPath.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgPath = "Required"
    }
    if (temp.Name === "") {
      this.myFormGroup.controls.inputName.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgName = "Required"
    }
    if (temp.Description === "") {
      this.myFormGroup.controls.textareaDescription.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgDescription = "Required"
    }
    if (!this.isFormError) {
      this.metadataservice.setMetadataValidation(temp, METADATA_TYPE.FOLDER, this.action)
      this.errMsgDescription = ""
      this.errMsgName = ""
      this.errMsgPath = ""
    }

  }

  public async launchBasicModal(item: FOLDER, action: METADATA_ACTION): Promise<void> {

    this.action = action
    this.item = item
    this.loadControls(item)
    this.onValidate()
    // Load meta tables only for the first time
    if (this.metadataservice.getAllPaths().length === 0)
      this.metadataservice.querySpecificMetadata(METADATA_TYPE.PATH)

    await this.basicModal.show();

  }

  private loadControls(item: FOLDER) {
    this.myFormGroup.controls['selectPath'].setValue(item.Path)
    this.myFormGroup.controls['inputName'].setValue(item.Name)
    this.myFormGroup.controls['textareaDescription'].setValue(item.Description)
  }

  private readControlsTemp(): FOLDER {

    var dummy: FOLDER = JSON.parse(JSON.stringify(this.item))
    var path: PATH = this.filteredItems.filter(p => p.Name === this.myFormGroup.controls['selectPath'].value)[0]
    dummy.Name = this.myFormGroup.controls['inputName'].value
    dummy.Description = this.myFormGroup.controls['textareaDescription'].value
    dummy.Type = OBJECT_TYPE.FOLDER

    if (path) {
      dummy.Path = path.Name
      dummy.PID = path.ID
      if (path.Name === " -- Top --") {
        dummy.SubjectArea = dummy.Name
        dummy.Path = dummy.Name
        dummy.PID = undefined
      }
      else
        dummy.SubjectArea = path.Name.split('/')[0]
    }
    return dummy
  }

  private readControlsPerm() {

    var path: PATH = this.filteredItems.filter(p => p.Name === this.myFormGroup.controls['selectPath'].value)[0]
    this.item.Name = this.myFormGroup.controls['inputName'].value
    this.item.Description = this.myFormGroup.controls['textareaDescription'].value
    this.item.SortOrder = 0
    this.item.IsCustom = 1
    this.item.IsActive = 1

    if (path) {
      this.item.Path = path.Name
      this.item.PID = path.ID
      this.item.SubjectArea = path.Name.split('/')[0]

      if (path.Name === " -- Top --") {
        this.item.SubjectArea = this.item.Name
        this.item.Path = this.item.Name
        this.item.PID = undefined
      }

    }

  }


  public showToast(item: NotificationComponent): void {
    item.show();
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe()
  }
}
