import { CdkDragDrop, CdkDragEnter, CdkDragExit, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ACTION, COMPARISON_OPERATOR, DATA_TYPE, FILTER, FILTER_CONTAINER, FILTER_CONTAINER_TYPE, FILTER_METHOD, LOGICAL_OPERATOR, OBJ, OBJECT_TYPE, OPERATOR } from '../../service/datamodel';
import { BuilderService } from '../../service/builder.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CoreUIModule, NotificationComponent, OnChangeFn } from '@epsilon/core-ui';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop'
import { FiltervalueComponent } from "../filtervalue/filtervalue.component";
import { Subscription } from 'rxjs';
import { SubquerydropComponent } from "../subquerydrop/subquerydrop.component";
import { FilteroperatorComponent } from "../filteroperator/filteroperator.component";
import { v4 as uuid } from 'uuid';
import { SubquerydisplayComponent } from "../subquerydisplay/subquerydisplay.component";

@Component({
  selector: 'app-droppablefilter',
  standalone: true,
  imports: [CoreUIModule, CommonModule, ReactiveFormsModule, CommonModule, DragDropModule, FiltervalueComponent, SubquerydropComponent, FilteroperatorComponent, SubquerydisplayComponent],
  templateUrl: './droppablefilter.component.html',
  styleUrl: './droppablefilter.component.scss'
})
export class DroppablefilterComponent implements OnInit, OnDestroy, OnChanges {

  @Input() containerID: number
  @Input() containerType: FILTER_CONTAINER_TYPE
  @Input() filtercontainer: FILTER_CONTAINER
  @ViewChild('infoNotMeasure', { static: true }) public infoNotMeasure: NotificationComponent;
  @ViewChild('infoMustInclude', { static: true }) public infoMustInclude: NotificationComponent;
  @ViewChild('infoNotDimension', { static: true }) public infoNotDimension: NotificationComponent;
  @ViewChild('infoNoBLOB', { static: true }) public infoNoBLOB: NotificationComponent;
  @ViewChild('infoStandalone', { static: true }) public infoStandalone: NotificationComponent;
  @ViewChild('infoSubq', { static: true }) public infoSubq: NotificationComponent;
  @ViewChild('infoDataTypes', { static: true }) public infoDataTypes: NotificationComponent;
  @ViewChild('filterVal', { static: true }) public filterVal: FiltervalueComponent;
  public params: string[]
  public items: FILTER[];
  public operators: OPERATOR[]
  public myFormGroup = new FormGroup({})
  private valueChangesSubscription: Subscription;
  private subscription: Subscription;
  public AND: LOGICAL_OPERATOR;
  public OR: LOGICAL_OPERATOR;
  private newSnapshot: any;
  private oldSnapshot: any;
  private subqueryParentFilter: FILTER
  public M_HANDWRITING: FILTER_METHOD;
  public M_LIKE_HANDWRITING: FILTER_METHOD;
  public M_DUAL_HANDWRITING: FILTER_METHOD;
  public M_SINGLE_SELECT: FILTER_METHOD;
  public M_MULTI_SELECT: FILTER_METHOD;
  public M_DATE: FILTER_METHOD;
  public M_DUAL_DATE: FILTER_METHOD;
  public M_TOGGLE: FILTER_METHOD;
  public M_DATETIME: FILTER_METHOD;
  public M_DUAL_DATETIME: FILTER_METHOD;
  public M_SUBQUERY: FILTER_METHOD;
  public M_IS_NULL: FILTER_METHOD;
  public M_IS_NOT_NULL: FILTER_METHOD;
  public DIMENSION: OBJECT_TYPE;
  public MEASURE: OBJECT_TYPE;
  public STANDALONE: OBJECT_TYPE;
  public SUBQUERY: OBJECT_TYPE
  public isDragging: boolean
  public dragging: number;
  private prevItem: FILTER;
  public filterDataType: DATA_TYPE;
  public subqueryDataType: DATA_TYPE;
  constructor(private builderservice: BuilderService, private changeDetectorRef: ChangeDetectorRef) {
    this.items = []
    this.AND = LOGICAL_OPERATOR.AND
    this.OR = LOGICAL_OPERATOR.OR
    this.M_HANDWRITING = FILTER_METHOD.HANDWRITING;
    this.M_LIKE_HANDWRITING = FILTER_METHOD.LIKE_HANDWRITING
    this.M_DUAL_HANDWRITING = FILTER_METHOD.DUAL_HANDWRITING;
    this.M_SINGLE_SELECT = FILTER_METHOD.SINGLE_SELECT;
    this.M_MULTI_SELECT = FILTER_METHOD.MULTI_SELECT
    this.M_DATE = FILTER_METHOD.DATE
    this.M_DUAL_DATE = FILTER_METHOD.DUAL_DATE;
    this.M_TOGGLE = FILTER_METHOD.TOGGLE;
    this.M_DATETIME = FILTER_METHOD.DATETIME;
    this.M_DUAL_DATETIME = FILTER_METHOD.DUAL_DATETIME;
    this.M_SUBQUERY = FILTER_METHOD.SUBQUERY;
    this.M_IS_NULL = FILTER_METHOD.IS_NULL;
    this.M_IS_NOT_NULL = FILTER_METHOD.IS_NOT_NULL;
    this.MEASURE = OBJECT_TYPE.MEASURE;
    this.DIMENSION = OBJECT_TYPE.DIMENSION;
    this.STANDALONE = OBJECT_TYPE.STANDALONE;
    this.SUBQUERY = OBJECT_TYPE.SUBQUERY
    this.isDragging = false
    this.prevItem = undefined
  }

  ngOnInit(): void {

    this.subscription = this.builderservice.selectedSinglerowsFilterContainers.subscribe(x => {
      if (this.containerType === FILTER_CONTAINER_TYPE.SINGLEROWS) {
        // Only one container matches both container type and ID
        var container = x.filter(y => y.ID === this.containerID)[0]
        if (container) {
          this.items = container.Filters
          this.buildForm(undefined, ACTION.INIT)
          for (const item of this.items) {
            this.buildForm(item, ACTION.ADD)
          }
        }
      }
    })

    this.subscription = this.builderservice.selectedGrouprowsFilterContainers.subscribe(x => {
      if (this.containerType === FILTER_CONTAINER_TYPE.GROUPROWS) {
        // Only one container matches both container type and ID
        var container = x.filter(y => y.ID === this.containerID)[0]
        if (container) {
          this.items = container.Filters
          this.buildForm(undefined, ACTION.INIT)
          for (const item of this.items) {
            this.buildForm(item, ACTION.ADD)
          }
        }
      }
    })
    this.subscription = this.builderservice.isDragging.subscribe(x => {
      this.isDragging = x
      this.changeDetectorRef.detectChanges();
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['filters']) {
    //   for (const f of this.filtercontainer.Filters) {
    //     this.buildForm(f, ACTION.ADD)
    //   }
    // }
  }

  public buildForm(obj?: FILTER, action?: ACTION): void {

    if (this.valueChangesSubscription)
      this.valueChangesSubscription.unsubscribe()

    //start with a clean slat
    if (action === ACTION.INIT) {
      this.myFormGroup = new FormGroup({})
    }

    else if (obj && action === ACTION.ADD) {
      this.myFormGroup.setControl('logical_operator_' + this.containerType + '_' + this.containerID + '_' + obj.ID + '_' + obj.FilterID, new FormControl(obj.FilterLogicalOperator, [Validators.required]))
      this.oldSnapshot = [...Object.entries(this.myFormGroup.value)].sort((a, b) => a[0].localeCompare(b[0]));
    }

    else if (obj && action === ACTION.DELETE) {
      this.myFormGroup.removeControl('logical_operator_' + this.containerType + '_' + this.containerID + '_' + obj.ID + '_' + obj.FilterID)
    }

    this.valueChangesSubscription = this.myFormGroup.valueChanges.subscribe(val => {
      this.handleValueChanges(val)
    })

  }

  private handleValueChanges(val) {
    this.newSnapshot = [...Object.entries(val)].sort((a, b) => a[0].localeCompare(b[0]))
    // compare the snapshots to identify what control was modified.
    const modifiedCntrl = this.compControlValues(this.newSnapshot, this.oldSnapshot)
    if (modifiedCntrl.name !== "") {
      var FilID = modifiedCntrl.name.split("_")[5]
      //var obj = this.builderservice.convertOBJtoFILTER(this.builderservice.getObjects().filter(o => o.ID === parseInt(ID))[0])
      var fil: FILTER = this.items.filter(i => i.FilterID === FilID)[0]
      fil.FilterLogicalOperator = modifiedCntrl.value
      this.builderservice.updateSelectedFilterValue(this.containerType, this.containerID, fil)

      this.oldSnapshot = [...this.newSnapshot]
    }
  }

  private compControlValues(s1, s2): any {
    var changedCtrlName: string = ''
    var changedCtrlValue: string = ''
    for (let i = 0; i < s1.length; i = i + 1) {
      if (s1[i][1] !== s2[i][1]) {
        changedCtrlName = s1[i][0]
        changedCtrlValue = s1[i][1]
        break;
      }
    }
    return { name: changedCtrlName, value: changedCtrlValue }

  }


  drop(event: CdkDragDrop<any[]>) {

    if (event.previousContainer === event.container) {
      //Move within container
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      //When order is swapped, need to swap the FilterIndex as well.
      var currDOMID = event.container.id
      var currContainerType = currDOMID.substring(currDOMID.indexOf('_') + 1, currDOMID.lastIndexOf('_'))
      var currContainerIndex = parseInt(currDOMID.substring(currDOMID.lastIndexOf('_') + 1, currDOMID.length))
      this.builderservice.reorderSlectedFilters(currContainerType, currContainerIndex, event.container.data)
    }
    else {
      //Cross containers
      var droppedItem: OBJ = event.previousContainer.data[event.previousIndex]
      // OBJ : FILTER <> 1 : 1
      var droppedFilter: FILTER = JSON.parse(JSON.stringify(event.previousContainer.data[event.previousIndex]))
      droppedFilter.FilterID = uuid();
      droppedFilter.FilterMethod = this.M_SINGLE_SELECT
      droppedFilter.isSubqueryMode = false
      droppedFilter.SubqueryObjID = undefined
      this.subqueryDataType = droppedFilter.DataType
      // Subquery drop
      if (this.subqueryParentFilter) {
        this.subqueryParentFilter.SubqueryObjID = droppedItem.ID
        this.subqueryParentFilter.FilterValue = undefined
        this.subqueryParentFilter.FilterValue2 = undefined
        this.subqueryParentFilter.isSubqueryMode = false
        this.subqueryParentFilter.SubqueryObj = droppedItem
        this.subqueryParentFilter.FilterMethod = FILTER_METHOD.SUBQUERY
        this.filterDataType = this.subqueryParentFilter.DataType
        this.builderservice.updateSelectedFilterValue(this.containerType, this.containerID, this.subqueryParentFilter)
        if (this.subqueryParentFilter.ComparisonOperator === COMPARISON_OPERATOR.BETWEEN || this.subqueryParentFilter.ComparisonOperator === COMPARISON_OPERATOR.NOT_BETWEEN)
          this.subqueryParentFilter.ComparisonOperator = undefined
        if (this.filterDataType !== undefined && this.subqueryDataType !== undefined && this.filterDataType !== this.subqueryDataType)
          this.showToast(this.infoDataTypes)
      }
      // Non-subfilter drop
      else {
        // Make sure it is not a duplicate
        //if (event.container.data.filter(i => i.ID === droppedItem.ID).length === 0) {
        //There are the possible cases:
        //1. Coming from one of the non-filter containers (i.e. draggableobject or droppableobject or searchedobject)
        //2. Coming from one of the same type filter containers (e.g., SingleRows from SingleRows)
        //3. Coming from one of the different type filter containers (e.g.,GroupRows from SingleRows)
        var prevDOMID: string = event.previousContainer.id
        // 1
        if (prevDOMID === 'draggableobject' || prevDOMID === 'droppableobject' || prevDOMID === 'searchedobject') {
          // Measure is not allowed in single row filter container.
          if (droppedItem.Type === OBJECT_TYPE.MEASURE && this.containerType === FILTER_CONTAINER_TYPE.SINGLEROWS)
            this.showToast(this.infoNotMeasure)
          // Dimension is not allowed in group row filter container.
          else if (droppedItem.Type === OBJECT_TYPE.DIMENSION && this.containerType === FILTER_CONTAINER_TYPE.GROUPROWS)
            this.showToast(this.infoNotDimension)
          else if (droppedItem.Type === OBJECT_TYPE.DIMENSION && droppedItem.DataType === DATA_TYPE.BLOB)
            this.showToast(this.infoNoBLOB)
          else if (droppedItem.Type === OBJECT_TYPE.STANDALONE)
            this.showToast(this.infoStandalone)
          else if (droppedItem.Type === OBJECT_TYPE.SUBQUERY)
            this.showToast(this.infoSubq)
          else {
            //Grouped row filter must also appear in result objects.
            if (this.containerType === FILTER_CONTAINER_TYPE.GROUPROWS && this.builderservice.selectedObjects.value.filter(o => o.ID === droppedItem.ID).length === 0) {
              this.showToast(this.infoMustInclude)
              this.builderservice.addSelectedObject(droppedItem)
            }
            droppedFilter.FilterContainerType = this.containerType
            droppedFilter.FilterLogicalOperator = this.AND
            droppedFilter.FilterIndex = event.currentIndex
            this.builderservice.addSelectedFilter(this.containerType, this.containerID, droppedFilter)
          }
        }
        // 2 & 3
        else {
          // Measure is not allowed in single row filter container.
          if (droppedItem.Type === OBJECT_TYPE.MEASURE && this.containerType === FILTER_CONTAINER_TYPE.SINGLEROWS)
            this.showToast(this.infoNotMeasure)
          // Dimension is not allowed in group row filter container.       
          else if (droppedItem.Type === OBJECT_TYPE.DIMENSION && this.containerType === FILTER_CONTAINER_TYPE.GROUPROWS)
            this.showToast(this.infoNotDimension)
          //Grouped row filter must also appear in result objects.
          else if (this.containerType === FILTER_CONTAINER_TYPE.GROUPROWS && this.builderservice.selectedObjects.value.filter(o => o.ID === droppedItem.ID).length === 0) {
            this.showToast(this.infoMustInclude)
            this.builderservice.addSelectedObject(droppedItem)
          }
          else {
            transferArrayItem(
              event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex,
            );
            var prevDOMID = event.previousContainer.id
            var prevContainerType = prevDOMID.substring(prevDOMID.indexOf('_') + 1, prevDOMID.lastIndexOf('_'))
            var prevContainerIndex = parseInt(prevDOMID.substring(prevDOMID.lastIndexOf('_') + 1, prevDOMID.length))
            var currDOMID = event.container.id
            var currContainerType = currDOMID.substring(currDOMID.indexOf('_') + 1, currDOMID.lastIndexOf('_'))
            var currContainerIndex = parseInt(currDOMID.substring(currDOMID.lastIndexOf('_') + 1, currDOMID.length))
            this.builderservice.reorderSlectedFilters(prevContainerType, prevContainerIndex, event.previousContainer.data)
            this.builderservice.reorderSlectedFilters(currContainerType, currContainerIndex, event.container.data)
          }
        }
      }
    }
    this.builderservice.setDragging(false, 'droppablefilter_' + this.containerType + '_' + this.containerID)
  }

  onMouseEntered(item: FILTER) {
    // reset
    if (this.prevItem)
      this.prevItem.isSubqueryMode = false

    this.prevItem = item
    if (this.isDragging) {
      this.builderservice.setMouseIn(true)
      item.isSubqueryMode = true
      this.subqueryParentFilter = item
      if (this.dragging) {
        window.clearTimeout(this.dragging);
        this.dragging = undefined;
      }
    }
    else {
      item.isSubqueryMode = false
      this.subqueryParentFilter = undefined
    }
  }

  onMouseExitted(item: FILTER) {
    //console.log('on mouse exitted')
    if (this.isDragging)
      this.timer(item)
  }

  private timer(item: FILTER) {
    if (this.dragging) {
      window.clearTimeout(this.dragging);
      this.dragging = undefined;
    }
    this.dragging = window.setTimeout(() => {
      this.builderservice.setMouseIn(false)
      this.subqueryParentFilter = undefined
      item.isSubqueryMode = false
    }, 800);
  }

  // when filter remove button is clicked
  public onRemove(item: FILTER) {
    this.builderservice.removeSelectedFilter(this.containerType, this.containerID, item)
    const index = this.items.findIndex(i => i.FilterID === item.FilterID);
    if (index !== -1) {
      this.items.splice(index, 1);
    }
    this.buildForm(item, ACTION.DELETE)
  }

  public showToast(item: NotificationComponent): void {
    item.show();
  }

  ngOnDestroy(): void {
    if (this.valueChangesSubscription)
      this.valueChangesSubscription.unsubscribe()

    if (this.subscription)
      this.subscription.unsubscribe()
  }

}
