import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CoreUIModule, TableHeaderColumnComponent } from '@epsilon/core-ui';
import { MetadataService } from '../../service/metadata.service';
import { METADATA_ACTION, METADATA_TYPE, TABLE } from '../../service/datamodel';
import { CommonModule } from '@angular/common';
import { OrderbyPipe } from '../../pipe/orderby.pipe';
import { TablesmodalComponent } from "../tablesmodal/tablesmodal.component";
import { DeletemodalComponent } from '../deletemodal/deletemodal.component';
import { Subscription } from 'rxjs';
import { ActionbarComponent } from '../actionbar/actionbar.component';
import { HighlighterPipe } from '../../pipe/highlighter.pipe';
import { DownloadService } from '../../service/download.service';
import { ErdmodalComponent } from "../erdmodal/erdmodal.component";

@Component({
  selector: 'app-tables',
  standalone: true,
  imports: [CoreUIModule, CommonModule, OrderbyPipe, TablesmodalComponent, DeletemodalComponent, ActionbarComponent, HighlighterPipe, ErdmodalComponent],
  templateUrl: './tables.component.html',
  styleUrl: './tables.component.scss'
})

export class TablesComponent implements OnInit, OnDestroy {
  public loadingTable: boolean;
  @ViewChild('tablesModal') tablessModal: TablesmodalComponent;
  @ViewChild('alertModal') alertModal: DeletemodalComponent;
  @ViewChild('erdModal') erdModal: ErdmodalComponent;
  public loadingItems: boolean = false;

  public defaultItems: TABLE[];
  private defaultItem: TABLE
  public chunkedItems: [TABLE[]];
  public aliased: string[]
  public derived: string[];
  public actived: string[];
  public customed: string[];
  private activeActived: string;
  private activeAliased: string;
  private activeDerived: string;
  private activeCustomed: string;
  private chunkSize = 20

  public defaultSortOption = 'Name';
  public defaultSortDirection = 'ASC';
  public scrollSortOption = '';
  public scrollSortDirection = '';
  private subscription: Subscription
  public UPDATE: METADATA_ACTION;
  public DELETE: METADATA_ACTION;
  public INSERT: METADATA_ACTION;
  public TABLE: METADATA_TYPE
  public searchedText: string

  constructor(private metadataservice: MetadataService, private downloadservice: DownloadService) {
    this.defaultItems = []
    this.UPDATE = METADATA_ACTION.UPDATE
    this.DELETE = METADATA_ACTION.DELETE
    this.INSERT = METADATA_ACTION.INSERT
    this.TABLE = METADATA_TYPE.TABLE
    this.aliased = ["All", "True", "False"]
    this.derived = ["All", "True", "False"]
    this.actived = ["All", "True", "False"]
    this.customed = ["All", "True", "False"]
    this.searchedText = ""
    this.activeAliased = "All"
    this.activeDerived = "All"
    this.activeActived = "All"
    this.activeCustomed = "All"
    this.defaultItem = {
      ID: 0,
      Name: "",
      Description: "",
      AliasedTable: "",
      IsDerived: 0,
      IsAliased: 0,
      Expression: ""
    }
  }

  public ngOnInit(): void {
    this.loadingTable = true;
    this.subscription = this.metadataservice.isMetaTablesLoading.subscribe(x => {
      this.chunkedItems = [[]]
      this.defaultItems = []
      if (!x && this.metadataservice.getTables().length > 0) {
        var items = this.metadataservice.getTables()
        this.chunkedItems.pop()

        for (let i = 0; i < items.length; i += this.chunkSize) {
          this.chunkedItems.push(items.slice(i, i + this.chunkSize))
        }
        this.onScrollLimitReached()
      }
      this.setFilteredItems()
      this.loadingTable = x;
    })
  }

  public scrollSort(
    sortComponent: TableHeaderColumnComponent,
    sortOption
  ): void {
    this.scrollSortOption = sortOption;
    this.scrollSortDirection = sortComponent.sortDirection;
  }


  public sortAscDesc(option: string | string[]): string | undefined {
    return this.defaultSortOption === option ||
      option.includes(this.defaultSortOption)
      ? this.defaultSortDirection === 'ASC'
        ? 'ascending'
        : 'descending'
      : undefined;
  }

  public onScrollLimitReached(): void {
    if (this.chunkedItems.length > 0) {
      this.loadingItems = true;
      setTimeout(() => {
        if (this.chunkedItems.length > 0) {
          this.defaultItems.push(...this.chunkedItems[0]);
          this.chunkedItems.shift()
        }
        this.loadingItems = false;
      }, 500);
    }

  }

  onFilter1(item: string) {
    this.activeAliased = item
    this.setFilteredItems()
  }

  onFilter2(item: string) {
    this.activeDerived = item
    this.setFilteredItems()
  }

  onFilter3(item: string) {
    this.activeCustomed = item
    this.setFilteredItems()
  }

  onFilter4(item: string) {
    this.activeActived = item
    this.setFilteredItems()
  }

  onSearch(item: string) {
    this.searchedText = item.toUpperCase()
    this.setFilteredItems()
  }

  onReset() {
    this.searchedText = ""
    this.activeAliased = "All"
    this.activeDerived = "All"
    this.activeActived = "All"
    this.activeCustomed = "All"
    this.setFilteredItems()
  }
  onDownload() {
    this.downloadservice.downloadMetadata(this.TABLE, this.metadataservice.getTables())
  }

  onRefresh() {
    this.metadataservice.querySpecificMetadata(this.TABLE)
  }

  private setFilteredItems() {
    this.defaultItems = []
    this.chunkedItems = [[]]
    this.chunkedItems.pop()

    var items = this.metadataservice.getTables().filter(t => (t.Name.toUpperCase().includes(this.searchedText) ||
      t.Description.toUpperCase().includes(this.searchedText) ||
      t.AliasedTable.toUpperCase().includes(this.searchedText) ||
      t.Expression.toUpperCase().includes(this.searchedText))
      && ((t.IsDerived === 1 && this.activeDerived === "True") || (t.IsDerived === 0 && this.activeDerived === "False") || this.activeDerived === "All")
      && ((t.IsAliased === 1 && this.activeAliased === "True") || (t.IsAliased === 0 && this.activeAliased === "False") || this.activeAliased === "All")
      && ((t.IsCustom === 1 && this.activeCustomed === "True") || (t.IsCustom === 0 && this.activeCustomed === "False") || this.activeCustomed === "All")
      && ((t.IsActive === 1 && this.activeActived === "True") || (t.IsActive === 0 && this.activeActived === "False") || this.activeActived === "All"))

    for (let i = 0; i < items.length; i += this.chunkSize) {
      this.chunkedItems.push(items.slice(i, i + this.chunkSize))
    }
    this.onScrollLimitReached()
  }

  public onEdit(item: TABLE) {
    this.tablessModal.launchBasicModal(item, this.UPDATE)
  }

  public onAdd() {
    this.tablessModal.launchBasicModal(JSON.parse(JSON.stringify(this.defaultItem)), this.INSERT)
  }

  public onDelete(item: TABLE) {
    this.alertModal.launchAlertModal(METADATA_TYPE.TABLE, item)
  }

  public onActivate(item: TABLE) {
    item.IsActive = 1
    this.metadataservice.setMeta(item, METADATA_TYPE.TABLE, this.UPDATE)
  }

  public onDeactivate(item: TABLE) {
    item.IsActive = 0
    this.metadataservice.setMeta(item, METADATA_TYPE.TABLE, this.UPDATE)
  }

  public onCustomize(item: TABLE) {
    item.IsCustom = 1
    this.metadataservice.setMeta(item, METADATA_TYPE.TABLE, this.UPDATE)
  }

  public onUncustomize(item: TABLE) {
    item.IsCustom = 0
    this.metadataservice.setMeta(item, METADATA_TYPE.TABLE, this.UPDATE)
  }

  public confirmDelete(item: TABLE) {
    this.metadataservice.deleteMeta(METADATA_TYPE.TABLE, item)
    this.defaultItems = this.defaultItems.filter(i => i.ID !== item.ID)
  }

  public onViewERD(item: TABLE) {
    this.erdModal.launchERDModal(item.Name)
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe()
  }

}
