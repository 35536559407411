import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SubjecthierarchyComponent } from "../../parts/subjecthierarchy/subjecthierarchy.component";
import { CoreUIModule, TabChangeEvent, TabGroupComponent } from '@epsilon/core-ui';
import { Subscription } from 'rxjs';
import { HistoryService } from '../../service/history.service';
import { ObjectsearchComponent } from "../../parts/objectsearch/objectsearch.component";
import { MetadataService } from '../../service/metadata.service';
import { UserService } from '../../service/user.service';
import { METADATA_TYPE } from '../../service/datamodel';
import { HistoryviewerComponent } from "../../parts/historyviewer/historyviewer.component";
import { HistoryfilterComponent } from "../../parts/historyfilter/historyfilter.component";

@Component({
  selector: 'app-objectselector',
  standalone: true,
  imports: [SubjecthierarchyComponent, CoreUIModule, ObjectsearchComponent, HistoryviewerComponent, HistoryfilterComponent],
  templateUrl: './objectselector.component.html',
  styleUrl: './objectselector.component.scss'
})
export class ObjectselectorComponent implements AfterViewInit, OnInit {
  @ViewChild(TabGroupComponent, { static: true })
  public tabGroup: TabGroupComponent;
  public leftNavDefaultCollapsed: boolean = false;
  private activeTab: string;

  private tabChangeSubscription: Subscription;

  constructor(private userservice: UserService, private historyservice: HistoryService, private metadataservice: MetadataService, private changeDetectorRef: ChangeDetectorRef) {
    this.activeTab = "Builder"
  }

  ngOnInit(): void {
    this.userservice.isTenantSelected.subscribe(x => {
      if (x) {
        if (this.activeTab === "Builder") {
          this.metadataservice.querySpecificMetadata(METADATA_TYPE.OBJ)
          this.metadataservice.querySpecificMetadata(METADATA_TYPE.HIERARCHY)
        }
        else if (this.activeTab === "History")
          this.historyservice.refreshHistories()
      }
    })
  }

  ngAfterViewInit() {
    this.tabChangeSubscription = this.tabGroup.tabChange.subscribe(
      (event: TabChangeEvent) => {
        //console.log(`Index: ${event.index}, Tab: ${event.tab.label}`);
        if (event.index === 0) {
          this.metadataservice.querySpecificMetadata(METADATA_TYPE.OBJ)
          this.metadataservice.querySpecificMetadata(METADATA_TYPE.HIERARCHY)
          this.activeTab = "Builder"
        }
        else if (event.index === 1) {
          this.historyservice.refreshHistories()
          this.activeTab = "History"
        }
      }
    );
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.tabChangeSubscription)
      this.tabChangeSubscription.unsubscribe();
  }

  // public get tooltipMessageDefault(): string {
  //   return this.leftNavDefaultCollapsed ? 'Expand' : 'Collapse';
  // }

  // public onToggleLeftNavDefaultClick(): void {
  //   this.leftNavDefaultCollapsed = !this.leftNavDefaultCollapsed;
  // }

}
