import { Pipe, PipeTransform } from '@angular/core';
import { DATA_TYPE, OBJECT_TYPE, OPERATOR, COMPARISON_OPERATOR, FILTER_METHOD } from '../service/datamodel';
import { filter } from 'd3';

@Pipe({
  name: 'operatorfilter',
  standalone: true
})
export class OperatorfilterPipe implements PipeTransform {


  transform(operators: OPERATOR[], objecttype: OBJECT_TYPE, datatype: DATA_TYPE, filtermethod: FILTER_METHOD): OPERATOR[] {

    var setMeasure = [
      { Name: COMPARISON_OPERATOR.EQUAL_TO },
      { Name: COMPARISON_OPERATOR.NOT_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.GREATER_THAN },
      { Name: COMPARISON_OPERATOR.LESS_THAN },
      { Name: COMPARISON_OPERATOR.GREATER_THAN_OR_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.LESS_THAN_OR_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.BETWEEN },
      { Name: COMPARISON_OPERATOR.NOT_BETWEEN }]

    var setDimNum = [
      { Name: COMPARISON_OPERATOR.EQUAL_TO },
      { Name: COMPARISON_OPERATOR.NOT_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.GREATER_THAN },
      { Name: COMPARISON_OPERATOR.LESS_THAN },
      { Name: COMPARISON_OPERATOR.GREATER_THAN_OR_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.LESS_THAN_OR_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.BETWEEN },
      { Name: COMPARISON_OPERATOR.NOT_BETWEEN },
      { Name: COMPARISON_OPERATOR.IS_NULL },
      { Name: COMPARISON_OPERATOR.IS_NOT_NULL }
    ]

    var setDimStr = [
      { Name: COMPARISON_OPERATOR.EQUAL_TO },
      { Name: COMPARISON_OPERATOR.NOT_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.IN },
      { Name: COMPARISON_OPERATOR.NOT_IN },
      { Name: COMPARISON_OPERATOR.GREATER_THAN },
      { Name: COMPARISON_OPERATOR.LESS_THAN },
      { Name: COMPARISON_OPERATOR.GREATER_THAN_OR_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.LESS_THAN_OR_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.BETWEEN },
      { Name: COMPARISON_OPERATOR.NOT_BETWEEN },
      { Name: COMPARISON_OPERATOR.LIKE },
      { Name: COMPARISON_OPERATOR.NOT_LIKE },
      { Name: COMPARISON_OPERATOR.IS_NULL },
      { Name: COMPARISON_OPERATOR.IS_NOT_NULL }
    ]

    var setDimDate = [
      { Name: COMPARISON_OPERATOR.EQUAL_TO },
      { Name: COMPARISON_OPERATOR.NOT_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.GREATER_THAN },
      { Name: COMPARISON_OPERATOR.LESS_THAN },
      { Name: COMPARISON_OPERATOR.GREATER_THAN_OR_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.LESS_THAN_OR_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.BETWEEN },
      { Name: COMPARISON_OPERATOR.NOT_BETWEEN },
      { Name: COMPARISON_OPERATOR.IS_NULL },
      { Name: COMPARISON_OPERATOR.IS_NOT_NULL }
    ]

    var setDimBool = [
      { Name: COMPARISON_OPERATOR.EQUAL_TO }
    ]

    var setDimFree = [
      { Name: COMPARISON_OPERATOR.LIKE },
      { Name: COMPARISON_OPERATOR.NOT_LIKE },
      { Name: COMPARISON_OPERATOR.IS_NULL },
      { Name: COMPARISON_OPERATOR.IS_NOT_NULL }
    ]

    var setSubquery = [
      { Name: COMPARISON_OPERATOR.EQUAL_TO },
      { Name: COMPARISON_OPERATOR.NOT_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.IN },
      { Name: COMPARISON_OPERATOR.NOT_IN },
      { Name: COMPARISON_OPERATOR.GREATER_THAN },
      { Name: COMPARISON_OPERATOR.LESS_THAN },
      { Name: COMPARISON_OPERATOR.GREATER_THAN_OR_EQUAL_TO },
      { Name: COMPARISON_OPERATOR.LESS_THAN_OR_EQUAL_TO }
    ]
    if (filtermethod === FILTER_METHOD.SUBQUERY)
      return setSubquery
    else if (objecttype === OBJECT_TYPE.MEASURE)
      return setMeasure
    else if (objecttype === OBJECT_TYPE.DIMENSION) {

      if (datatype === DATA_TYPE.STRING)
        return setDimStr
      else if (datatype === DATA_TYPE.NUMERIC)
        return setDimNum
      else if (datatype === DATA_TYPE.DATE || datatype === DATA_TYPE.DATETIME)
        return setDimDate
      else if (datatype === DATA_TYPE.LONGTEXT || datatype === DATA_TYPE.BLOB)
        return setDimFree
      else if (datatype === DATA_TYPE.BOOLEAN)
        return setDimBool
      else
        return []

    }
    return []

  }
}
