<coreui-scroll-indicator>
    <div class="Scrolling-InfiniteTarget" coreUIInfiniteScroll [scrollTarget]="scrollTarget" [triggerElementOffset]="50"
        (scrollLimitReached)="limitReached()" tabIndex=0 #target coreUIScrollIndicatorTarget>
        <ul coreUIUnorderedList>
            <ng-container *ngFor="let item of items$ | async" coreUIInfiniteScrollItem>
                <coreui-card class="Card-block " [header]="item.DatasourceName" [text]="cardTxt4"
                    [headerActions]="headerActions2" cardType="block">
                    <ng-template #headerActions2>
                        <coreui-menu icon="more" menuButtonStyle="link">
                            <coreui-menu-section>
                                <button type="button" coreUIMenuItem (click)="onLoad(item)">Load</button>
                                <button type="button" coreUIMenuItem (click)="onDelete(item)">Delete</button>
                            </coreui-menu-section>
                        </coreui-menu>
                    </ng-template>
                    <ng-template #cardTxt4>
                        <div class="inline-item">
                            <div class="Core-Card-iconContainer">
                                <i [coreUIIcon]="item.Action" [scale]="1.5" class="history-icon"></i>
                            </div>
                        </div>
                        <div class="inline-item2">
                            <span class="text-style-muted-3">
                                Date: {{item.UpdateTS}}<br />
                                By: {{item.UpdateBy}}
                            </span><br /><br />
                            <span class="text-style-5">
                                # of Objects: {{item.Objects.length}},
                                # of Filters: {{item.NumOfFilters}}
                            </span>
                        </div>
                    </ng-template>
                </coreui-card>
            </ng-container>
        </ul>
    </div>
</coreui-scroll-indicator>