import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { CoreUIModule } from '@epsilon/core-ui';
import { METADATA_TYPE } from '../../service/datamodel';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { MetadataService } from '../../service/metadata.service';

@Component({
  selector: 'app-actionbar',
  standalone: true,
  imports: [CoreUIModule, ReactiveFormsModule, CommonModule],
  templateUrl: './actionbar.component.html',
  styleUrl: './actionbar.component.scss'
})
export class ActionbarComponent implements OnInit {
  @Input() filterName1: string = undefined;
  @Input() filterOptions1: any[] = []
  @Input() filterName2: string = undefined
  @Input() filterOptions2: any[] = []
  @Input() filterName3: string = undefined
  @Input() filterOptions3: any[] = []
  @Input() filterName4: string = undefined
  @Input() filterOptions4: any[] = []
  @Input() metadataType: METADATA_TYPE = undefined
  @Output('filterValue1') filterValue1: EventEmitter<any> = new EventEmitter();
  @Output('filterValue2') filterValue2: EventEmitter<any> = new EventEmitter();
  @Output('filterValue3') filterValue3: EventEmitter<any> = new EventEmitter();
  @Output('filterValue4') filterValue4: EventEmitter<any> = new EventEmitter();
  @Output('searchedText') searchedText: EventEmitter<any> = new EventEmitter();
  @Output('add') add: EventEmitter<any> = new EventEmitter();
  @Output('reset') reset: EventEmitter<any> = new EventEmitter();
  @Output('download') download: EventEmitter<any> = new EventEmitter();
  @Output('refresh') refresh: EventEmitter<any> = new EventEmitter();


  public filterActive1: string;
  public filterActive2: string;
  public filterActive3: string;
  public filterActive4: string;

  public isLoading: WritableSignal<boolean> = signal(false);

  public formGroup: FormGroup = new FormGroup({
    filterControl1: new FormControl(''),
    filterControl2: new FormControl(''),
    filterControl3: new FormControl(''),
    filterControl4: new FormControl('')
  })

  public searchForm = new FormGroup({
    search: new FormControl()
  });

  constructor(private changeDetectorRef: ChangeDetectorRef, private metadataservice: MetadataService) {
    this.filterActive1 = 'All'
    this.filterActive2 = 'All'
    this.filterActive3 = 'All'
    this.filterActive4 = 'All'
  }

  private searching: number;
  private subscription: Subscription

  ngOnInit(): void {

    this.searchForm.valueChanges.subscribe(val => {
      this.onSearchChange(val.search)
    })

  }

  ngAfterViewInit(): void {
  }

  // displayWith tells the autocomplete how to convert an object value into
  // a string value for display; this may not be necessary if your values are
  // strings or numbers rather than objects.
  // public displayWith(item: OBJ): string {
  //   return item.Name;
  // }

  public onSearchChange(searchText: string): void {
    this.isLoading.set(true);
    if (this.searching) {
      window.clearTimeout(this.searching);
      this.searching = undefined;
    }
    this.searching = window.setTimeout(() => {
      this.searchedText.emit(searchText)
      this.isLoading.set(false);
      this.changeDetectorRef.detectChanges();
    }, 600);
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe()
  }

  onFilterControl1Click(item: any) {
    this.filterActive1 = item
    this.filterValue1.emit(item)
  }
  onFilterControl2Click(item: any) {
    this.filterActive2 = item
    this.filterValue2.emit(item)
  }
  onFilterControl3Click(item: any) {
    this.filterActive3 = item
    this.filterValue3.emit(item)
  }
  onFilterControl4Click(item: any) {
    this.filterActive4 = item
    this.filterValue4.emit(item)
  }

  onAdd() {
    this.add.emit()
  }

  onReset() {
    this.reset.emit()
    this.filterActive1 = 'All'
    this.filterActive2 = 'All'
    this.filterActive3 = 'All'
    this.filterActive4 = 'All'
    this.searchForm.controls.search.setValue("")
  }

  onDownload() {
    this.download.emit()
  }

  onRefresh() {
    this.refresh.emit()
  }

}
