import { Component } from '@angular/core';
import { DroppableobjectComponent } from "../../parts/droppableobject/droppableobject.component";

@Component({
  selector: 'app-resultcontainer',
  standalone: true,
  imports: [DroppableobjectComponent],
  templateUrl: './resultcontainer.component.html',
  styleUrl: './resultcontainer.component.scss'
})
export class ResultcontainerComponent {

}
