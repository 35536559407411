import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MetadataService } from '../../service/metadata.service';
import { CoreUIModule, ModalComponent, NotificationComponent } from '@epsilon/core-ui';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DATA_TYPE, JOIN, JOIN_TYPE, METADATA_ACTION, METADATA_TYPE, TABLE } from '../../service/datamodel';
import { Subscription } from 'rxjs';
import { OrderbyPipe } from "../../pipe/orderby.pipe";
import { ActiveonlyPipe } from '../../pipe/activeonly.pipe';
import { ExpressionbuilderComponent } from "../expressionbuilder/expressionbuilder.component";

@Component({
  selector: 'app-joinsmodal',
  standalone: true,
  imports: [CoreUIModule, ReactiveFormsModule, CommonModule, OrderbyPipe, ActiveonlyPipe, ExpressionbuilderComponent],
  templateUrl: './joinsmodal.component.html',
  styleUrl: './joinsmodal.component.scss'
})
export class JoinsmodalComponent implements OnInit {
  @ViewChild('basicModal', { static: true }) private basicModal: ModalComponent;
  @ViewChild('infoFilterError', { static: true }) public infoFilterError: NotificationComponent;
  @ViewChild('infoError', { static: true }) public infoError: NotificationComponent;
  @ViewChild('infoSuccess', { static: true }) public infoSuccess: NotificationComponent;
  public myFormGroup = new FormGroup({
    selectLeftTable: new FormControl("", [Validators.required]),
    selectRightTable: new FormControl("", [Validators.required]),
    textareaJoinExpression92: new FormControl("", [Validators.required]),
    selectJoinType: new FormControl("", [Validators.required]),
    selectTable92: new FormControl('', []),
    selectColumn92: new FormControl('', []),
  })
  private subscription: Subscription
  public isJoinValid: boolean;
  public isJoinValidating: boolean;
  public item: JOIN;
  public action: METADATA_ACTION
  public tables: TABLE[]
  public table_name: string;
  private column_name: string;
  private posCursor: number;
  public joinTypes: JOIN_TYPE[]
  public isFormError: boolean;
  public errMsgLeftTable: string
  public errMsgRightTable: string;
  public errMsgJoinExpression92: string;
  public errMsgJoinType: string
  private typing: number;
  private _filteredItems: TABLE[] = [];
  private _filteredItems2: TABLE[] = [];

  public set filteredItems(value: TABLE[]) {
    this._filteredItems = value;
  }
  public get filteredItems(): TABLE[] {
    return this._filteredItems;
  }
  public set filteredItems2(value: TABLE[]) {
    this._filteredItems2 = value;
  }
  public get filteredItems2(): TABLE[] {
    return this._filteredItems2;
  }
  public BLOB: DATA_TYPE;
  public BOOLEAN: DATA_TYPE;
  public DATE: DATA_TYPE;
  public DATETIME: DATA_TYPE;
  public LONGTEXT: DATA_TYPE;
  public NUMERIC: DATA_TYPE;
  public STRING: DATA_TYPE
  public msg: string;
  public isExpressionBuilder: boolean;
  public activeLeftTableName: string;
  public activeRightTableName: string;
  public JOIN: METADATA_TYPE

  constructor(private changeDetectorRef: ChangeDetectorRef, private metadataservice: MetadataService) {
    this.tables = []
    this.joinTypes = [JOIN_TYPE.LEFT_JOIN, JOIN_TYPE.RIGHT_JOIN, JOIN_TYPE.INNER_JOIN]
    this.isJoinValid = false
    this.isJoinValidating = false
    this.posCursor = 0
    this.BLOB = DATA_TYPE.BLOB;
    this.BOOLEAN = DATA_TYPE.BOOLEAN;
    this.DATE = DATA_TYPE.DATE;
    this.DATETIME = DATA_TYPE.DATETIME;
    this.LONGTEXT = DATA_TYPE.LONGTEXT;
    this.NUMERIC = DATA_TYPE.NUMERIC;
    this.STRING = DATA_TYPE.STRING;
    this.msg = ""
    this.JOIN = METADATA_TYPE.JOIN
  }

  ngOnInit(): void {
    this.subscription = this.metadataservice.isMetaTablesLoading.subscribe(x => {
      if (!x) {
        this.tables = this.metadataservice.getTables()
        this.isJoinValid = false
        this.subscription = this.metadataservice.isMetaJoinValidating.subscribe(x => {
          this.isJoinValidating = x
        })
      }
    })
    this.subscription = this.metadataservice.isMetaJoinValid.subscribe(x => {
      if (x) {
        this.isJoinValid = x.Result
        if (this.isJoinValid) {
          // this.myFormGroup.controls.textareaJoinExpression89.setErrors(null)
          this.myFormGroup.controls.textareaJoinExpression92.setErrors(null)
          this.myFormGroup.controls.selectLeftTable.setErrors(null)
          this.myFormGroup.controls.selectRightTable.setErrors(null)
          this.myFormGroup.controls.selectJoinType.setErrors(null)
        }
        else {
          if (x.Message === "Failed: This table combination already exists") {
            this.myFormGroup.controls.selectLeftTable.setErrors({ msg: "Invalid Table" })
            this.errMsgLeftTable = "This table combination already exists"
            this.myFormGroup.controls.selectRightTable.setErrors({ msg: "Invalid Table" })
            this.errMsgRightTable = "This table combination already exists"
          }
          else if (x.Message.indexOf("Failed: Invalid ANIS92 Expression") >= 0) {
            this.myFormGroup.controls.textareaJoinExpression92.setErrors({ msg: "Invalid Expression" })
            this.errMsgJoinExpression92 = x.Message.replace("Failed: ", "")
          }
        }
      }

    })

    this.subscription = this.metadataservice.isMetaTablesLoading.subscribe(x => {
      if (!x) {
        this.tables = this.metadataservice.getTables()
        this.filteredItems = this.tables
        this.filteredItems2 = this.tables

      }
    })

    this.subscription = this.metadataservice.msgMetaAction.subscribe(x => {
      if (x) {
        this.msg = x
        if (this.msg.toUpperCase().indexOf("SUCCESS") >= 0)
          this.showToast(this.infoSuccess)
        else
          this.showToast(this.infoError)
      }
    })
  }

  private formChanged(val: any) {
    this.isJoinValid = false
  }

  public async closeBasicModal(): Promise<void> {
    await this.basicModal.hide();
    //this.basicModalElementRef.nativeElement.focus();
  }

  public onBlur(event) {
    this.onValidate()
  }

  public onTextBlur(event: any) {
    this.getCaretPos(event)
  }

  // find cursor position and return it
  public getCaretPos(event: any): void {
    if (event) {
      const target = event.target as HTMLInputElement | HTMLTextAreaElement;
      this.posCursor = target.selectionStart;
    }
  }

  // show intellisense dropdowns on click
  public onExpression(event: string) {
    var currentText = this.myFormGroup.controls.textareaJoinExpression92.value
    let result = currentText.slice(0, this.posCursor) + (currentText.substring(this.posCursor, currentText.length) === ' ' ? '' : ' ') + event + ' ' + currentText.slice(this.posCursor);
    this.myFormGroup.controls.textareaJoinExpression92.setValue(result)
    this.onValidate()
  }

  public onSearchChange(searchText): void {
    this.filteredItems = searchText
      ? this.tables.filter(
        (item) =>
          item.Name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      : this.tables;
  }

  public onSearchChange2(searchText): void {
    this.filteredItems2 = searchText
      ? this.tables.filter(
        (item) =>
          item.Name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      : this.tables;
  }

  public onKeyPress(event: any) {
    this.getCaretPos(event)
    if (this.typing) {
      window.clearTimeout(this.typing);
      this.typing = undefined;
    }
    this.typing = window.setTimeout(() => {
      this.onValidate()
      this.changeDetectorRef.detectChanges();
    }, 600);
  }


  public onValidate() {
    var temp: JOIN = this.readControlsTemp()
    this.isFormError = false

    if (temp.LeftTable === "") {
      this.myFormGroup.controls.selectLeftTable.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgLeftTable = "Required"
    }
    if (temp.RightTable === "") {
      this.myFormGroup.controls.selectRightTable.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgRightTable = "Required"
    }
    if (temp.RightTable === temp.LeftTable && temp.LeftTable !== "") {
      this.myFormGroup.controls.selectLeftTable.setErrors({ msg: "Cannot be the same" })
      this.errMsgLeftTable = "Cannot be the same"
      this.myFormGroup.controls.selectRightTable.setErrors({ msg: "Cannot be the same" })
      this.isFormError = true
      this.errMsgRightTable = "Cannot be the same"
    }
    else if (temp.RightTable !== temp.LeftTable && temp.LeftTable !== "") {
      this.myFormGroup.controls.selectLeftTable.setErrors(undefined)
      this.errMsgLeftTable = ""
      this.myFormGroup.controls.selectRightTable.setErrors(undefined)
      this.errMsgRightTable = ""
    }
    if (temp.JoinExpression92.trim() === "") {
      this.myFormGroup.controls.textareaJoinExpression92.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgJoinExpression92 = "Required"
    }
    if (temp.JoinType === "") {
      this.myFormGroup.controls.selectJoinType.setErrors({ msg: "Required" })
      this.isFormError = true
      this.errMsgJoinType = "Required"
    }

    // If passes client-side validation, perform server-side validation
    if (!this.isFormError) {
      this.metadataservice.setMetadataValidation(temp, METADATA_TYPE.JOIN, this.action)
      this.myFormGroup.controls.selectLeftTable.setErrors(undefined)
      this.myFormGroup.controls.selectRightTable.setErrors(undefined)
      this.errMsgLeftTable = ""
      this.errMsgRightTable = ""
    }

  }

  public async launchBasicModal(item: JOIN, action: METADATA_ACTION): Promise<void> {
    this.action = action
    this.item = item
    if (this.metadataservice.getTables().length === 0)
      this.metadataservice.querySpecificMetadata(METADATA_TYPE.TABLE)
    this.isJoinValid = false
    this.loadControls(item)
    await this.basicModal.show();
  }

  public async saveBasicModal(): Promise<void> {
    this.readControlsPerm()
    this.metadataservice.setMeta(this.item, METADATA_TYPE.JOIN, this.action)
    this.isJoinValidating = false
    await this.basicModal.hide()
  }


  private loadControls(item: JOIN) {
    this.myFormGroup.controls.selectLeftTable.setValue(item.LeftTable)
    this.myFormGroup.controls.selectRightTable.setValue(item.RightTable)
    this.myFormGroup.controls.textareaJoinExpression92.setValue(item.JoinExpression92)
    this.myFormGroup.controls.selectJoinType.setValue(item.JoinType)
  }

  private readControlsTemp(): JOIN {
    var dummy = JSON.parse(JSON.stringify(this.item))
    dummy.LeftTable = this.myFormGroup.controls.selectLeftTable.value
    dummy.RightTable = this.myFormGroup.controls.selectRightTable.value
    dummy.JoinExpression92 = this.myFormGroup.controls.textareaJoinExpression92.value
    dummy.JoinType = this.myFormGroup.controls.selectJoinType.value
    this.activeLeftTableName = dummy.LeftTable
    this.activeRightTableName = dummy.RightTable
    return dummy
  }

  private readControlsPerm() {
    this.item.LeftTable = this.myFormGroup.controls.selectLeftTable.value
    this.item.RightTable = this.myFormGroup.controls.selectRightTable.value
    this.item.JoinExpression92 = this.myFormGroup.controls.textareaJoinExpression92.value
    this.item.JoinType = this.myFormGroup.controls.selectJoinType.value
  }


  public showToast(item: NotificationComponent): void {
    item.show();
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe()
  }


}
