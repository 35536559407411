import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CoreUIModule, TabChangeEvent, TabComponent, TabGroupComponent } from '@epsilon/core-ui';
import { TablesComponent } from '../tables/tables.component';
import { ObjectsComponent } from '../objects/objects.component';
import { JoinsComponent } from '../joins/joins.component';
import { MetadataService } from '../../service/metadata.service';
import { METADATA_TYPE, PARALLEL_HINT, PARALLEL_HINT_USAGE, USER_ROLE } from '../../service/datamodel';
import { Subscription } from 'rxjs';
import { EstimationsComponent } from "../estimations/estimations.component";
import { OptimizationsComponent } from '../optimizations/optimizations.component';
import { MultitenantComponent } from '../multitenant/multitenant.component';
import { AdminService } from '../../service/admin.service';
import { CommonModule } from '@angular/common';
import { UserService } from '../../service/user.service';
import { FolderComponent } from '../folder/folder.component';

@Component({
  selector: 'app-console',
  standalone: true,
  imports: [CoreUIModule, CommonModule, ObjectsComponent, JoinsComponent, TablesComponent, EstimationsComponent,
    OptimizationsComponent, MultitenantComponent, CommonModule, FolderComponent],
  templateUrl: './console.component.html',
  styleUrl: './console.component.scss'
})
export class ConsoleComponent implements OnInit, OnDestroy {
  @ViewChild(TabGroupComponent, { static: true }) public tabGroup: TabGroupComponent;
  public HIERARCHY: METADATA_TYPE
  public HIERARCHY_EDITOR: METADATA_TYPE
  public FOLDER: METADATA_TYPE
  public OBJECT: METADATA_TYPE
  public JOINS: METADATA_TYPE
  public ERD: METADATA_TYPE
  public TABLES: METADATA_TYPE
  public PARAMETERS: METADATA_TYPE
  public ESTIMATION: METADATA_TYPE
  public OPTIMIZATION: METADATA_TYPE
  public MULTITENANT: METADATA_TYPE
  public isSuperAdmin: boolean
  public isOptimization: boolean
  public parallelHint: PARALLEL_HINT
  public joinSyntax: string


  constructor(private metadataservice: MetadataService, private adminservice: AdminService, private userservice: UserService) {
    this.HIERARCHY = METADATA_TYPE.HIERARCHY
    this.HIERARCHY_EDITOR = METADATA_TYPE.HIERARCHY_EDITOR
    this.OBJECT = METADATA_TYPE.OBJECT
    this.JOINS = METADATA_TYPE.JOIN
    this.FOLDER = METADATA_TYPE.FOLDER
    this.ERD = METADATA_TYPE.ERD
    this.TABLES = METADATA_TYPE.TABLE
    this.ESTIMATION = METADATA_TYPE.ESTIMATION
    this.OPTIMIZATION = METADATA_TYPE.OPTIMIZATION
    this.MULTITENANT = METADATA_TYPE.MULTITENANT
    this.isSuperAdmin = false
    this.isOptimization = false
    this.joinSyntax = ""
  }

  private tabChangeSubscription: Subscription;

  ngOnInit() {
    //one-time
    this.tabChangeSubscription = this.tabGroup.tabChange.subscribe(
      (event: TabChangeEvent) => {
        // console.log(`Index: ${event.index}, Tab: ${event.tab.label}`);
        this.loadMetadata(event.tab.label as METADATA_TYPE)
      }
    )
    this.userservice.isTenantSelected.subscribe(x => {
      if (this.tabGroup.tabs) {
        this.tabGroup.tabs.forEach(t => {
          if (t.active) {
            this.loadMetadata(t.label as METADATA_TYPE)
          }
        })
      }
      if (this.userservice.getUserRole() === USER_ROLE.SUPERADMIN)
        this.isSuperAdmin = true
    })
    this.adminservice.isOptimizationLoading.subscribe(x => {
      if (!x && this.adminservice.getParallelHint() !== undefined) {
        this.parallelHint = this.adminservice.getParallelHint()
        this.joinSyntax = this.adminservice.getJoinSyntax()
        this.isOptimization = true
      }
    })
  }

  private loadMetadata(metadata_type: METADATA_TYPE) {

    this.metadataservice.querySpecificMetadata(metadata_type)

    if (metadata_type === METADATA_TYPE.OPTIMIZATION)
      this.adminservice.getOptimization()


  }

  ngOnDestroy() {
    if (this.tabChangeSubscription)
      this.tabChangeSubscription.unsubscribe();
  }

}
