import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { OktaService } from './okta.service';
import { AccessToken, IDToken } from '@okta/okta-auth-js';
import { ANALYZED_DATASET, ESTIMATE, USER_ROLE } from './datamodel';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class EstimateService {

  public estimatedCount: BehaviorSubject<string>;
  public estimatedProcessTime: BehaviorSubject<string>;
  public datasetStatus: BehaviorSubject<ANALYZED_DATASET>
  private oktaToken: AccessToken;
  private oktaToken2: IDToken;
  public numRowCount: BehaviorSubject<number>;
  public numRefreshSecs: BehaviorSubject<number>;
  public isEstimating: BehaviorSubject<boolean>;
  public errorMessage: BehaviorSubject<string>;
  private subscription: Subscription;
  private userRole: USER_ROLE;
  public isPublishShow: BehaviorSubject<boolean>

  constructor(private http: HttpClient, private oktaservice: OktaService, private userservice: UserService) {
    this.estimatedCount = new BehaviorSubject<string>('n/a')
    this.estimatedProcessTime = new BehaviorSubject<string>('n/a')
    this.datasetStatus = new BehaviorSubject<ANALYZED_DATASET>(ANALYZED_DATASET.NA)
    this.isEstimating = new BehaviorSubject<boolean>(false);
    this.numRowCount = new BehaviorSubject<number>(0);
    this.numRefreshSecs = new BehaviorSubject<number>(0);
    this.oktaservice.accessToken$.subscribe(x => { if (x) { this.oktaToken = x } })
    this.oktaservice.idToken$.subscribe(x => { if (x) { this.oktaToken2 = x } })
    this.errorMessage = new BehaviorSubject<string>('')
    this.userRole = this.userservice.getUserRole()
    this.isPublishShow = new BehaviorSubject<boolean>(false)
  }

  public resetEstimate() {
    this.estimatedCount.next('n/a')
    this.estimatedProcessTime.next('n/a')
    this.datasetStatus.next(ANALYZED_DATASET.NA)
    this.isEstimating.next(false);
    this.numRowCount.next(0);
    this.numRefreshSecs.next(0);
    this.errorMessage.next('')
  }

  public obtainEstimates(tenantCode: string, strObjects: string, singlerowsFilters: string, grouprowsFilters: string) {

    this.isEstimating.next(true);
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let body = new URLSearchParams();
    body.set('Objects', strObjects);
    body.set('SinglerowsFilters', singlerowsFilters);
    body.set('GrouprowsFilters', grouprowsFilters);
    body.set('TenantCode', tenantCode)
    console.log('calling estimate backend')
    this.subscription = this.http.post<ESTIMATE>(`${environment.REST_API_SERVER}/estimate`, body, options).subscribe(x => {
      this.numRowCount.next(x.Count)
      this.numRefreshSecs.next(x.Time)
      this.datasetStatus.next(x.Status)
      this.isEstimating.next(false)
      if (this.datasetStatus.value === ANALYZED_DATASET.ERROR) {
        this.errorMessage.next(this.interpretError(x.Error))
        this.isPublishShow.next(false)
      }
      else if (this.datasetStatus.value === ANALYZED_DATASET.TOO_LARGE) {
        this.isPublishShow.next(false)
      }
      else
        this.isPublishShow.next(true)
      this.subscription.unsubscribe()
    })
  }

  public setError(message: string) {
    this.errorMessage.next(this.interpretError(message))
    this.datasetStatus.next(ANALYZED_DATASET.ERROR)
    this.numRowCount.next(0)
    this.numRefreshSecs.next(0)
  }


  public interpretError(message: string): string {
    if (message) {
      if (this.userRole === USER_ROLE.POWERUSER) {
        // Dumb down error messages for power users
        if (message.indexOf("ORA-01427") > 0)
          return "Subquery returns multiple rows. Use 'in' or 'not in' operator"
        else if (message.indexOf("ORA-01858") >= 0)
          return "A non-numeric character was found where a numeric was expected"
        else if (message.indexOf('ORA-01722') >= 0)
          return "Inconsistent datatypes: Expected NUMBER got something else"
        else if (message.indexOf('ORA-00932') >= 0)
          return "Inconsistent datatypes: Expected NUMBER got DATE"
        else if (message.indexOf('ORA-00937') >= 0)
          return "Grouprow filter(s) must be present in the Result objects"
        else if (message.indexOf('ORA-00904') >= 0)
          return "Subject areas you have selected cannot be joined"
        else if (message.indexOf('ORA-01719') >= 0)
          return "OR or IN operator is not allowed in the outer joins"
        else if (message.toLowerCase().indexOf('cartesian join') >= 0)
          return "These subject areas cannot be combined. Please simplify your data model."
        else
          return "Detail errors are unavailable. Please contact your administrator"
      }
      // Admin users get detailed message
      return message.replace("Failed:", "")
    }
    else {
      return undefined
    }
  }

  public getDatatSetStatus(): ANALYZED_DATASET {
    return this.datasetStatus.value
  }
}
