import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AccessToken, IDToken } from '@okta/okta-auth-js';
import { OktaService } from './okta.service';
import { environment } from '../../environments/environment';
import { DATASOURCE, FILTER_CONTAINER, FILTER_CONTAINER_TYPE, HISTORY_ACTION, PROJECT } from './datamodel';
import { PreviewService } from './preview.service';
import { BuilderService } from './builder.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})

export class DatasourceService {

  public statusDatasourcePublishing: BehaviorSubject<string>;
  private oktaToken: AccessToken;
  private oktaToken2: IDToken;
  public newDatasourceURL: BehaviorSubject<string>;
  public selectedSinglerowsFilterContainers: FILTER_CONTAINER[];
  public selectedGrouprowsFilterContainers: FILTER_CONTAINER[];
  private selectedDatasourceName: BehaviorSubject<string>;
  private username: string;
  private userID: string;
  public datasources: BehaviorSubject<DATASOURCE[]>
  private subscription: Subscription
  public projects: BehaviorSubject<PROJECT[]>
  public selectedTargetProject: BehaviorSubject<PROJECT>
  public interprettedSinglerowFilters: BehaviorSubject<string>;
  public interprettedGrouprowFilters: BehaviorSubject<string>;
  public describedSiglerowFilters: BehaviorSubject<string>;
  public describedGrouprowFilters: BehaviorSubject<string>;
  public isDatasourcesQuerying: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private oktaservice: OktaService, private previewservice: PreviewService, private builderservice: BuilderService, private userservice: UserService) {
    this.statusDatasourcePublishing = new BehaviorSubject<string>('');
    this.oktaservice.accessToken$.subscribe(x => { if (x) { this.oktaToken = x } })
    this.oktaservice.idToken$.subscribe(x => { if (x) { this.oktaToken2 = x } })
    this.oktaservice.preferredUsername$.subscribe(x => {
      this.username = x;
    })
    this.newDatasourceURL = new BehaviorSubject<string>(undefined);
    this.selectedDatasourceName = new BehaviorSubject<string>('')
    this.projects = new BehaviorSubject<PROJECT[]>([])
    this.selectedTargetProject = new BehaviorSubject<PROJECT>({ ProjectID: '', ProjectName: '', BreadCrumb: '' })
    this.interprettedSinglerowFilters = new BehaviorSubject<string>('')
    this.interprettedGrouprowFilters = new BehaviorSubject<string>('')
    this.describedSiglerowFilters = new BehaviorSubject<string>('')
    this.describedGrouprowFilters = new BehaviorSubject<string>('')
    this.datasources = new BehaviorSubject<any[]>([])
    this.isDatasourcesQuerying = new BehaviorSubject<boolean>(false)
  }

  public postDatasource() {

    this.statusDatasourcePublishing.next('Publishing');
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let body = new URLSearchParams();
    body.set('Columns', JSON.stringify(this.previewservice.getResultColumns()));
    body.set('Objects', this.builderservice.exportifyObjects(this.builderservice.getSelectedObjects()));
    body.set('SinglerowsFilters', this.builderservice.exportifyFilterContainers(this.builderservice.getSelectedFilterContrainers(FILTER_CONTAINER_TYPE.SINGLEROWS)));
    body.set('GrouprowsFilters', this.builderservice.exportifyFilterContainers(this.builderservice.getSelectedFilterContrainers(FILTER_CONTAINER_TYPE.GROUPROWS)));
    body.set('DatasoureName', this.selectedDatasourceName.value)
    body.set('TenantCode', this.userservice.getSelectedSite().SiteCode)
    body.set('UserName', this.username)
    body.set('Action', HISTORY_ACTION.PUBLISH)
    body.set('UserID', this.userservice.getUserID())
    body.set('TargetProjectID', this.projects.value.filter(p => p.IsActive === 1)[0].ProjectID)

    this.subscription = this.http.post<any>(`${environment.REST_API_SERVER}/datasource`, body, options).subscribe(x => {
      this.newDatasourceURL.next(x.url);
      this.statusDatasourcePublishing.next('Success')
      // this.subscription.unsubscribe()
    })
  }

  public queryDatasources() {

    this.isDatasourcesQuerying.next(true)

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let tenantCode = this.userservice.getSelectedSite().SiteCode

    this.subscription = this.http.get<any>(`${environment.REST_API_SERVER}/datasources?TenantCode=${tenantCode}`, options).subscribe(x => {
      this.datasources.next(x)
      // this.subscription.unsubscribe()
      this.isDatasourcesQuerying.next(false)

    })

  }

  public setSelectedDatasourceName(name: string) {
    this.selectedDatasourceName.next(name)
  }

  public getSelectedDatasourceName(): string {
    return this.selectedDatasourceName.value
  }

  public getDatasources(): DATASOURCE[] {
    return this.datasources.value
  }

  public getTargetProject(): PROJECT {
    return this.projects.value.filter(f => f.IsActive)[0]
  }

  public setTargetProject(item: PROJECT) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let tenantcode = this.userservice.getSelectedSite().SiteCode
    let body = new URLSearchParams();
    body.set('TenantCode', tenantcode)
    body.set('TargetProjectID', item.ProjectID)

    this.subscription = this.http.post<PROJECT[]>(`${environment.REST_API_SERVER}/target-project`, body, options).subscribe(x => {
    })
  }

  public queryProjects() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let tenantcode = this.userservice.getSelectedSite().SiteCode

    this.subscription = this.http.get<PROJECT[]>(`${environment.REST_API_SERVER}/target-project?tenantcode=${tenantcode}`, options).subscribe(x => {
      this.reflectLocalSettings(x)
    })
  }

  private reflectLocalSettings(fols: PROJECT[]) {

    var tenantcode = this.userservice.getSelectedSite().SiteCode
    var localProjectID = localStorage.getItem(tenantcode + '_destinationfolder_projectid')
    if (localProjectID)
      // find the folder that matches the local settings and change the active flag
      fols.forEach(f => {
        if (f.ProjectID === localProjectID)
          f.IsActive = 1
        else
          f.IsActive = 0
      })
    //else - do nothing
    this.projects.next(fols)
  }

  public overwriteLocalSettings(item: PROJECT) {
    localStorage.setItem(this.userservice.getSelectedSite().SiteCode + '_destinationfolder_projectid', item.ProjectID);
    this.reflectLocalSettings(this.projects.value)
  }

  public interpretFilters(complexity: string) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let tenantcode = this.userservice.getSelectedSite().SiteCode
    let body = new URLSearchParams();
    body.set('TenantCode', tenantcode)
    body.set('SinglerowsFilters', this.builderservice.getJsonifiedSelectedFilterContainers(FILTER_CONTAINER_TYPE.SINGLEROWS))
    body.set('GrouprowsFilters', this.builderservice.getJsonifiedSelectedFilterContainers(FILTER_CONTAINER_TYPE.GROUPROWS))
    body.set('IsSimple', complexity === "simple" ? "1" : "0")

    this.subscription = this.http.post<string[]>(`${environment.REST_API_SERVER}/filters`, body, options).subscribe(x => {
      this.interprettedSinglerowFilters.next(x[0])
      this.interprettedGrouprowFilters.next(x[1])
    })

  }

}
