<coreui-modal modalWidth="40" #alertModal>
    <ng-template #titleTemplate>
        <div class="titleTemplate">
            <span coreUIIcon="warning" class="Modal-titleIcon"></span>Confirm your action
        </div>
    </ng-template>
    <ng-template #titleCloseButtonTemplate>
        <button type="button" aria-label="Close" (click)="closeAlertModal(false)" coreUIButton="icon-large"><i
                coreUIIcon="close"></i></button>
    </ng-template>
    <ng-template #contentTemplate>
        <span>Are you sure you want to delete this item?</span>
    </ng-template>
    <ng-template #buttonsTemplate>
        <button type="button" coreUIButton="primary" (click)="closeAlertModal(true)">Delete</button>
        <button type="button" coreUIButton="secondary" (click)="closeAlertModal(false)"
            coreUITrapFocusInitial>Cancel</button>
    </ng-template>
</coreui-modal>