import { Injectable } from '@angular/core';
import { OktaService } from './okta.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccessToken, IDToken } from '@okta/okta-auth-js';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TABLEAU_SITE, TABLEAU_SITE_ROLE, USER_ROLE } from './datamodel';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private name: string = "";
  private preferredUserName = ""
  private email: string = "";
  private oktaToken: AccessToken;
  private oktaToken2: IDToken;
  private subscription: Subscription
  public usersites: BehaviorSubject<TABLEAU_SITE[]>
  private selectedSite: TABLEAU_SITE
  public isTenantSelected: BehaviorSubject<boolean>;

  constructor(private oktaservice: OktaService, private http: HttpClient) {
    this.usersites = new BehaviorSubject<TABLEAU_SITE[]>([])
    this.isTenantSelected = new BehaviorSubject<boolean>(false)
    this.oktaservice.email$.subscribe(x => {
      this.email = x
      if (this.name !== "" && this.preferredUserName !== "" && this.oktaToken !== undefined && this.oktaToken2 !== undefined)
        this.getUserInfo()
    })
    this.oktaservice.name$.subscribe(x => {
      this.name = x
      if (this.email !== "" && this.preferredUserName !== "" && this.oktaToken !== undefined && this.oktaToken2 !== undefined)
        this.getUserInfo()
    })
    this.oktaservice.preferredUsername$.subscribe(x => {
      this.preferredUserName = x
      if (this.email !== "" && this.name !== "" && this.oktaToken !== undefined && this.oktaToken2 !== undefined)
        this.getUserInfo()
    })
    this.oktaservice.accessToken$.subscribe(x => {
      this.oktaToken = x
      if (this.email !== "" && this.name !== "" && this.preferredUserName !== "" && this.oktaToken2 !== undefined)
        this.getUserInfo()
    })
    this.oktaservice.idToken$.subscribe(x => {
      this.oktaToken2 = x
      if (this.email !== "" && this.name !== "" && this.preferredUserName !== "" && this.oktaToken !== undefined)
        this.getUserInfo()
    })

  }

  public getUserInfo() {

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };

    this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/userinfo?username=${this.preferredUserName}&emailaddress=${this.email}`, options).subscribe((x) => {

      this.usersites.next(x)
      // this.subscription.unsubscribe()
      // this.setSelectedSite({ SiteCode: 'U1LYDV', SiteName: '...', SiteID: '' })

    });
  }

  public getUserRole(): USER_ROLE {

    if (this.selectedSite) {
      var userSite = this.usersites.value.filter(s => s.SiteCode === this.selectedSite.SiteCode)[0]

      // return USER_ROLE.POWERUSER

      if (userSite.AdminLevel === 10)
        return USER_ROLE.SUPERADMIN
      else if (userSite.RoleName === TABLEAU_SITE_ROLE.SITE_ADMINISTRATOR_EXPLORER ||
        userSite.RoleName === TABLEAU_SITE_ROLE.SITE_ADMINISTRATOR_CREATOR)
        return USER_ROLE.ADMIN
      else if (userSite.RoleName === TABLEAU_SITE_ROLE.CREATTOR && (this.preferredUserName.includes('@publicisgroup.net') || this.preferredUserName.includes('@lgn.prod.global')))
        return USER_ROLE.ADMIN
      else
        return USER_ROLE.POWERUSER
    }
    else
      return USER_ROLE.VIEWER
  }

  public getMaxUserRole(): USER_ROLE {

    var maxuserrole: USER_ROLE;

    for (const site of this.usersites.value) {
      if (site.AdminLevel === 10)
        maxuserrole = USER_ROLE.SUPERADMIN
      else if (maxuserrole !== USER_ROLE.SUPERADMIN && (site.RoleName === TABLEAU_SITE_ROLE.SITE_ADMINISTRATOR_EXPLORER ||
        site.RoleName === TABLEAU_SITE_ROLE.SITE_ADMINISTRATOR_CREATOR))
        maxuserrole = USER_ROLE.ADMIN
      else if (!(maxuserrole === USER_ROLE.SUPERADMIN || maxuserrole === USER_ROLE.ADMIN)
        && (site.RoleName === TABLEAU_SITE_ROLE.CREATTOR || site.RoleName === TABLEAU_SITE_ROLE.EXPLORER || site.RoleName === TABLEAU_SITE_ROLE.EXPLORER_PUBLISH))
        maxuserrole = USER_ROLE.POWERUSER
      else
        maxuserrole = USER_ROLE.VIEWER
    }
    return maxuserrole
  }

  public getUserID(): string {
    return this.usersites.value.filter(u => u.SiteCode === this.selectedSite.SiteCode)[0].UserID
  }

  public getUserName(): string {
    return this.preferredUserName
  }

  public setSelectedSite(site: TABLEAU_SITE) {
    this.selectedSite = site
    this.isTenantSelected.next(true)

  }

  public getSelectedSite() {
    return this.selectedSite
  }


}
