import { ApplicationConfig, provideZoneChangeDetection, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';

const oktaConfig = new OktaAuth({
  issuer: environment.OKTA_ISSUER,
  clientId: environment.OKTA_CLIENT_ID,
  redirectUri: environment.OKTA_REDIRECT_URI
});

export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes),
  importProvidersFrom(
    OktaAuthModule.forRoot({ oktaAuth: oktaConfig })),
  provideAnimations(),
  provideHttpClient(),

  ]
};
