<form class="Core-Form" [formGroup]="singeSliderForm">
    <section class="component-wrapper">
        <h3>Data Set Thresholds</h3><br />
        <h5>Maximum Rows</h5><br />
        <coreui-form-field label="Est. Row Count">
            <div class="slider">
                <coreui-slider formControlName="sliderEstRowCount"
                    (valueChange)="onSliderChange($event)"></coreui-slider>
                <span>{{defaultSliderValue}}</span>
            </div>
        </coreui-form-field>
        <h5>Maximum Duration</h5><br />
        <coreui-form-field label="Est. Refresh Time (m)">
            <div class="slider">
                <coreui-slider formControlName="sliderEstRefreshTime"
                    (valueChange)="onSliderChange($event)"></coreui-slider>
                <span>{{defaultSliderValue}}</span>
            </div>
        </coreui-form-field>
    </section>
    <section class="component-wrapper">
        <h3>Full Table Scan</h3><br />
        <h5>Time Complexity: k*O(1) where k is a coefficient</h5><br />
        <coreui-form-field label="Coefficient (k)">
            <div class="slider">
                <coreui-slider formControlName="sliderCoefficient1"
                    (valueChange)="onSliderChange($event)"></coreui-slider>
                <span>{{defaultSliderValue}}</span>
            </div>
            <button type="button" coreUIButton withIcon="left"> <i coreUIIcon="serviceTask"></i>Calibrate</button>
        </coreui-form-field>
    </section>
    <section class="component-wrapper">
        <h3>Hash Join</h3><br />
        <h5>Time Complexity: k*O(n+m) where k is a coefficient, n and m are the number rows.</h5><br />
        <coreui-form-field label="Coefficient (k)">
            <div class="slider">
                <coreui-slider formControlName="sliderCoefficient2"
                    (valueChange)="onSliderChange($event)"></coreui-slider>
                <span>{{defaultSliderValue}}</span>
            </div>
            <button type="button" coreUIButton withIcon="left"> <i coreUIIcon="serviceTask"></i>Calibrate</button>
        </coreui-form-field>
    </section>
    <section class="component-wrapper">
        <h3>Index Scan</h3><br />
        <h5>Time Complexity: kO(nlogn) where k is a coefficient, n is the number rows.</h5><br />
        <coreui-form-field label="Coefficient (k)">
            <div class="slider">
                <coreui-slider formControlName="sliderCoefficient3"
                    (valueChange)="onSliderChange($event)"></coreui-slider>
                <span>{{defaultSliderValue}}</span>
            </div>
            <button type="button" coreUIButton withIcon="left"> <i coreUIIcon="serviceTask"></i>Calibrate</button>
        </coreui-form-field>
    </section>
</form>