<coreui-tab-group tabStyle="primary">
    <!-- Note this uses one time string initialization  -->
    <coreui-tab [label]="OBJECT" [active]="true">
        <div class="area"><app-objects></app-objects></div>
    </coreui-tab>
    <coreui-tab [label]="TABLES" [disabled]="false">
        <div class="area"><app-tables></app-tables></div>
    </coreui-tab>
    <coreui-tab [label]="JOINS" [disabled]="false">
        <div class="area"><app-joins></app-joins></div>
    </coreui-tab>
    <coreui-tab [label]="FOLDER" [active]="false" [disabled]="!isSuperAdmin">
        <div class="area"><app-folder></app-folder></div>
    </coreui-tab>
    <coreui-tab [label]="OPTIMIZATION">
        <div class="area"><app-optimizations *ngIf="isOptimization" [parallelHint]="parallelHint"
                [joinSyntax]="joinSyntax"></app-optimizations></div>
    </coreui-tab>
    <coreui-tab [label]="ESTIMATION" [disabled]="true">
        <div class="area"><app-estimations></app-estimations></div>
    </coreui-tab>
    <coreui-tab [label]="MULTITENANT" [disabled]="!isSuperAdmin">
        <div class="area"><app-multitenant></app-multitenant></div>
    </coreui-tab>
</coreui-tab-group>