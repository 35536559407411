import { Injectable } from '@angular/core';
import { FILTER_CONTAINER, FILTER_CONTAINER_EXPORT, FILTER_CONTAINER_TYPE, HISTORY_ACTION, OBJ, OBJ_EXPORT, PUBLISH_HISTORY, PUBLISH_HISTORY_IMPORT } from './datamodel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BuilderService } from './builder.service';
import { environment } from '../../environments/environment';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { AccessToken, IDToken } from '@okta/okta-auth-js';
import { OktaService } from './okta.service';
import { DatasourceService } from './datasource.service';
import { UserService } from './user.service';
import { MetadataService } from './metadata.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  private histories: BehaviorSubject<PUBLISH_HISTORY[]>;
  private filteredHistories: PUBLISH_HISTORY[]
  private oktaToken: AccessToken;
  private oktaToken2: IDToken;
  private username: string;
  private uniqueUserNames: string[]
  private subscription: Subscription
  private objs: OBJ[]
  public isHistoryLoading: BehaviorSubject<boolean>
  public isHistoryQuerying: BehaviorSubject<boolean>
  public isHistoryFiltering: BehaviorSubject<boolean>
  private activeUser: string
  private activeDates: string

  constructor(private userservice: UserService, private builderservice: BuilderService, private http: HttpClient, private oktaservice: OktaService, private datasourceservice: DatasourceService, private metadataservice: MetadataService) {
    this.histories = new BehaviorSubject<PUBLISH_HISTORY[]>([])
    this.filteredHistories = []
    this.oktaservice.accessToken$.subscribe(x => { if (x) { this.oktaToken = x } })
    this.oktaservice.idToken$.subscribe(x => { if (x) { this.oktaToken2 = x } })
    this.oktaservice.preferredUsername$.subscribe(x => {
      this.username = x;
    })
    this.isHistoryLoading = new BehaviorSubject<boolean>(false)
    this.isHistoryQuerying = new BehaviorSubject<boolean>(false)
    this.isHistoryFiltering = new BehaviorSubject<boolean>(false)
    this.metadataservice.isMetaObjsLoading.subscribe(x => {
      if (!x)
        this.objs = this.metadataservice.getObjs()
    })
    this.activeDates = 'All'
    this.activeUser = 'All'
    this.uniqueUserNames = ['All']
  }

  public refreshHistories() {
    this.queryHistories()
  }

  // load selected history back in to Result Object, and Filter Containers.
  public loadHistory(history: PUBLISH_HISTORY) {
    this.isHistoryLoading.next(true)
    this.builderservice.setSelectedObjects(history.Objects)
    this.builderservice.setSelectedFilterContainers(FILTER_CONTAINER_TYPE.SINGLEROWS, JSON.parse(JSON.stringify(history.SinglerowFilterContainers)))
    this.builderservice.setSelectedFilterContainers(FILTER_CONTAINER_TYPE.GROUPROWS, JSON.parse(JSON.stringify(history.GrouprowFilterContainers)))
    this.datasourceservice.setSelectedDatasourceName(history.DatasourceName)
    this.isHistoryLoading.next(false)
  }

  //////////////////////// HTTP ///////////////////////

  private queryHistories() {


    this.isHistoryQuerying.next(true)
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let tenantcode = this.userservice.getSelectedSite().SiteCode

    this.subscription = this.http.get<PUBLISH_HISTORY_IMPORT[]>(`${environment.REST_API_SERVER}/history?tenantcode=${tenantcode}`, options).subscribe(x => {
      var hists: PUBLISH_HISTORY[] = []

      x.forEach(h => {
        //deep copy to convert PUBLISH_HISTORY_IMPORT to PUBLISH _HISTORY
        var hist: PUBLISH_HISTORY = JSON.parse(JSON.stringify(h))
        hist.Objects = this.deexportifyObjects(hist.Objects)
        hist.SinglerowFilterContainers = this.deexportifyFilterContainers(hist.SinglerowFilterContainers)
        hist.GrouprowFilterContainers = this.deexportifyFilterContainers(hist.GrouprowFilterContainers)
        this.countFilters(hist)
        hists.push(hist)
      })
      this.histories.next(hists)
      this.isHistoryFiltering.next(true)
      this.handleFilters()
      this.isHistoryFiltering.next(false)
      this.updateUniqueUserNames()
      this.isHistoryQuerying.next(false)
      // this.subscription.unsubscribe()
    })

  }

  public saveHistory() {

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let body = new URLSearchParams();
    // body.set('Columns', JSON.stringify(this.previewservice.resultedColumns.value));
    body.set('Objects', this.builderservice.exportifyObjects(this.builderservice.getSelectedObjects()));
    body.set('SinglerowsFilters', this.builderservice.getJsonifiedSelectedFilterContainers(FILTER_CONTAINER_TYPE.SINGLEROWS));
    body.set('GrouprowsFilters', this.builderservice.getJsonifiedSelectedFilterContainers(FILTER_CONTAINER_TYPE.GROUPROWS));
    body.set('DatasoureName', this.datasourceservice.getSelectedDatasourceName())
    body.set('TenantCode', this.userservice.getSelectedSite().SiteCode)
    body.set('UserName', this.username)
    body.set('UserID', this.userservice.getUserID())
    body.set('Action', HISTORY_ACTION.SAVE)

    this.subscription = this.http.post<PUBLISH_HISTORY[]>(`${environment.REST_API_SERVER}/history`, body, options).subscribe(x => {
      // this.subscription.unsubscribe()

    })

  }

  public deleteHistory(item: PUBLISH_HISTORY) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let tenantcode = this.userservice.getSelectedSite().SiteCode

    this.subscription = this.http.delete<PUBLISH_HISTORY[]>(`${environment.REST_API_SERVER}/history?tenantcode=${tenantcode}&historyID=${item.ID}`, options).subscribe(x => {
      // this.subscription.unsubscribe()
    })

  }

  public getHistories(): PUBLISH_HISTORY[] {
    return this.histories.value
  }

  public getFilteredHistories(): PUBLISH_HISTORY[] {
    return this.filteredHistories
  }

  public getUniqueUserNames() {
    return this.uniqueUserNames
  }

  private updateUniqueUserNames() {
    this.uniqueUserNames = ['All']

    // build a list of users for the filter
    if (this.histories.value.length > 0) {
      this.histories.value.map(h => {
        //construct a list of unique users
        if (!this.uniqueUserNames.includes(h.UpdateBy))
          this.uniqueUserNames.push(h.UpdateBy)
      })
    }
  }

  public setFilterDates(val: string) {
    this.isHistoryFiltering.next(true)
    this.activeDates = val
    this.handleFilters()
    this.isHistoryFiltering.next(false)
  }
  public setFilterUser(val: string) {
    this.isHistoryFiltering.next(true)
    this.activeUser = val
    this.handleFilters()
    this.isHistoryFiltering.next(false)
  }

  private handleFilters() {
    var filterDate = this.translateDate(this.activeDates)
    this.filteredHistories = this.histories.value.filter(h => (h.UpdateBy === this.activeUser || this.activeUser === 'All') &&
      (new Date(h.UpdateTS) > new Date(filterDate) || this.activeDates === 'All'))

  }


  //////////////////////// Helper for HTTP ////////////////////////


  private deexportifyFilterContainers(filtercontainerexports: FILTER_CONTAINER_EXPORT[]): FILTER_CONTAINER[] {
    //convert FILTER_CONTAINER_EXPORT[] to FILTER_CONTAINER[]
    var filtercontainers: FILTER_CONTAINER[] = JSON.parse(JSON.parse(JSON.stringify(filtercontainerexports)))
    var fc: FILTER_CONTAINER = undefined
    filtercontainers.forEach(fc => {
      fc.Filters.forEach(f => {
        var obj = this.objs.filter(o => o.ID === f.ID)[0]
        var subobj = this.objs.filter(o => o.ID === f.SubqueryObjID)[0]
        if (obj) {
          f.ID = obj.ID
          f.Type = obj.Type;
          f.DataType = obj.DataType
          f.Name = obj.Name
          f.SubqueryObjID = f.SubqueryObjID
          f.SubqueryObj = subobj
        }
      })
    })
    return filtercontainers
  }

  public deexportifyObjects(eo: OBJ_EXPORT[]): OBJ[] {
    var listObjects: OBJ[] = []
    for (const obj of JSON.parse(JSON.parse(JSON.stringify(eo)))) {
      for (const mo of this.objs) {
        if (mo.ID === obj.ID)
          listObjects.push(mo)
      }
    }
    return listObjects
  }


  private countFilters(hist: PUBLISH_HISTORY) {
    var num = 0

    //tally the number of filters
    hist.GrouprowFilterContainers.map(gfc => {
      num += gfc.Filters.length
    })
    hist.SinglerowFilterContainers.map(sfc =>
      num += sfc.Filters.length
    )
    hist.NumOfFilters = num

  }

  private translateDate(str): Date {

    var diff: number = 0;
    var today: Date = new Date()
    if (str === 'All')
      diff = 10000
    else if (str === 'Today')
      diff = 0
    else if (str === 'Yesterday')
      diff = 1
    else if (str === '3 Days Ago')
      diff = 3
    else if (str === '10 Days Ago')
      diff = 10

    var xDaysAgo: Date = new Date()
    xDaysAgo.setDate(today.getDate() - diff)
    xDaysAgo.setHours(0)
    xDaysAgo.setMinutes(0)
    xDaysAgo.setSeconds(0)

    return xDaysAgo
  }


}
