<!--------------- modal --------------->
<app-savemodal #basicModal2></app-savemodal>
<app-sqlmodal #basicModal3></app-sqlmodal>
<!-- <form [formGroup]="radioFormGroup"> -->
<coreui-action-bar>
    <!----- Left Side ----->
    <ng-template #leftTpl>
        <div class="inline_spacing">
            <div coreUIButtonGroup>
                <button type="button" coreUIButton="icon-large" (click)="launchBasicModal2()"
                    coreUITooltip="Save your progress" #launchBasicModalButton withBorder><i
                        coreUIIcon="save"></i></button>
                <button type="button" coreUIButton="icon-large" (click)="onReset()" coreUITooltip="Reset the containers"
                    withBorder><i coreUIIcon="revert"></i></button>
            </div>
        </div>
    </ng-template>
    <!----- Center Side (Estimation) ----->
    <ng-template #centerTpl>
        <div class="product-totals">
            <!----- Info Tooltip ----->
            <i coreUIIcon="information" [coreUITooltip]="tooptip1" class="info" scale="1.5"></i>
            <ng-template #tooptip1>
                Data set should be small enough to refresh in a reasonable amount of time. <br />
                Simplify your data set or add filter(s) to reduce the size of your data set.
            </ng-template>
            <span>Data Set Status</span>
            <div class="badge-width">
                <div *ngIf="!isEstimating">
                    <coreui-badge color="Slate" [border]="true" *ngIf="datasetStatus === NA">n/a</coreui-badge>
                    <coreui-badge color="Lime" [border]="true" *ngIf="datasetStatus === GOOD">Good</coreui-badge>
                    <coreui-badge color="Pineapple" [border]="true" *ngIf="datasetStatus === LARGE">Large</coreui-badge>
                    <coreui-badge color="Apple" [border]="true" *ngIf="datasetStatus === TOO_LARGE">Too
                        Large</coreui-badge>
                    <coreui-badge color="Apple" [hasIcon]="true" [subMessage]="progressTpl"
                        *ngIf="datasetStatus === ERROR"><i coreUIIcon="errorShield"></i>Error</coreui-badge>
                    <ng-template #progressTpl><a href="javascript:void(0);" style="text-decoration: underline;"
                            (click)="showToast(infoError)">View
                            details</a></ng-template>
                </div>
                <coreui-load-mask [active]="true" *ngIf="isEstimating" class="load-mask">
                </coreui-load-mask>
            </div>
        </div>
        <!----- Accordion (Details)----->
        <coreui-standalone-accordion label="Details" (activeChange)="onActiveChange($event)">
            <div class="single" *ngIf="isAccordionExpanded">
                <div class="single__content">
                    <div class="product-totals">
                        <span>Measures</span>
                        <coreui-badge [textSimple]="true" color="Slate">
                            {{ numMeasures}}
                        </coreui-badge>
                    </div>
                </div>
                <div class="single__content">
                    <div class="product-totals">
                        <span>Dimensions</span>
                        <coreui-badge [textSimple]="true" color="Slate">
                            {{numDimensions }}
                        </coreui-badge>
                    </div>
                </div>
                <div class="single__content">
                    <div class="product-totals">
                        <span>Filters</span>
                        <coreui-badge [textSimple]="true" color="Slate">
                            {{ numFilters }}
                        </coreui-badge>
                    </div>
                </div>
                <div class="single__content">
                    <div class="product-totals">
                        <span>Subject Areas</span>
                        <coreui-badge [textSimple]="true" color="Slate">
                            {{ numSubjectAreas }}
                        </coreui-badge>
                    </div>
                </div>
                <div class="single__content">
                    <div class="product-totals">
                        <span>Est. Row Count</span>
                        <div class="badge-width">
                            <coreui-badge [textSimple]="true" color="Slate" *ngIf="!isEstimating">
                                {{ numRowCount | coreUINumber:'1.0-2' }}
                            </coreui-badge>
                            <coreui-load-mask [active]="true" *ngIf="isEstimating" class="load-mask">
                            </coreui-load-mask>
                        </div>
                    </div>
                </div>
                <div class="single__content">
                    <div class="product-totals">
                        <span>Est. Refresh Time</span>
                        <div class="badge-width">
                            <coreui-badge [textSimple]="true" color="Slate" *ngIf="!isEstimating">
                                {{ timeRefresh }}
                            </coreui-badge>
                            <coreui-load-mask [active]="true" *ngIf="isEstimating" class="load-mask">
                            </coreui-load-mask>
                        </div>
                    </div>
                </div>
            </div>
        </coreui-standalone-accordion>
    </ng-template>
    <!----- Right Side Admin ----->
    <ng-template #rightTpl>
        <div class="product-totals">
            <coreui-menu buttonLabel="Admin Tools" buttonType="tertiary" menuButtonStyle="button" menuAlignment="right"
                *ngIf="isAdmin">
                <coreui-menu-section>
                    <button type="button" coreUIMenuItem (click)="onSQL()">View SQL</button>
                    <button type="button" coreUIMenuItem (click)="onXPlan()">View Execution Plan</button>
                    <button type="button" coreUIMenuItem (click)="onConsole()">Console</button>
                </coreui-menu-section>
            </coreui-menu>
        </div>
    </ng-template>
</coreui-action-bar>
<!-- </form> -->

<!--------------- Toast --------------->
<coreui-notification notificationStyle="error" #infoError>
    {{errorMessage}}
</coreui-notification>
<coreui-notification notificationStyle="error" #infoFilterError>
    Error(s) exist in the filter container(s).
</coreui-notification>
<coreui-notification notificationStyle="information" #infoNoObjectError>
    Select at least one Result Object.
</coreui-notification>