<form coreUIForm [formGroup]="myFormGroup">

    <coreui-modal modalWidth="60" #basicModal>
        <ng-template #titleTemplate>
            Publish DataSource
        </ng-template>
        <ng-template #titleCloseButtonTemplate>
            <button type="button" aria-label="Close" (click)="closeBasicModal()" coreUIButton="icon-large">
                <i coreUIIcon="close"></i></button>
        </ng-template>
        <ng-template #contentTemplate>
            <div class="inline_control">Target:<span class="inline_space text-style-2">{{targetServer}}</span></div>
            <div class="inline_control">Site:<span class="inline_space text-style-2">{{siteName}}</span></div>
            <div class="inline_control">Objects:
                <div *ngIf="listObjects.length>0" class="inline_space text-style-2">{{listObjects.join(", ")}}</div>
            </div>
            <div class="inline_control">Single Rows Filters:<span class="inline_space text-style-2">
                    {{interprettedSinglerowFilters}}</span></div>
            <div class="inline_control">Grouped Rows Filters:<span class="inline_space text-style-2">
                    {{interprettedGrouprowFilters}}</span></div>
            <div class="inline_control">Destination Project:<span class="inline_space text-style-2">
                    {{selectedDestinationProject.BreadCrumb}}</span>
                <!-- <span class="navi-link"><a href="javascript:void(0);" (click)="goSettings()">(Change)</a></span> -->
            </div>
            <div class="inline_control">Datasource Name:
                <input coreUIInput formControlName="inputDatasourceName" placeholder="Enter a name" class="inline_space"
                    coreUIFixedWidth="short" />
                <div *ngIf="msgWarningDSName.length > 0" class="inline_space text-style-3">
                    <span class="text-warning">
                        <i coreUIIcon="warning" scale="1.2"></i>
                    </span> {{ msgWarningDSName}}
                </div>
                <div *ngIf="msgErrorDSName.length> 0 " class="inline_space text-style-error">
                    {{msgErrorDSName}}
                </div>
            </div>
            <div class="inline_control">Publish Status:
                <coreui-badge color="Lime" *ngIf="statusDatasourcePublishing === 'Success'"
                    class="inline_space">Success</coreui-badge>
                <coreui-badge color="Apple" *ngIf="statusDatasourcePublishing === 'Error'"
                    class="inline_space">Error</coreui-badge>
                <coreui-badge color="Slate" *ngIf="statusDatasourcePublishing === ''" class="inline_space">Not
                    Running</coreui-badge>
                <coreui-badge color="Plum" *ngIf="statusDatasourcePublishing === 'Publishing'"
                    class="inline_space">Publishing...</coreui-badge>
            </div>
            <div class="inline_control">URL Link: <span class="inline_space text-style-2"><a href="{{publishedURL}}"
                        target="_blank">{{publishedURL}}</a></span></div>

        </ng-template>
        <ng-template #buttonsTemplate>
            <button type="button" coreUIButton="primary" (click)="publishDataSource()"
                [disabled]="!isSubmittable">Publish</button>
            <button type="button" coreUIButton="secondary" (click)="closeBasicModal()"
                coreUITrapFocusInitial>Close</button>
        </ng-template>
    </coreui-modal>
</form>