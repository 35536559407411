<coreui-action-bar>
    <ng-template #leftTpl>
        <coreui-filter-group>
            <!-- User Filter -->
            <coreui-filter data-qa="usersFilter-filters" panelCssClass="usersPanel" #userFilter icon="users"
                [buttonLabel]="selectedPerson" class="userGroup">
                <coreui-filter-select label="Users">
                    <div class="toggle-list" [class.scroll]="(uniqueUsers.length || 0) > 8">
                        <button type="button" [active]="item === selectedPerson" *ngFor="let item of uniqueUsers"
                            coreUIFilterOption (click)="onUserClick(item)">
                            {{ item }}
                        </button>
                    </div>
                </coreui-filter-select>
            </coreui-filter>
            <!-- Date Filter-->
            <coreui-filter data-qa="dateAddedFilter-filters" panelCssClass="dateAddedPanel" #dateAddedFilter
                icon="calendar" [buttonLabel]="selectedTimeFrame" class="dateGroup">
                <coreui-filter-select label="Created">
                    <div class="toggle-list" [class.scroll]="(createdDaysAgo.length || 0) > 8">
                        <button type="button" [active]="item === selectedTimeFrame" *ngFor="let item of createdDaysAgo"
                            coreUIFilterOption (click)="onDateClick(item)">
                            {{ item }}
                        </button>
                    </div>
                </coreui-filter-select>
            </coreui-filter>
        </coreui-filter-group>
    </ng-template>
    <ng-template #rightTpl>
        <button type="button" coreUIButton="icon-large" withBorder (click)="onRefresh()"><i
                coreUIIcon="refresh"></i></button>
    </ng-template>
</coreui-action-bar>