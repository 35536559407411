import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CoreUIModule, ModalComponent, NotificationComponent } from '@epsilon/core-ui';
import { ANALYZED_DATASET, COLUMN, ROW } from '../../service/datamodel';
import { BuilderService } from '../../service/builder.service';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from "../../parts/datatable/datatable.component";
import { PublishmodalComponent } from '../../parts/publishmodal/publishmodal.component';
import { PreviewService } from '../../service/preview.service';
import { EstimateService } from '../../service/estimate.service';

@Component({
  selector: 'app-previewcontainer',
  standalone: true,
  imports: [CoreUIModule, CommonModule, DatatableComponent, PublishmodalComponent],
  templateUrl: './previewcontainer.component.html',
  styleUrl: './previewcontainer.component.scss'
})
export class PreviewcontainerComponent implements OnInit {

  @ViewChild('basicModal1') basicModal1: PublishmodalComponent
  @ViewChild('infoFilterError', { static: true }) public infoFilterError: NotificationComponent;
  @ViewChild('infoNoObjectError', { static: true }) public infoNoObjectError: NotificationComponent;
  @ViewChild('infoQueryError', { static: true }) public infoQueryError: NotificationComponent;


  public defaultSortOption = 'Product';
  public defaultSortDirection = 'ASC';
  public isPublishDisabled = true;

  constructor(private previewservice: PreviewService, private builderservice: BuilderService, private estimateservice: EstimateService) {
    this.isPublishDisabled = true
  }

  public ngOnInit(): void {

    this.previewservice.isPreviewError.subscribe(x => {
      this.isPublishDisabled = x
      var dsStatus: ANALYZED_DATASET = this.estimateservice.getDatatSetStatus()
      // not undefined means, there is an error
      if (dsStatus === ANALYZED_DATASET.ERROR) {
        this.showToast(this.infoQueryError)
      }
    })
    this.estimateservice.isPublishShow.subscribe(x => {
      this.isPublishDisabled = !x
    })
  }

  public viewData() {
    if (!this.builderservice.getIsFiltersValid())
      this.showToast(this.infoFilterError)
    else if (this.builderservice.getSelectedObjects().length === 0)
      this.showToast(this.infoNoObjectError)
    else
      this.previewservice.getSampleData()
  }

  public launchBasicModal1() {
    if (!this.builderservice.getIsFiltersValid())
      this.showToast(this.infoFilterError)
    else if (this.builderservice.getSelectedObjects().length === 0)
      this.showToast(this.infoNoObjectError)
    else
      this.basicModal1.launchBasicModal();
  }

  public showToast(item: NotificationComponent): void {
    item.show();
  }


}
