export const environment = {

    // REST_API_SERVER: 'https://data-prep-insights-dev.peoplecloud.epsilon.com'
    TABLEAU_SERVER: 'https://insights-dev.peoplecloud.epsilon.com',
    REST_API_SERVER: 'https://data-prep-api-dev-insights.peoplecloud.epsilon.com',

    OKTA_ISSUER: 'https://epsilonclient.okta.com/oauth2/aus18eam3bo4xADdD358',
    OKTA_CLIENT_ID: '0oa18e9ns7pOSMi2T358',
     OKTA_REDIRECT_URI: 'https://data-prep-dev-insights.peoplecloud.epsilon.com/login/callback'
    //OKTA_REDIRECT_URI: 'http://localhost:4500/login/callback'

};
