import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CoreUIModule, NotificationComponent } from '@epsilon/core-ui';
import { BuilderService } from '../../service/builder.service';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMPARISON_OPERATOR, FILTER, FILTER_CONTAINER_TYPE, FILTER_METHOD, LOGICAL_OPERATOR, OBJ, OBJECT_TYPE } from '../../service/datamodel';
import { MetadataService } from '../../service/metadata.service';

@Component({
  selector: 'app-subquerydrop',
  standalone: true,
  imports: [CoreUIModule, CommonModule, DragDropModule],
  templateUrl: './subquerydrop.component.html',
  styleUrl: './subquerydrop.component.scss'
})
export class SubquerydropComponent implements OnInit {
  public allitems: OBJ[]
  @Input() parent: FILTER
  @Input() containerID: number;
  @Input() containerType: FILTER_CONTAINER_TYPE;
  // @ViewChild('infoNotMeasure', { static: true }) public infoNotMeasure: NotificationComponent;
  @ViewChild('infoNotMoreThanOne', { static: true }) public infoNotMoreThanOne: NotificationComponent;
  @Output('subqueryDroppedEvent') subqueryDroppedEvent: EventEmitter<any> = new EventEmitter();
  public MEASURE: OBJECT_TYPE;
  public DIMENSION: OBJECT_TYPE;
  public STANDALONE: OBJECT_TYPE
  public SUBQUERY: OBJECT_TYPE
  public items: OBJ[]
  public listID: string;
  public M_DUAL_HANDWRITING: FILTER_METHOD;
  public M_DUAL_DATETIME: FILTER_METHOD;
  public M_DUAL_DATE: FILTER_METHOD;

  constructor(private metadataservice: MetadataService) {
    this.allitems = []
    this.items = []
    this.DIMENSION = OBJECT_TYPE.DIMENSION
    this.MEASURE = OBJECT_TYPE.MEASURE
    this.STANDALONE = OBJECT_TYPE.STANDALONE
    this.SUBQUERY = OBJECT_TYPE.SUBQUERY
    this.M_DUAL_HANDWRITING = FILTER_METHOD.DUAL_HANDWRITING;
    this.M_DUAL_DATE = FILTER_METHOD.DUAL_DATE;
    this.M_DUAL_DATETIME = FILTER_METHOD.DUAL_DATETIME;
  }

  ngOnInit(): void {
    this.allitems = this.metadataservice.getObjs()
  }

  drop(event: CdkDragDrop<any[]>) {

    // if (event.previousContainer === event.container) {
    //   // do nothing
    // }
    // else {
    //   var droppedObj: OBJ = JSON.parse(JSON.stringify(event.previousContainer.data[event.previousIndex]))
    //   // replace
    //   this.items = []
    //   this.items.push(droppedObj)
    //   this.parent.SubqueryObj = droppedObj
    //   this.subqueryDroppedEvent.emit()
    // }
  }

  public onRemove(item: OBJ) {
    // this.subqueryDroppedEvent.emit(undefined)
    this.items = []

  }

}
