<div class="sub_display_box">
    <div class="sub_nav__item">
        <div [class]="item.Type">
            <div *ngIf="item.Type === DIMENSION">
                <i coreUIIcon="boxFill" scale="1" class="sub_nav_component"></i>
            </div>
            <div *ngIf="item.Type === STANDALONE">
                <i coreUIIcon="rectangle" scale="1" class="sub_nav_component"></i>
            </div>
            <div *ngIf="item.Type === SUBQUERY">
                <i coreUIIcon="object" scale="1" class="sub_nav_component"></i>
            </div>
            <div *ngIf="item.Type === MEASURE">
                <i coreUIIcon="radioFill" scale="1" class="sub_nav_component"></i>
            </div>
        </div> <span class="sub_nav_component">{{ item.Name }}</span>
        <a coreUIPrimaryNavigationItemLink class="sub-new-tab-icon" (click)="onSubqueryRemove()"><i coreUIIcon="close"
                scale="0.9"></i></a>
    </div>
</div>