<coreui-action-bar>
    <ng-template #leftTpl>
        <div class="actionsWrapper">
            <div coreUIButtonGroup class="menu-spacing" *ngIf="false">
                <!-- Link with "clean" icon -->
                <coreui-menu icon="string" menuButtonStyle="button" buttonType="micro-secondary" [iconAlignment]="null">
                    <coreui-menu-section>
                        <button type="button" coreUIMenuItem *ngFor="let keyword of keywords"
                            (select)="onSelectKeyword(keyword)">{{keyword}}</button>
                    </coreui-menu-section>
                </coreui-menu>
            </div>
            <div coreUIButtonGroup class="menu-spacing" *ngIf="false">
                <!-- Link with "clean" icon -->
                <coreui-menu icon="formula" menuButtonStyle="button" buttonType="micro-secondary"
                    [iconAlignment]="null">
                    <coreui-menu-section>
                        <button type="button" coreUIMenuItem *ngFor="let formula of formulas"
                            (select)="onSelectFormula(formula)">{{formula}}</button>
                    </coreui-menu-section>
                </coreui-menu>
            </div>
            <!----- Table Selector ----->
            <div coreUIButtonGroup class=" menu-spacing">
                <coreui-select type="secondary" placeholder="Select Table" data-qa="select-search" [searchable]="true"
                    formControlName="selectTable" (searchValueChange)="onSearchChange3($event)"
                    coreUIFixedWidth="extraShort" [disabled]="!isTableActive">
                    <coreui-option *ngFor="let item of filteredItems3 | activeonly | orderby:'name':'ASC'" [value]="item.Name"
                        (select)="onSelectTable(item)">
                        <i coreUIIcon="table" scale="1.2" *ngIf="item.IsAliased === 0 && item.IsDerived === 0"></i>
                        <i coreUIIcon="clone" scale="1.2" *ngIf="item.IsAliased === 1"></i>
                        <i coreUIIcon="tools" scale="1.2" *ngIf="item.IsDerived === 1"></i>
                        {{item.Name}}
                    </coreui-option>
                    <span *ngIf="filteredItems3.length === 0" class="no-results text-style-3">
                        No results found
                    </span>
                </coreui-select>
            </div>
            <!------ Column Selector ------>
            <div coreUIButtonGroup class="menu-spacing">
                <coreui-select type="secondary" placeholder="Select Column" data-qa="select-search" [searchable]="true"
                    formControlName="selectColumn" (searchValueChange)="onSearchChange4($event)"
                    coreUIFixedWidth="extraShort" [disabled]="!isColumnActive">
                    <coreui-option *ngFor="let item of filteredItems4 | orderby:'name':'ASC'" [value]="item.Name"
                        (select)="onSelectColumn(item.Name)">
                        {{item.Name}}
                    </coreui-option>
                    <span *ngIf="filteredItems4.length === 0" class="no-results text-style-3">
                        No results found
                    </span>
                </coreui-select>
            </div>
        </div>
    </ng-template>
</coreui-action-bar>