import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MetadataService } from '../../service/metadata.service';
import { COLUMN, COMPARISON_OPERATOR, EXPRESSION_TABLE_TYPE, FORMULA_TYPE, METADATA_TYPE, OBJECT_TYPE, SQL_KEYWORD, TABLE, TABLE_TYPE } from '../../service/datamodel';
import { Subscription } from 'rxjs';
import { CoreUIModule } from '@epsilon/core-ui';
import { CommonModule } from '@angular/common';
import { OrderbyPipe } from "../../pipe/orderby.pipe";
import { FormControl, FormGroup } from '@angular/forms';
import { ActiveonlyPipe } from "../../pipe/activeonly.pipe";

@Component({
  selector: 'app-expressionbuilder',
  standalone: true,
  imports: [CoreUIModule, CommonModule, OrderbyPipe, ActiveonlyPipe],
  templateUrl: './expressionbuilder.component.html',
  styleUrl: './expressionbuilder.component.scss'
})
export class ExpressionbuilderComponent implements OnInit {
  private _tableType: TABLE_TYPE
  private _metadataType: METADATA_TYPE;
  private _objectType: OBJECT_TYPE;
  private _leftTableName: string;
  private _rightTableName: string;
  @Input('tableType')
  set tableType(value: TABLE_TYPE) {
    this._tableType = value
    this.handleControls()
  }
  get tableType() {
    return this._tableType
  }
  @Input('objectType')
  set objectType(value: OBJECT_TYPE) {
    this._objectType = value
    this.handleControls()
  }
  get objectType() {
    return this._objectType
  }
  @Input('metadataType')
  set metadataType(value: METADATA_TYPE) {
    this._metadataType = value
    this.handleControls()
  }
  get metadataType() {
    return this._metadataType
  }
  @Input('leftTableName')
  set leftTableName(val: string) {
    this._leftTableName = val
    this.handleControls()
  }
  get leftTableName() {
    return this._leftTableName
  }
  @Input('rightTableName')
  set rightTableName(val: string) {
    this._rightTableName = val
    this.handleControls()
  }
  get rightTableName() {
    return this._rightTableName
  }
  public formulas: FORMULA_TYPE[]
  public keywords: SQL_KEYWORD[]
  public operators: COMPARISON_OPERATOR[]
  @Output('expression') expression: EventEmitter<string> = new EventEmitter()
  public myFormGroup = new FormGroup({
    selectTable: new FormControl('', []),
    selectColumn: new FormControl('', [])
  })
  private tables: TABLE[]
  private oraTables: TABLE[]
  private columns: COLUMN[]
  private oraColumns: COLUMN[]
  public table_name: string;
  private column_name: string;
  private _filteredItems3: TABLE[] = [];
  private _filteredItems4: COLUMN[] = [];
  public set filteredItems3(value: TABLE[]) {
    this._filteredItems3 = value;
  }
  public get filteredItems3(): TABLE[] {
    return this._filteredItems3;
  }
  public set filteredItems4(value: COLUMN[]) {
    this._filteredItems4 = value;
  }
  public get filteredItems4(): COLUMN[] {
    return this._filteredItems4;
  }
  private subscription: Subscription
  private expressionTableType: EXPRESSION_TABLE_TYPE;
  public isTableActive: boolean;
  public isColumnActive: boolean;


  constructor(private metadataservice: MetadataService) {
    this.tables = []
    this.columns = []
    this.oraTables = []
    this.oraColumns = []
    this.isColumnActive = false;
    this.isTableActive = false;
    this.formulas = [FORMULA_TYPE.AVG, FORMULA_TYPE.COUNT, FORMULA_TYPE.DCOUNT, FORMULA_TYPE.MAX, FORMULA_TYPE.MIN]
    this.keywords = [SQL_KEYWORD.SELECT, SQL_KEYWORD.FROM, SQL_KEYWORD.WHERE, SQL_KEYWORD.GROUP_BY,
    SQL_KEYWORD.HAVING, SQL_KEYWORD.ORDER_BY, SQL_KEYWORD.LEFT_JOIN, SQL_KEYWORD.RIGHT_JOIN]
    this.operators = [COMPARISON_OPERATOR.EQUAL_TO, COMPARISON_OPERATOR.NOT_EQUAL_TO]
  }

  handleControls() {
    if (this.metadataType === METADATA_TYPE.OBJECT) {
      if (this.objectType === OBJECT_TYPE.MEASURE || this.objectType === OBJECT_TYPE.DIMENSION || OBJECT_TYPE.SUBQUERY) {
        this.isTableActive = true
        this.filteredItems3 = this.tables
      }
      else if (this.objectType === OBJECT_TYPE.STANDALONE) {
        this.isTableActive = true
        this.filteredItems3 = this.oraTables
      }
      else {
        this.isTableActive = false
        this.isColumnActive = false
      }
    }
    else if (this.metadataType === METADATA_TYPE.TABLE) {
      if (this.tableType === TABLE_TYPE.DERIVED) {
        this.isTableActive = true
        this.filteredItems3 = this.tables
      }
      else {
        this.isTableActive = false
        this.isColumnActive = false
        this.filteredItems3 = this.oraTables
      }
    }
    else if (this.metadataType === METADATA_TYPE.JOIN) {
      this.isTableActive = true
      this.isColumnActive = false
      this.filteredItems3 = this.tables.filter(t => t.Name === this.leftTableName || t.Name === this.rightTableName)
    }
  }

  ngOnInit(): void {

    this.subscription = this.metadataservice.isMetaTablesLoading.subscribe(x => {
      if (!x) {
        this.tables = this.metadataservice.getTables()
        this.filteredItems3 = this.tables
      }
    })

    this.subscription = this.metadataservice.isOracleTableLoading.subscribe(x => {
      if (!x) {
        this.oraTables = this.metadataservice.getOracleTables()
        this.filteredItems3 = this.oraTables
      }
    })

    this.subscription = this.metadataservice.isDerivedColumnLoading.subscribe(x => {
      if (!x) {
        this.columns = this.metadataservice.getColumns()
        this.filteredItems4 = this.columns
      }
    })

    this.subscription = this.metadataservice.isOracleColumnLoading.subscribe(x => {
      if (!x) {
        this.oraColumns = this.metadataservice.getOracleColumns()
        this.filteredItems4 = this.oraColumns
      }
    })

  }

  public onSearchChange3(searchText): void {

    this.filteredItems3 = searchText
      ? this.tables.filter(
        (item) =>
          item.Name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      : this.tables;

  }

  public onSearchChange4(searchText): void {
    this.filteredItems4 = searchText
      ? this.columns.filter(
        (item) =>
          item.Name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      : this.columns;

  }

  public onSelectTable(item: TABLE) {
    this.table_name = item.Name
    this.isColumnActive = true
    if (item.IsDerived)
      this.metadataservice.queryColumnsOfDerviedTable(item)
    else if (item.IsAliased)
      this.metadataservice.querySpecificOracleMetadata(METADATA_TYPE.ORACLECOLUMNS, item.AliasedTable)
    else
      this.metadataservice.querySpecificOracleMetadata(METADATA_TYPE.ORACLECOLUMNS, item.Name)

  }

  public onSelectColumn(name: string) {
    this.column_name = name
    this.expression.emit(this.table_name + '.' + this.column_name);
  }

  public onSelectFormula(val: FORMULA_TYPE) {
    this.expression.emit(val)
  }

  public onSelectKeyword(val: SQL_KEYWORD) {
    this.expression.emit(val)
  }


}
