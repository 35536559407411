import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { COLUMN, ROW } from '../../service/datamodel';
import { CoreUIModule } from '@epsilon/core-ui';
import { NgFor, NgIf } from '@angular/common';
import { PreviewService } from '../../service/preview.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datatable',
  standalone: true,
  imports: [CoreUIModule, NgIf, NgFor],
  templateUrl: './datatable.component.html',
  styleUrl: './datatable.component.scss'
})
export class DatatableComponent implements OnInit, OnDestroy {
  @Input() isLoadingTable;
  public tableColumns: COLUMN[]
  public tableRows: ROW[];
  public isTimeOut: boolean

  public defaultSortOption = 'Product';
  public defaultSortDirection = 'ASC';
  private subscription: Subscription

  constructor(private previewservice: PreviewService) {
    this.isTimeOut = false
  }

  public ngOnInit(): void {

    this.subscription = this.previewservice.resultedColumns.subscribe(x => {
      this.tableColumns = x;
    })
    this.subscription = this.previewservice.returnedRows.subscribe(x => {
      this.tableRows = x
    })
    this.subscription = this.previewservice.isLoadingTable.subscribe(x => {
      this.isLoadingTable = x
    })
    this.subscription = this.previewservice.isTimeOut.subscribe(x=>{
      this.isTimeOut = x
    })

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

}
