import { Injectable } from '@angular/core';
import { OktaService } from './okta.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { COLUMN, FILTER_CONTAINER, FILTER_CONTAINER_TYPE, OBJ, ROW, USER_ROLE } from './datamodel';
import { BehaviorSubject, catchError, Subscription, throwError, timeout } from 'rxjs';
import { environment } from '../../environments/environment';
import { AccessToken, IDToken } from '@okta/okta-auth-js';
import { BuilderService } from './builder.service';
import { UserService } from './user.service';
import { EstimateService } from './estimate.service';

@Injectable({
  providedIn: 'root'
})
export class PreviewService {

  public isLoadingTable: BehaviorSubject<boolean>;
  private oktaToken: AccessToken;
  private oktaToken2: IDToken;
  public resultedColumns: BehaviorSubject<COLUMN[]>;
  public returnedRows: BehaviorSubject<ROW[]>;
  private subscription: Subscription;
  public isPreviewError: BehaviorSubject<boolean>;
  public isTimeOut: BehaviorSubject<boolean>

  constructor(private http: HttpClient, private oktaservice: OktaService, private builderservice: BuilderService, private userservice: UserService, private estimateservice: EstimateService) {
    this.isLoadingTable = new BehaviorSubject<boolean>(false);
    this.resultedColumns = new BehaviorSubject<COLUMN[]>([]);
    this.returnedRows = new BehaviorSubject<ROW[]>([]);
    this.oktaservice.accessToken$.subscribe(x => { if (x) { this.oktaToken = x } })
    this.oktaservice.idToken$.subscribe(x => { if (x) { this.oktaToken2 = x } })
    this.builderservice.isReset.subscribe(x => { if (x) this.resetTable() })
    this.isPreviewError = new BehaviorSubject<boolean>(true);
    this.isTimeOut = new BehaviorSubject<boolean>(false)
  }

  public getSampleData() {

    if (this.builderservice.getSelectedObjects().length > 0) {
      this.resultedColumns.next(this.builderservice.getSelectedObjects().map(s => { return { Name: s.Name, Value: '', DataType: s.DataType, Type: s.Type } }))
      this.queryPreview()
    }
  }

  public getResultColumns(): COLUMN[] {
    return this.builderservice.getSelectedObjects().map(s => { return { Name: s.Name, Value: '', DataType: s.DataType, Type: s.Type } })
  }

  private queryPreview() {

    this.isLoadingTable.next(true);
    this.isTimeOut.next(false)
    var siteCode = this.userservice.getSelectedSite().SiteCode

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let body = new URLSearchParams();
    body.set('Objects', this.builderservice.getJsonifiedSelectedObjects());
    body.set('SinglerowsFilters', this.builderservice.getJsonifiedSelectedFilterContainers(FILTER_CONTAINER_TYPE.SINGLEROWS));
    body.set('GrouprowsFilters', this.builderservice.getJsonifiedSelectedFilterContainers(FILTER_CONTAINER_TYPE.GROUPROWS));
    body.set('TenantCode', siteCode)
    this.subscription = this.http.post<any>(`${environment.REST_API_SERVER}/preview`, body, options).pipe(
      timeout(60000), // Timeout after 60 seconds
      catchError(a => { return this.handleError(this) })
    ).subscribe(x => {

      if (x.Message === "Success") {
        var rows: ROW[] = []
        for (const r of x.data) {
          var row: ROW = { cols: [] };
          for (var i = 0; i < this.resultedColumns.value.length; i++) {
            row.cols.push({ Name: this.resultedColumns.value[i].Name, Value: r[i], DataType: this.resultedColumns.value[i].DataType })
          }
          rows.push(row)
        }
        this.returnedRows.next(rows);
        this.isPreviewError.next(false)
      }
      else {
        this.returnedRows.next([]);
        this.estimateservice.setError(x.Message)
        this.isPreviewError.next(true)
      }

      this.isLoadingTable.next(false)
      this.subscription.unsubscribe()
    })

  }

  private handleError(pointer: any): any {
    // Handle the error here, e.g., log it, show an error message to the user, etc.
    //console.error('An error occurred:', error);
    pointer.isTimeOut.next(true)
    pointer.isLoadingTable.next(false)
    return throwError('Something went wrong; please try again later.');
  }

  private resetTable() {
    this.resultedColumns.next([])
    this.returnedRows.next([])
  }

  public setIsPreviewError(flag: boolean) {
    this.isPreviewError.next(flag)
  }

}
