<div class="container">
    <!-- Searched item is inactive -->
    <coreui-tree-progressive [withIcon]="true" #myProgressiveTree [breadcrumbLabel]="'Top'">
        <ng-container *ngTemplateOutlet="recursiveTree; context: { $implicit: items}"></ng-container>
        <ng-template #recursiveTree let-items="">
            <!-- Recursive Component -->
            <coreui-tree-progressive-pane *ngFor="let item of items| orderby : 'SortOrder': 'ASC'" [header]="item.Name"
                (activeChange)="onActiveChange(item)">
                <ng-container *ngTemplateOutlet="recursiveTree;context: { $implicit: item.Children }"></ng-container>
                <app-draggableobject #draggable class="text-style-3" [orient]="'vertical'" [PID]="item.ID"
                    *ngIf="item.IsHierarchy"></app-draggableobject>
            </coreui-tree-progressive-pane>
        </ng-template>
    </coreui-tree-progressive>
    <!-- Searched item is active -->
</div>