import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CoreUIModule } from '@epsilon/core-ui';
import { HistoryService } from '../../service/history.service';


@Component({
  selector: 'app-historyfilter',
  standalone: true,
  imports: [CoreUIModule, CommonModule],
  templateUrl: './historyfilter.component.html',
  styleUrl: './historyfilter.component.scss'
})
export class HistoryfilterComponent implements OnInit {
  public selectedPerson: string;
  public selectedTimeFrame: string;
  public createdDaysAgo: string[];
  public uniqueUsers: string[];

  constructor(private historyservice: HistoryService) {
    this.createdDaysAgo = ['All', 'Yesterday', 'Today', '3 Days Ago', '10 Days Ago']
    this.uniqueUsers = ['All']
    this.selectedPerson = 'All'
    this.selectedTimeFrame = 'All'
  }

  ngOnInit(): void {
    this.historyservice.isHistoryQuerying.subscribe(x => {
      if (!x)
        this.uniqueUsers = this.historyservice.getUniqueUserNames()
    })
  }

  public onDateClick(value: string): void {
    this.selectedTimeFrame = value
    this.historyservice.setFilterDates(value)
  }

  public onUserClick(value: string): void {
    this.selectedPerson = value
    this.historyservice.setFilterUser(value)
  }

  public onRefresh() {
    this.historyservice.refreshHistories()
  }




}
