import { Inject, Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AccessToken, AuthState, IDToken } from '@okta/okta-auth-js';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OktaService {

  public name$!: Observable<string>;
  public isAuthenticated$!: Observable<boolean>;
  public preferredUsername$!: Observable<string>;
  public email$!: Observable<string>;
  public accessToken$: Observable<AccessToken>;
  public idToken$: Observable<IDToken>;

  constructor(private _router: Router, private _oktaStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }

  public svInit() {
    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
    this.name$ = this._oktaStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.idToken?.claims.name ?? '')
    );
    // usually preferredusername (okta) == Tableau username
    this.preferredUsername$ = this._oktaStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.idToken?.claims.preferred_username ?? '')
    )
    this.email$ = this._oktaStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.idToken?.claims.email ?? '')
    )
    this.accessToken$ = this._oktaStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.accessToken)
    )
    this.idToken$ = this._oktaStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.idToken)
    )
    console.log(this.accessToken$)
  }

  public async signIn(): Promise<void> {
    this._oktaAuth.closeSession()
      .then(a => {
        this._oktaAuth.signInWithRedirect().then(
          _ => {
            this._router.navigate(['/']);
          }
        );
      })
  }

  public async signOut(): Promise<void> {
    await this._oktaAuth.signOut();
  }

}
