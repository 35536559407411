import { Component, OnDestroy, OnInit } from '@angular/core';
import { TABLEAU_SITE } from '../../service/datamodel';
import { CoreUIModule } from '@epsilon/core-ui';
import { SitefilterPipe } from "../../pipe/sitefilter.pipe";
import { OrderbyPipe } from '../../pipe/orderby.pipe';
import { CommonModule } from '@angular/common';
import { AdminService } from '../../service/admin.service';
import { FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-multitenant',
  standalone: true,
  imports: [CoreUIModule, SitefilterPipe, OrderbyPipe, CommonModule, ReactiveFormsModule],
  templateUrl: './multitenant.component.html',
  styleUrl: './multitenant.component.scss'
})
export class MultitenantComponent implements OnInit, OnDestroy {
  public usersites: TABLEAU_SITE[];
  public isTenantCopying: boolean;
  public statusTenantCopy: string;
  public tenantTo: TABLEAU_SITE;
  public tenantFrom: TABLEAU_SITE;
  public isTenantExists: boolean;
  public errMsgTenantFrom: string;
  public errMsgTenantTo: string;
  public msgCopy: string;
  private subscription: Subscription;
  private subFormChange: Subscription;
  public isTenantsValid: boolean;
  public isSomeChecked: boolean;
  public selectItems: string[] = [];
  private _filteredItems: TABLEAU_SITE[] = [];
  public set filteredItems(value: TABLEAU_SITE[]) {
    this._filteredItems = value;
  }
  public get filteredItems(): TABLEAU_SITE[] {
    return this._filteredItems;
  }
  private _filteredItems2: TABLEAU_SITE[] = [];
  public set filteredItems2(value: TABLEAU_SITE[]) {
    this._filteredItems2 = value;
  }
  public get filteredItems2(): TABLEAU_SITE[] {
    return this._filteredItems2;
  }

  public myFormGroup: FormGroup = new FormGroup({
    selectTenantTo: new FormControl('', Validators.required),
    selectTenantFrom: new FormControl('', Validators.required)
  })

  public checkboxRequiredFormGroup = new FormGroup(
    {
      requiredCheckbox1: new FormControl(true),
      requiredCheckbox2: new FormControl(true),
      requiredCheckbox3: new FormControl(true)
    },
    {
      updateOn: 'change',
      validators: (formGroup: FormGroup): ValidationErrors => {
        return this.validateSomeChecked(formGroup);
      }
    }
  );

  constructor(private userservice: UserService, private adminservice: AdminService) {
    this.isTenantCopying = false;
    this.statusTenantCopy = 'n/a';
    this.isTenantExists = false;
    this.msgCopy = ''
  }

  ngOnInit(): void {
    this.userservice.usersites.subscribe(x => {
      this.usersites = x
      this.filteredItems = this.usersites
      this.filteredItems2 = this.usersites
      this.validateTenants("")
    })
    this.subFormChange = this.myFormGroup.valueChanges.subscribe(val => this.validateTenants(val))
    this.subscription = this.adminservice.isTenantCopying.subscribe(x => {
      if (!x) {
        this.isTenantCopying = x
        this.statusTenantCopy = this.adminservice.getStatusTenantCopy()
        this.msgCopy = this.adminservice.getMessageTenantCopy()
      }
    })

  }

  private validateSomeChecked(formGroup: FormGroup): null | object {
    // See if any of the checkboxes are checked.
    const someChecked = Object.keys(formGroup.controls)
      .map((key) => formGroup.controls[key])
      .some((checkbox) => {
        return checkbox.value;
      });

    if (someChecked) {
      this.isSomeChecked = true
      return null;
    } else {
      this.isSomeChecked = false
      return { someChecked: { valid: false } };
    }
  }

  private validateTenants(val: any) {

    this.isTenantsValid = true
    this.errMsgTenantFrom = ""
    this.errMsgTenantTo = ""
    this.myFormGroup.controls["selectTenantFrom"].setErrors(undefined)
    this.myFormGroup.controls["selectTenantTo"].setErrors(undefined)

    if (this.myFormGroup.controls["selectTenantFrom"].value === "") {
      this.errMsgTenantFrom = "Required"
      this.isTenantsValid = false
      this.myFormGroup.controls["selectTenantFrom"].setErrors({ msg: 'Required' })
    }
    if (this.myFormGroup.controls["selectTenantTo"].value === "") {
      this.errMsgTenantTo = "Required"
      this.isTenantsValid = false
      this.myFormGroup.controls["selectTenantTo"].setErrors({ msg: 'Required' })
    }
    if (this.myFormGroup.controls["selectTenantTo"].value === this.myFormGroup.controls["selectTenantFrom"].value) {
      this.errMsgTenantFrom = "Target tenant cannot be the same as the source tenant"
      this.errMsgTenantTo = "Target tenant cannot be the same as the source tenant"
      this.isTenantsValid = false
      this.myFormGroup.controls["selectTenantFrom"].setErrors({ msg: 'Conflicts' })
      this.myFormGroup.controls["selectTenantTo"].setErrors({ msg: 'Conflicts' })
    }

    if (this.isTenantsValid === false)
      this.myFormGroup.setErrors({ msg: "Error" })
    else
      this.subFormChange = this.myFormGroup.valueChanges.subscribe(val => this.validateTenants(val))

  }

  public onSelectSource(t: TABLEAU_SITE) {
    this.tenantFrom = t
  }
  public onSelectTarget(t: TABLEAU_SITE) {
    this.tenantTo = t
  }

  public onCopy() {
    this.validateTenants("")
    var types: string[] = [];
    if (this.checkboxRequiredFormGroup.controls.requiredCheckbox1.value === true)
      types.push("O")
    if (this.checkboxRequiredFormGroup.controls.requiredCheckbox2.value === true)
      types.push("T")
    if (this.checkboxRequiredFormGroup.controls.requiredCheckbox3.value === true)
      types.push("J")
    this.adminservice.copyTenant(this.tenantFrom, this.tenantTo, types)
  }

  public onCreate() {
  }

  public onSelectCreate(t: TABLEAU_SITE) {

  }

  public onSearchChange(searchText): void {
    this.filteredItems = searchText
      ? this.usersites.filter(
        (item) =>
          item.SiteCode.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          item.SiteName.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      : this.usersites;
  }

  public onSearchChange2(searchText): void {
    this.filteredItems2 = searchText
      ? this.usersites.filter(
        (item) =>
          item.SiteCode.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          item.SiteName.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      : this.usersites;
  }

  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe()
  }


}
