import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subscription, timer } from 'rxjs';
import { COLUMN, ESTIMATE, HIERARCHY, JOIN, METADATA_ACTION, METADATA_TYPE, OBJ, OBJECT, OBJECT_TYPE, PATH, TABLE, TENANT, FOLDER } from './datamodel';
import { environment } from '../../environments/environment';
import { OktaService } from './okta.service';
import { AccessToken, IDToken } from '@okta/okta-auth-js';
import { format } from 'sql-formatter';
import { UserService } from './user.service';
import { DownloadService } from './download.service';


export class RepeatingServiceCall<T> {
  readonly observable$: Observable<T>;

  constructor(delay: number) {
    this.observable$ = timer(0, delay)
      .pipe(
        map(() => <T>{})
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  private objects: OBJ[]
  private metadataObjects: OBJECT[]
  private metadataTables: TABLE[]
  private metadataJoins: JOIN[]
  private metadataFolders: FOLDER[]
  private metadataHierarchies: HIERARCHY[]
  private metaEstimates: ESTIMATE[]
  private metadataFlatHierarchies: HIERARCHY[]
  private metaPaths: PATH[]
  public subjectAreas: string[]
  public oracleTables: TABLE[]
  public oracleColumns: COLUMN[]
  public derivedColumns: COLUMN[]
  public tenants: TENANT[]
  private oktaToken: AccessToken
  private oktaToken2: IDToken;
  private subscription: Subscription
  public isMetaObjsLoading: BehaviorSubject<boolean>
  public isMetaObjectsLoading: BehaviorSubject<boolean>
  public isMetaTablesLoading: BehaviorSubject<boolean>
  public isMetaJoinsLoading: BehaviorSubject<boolean>
  public isMetaFoldersLoading: BehaviorSubject<boolean>
  public isMetaFlatHierarchiesLoading: BehaviorSubject<boolean>
  public isMetaHierarchiesLoading: BehaviorSubject<boolean>
  public isMetaEstimatesLoading: BehaviorSubject<boolean>
  public isMetaPathLoading: BehaviorSubject<boolean>
  public isMetaOptimizationsLoading: BehaviorSubject<boolean>
  public isMetaObjectValidating: BehaviorSubject<boolean>
  public isMetaObjectValid: BehaviorSubject<any>
  public isMetaFolderValidating: BehaviorSubject<boolean>
  public isMetaFolderValid: BehaviorSubject<any>
  public isMetaTableValid: BehaviorSubject<any>
  public isMetaTableValidating: BehaviorSubject<boolean>
  public isMetaJoinValid: BehaviorSubject<any>
  public isMetaJoinValidating: BehaviorSubject<boolean>
  public isOracleTableLoading: BehaviorSubject<boolean>
  public isOracleColumnLoading: BehaviorSubject<boolean>
  public isDerivedColumnLoading: BehaviorSubject<boolean>
  public isTenantsLoading: BehaviorSubject<boolean>
  public metaERDjoins: Observable<JOIN[]>
  private duration: number = 1;
  private timerOn: boolean = false;
  private currTime: number = 0;
  private caller: any;
  private activeMetadataType: METADATA_TYPE;
  private activeItem: any;
  private activeAction: METADATA_ACTION
  public activeERDTable: BehaviorSubject<TABLE>
  public msgMetaAction: BehaviorSubject<string>


  constructor(private http: HttpClient, private oktaservice: OktaService, private userservice: UserService, private downloadservice: DownloadService) {
    this.objects = []
    this.metadataObjects = []
    this.metadataTables = []
    this.metadataJoins = []
    this.metadataFolders = []
    this.metadataHierarchies = []
    this.metaEstimates = []
    this.metaPaths = []
    this.metaERDjoins = new Observable<JOIN[]>((subscriber) => { })
    this.subjectAreas = []
    this.oracleTables = []
    this.oracleColumns = []
    this.tenants = []
    this.oktaservice.accessToken$.subscribe(x => { if (x) { this.oktaToken = x } })
    this.oktaservice.idToken$.subscribe(x => { if (x) { this.oktaToken2 = x } })
    this.isMetaObjsLoading = new BehaviorSubject<boolean>(false)
    this.isMetaObjectsLoading = new BehaviorSubject<boolean>(false)
    this.isMetaFoldersLoading = new BehaviorSubject<boolean>(false)
    this.isMetaTablesLoading = new BehaviorSubject<boolean>(false)
    this.isMetaFlatHierarchiesLoading = new BehaviorSubject<boolean>(false)
    this.isMetaJoinsLoading = new BehaviorSubject<boolean>(false)
    this.isMetaHierarchiesLoading = new BehaviorSubject<boolean>(false)
    this.isMetaEstimatesLoading = new BehaviorSubject<boolean>(false)
    this.isMetaOptimizationsLoading = new BehaviorSubject<boolean>(false)
    this.isMetaPathLoading = new BehaviorSubject<boolean>(false)
    this.isMetaObjectValidating = new BehaviorSubject<boolean>(false)
    this.isMetaObjectValid = new BehaviorSubject<any>(false)
    this.isMetaFolderValidating = new BehaviorSubject<boolean>(false)
    this.isMetaFolderValid = new BehaviorSubject<any>(false)
    this.isMetaTableValid = new BehaviorSubject<any>(false)
    this.isMetaJoinValidating = new BehaviorSubject<boolean>(false)
    this.isMetaJoinValid = new BehaviorSubject<any>(false)
    this.isMetaTableValidating = new BehaviorSubject<boolean>(false)
    this.isOracleTableLoading = new BehaviorSubject<boolean>(false)
    this.isOracleColumnLoading = new BehaviorSubject<boolean>(false)
    this.isDerivedColumnLoading = new BehaviorSubject<boolean>(false)
    this.isTenantsLoading = new BehaviorSubject<boolean>(false)
    this.caller = new RepeatingServiceCall<any>(1000);
    this.caller.observable$.subscribe(() => this.onTick());
    this.derivedColumns = []
    this.msgMetaAction = new BehaviorSubject<string>('')
    this.activeERDTable = new BehaviorSubject<TABLE>(undefined)
  }

  public onTick() {

    if (this.timerOn && this.currTime > this.duration) {
      this.timerOn = false
      this.validateMeta(this.activeItem, this.activeMetadataType, this.activeAction)
      this.currTime = 0
    }
    else if (this.timerOn)
      this.currTime++
  }

  public resetTimer() {
    this.timerOn = true
    this.currTime = 0
  }

  public setMetadataValidation(item: any, metadatatype: METADATA_TYPE, action: METADATA_ACTION) {
    this.activeMetadataType = metadatatype
    this.activeItem = item
    this.activeAction = action
    this.resetTimer()
  }

  public getObjs(): OBJ[] {
    return this.objects
  }
  public getObjects(): OBJECT[] {
    return this.metadataObjects
  }
  public getFolders(): FOLDER[] {
    return this.metadataFolders
  }
  public getTables(): TABLE[] {
    return this.metadataTables
  }
  public getFlatHierarchies(): HIERARCHY[] {
    return this.metadataFlatHierarchies
  }
  public getJoins(): JOIN[] {
    return this.metadataJoins
  }
  public getHierachies(): HIERARCHY[] {
    return this.metadataHierarchies
  }
  public getEstimations(): ESTIMATE[] {
    return this.metaEstimates
  }
  public getPaths(): PATH[] {
    return JSON.parse(JSON.stringify(this.metaPaths.filter(p => p.IsActive === 1)))
  }
  public getPathsPlus(): PATH[] {
    return JSON.parse(JSON.stringify(this.metaPaths.filter(p => p.IsActive === 1 || p.Name === ' -- Top --')))
  }
  public getAllPaths(): PATH[] {
    return JSON.parse(JSON.stringify(this.metaPaths))
  }
  public getSubjectAreas(): string[] {
    return this.subjectAreas
  }
  public getOracleTables(): TABLE[] {
    return this.oracleTables
  }
  public getOracleColumns(): COLUMN[] {
    return this.oracleColumns
  }
  public getColumns(): COLUMN[] {
    return this.derivedColumns
  }
  public addFolder(item: FOLDER) {
    this.metadataFolders.push(item)
  }

  public querySpecificMetadata(type: METADATA_TYPE) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let tenantcode = this.userservice.getSelectedSite()

    if (tenantcode) {

      if (type === METADATA_TYPE.OBJ) {
        this.isMetaObjsLoading.next(true)
        this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/metadata?type=${METADATA_TYPE.OBJ}&tenantcode=${tenantcode.SiteCode}`, options).subscribe((resp: OBJ[]) => {
          this.objects = resp.filter(o => o.IsActive === 1)
          this.isMetaObjsLoading.next(false)
          //this.subscription.unsubscribe()
        })
      }
      else if (type === METADATA_TYPE.OBJECT || type === METADATA_TYPE.FOLDER) {
        if (type === METADATA_TYPE.OBJECT)
          this.isMetaObjectsLoading.next(true)
        else if (type === METADATA_TYPE.FOLDER)
          this.isMetaFoldersLoading.next(true)
        this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/metadata?type=${METADATA_TYPE.OBJECT}&tenantcode=${tenantcode.SiteCode}`, options).subscribe((resp: OBJECT[]) => {
          this.metadataObjects = this.formatObject(resp.filter(o => o.Type !== OBJECT_TYPE.FOLDER))
          this.metadataFolders = resp.filter(d => d.Type === OBJECT_TYPE.FOLDER && d.ID !== 0)
          for (const obj of this.metadataObjects) {
            if (obj.SubjectArea || '' !== '')
              this.subjectAreas = [...new Set([...this.subjectAreas, obj.SubjectArea])]
          }
          if (type === METADATA_TYPE.OBJECT)
            this.isMetaObjectsLoading.next(false)
          else if (type === METADATA_TYPE.FOLDER)
            this.isMetaFoldersLoading.next(false)
          //this.subscription.unsubscribe()
        })
      }
      else if (type === METADATA_TYPE.TABLE) {
        this.isMetaTablesLoading.next(true)
        this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/metadata?type=${METADATA_TYPE.TABLE}&tenantcode=${tenantcode.SiteCode}`, options).subscribe((resp: TABLE[]) => {
          this.metadataTables = this.formatTable(resp)
          this.isMetaTablesLoading.next(false)
          //this.subscription.unsubscribe()
        });
      }
      else if (type === METADATA_TYPE.JOIN) {
        this.isMetaJoinsLoading.next(true)
        this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/metadata?type=${METADATA_TYPE.JOIN}&tenantcode=${tenantcode.SiteCode}`, options).subscribe((resp: JOIN[]) => {
          this.metadataJoins = this.formatJoin(resp)
          this.isMetaJoinsLoading.next(false)
          this.metaERDjoins = new Observable<JOIN[]>((subscriber) => {
            subscriber.next(this.metadataJoins)
          })
          //this.subscription.unsubscribe()
        });
      }
      else if (type === METADATA_TYPE.HIERARCHY) {
        this.isMetaHierarchiesLoading.next(true)
        this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/metadata?type=${METADATA_TYPE.HIERARCHY}&tenantcode=${tenantcode.SiteCode}`, options).subscribe((resp: HIERARCHY[]) => {
          this.metadataHierarchies = resp
          this.isMetaHierarchiesLoading.next(false)

          //this.subscription.unsubscribe()
        });
      }
      else if (type === METADATA_TYPE.ESTIMATION) {
        this.isMetaEstimatesLoading.next(true)
        this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/metadata?type=${METADATA_TYPE.ESTIMATION}&tenantcode=${tenantcode.SiteCode}`, options).subscribe((resp: ESTIMATE[]) => {
          this.metaEstimates = resp
          this.isMetaEstimatesLoading.next(false)
          //this.subscription.unsubscribe()
        });
      }
      else if (type === METADATA_TYPE.PATH) {
        this.isMetaPathLoading.next(true)
        this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/metadata?type=${METADATA_TYPE.PATH}&tenantcode=${tenantcode.SiteCode}`, options).subscribe((resp: PATH[]) => {
          this.metaPaths = resp
          this.isMetaPathLoading.next(false)
          //this.subscription.unsubscribe()
        });
      }

      else if (type === METADATA_TYPE.TENANTS) {
        this.isTenantsLoading.next(true)
        this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/metadata?type=${METADATA_TYPE.TENANTS}&tenantcode=${tenantcode.SiteCode}`, options).subscribe((resp: TENANT[]) => {
          this.tenants = resp
          this.isTenantsLoading.next(false)
          //this.subscription.unsubscribe()
        });
      }
    }
  }

  public querySpecificOracleMetadata(type: METADATA_TYPE, tablename?: string) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let tenantcode = this.userservice.getSelectedSite()

    if (tenantcode) {

      if (type === METADATA_TYPE.ORACLETABLES) {
        this.isOracleTableLoading.next(true)
        this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/metadata?type=${METADATA_TYPE.ORACLETABLES}&tenantcode=${tenantcode.SiteCode}`, options).subscribe((resp: TABLE[]) => {
          this.oracleTables = resp
          this.isOracleTableLoading.next(false)
          //this.subscription.unsubscribe()
        });
      }
      else if (type === METADATA_TYPE.ORACLECOLUMNS) {
        this.isOracleColumnLoading.next(true)
        this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/metadata?type=${METADATA_TYPE.ORACLECOLUMNS}&tenantcode=${tenantcode.SiteCode}&tablename=${tablename}`, options).subscribe((resp: COLUMN[]) => {
          this.oracleColumns = resp
          this.isOracleColumnLoading.next(false)
          //this.subscription.unsubscribe()
        });
      }
    }
  }

  public queryColumnsOfDerviedTable(item: TABLE) {
    this.isDerivedColumnLoading.next(false)

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let tenantcode = this.userservice.getSelectedSite()

    this.subscription = this.http.get<any[]>(`${environment.REST_API_SERVER}/metadata?type=${METADATA_TYPE.COLUMNS}&tenantcode=${tenantcode.SiteCode}&tableid=${item.ID}`, options).subscribe((resp: COLUMN[]) => {
      this.derivedColumns = resp
      this.isDerivedColumnLoading.next(false)
      //this.subscription.unsubscribe()
    });

  }

  public setMeta(data: any, metadatatype: METADATA_TYPE, metadataaction: METADATA_ACTION) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let body = new URLSearchParams();
    body.set('TenantCode', this.userservice.getSelectedSite().SiteCode)
    body.set('Data', JSON.stringify(data));
    body.set('Operation', metadataaction) // UPDATE or INSERT
    body.set('MetaType', metadatatype)

    this.subscription = this.http.post<any>(`${environment.REST_API_SERVER}/metadata`, body, options).subscribe(x => {
      this.msgMetaAction.next(x.Message)
      if (metadataaction === METADATA_ACTION.INSERT)
        this.addMeta(data, metadatatype)
    })

  }

  public addMeta(data: any, type: METADATA_TYPE) {
    if (type === METADATA_TYPE.OBJECT) {
      // this.isMetaObjectsLoading.next(true)
      this.metadataObjects.push(data)
      // this.isMetaObjectsLoading.next(false)
    }
    else if (type === METADATA_TYPE.FOLDER) {
      // this.isMetaFoldersLoading.next(true)
      this.metadataObjects.push(data)
      // this.isMetaFoldersLoading.next(false)
    }
    else if (type === METADATA_TYPE.TABLE) {
      // this.isMetaTablesLoading.next(true)
      this.metadataTables.push(data)
      // this.isMetaTablesLoading.next(false)
    }
    else if (type === METADATA_TYPE.JOIN) {
      // this.isMetaJoinsLoading.next(true)
      this.querySpecificMetadata(METADATA_TYPE.JOIN)
      // this.isMetaJoinsLoading.next(false)
    }
  }

  public deleteMeta(type: METADATA_TYPE, obj: any) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let tenantcode = this.userservice.getSelectedSite().SiteCode
    let ID = obj.ID
    this.subscription = this.http.delete<any>(`${environment.REST_API_SERVER}/metadata?type=${type}&tenantcode=${tenantcode}&ID=${ID}`, options).subscribe(x => {
      this.msgMetaAction.next(x.Message)
      this.removeMeta(obj, type)

    })

  }

  private removeMeta(data: any, type: METADATA_TYPE) {
    if (type === METADATA_TYPE.OBJECT) {
      // this.isMetaObjectsLoading.next(true)
      this.metadataObjects = this.metadataObjects.filter(o => o.ID !== data.ID)
      // this.isMetaObjectsLoading.next(false)
    }
    else if (type === METADATA_TYPE.FOLDER) {
      // this.isMetaFoldersLoading.next(true)
      this.metadataFolders = this.metadataFolders.filter(f => f.ID !== data.ID)
      // this.isMetaFoldersLoading.next(false)
    }
    else if (type === METADATA_TYPE.TABLE) {
      // this.isMetaTablesLoading.next(true)
      this.metadataTables = this.metadataTables.filter(t=> t.ID !== data.ID)
      // this.isMetaTablesLoading.next(false)
    }
    else if (type === METADATA_TYPE.JOIN) {
      // this.isMetaJoinsLoading.next(true)
      this.querySpecificMetadata(METADATA_TYPE.JOIN)
      // this.isMetaJoinsLoading.next(false)
    }
  }

  public validateMeta(data: any, metadatatype: METADATA_TYPE, metadataaction?: METADATA_ACTION) {

    if (metadatatype === METADATA_TYPE.OBJECT)
      this.isMetaObjectValidating.next(true)
    else if (metadatatype === METADATA_TYPE.TABLE)
      this.isMetaTableValidating.next(true)
    else if (metadatatype === METADATA_TYPE.JOIN)
      this.isMetaJoinValidating.next(true)
    else if (metadatatype === METADATA_TYPE.FOLDER)
      this.isMetaFoldersLoading.next(true)

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'AuthCode': this.oktaToken.accessToken,
      'AuthCode2': this.oktaToken2.idToken,
    });
    let options = { headers: headers };
    let body = new URLSearchParams();
    body.set('TenantCode', this.userservice.getSelectedSite().SiteCode)
    body.set('Data', JSON.stringify(data));
    body.set('Operation', metadataaction) // UPDATE or INSERT
    body.set('MetaType', metadatatype)
    // http PUT method is for validation
    this.subscription = this.http.put<any>(`${environment.REST_API_SERVER}/metadata`, body, options).subscribe(x => {
      if (metadatatype === METADATA_TYPE.OBJECT) {

        if (x.Message === "Success")
          this.isMetaObjectValid.next({ Result: true, Message: "" })
        // Failure: ...
        else {
          this.isMetaObjectValid.next({ Result: false, Message: x.Message })
        }
        this.isMetaObjectValidating.next(false)
      }
      else if (metadatatype === METADATA_TYPE.TABLE) {

        if (x.Message === "Success")
          this.isMetaTableValid.next({ Result: true, Message: "" })
        else {
          this.isMetaTableValid.next({ Result: false, Message: x.Message })
        }
        this.isMetaTableValidating.next(false)
      }
      else if (metadatatype === METADATA_TYPE.JOIN) {

        if (x.Message === "Success")
          this.isMetaJoinValid.next({ Result: true, Message: "" })
        else {
          this.isMetaJoinValid.next({ Result: false, Message: x.Message })
        }
        this.isMetaJoinValidating.next(false)
      }
      else if (metadatatype === METADATA_TYPE.FOLDER) {

        if (x.Message === "Success")
          this.isMetaFolderValid.next({ Result: true, Message: "" })
        else {
          this.isMetaFolderValid.next({ Result: false, Message: x.Message })
        }
        this.isMetaFoldersLoading.next(false)
      }
    })
  }

  private formatObject(objects: OBJECT[]) {
    for (var o of objects) {
      o.Excerpt = this.formatSQL(o.Expression).substring(0, 150) + "..."
    }
    return objects
  }

  private formatTable(tables: TABLE[]) {
    for (var t of tables) {
      if (t.Expression.length > 150)
        t.Excerpt = this.formatSQL(t.Expression).substring(0, 150) + "..."
      else t.Excerpt = t.Expression
    }
    return tables
  }

  private formatJoin(joins: JOIN[]) {
    for (var j of joins) {
      j.Excerpt92 = "<span class='text-wrap'>" + (j.JoinExpression92.length > 250 ? j.JoinExpression92.substring(0, 250) + "..." : j.JoinExpression92) + "</span>"
      j.Excerpt89 = "<span class='text-wrap'>" + (j.JoinExpression89.length > 250 ? j.JoinExpression89.substring(0, 250) + "..." : j.JoinExpression89) + "</span>"
    }
    return joins
  }

  private formatSQL(str: string): string {
    try {
      //additinoal formatting is for tooltip innerHTML
      return format(str, { language: 'plsql' }).replace(/( )/gm, '&nbsp;&nbsp;').replace(/(\r\n|\n|\r)/gm, "<br />")
    }
    catch (e) {
      return str
    }
  }


}
