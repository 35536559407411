<coreui-card-container>
    <coreui-card class="Card-block" header="User Profile" [text]="cardTxt3" cardType="standard" icon="users">
        <ng-template #cardTxt3>
            <br />
            <div class="card-content text-style-3">
                Name: {{username}}<br />
                Authentication Method: Okta<br />
                Email Address: {{emailaddress}}<br />
                Max User Role: {{userRole}}
            </div>
        </ng-template>
    </coreui-card>
    <coreui-card class="Card-block" header="Tableau Access" [text]="cardTxt4" cardType="standard" icon="tableau">
        <ng-template #cardTxt4>
            <br />
            <div class="card-content text-style-3">
                Site(s):<br />
                @for(s of usersites | sitefilter: false | orderby: 'SiteName' : 'ASC'; track $index){
                {{s.SiteName}}<br />
                }
            </div>
        </ng-template>
    </coreui-card>
</coreui-card-container>