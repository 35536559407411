import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CoreUIModule } from '@epsilon/core-ui';
import { ACTION, COMPARISON_OPERATOR, DATA_TYPE, FILTER, FILTER_CONTAINER_TYPE, FILTER_METHOD, LOGICAL_OPERATOR, OBJECT_TYPE, OPERATOR, PARAM } from '../../service/datamodel';
import { CommonModule } from '@angular/common';
import { BuilderService } from '../../service/builder.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeFr, 'fr-CA', localeFrExtra);

@Component({
  selector: 'app-filtervalue',
  standalone: true,
  imports: [CoreUIModule, ReactiveFormsModule, CommonModule],
  templateUrl: './filtervalue.component.html',
  styleUrl: './filtervalue.component.scss'
})
export class FiltervalueComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() containerID: number;
  @Input() containerType: FILTER_CONTAINER_TYPE;
  private _item: FILTER
  @Input()
  set item(value: any) {
    this._item = value;
    this.builderservice.validateFilter(value)
  }
  get item(): any {
    return this._item;
  }
  @Output('filterSetEvent') filterSetEvent: EventEmitter<any> = new EventEmitter();

  public M_HANDWRITING: FILTER_METHOD;
  public M_LIKE_HANDWRITING: FILTER_METHOD;
  public M_DUAL_HANDWRITING: FILTER_METHOD;
  public M_SINGLE_SELECT: FILTER_METHOD;
  public M_MULTI_SELECT: FILTER_METHOD;
  public M_DATE: FILTER_METHOD;
  public M_DUAL_DATE: FILTER_METHOD;
  public M_TOGGLE: FILTER_METHOD;
  public M_DATETIME: FILTER_METHOD;
  public M_DUAL_DATETIME: FILTER_METHOD;
  public M_SUBQUERY: FILTER_METHOD;
  public M_IS_NULL: FILTER_METHOD;
  public M_IS_NOT_NULL: FILTER_METHOD;
  public DT_STRING: DATA_TYPE.STRING;
  public DT_NUMERIC: DATA_TYPE.NUMERIC;
  public myFormGroup = new FormGroup({})
  public minDate: Date
  public params: PARAM[] = []
  public isParamed: boolean
  private subFormChange: Subscription
  public isValid: boolean;

  constructor(private builderservice: BuilderService, private changeDetectorRef: ChangeDetectorRef) {
    this.minDate = new Date(2000, 1, 1, 0, 0, 0)
    this.M_HANDWRITING = FILTER_METHOD.HANDWRITING;
    this.M_LIKE_HANDWRITING = FILTER_METHOD.LIKE_HANDWRITING
    this.M_DUAL_HANDWRITING = FILTER_METHOD.DUAL_HANDWRITING;
    this.M_SINGLE_SELECT = FILTER_METHOD.SINGLE_SELECT;
    this.M_MULTI_SELECT = FILTER_METHOD.MULTI_SELECT
    this.M_DATE = FILTER_METHOD.DATE
    this.M_DUAL_DATE = FILTER_METHOD.DUAL_DATE;
    this.M_TOGGLE = FILTER_METHOD.TOGGLE;
    this.M_DATETIME = FILTER_METHOD.DATETIME;
    this.M_DUAL_DATETIME = FILTER_METHOD.DUAL_DATETIME;
    this.M_SUBQUERY = FILTER_METHOD.SUBQUERY;
    this.M_IS_NULL = FILTER_METHOD.IS_NULL;
    this.M_IS_NOT_NULL = FILTER_METHOD.IS_NOT_NULL;
    this.DT_STRING = DATA_TYPE.STRING;
    this.DT_NUMERIC = DATA_TYPE.NUMERIC;
    this.isParamed = false;
    this.isValid = true
  }

  ngOnInit(): void {

    if (this.subFormChange)
      this.subFormChange.unsubscribe()

    this.buildForm(this.item, ACTION.ADD)

    this.builderservice.arrParams.subscribe(arr => {

      var match = arr.filter(y => y.ID === this.item.ID)
      if (match.length > 0) {
        try {
          this.params = match[0].Params
          this.multiSearchSelectAllFilteredItems$.next(this.params)
          this.setMultiValues()
        }
        catch (e) {
          this.params = []
          this.multiSearchSelectAllFilteredItems$.next([])
          this.setMultiValues()
        }
      }
    })

    this.subFormChange = this.myFormGroup.valueChanges.subscribe(val => this.onFilterValueChange(val))

  }

  ngAfterViewInit(): void {
    // this.patchForm()
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      //this.builderservice.updateSelectedFilterValue(this.containerType, this.containerID, this.item)
    }
  }

  private buildForm(obj: FILTER, action?: ACTION): void {
    if (this.subFormChange)
      this.subFormChange.unsubscribe()

    //start with a clean slat
    if (action === ACTION.INIT) {
    }

    else if (obj && action === ACTION.ADD) {
      this.myFormGroup.setControl('filter_multivalue', new FormControl(this.primeValues(obj.FilterValue, this.M_MULTI_SELECT), [Validators.required]))
      this.myFormGroup.setControl('filter_value', new FormControl(this.primeValues(obj.FilterValue, this.item.FilterMethod), [Validators.required]))
      this.myFormGroup.setControl('filter_value2', new FormControl(this.primeValues(obj.FilterValue2, this.item.FilterMethod), [Validators.required]))
    }

    else if (obj && action === ACTION.DELETE) {
      this.myFormGroup.removeControl('filter_multivalue')
      this.myFormGroup.removeControl('filter_value')
      this.myFormGroup.removeControl('filter_value2')
    }

    this.subFormChange = this.myFormGroup.valueChanges.subscribe(val => this.onFilterValueChange(val))

  }

  // private patchForm() {
  //   this.myFormGroup.controls["filter_value"].setValue(this.item.FilterValue)
  //   this.myFormGroup.controls["filter_value"].patchValue(this.item.FilterValue)
  // }

  private primeValues(val: any, selectType: FILTER_METHOD): any {
    if (selectType === this.M_MULTI_SELECT) { // ["value1", "value2"]
      if (Array.isArray(val))
        return val.map(v => { return { Name: v, Value: v } })
      else
        return []
    }
    else if (selectType === this.M_DATETIME || selectType === this.M_DUAL_DATETIME) {
      return new Date(val)
    }
    else
      if (Array.isArray(val))
        return undefined
      else
        return val ?? undefined
  }

  private onFilterValueChange(val) {
    this.readFromControl()
    this.builderservice.updateSelectedFilterValue(this.containerType, this.containerID, this.item)
  }

  private readFromControl() {
    var item: FILTER = this.item
    var value = this.myFormGroup.controls['filter_value'].value
    var multivalue = this.myFormGroup.controls['filter_multivalue'].value
    var value2 = this.myFormGroup.controls['filter_value2'].value
    if (item.FilterMethod === this.M_MULTI_SELECT) {
      var actualValue = multivalue.map(v => { return v.Value })
      item.FilterValue = actualValue || []
    }
    else if (item.FilterMethod === this.M_DATE || item.FilterMethod === this.M_DUAL_DATE || item.FilterMethod === this.M_DATETIME || item.FilterMethod === this.M_DUAL_DATETIME) {
      item.FilterValue = value || undefined
      item.FilterValue2 = value2 || undefined
    }
    else {
      item.FilterValue = value ?? undefined
      item.FilterValue2 = value2 ?? undefined
    }

    // this.filterSetEvent.emit(item)
  }

  //Multi-select related
  /////////////////////////////////////////////////////////////////////////////////////////////////
  private _multiSearchSelectAllFilteredItems: BehaviorSubject<PARAM[]> =
    new BehaviorSubject<PARAM[]>([]);

  private _multiSearchSelectAllSelectedItems: BehaviorSubject<PARAM[]> =
    new BehaviorSubject<PARAM[]>([]);

  public get multiSearchSelectAllFilteredItems$(): BehaviorSubject<PARAM[]> {
    return this._multiSearchSelectAllFilteredItems;
  }
  public get multiSearchSelectAllSelectedItems$(): BehaviorSubject<PARAM[]> {
    return this._multiSearchSelectAllSelectedItems;
  }

  public strSelectedOptions: string[];

  public onSearchChange(searchText): void {

    const multiSearchSelectAllSelectedItems = this
      .myFormGroup.controls['filter_multivalue']
      .value as PARAM[];
    multiSearchSelectAllSelectedItems.sort();

    const multiSearchSelectAllFilteredItems = this.params
      .filter(
        (item) =>
          multiSearchSelectAllSelectedItems.indexOf(item) < 0 &&
          item.Value.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      .sort();

    const multiSearchSelectAllFilteredSelectedItems =
      multiSearchSelectAllSelectedItems
        .filter(
          (item) =>
            item.Name.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1
        )
        .sort();

    this.multiSearchSelectAllFilteredItems$.next(
      multiSearchSelectAllFilteredItems.map(i => { return i })
    );
    this.multiSearchSelectAllSelectedItems$.next(
      multiSearchSelectAllFilteredSelectedItems
    );
  }

  public onSelectBlur(): void {
    this.setMultiValues();
  }

  private setMultiValues(): void {

    //selected items
    const multiSelectSearchSelectAllItemsSelected = this.myFormGroup.controls['filter_multivalue']
      .value as PARAM[];

    // if something is selected
    if (multiSelectSearchSelectAllItemsSelected) {
      // sort it
      multiSelectSearchSelectAllItemsSelected.sort();
      // find filtered items = all items - selected items
      const multiSelectSearchSelectAllFilteredItems = this.params
        .filter(
          (item) =>
            multiSelectSearchSelectAllItemsSelected.findIndex(i => i.Value === item.Value) < 0
        )
        .sort();

      // set selected items
      this.multiSearchSelectAllSelectedItems$.next(
        multiSelectSearchSelectAllItemsSelected.filter(
          (item) => item !== undefined
        )
      );
      // string version of selected items
      this.strSelectedOptions = multiSelectSearchSelectAllItemsSelected.map(i => { return i.Name })

      // set filtered items = all items - selected items
      this.multiSearchSelectAllFilteredItems$.next(
        multiSelectSearchSelectAllFilteredItems.map(i => { return i })
      );
    }

    //this.changeDetector.detectChanges();
  }

  public getSelectedItems(): void {
    const multiSelectSearchSelectAllItemsSelected = this
      .myFormGroup.controls['filter_multivalue_' + this.containerType + '_' + this.containerID + '_' + this.item.FilterID]
      .value as PARAM[];
    multiSelectSearchSelectAllItemsSelected.sort();
    const multiSelectSearchSelectAllFilteredItems = this.params
      .filter(
        (item) =>
          multiSelectSearchSelectAllItemsSelected.indexOf(item) < 0
      )
      .sort();
    this.multiSearchSelectAllSelectedItems$.next(
      multiSelectSearchSelectAllItemsSelected
    );
    this.multiSearchSelectAllFilteredItems$.next(
      multiSelectSearchSelectAllFilteredItems.map(i => { return i })
    );
  }

  ngOnDestroy(): void {
    if (this.subFormChange)
      this.subFormChange.unsubscribe()
  }


}